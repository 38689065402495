// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import {
  GET_UNIFORM,
  useGetUniformCheckout,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateUniformCheckout } from 'gql/mutations';

// Local Dependencies
import UniformCheckoutForm, { UniformCheckoutFormValues } from '../shared/UniformCheckoutForm';

// Local Variables

// Component Definition
const UniformCheckoutEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const [uniformId, setUniformId] = useState<string | null>(null);

  const navigateToUniformShow = useCallback(() => {
    navigate(`/${PATHS.UNIFORMS}/${uniformId}`);
  }, [navigate, uniformId]);

  const {
    data: uniformCheckoutData,
    loading: isUniformCheckoutLoading,
  } = useGetUniformCheckout(id);

  const [updateUniformCheckout] = useUpdateUniformCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: navigateToUniformShow,
      refetchQueries: () => [
        { query: GET_UNIFORM, variables: { id: uniformId } },
      ],
    },
  );

  const handleSubmit = async (values: UniformCheckoutFormValues) => {
    await updateUniformCheckout({
      variables: {
        ...values,
        id: id!,
      },
    });
  };

  useEffect(() => {
    setUniformId(uniformCheckoutData?.uniformCheckout.uniform.id ?? null);
  }, [uniformCheckoutData]);

  return (
    <Page isLoading={isUniformCheckoutLoading}>
      {uniformCheckoutData?.uniformCheckout && (
        <UniformCheckoutForm
          onSubmit={handleSubmit}
          title="Edit Uniform Checkout"
          uniform={uniformCheckoutData.uniformCheckout.uniform}
          uniformCheckout={uniformCheckoutData.uniformCheckout}
          user={uniformCheckoutData.uniformCheckout.user}
        />
      )}
    </Page>
  );
};

export default UniformCheckoutEdit;
