// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  Container,
  History,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialAccount, useGetOrganization } from 'gql/queries';
import { useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import OnlinePaymentsAlert from '../shared/OnlinePaymentsAlert';

// Component Definition
const AccountsShowPage: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetFinancialAccount(id!);

  const {
    data: orgData,
  } = useGetOrganization();

  const areOnlinePaymentsEnabledForOrg = orgData?.organization.areOnlinePaymentsEnabled;

  const isOnlinePaymentsFeatureAvailable = useIsOnlinePaymentsFeatureAvailable();

  const canEditAccounts = useSelector(hasPermission('financialAccounts', 'edit'));

  const editPath = canEditAccounts
    ? `/${PATHS.FINANCIAL_ACCOUNTS}/${id}/edit` : undefined;

  return (
    <Page
      backButtonProps={{
        label: 'Financial Overview',
        path: `/${PATHS.FINANCIAL_OVERVIEW}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={!data || loading}
    >
      <Container>
        <ShowCard
          icon={AccountBalance}
          isLoading={loading}
          title="Financial Account"
        >
          <ShowPageDataDisplay
            label="Name"
            value={data?.financialAccount.label}
          />

          {isOnlinePaymentsFeatureAvailable && (
            <ShowPageDataDisplay
              label="Online Payments Allowed"
              type="boolean"
              value={data?.financialAccount.onlinePaymentsEnabled}
            />
          )}

          <ShowPageDataDisplay
            label="Can Apply Fundraiser Credits"
            type="boolean"
            value={data?.financialAccount.canApplyFundraiserCredits}
          />

          {!areOnlinePaymentsEnabledForOrg
            && (
              <Box marginTop={2}>
                <OnlinePaymentsAlert />
              </Box>
            )}
        </ShowCard>

        {data?.financialAccount && (
          <History
            id={data.financialAccount.id}
            resource="financialAccounts"
          />
        )}
      </Container>
    </Page>
  );
};

export default AccountsShowPage;
