// External Dependencies
import { ButtonProps } from '@mui/material/Button';
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';

// Local Dependencies
import SubscriberOnlyButton from './SubscriberOnlyButton';

// Local Typings
export interface SubscriberAddButtonProps extends ButtonProps {
  disabled?: boolean;
  hideIcon?: boolean;
  label: string;
  marginRight?: number;
  onClick?: () => void;
  to?: string;
}

interface StyledSubscriberOnlyButtonProps {
  $marginRight?: number;
}

// Local Variables
const StyledSubscriberOnlyButton = styled(SubscriberOnlyButton)<StyledSubscriberOnlyButtonProps>(({
  $marginRight,
}) => ({
  '&.button': {
    marginRight: $marginRight,
  },
}));

// Component Definition
const SubscriberAddButton = ({
  disabled,
  hideIcon,
  label,
  marginRight,
  onClick,
  to,
  ...otherProps
}: SubscriberAddButtonProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (to) {
      navigate(to);
    }
  }, [onClick, navigate, to]);

  return (
    <StyledSubscriberOnlyButton
      $marginRight={marginRight}
      aria-label={`Add ${label}`}
      className="button"
      color="primary"
      disabled={disabled}
      onClick={handleClick}
      size="small"
      startIcon={hideIcon ? null : <AddIcon fontSize="small" />}
      variant="outlined"
      {...otherProps}
    >
      {label}
    </StyledSubscriberOnlyButton>
  );
};

export default SubscriberAddButton;
