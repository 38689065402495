// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useTheme } from 'styled-components';
import AccountCheckIcon from 'mdi-material-ui/AccountCheck';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetMyChildrenUnderThirteen } from 'gql/queries';

// Component Definition
const MyChildrenUnder13Card = (): JSX.Element | null => {
  const navigate = useNavigate();

  const {
    palette: {
      history,
    },
  } = useTheme();

  const { data } = useGetMyChildrenUnderThirteen();

  const handleClickInviteFamily = useCallback(() => {
    navigate(PATHS.PROFILE);
  }, [navigate]);

  const iconElement = useMemo(() => (
    <ZeroStateIcon
      Icon={AccountCheckIcon}
      htmlColor={history.showMoreButton}
    />
  ), [history]);

  const contentElement = useMemo(() => (
    <>
      {iconElement}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Authorize your family members under 13 years old
          to use {APP_NAME}.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleClickInviteFamily}
        size="small"
        variant="outlined"
      >
        Go to Profile Page
      </Button>
    </>
  ), [handleClickInviteFamily, iconElement]);

  // Check if any of the myChildrenUnderThirteen need parental consent
  const hasChildrenUnder13WithoutParentalConsent = data?.myChildrenUnderThirteen.some(
    (child) => child.parentalConsent === null,
  );

  if (!hasChildrenUnder13WithoutParentalConsent) {
    return null;
  }

  return (
    <DashboardCard
      content={contentElement}
      horizontallyCenterContent
      title="Children Under 13"
      verticallyCenterContent
    />
  );
};

export default MyChildrenUnder13Card;
