// External Dependencies
import {
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import { waiveFinancialFeeSchema } from '@presto-assistant/api_types/schemas/financialFees';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Box from '@mui/material/Box';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import Collapse from '@mui/material/Collapse';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import LinearProgress from '@mui/material/LinearProgress';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import TextBoxCheckIcon from 'mdi-material-ui/TextBoxCheck';
import TextBoxIcon from 'mdi-material-ui/TextBox';
import TextBoxSearchIcon from 'mdi-material-ui/TextBoxSearch';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import {
  DELETE_FINANCIAL_FEE,
  WAIVE_FINANCIAL_FEE,
  useDeleteFinancialFees,
  useUnwaiveFinancialFee, useWaiveFinancialFee,
} from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import {
  createDataGridActionsColumn,
} from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/uncategorizedFinancialFeeUploadDialog/actions';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useGetFinancialFeesIndex,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';
import DialogUncategorizedFinancialFeeFileUpload
  from 'components/shared/DialogUncategorizedFinancialFeeFileUpload';
import EnhancedAlert from 'components/shared/EnhancedAlert';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import { SELECTION_TYPES } from 'utils/constants';
import { useColumns } from './hooks';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Local Variables
const waiveFeeClearCachePredicates = ['financialFeesIndex', 'financialFeesOverview'];
const waiveFeeContext: [string] = ['fee'];

// Component Definition
const FinancialFeesTable = ({
  schoolYearEnding,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteNoteFieldError, setDeleteNoteFieldError] = useState<string | null>(null);
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);
  const [itemToWaiveId, setItemToWaiveId] = useState<string | null>(null);
  const [itemToUnwaiveId, setItemToUnwaiveId] = useState<string | null>(null);
  const [memberIdsToEmail, setMemberIdsToEmail] = useState<string[] | null>(null);
  const [selectedFeeWaiveProgress, setSelectedFeeWaiveProgress] = useState(0);

  const canWritePayments = useSelector(hasPermission('payments', 'write'));
  const canDeleteFinances = useSelector(hasPermission('finances', 'delete'));
  const canReadMembers = useSelector(hasPermission('users', 'read'));
  const canWaiveFees = useSelector(hasPermission('finances', 'edit'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));

  const deleteNoteField = useTextField();

  const [deleteFinancialFees] = useDeleteFinancialFees();
  const [waiveFinancialFee] = useWaiveFinancialFee();

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedFeeIds,
    setSelectedFeeIds,
  ] = useState<GridRowSelectionModel>([]);

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleDeleteDialog,
  } = useIsOpen();
  const {
    isOpen: isWaiveDialogOpen,
    toggleIsOpen: toggleWaiveDialog,
  } = useIsOpen();
  const {
    isOpen: isWaiveSelectedFeesDialogOpen,
    toggleIsOpen: toggleWaiveSelectedFeesDialog,
  } = useIsOpen();
  const {
    isOpen: isDeleteSelectedFeesDialogOpen,
    toggleIsOpen: toggleDeleteSelectedFeesDialog,
  } = useIsOpen();
  const {
    isOpen: isUnwaiveDialogOpen,
    toggleIsOpen: toggleUnwaiveDialog,
  } = useIsOpen();
  const {
    handleOpen: handleOpenSendStatementDialog,
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();
  const {
    isOpen: isWaivingSelectedFees,
    toggleIsOpen: toggleIsWaivingSelectedFees,
  } = useIsOpen();
  const {
    isOpen: isDeletingSelectedFees,
    toggleIsOpen: toggleIsDeletingSelectedFees,
  } = useIsOpen();

  useEffect(() => {
    setDeleteNoteFieldError(null);
  }, [deleteNoteField.value]);

  const handleCancelWaiveSelectedFees = useCallback(() => {
    toggleWaiveSelectedFeesDialog();
    deleteNoteField.onReset();
  }, [deleteNoteField, toggleWaiveSelectedFeesDialog]);

  const handleCancelDeleteSelectedFees = useCallback(() => {
    toggleDeleteSelectedFeesDialog();
    deleteNoteField.onReset();
  }, [deleteNoteField, toggleDeleteSelectedFeesDialog]);

  const {
    data,
    isLoading,
  } = useGetFinancialFeesIndex(schoolYearEnding);

  const handleWaiveSelectedFees = useCallback(async () => {
    try {
      toggleIsWaivingSelectedFees();

      if (deleteNoteField.value.trim().length < 5) {
        setDeleteNoteFieldError('Please provide a reason for waiving the fee that is at least 5 characters long.');
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const selectedFeeId of selectedFeeIds) {
        // eslint-disable-next-line no-await-in-loop
        await waiveFinancialFee({
          variables: {
            input: {
              financialFeeId: selectedFeeId as string,
              waivedReason: deleteNoteField.value,
            },
          },
        });

        setSelectedFeeWaiveProgress((prevProgress) => prevProgress + 1);
      }

      toggleWaiveSelectedFeesDialog();
      dispatch(addNotification('Fees waived successfully', 'success'));
      deleteNoteField.onReset();
      setSelectedFeeIds([]);
    } finally {
      toggleIsWaivingSelectedFees();
      setSelectedFeeWaiveProgress(0);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    }
  }, [
    deleteNoteField,
    dispatch,
    selectedFeeIds,
    toggleIsWaivingSelectedFees,
    toggleWaiveSelectedFeesDialog,
    waiveFinancialFee,
  ]);

  const handleDeleteSelectedFees = useCallback(async () => {
    try {
      const selectedData = data?.filter((row) => selectedFeeIds.includes(row.id)) ?? [];
      const isEveryFeeDeletable = selectedData.every((row) => row.canBeDeleted) ?? false;

      const feeWord = pluralize(selectedFeeIds.length, 'fee');

      toggleIsDeletingSelectedFees();

      if (!isEveryFeeDeletable) {
        dispatch(addNotification('Some selected fees cannot be deleted. Apply the filter for "Can Be Deleted" to only see deletable fees.', 'error'));
        return;
      }

      if (deleteNoteField.value.trim().length < 5) {
        setDeleteNoteFieldError(`Please provide a reason for deleting the ${feeWord} that is at least 5 characters long.`);
        return;
      }

      await deleteFinancialFees({
        variables: {
          deletedNote: deleteNoteField.value,
          selection: {
            ids: selectedFeeIds as string[],
            mutationFlag: SELECTION_TYPES.SELECTED_MANY as GQL.MutationFlag,
            queryParams: {},
          },
        },
      });

      toggleDeleteSelectedFeesDialog();
      dispatch(addNotification('Fees deleted successfully', 'success'));
      deleteNoteField.onReset();
      setSelectedFeeIds([]);
    } finally {
      toggleIsDeletingSelectedFees();
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    }
  }, [
    data,
    deleteFinancialFees,
    deleteNoteField,
    dispatch,
    selectedFeeIds,
    toggleDeleteSelectedFeesDialog,
    toggleIsDeletingSelectedFees,
  ]);

  const handleClickSendStatements = useCallback((memberIds: string[] | null) => () => {
    setMemberIdsToEmail(memberIds);
    handleOpenSendStatementDialog();
  }, [handleOpenSendStatementDialog]);

  const handleClickWaiveSelectedFees = useCallback(() => {
    toggleWaiveSelectedFeesDialog();
  }, [toggleWaiveSelectedFeesDialog]);

  const handleClickDeleteSelectedFees = useCallback(() => {
    toggleDeleteSelectedFeesDialog();
  }, [toggleDeleteSelectedFeesDialog]);

  const [
    unwaiveFinancialFee,
    {
      loading: isUnwaivingFinancialFee,
    },
  ] = useUnwaiveFinancialFee({
    onCompleted: () => {
      toggleUnwaiveDialog();
      setItemToUnwaiveId(null);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleClickViewFinancialItem = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}/${row.financialItemId}`);
  }, [navigate]);

  const handleClickViewMember = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.MEMBERS}/${row.userId}`);
  }, [navigate]);

  const handleNavigateToPaymentPage = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_NEW}?user_id=${row.userId}`);
  }, [navigate]);

  const handleOpenDialogUncategorizedFinancialFeeFileUpload = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setSingleItemDeleteId(row.id);
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  const handleOpenWaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToWaiveId(row.id);
    toggleWaiveDialog();
  }, [toggleWaiveDialog]);

  const handleOpenUnwaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToUnwaiveId(row.id);
    toggleUnwaiveDialog();
  }, [toggleUnwaiveDialog]);

  const handleUnwaiveFee = useCallback(() => {
    if (itemToUnwaiveId) {
      unwaiveFinancialFee({
        variables: {
          input: {
            financialFeeId: itemToUnwaiveId,
          },
        },
      });
    }
  }, [
    itemToUnwaiveId,
    unwaiveFinancialFee,
  ]);

  const formatWaiveFeePayload = useCallback<DeleteDialogV2Props<GQL.IWaiveFinancialFeeOnMutationArguments>['formatPayload']>((note) => ({
    input: {
      financialFeeId: itemToWaiveId ?? '',
      waivedReason: note ?? '',
    },
  }), [itemToWaiveId]);

  const filteredMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        filteredRowIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, filteredRowIds],
  );

  const selectedMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        selectedFeeIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, selectedFeeIds],
  );

  const toolbarActions = useMemo<IToolbarAction[]>(() => [
    ...(canWriteFinances ? [{
      action: handleOpenDialogUncategorizedFinancialFeeFileUpload,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import fees',
    }] : []),
    {
      action: handleClickSendStatements(null),
      icon: <TextBoxIcon />,
      text: 'Send statements to everyone',
    },
    {
      action: handleClickSendStatements(selectedMemberIds),
      icon: <TextBoxCheckIcon />,
      isDisabled: selectedMemberIds.length === 0,
      text: `Send statements to selected (${selectedMemberIds.length})`,
    },
    {
      action: handleClickSendStatements(filteredMemberIds),
      icon: <TextBoxSearchIcon />,
      isDisabled: filteredMemberIds.length === 0,
      text: `Send statements to filtered (${filteredMemberIds.length})`,
    },
    ...(canWaiveFees ? [{
      action: handleClickWaiveSelectedFees,
      icon: <DoDisturbIcon />,
      isDisabled: selectedFeeIds.length === 0,
      text: `Waive selected fees (${selectedFeeIds.length})`,
    }] : []),
    ...(canDeleteFinances ? [{
      action: handleClickDeleteSelectedFees,
      icon: <DeleteIcon />,
      isDisabled: selectedFeeIds.length === 0,
      text: `Delete selected fees (${selectedFeeIds.length})`,
    }] : []),
  ], [
    canDeleteFinances,
    canWaiveFees,
    canWriteFinances,
    filteredMemberIds,
    handleClickDeleteSelectedFees,
    handleClickSendStatements,
    handleClickWaiveSelectedFees,
    handleOpenDialogUncategorizedFinancialFeeFileUpload,
    selectedFeeIds,
    selectedMemberIds,
  ]);

  const extraColumns = useMemo<DataGridColDef<GQL.IFinancialFeeIndexItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IFinancialFeeIndexItem>([
        ...(canWritePayments ? [{
          action: handleNavigateToPaymentPage,
          icon: <AttachMoney />,
          text: 'Add a Payment',
        }] : []),
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View Member',
        }] : []),
        {
          action: handleClickViewFinancialItem,
          icon: <ReceiptIcon />,
          text: 'View Item',
        },
        ...(canWaiveFees ? [
          {
            action: handleOpenWaiveFeeDialog,
            icon: <DoDisturbIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => !row.isWaived,
            text: 'Waive Fee',
          },
          {
            action: handleOpenUnwaiveFeeDialog,
            icon: <AddShoppingCartIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => row.isWaived,
            text: 'Unwaive Fee',
          },
        ] : []),
        ...(canDeleteFinances ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <DeleteIcon />,
          isDisabled: (row: GQL.IFinancialFeeIndexItem) => !row.canBeDeleted,
          text: 'Delete',
        }] : []),
      ]) as DataGridColDef<GQL.IFinancialFeeIndexItem> | null;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteFinances,
      canReadMembers,
      canWaiveFees,
      canWritePayments,
      handleClickViewFinancialItem,
      handleClickViewMember,
      handleNavigateToPaymentPage,
      handleOpenSingleItemDeleteDialog,
      handleOpenUnwaiveFeeDialog,
      handleOpenWaiveFeeDialog,
    ],
  );

  const columnArgs = useMemo(() => ({
    extraColumns,
    schoolYearEnding,
  }), [
    extraColumns,
    schoolYearEnding,
  ]);

  const columns = useColumns(columnArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWriteFinances ? {
            label: 'Fee',
            to: `/${PATHS.FINANCIAL_FEES_NEW}`,
          } : null}
          checkboxSelection
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          key={schoolYearEnding}
          loading={isLoading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedFeeIds}
          rows={data}
          selectionModel={selectedFeeIds}
          tableResource={`financialFees-${schoolYearEnding}`}
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <SendFinancialStatementDialog
        isOpen={isSendStatementDialogOpen}
        memberIds={memberIdsToEmail}
        onClose={toggleSendStatementDialog}
      />

      <DialogUncategorizedFinancialFeeFileUpload />

      <DeleteDialogV2<GQL.IWaiveFinancialFeeOnMutationArguments>
        actionVerb="Waive"
        clearCachePredicates={waiveFeeClearCachePredicates}
        context={waiveFeeContext}
        descriptionText="The member will no longer be able to make payments for this fee."
        formatPayload={formatWaiveFeePayload}
        isOpen={isWaiveDialogOpen}
        mutation={WAIVE_FINANCIAL_FEE}
        onClose={toggleWaiveDialog}
        validationSchemaProps={{
          selectPayloadToValidate: (mutationPayload) => mutationPayload.input,
          validationSchema: waiveFinancialFeeSchema as any,
        }}
        withNote
      />

      <ConfirmationDialog
        confirmButtonAction={handleWaiveSelectedFees}
        declineButtonAction={handleCancelWaiveSelectedFees}
        deletedNoteInputProps={isWaivingSelectedFees ? undefined : deleteNoteField}
        description={isWaivingSelectedFees ? (
          <>
            <Typography paragraph>
              Waiving {selectedFeeIds.length} {pluralize(selectedFeeIds.length, 'fee')}...
            </Typography>

            <LinearProgress
              color="primary"
              sx={{ marginY: 2 }}
              value={(selectedFeeWaiveProgress / selectedFeeIds.length) * 100}
              variant="determinate"
            />
          </>
        ) : (
          <>
            <Typography>
              Would you like to waive the selected fees ({selectedFeeIds.length})?
            </Typography>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        handleClose={toggleWaiveSelectedFeesDialog}
        isSubmitting={isWaivingSelectedFees}
        open={isWaiveSelectedFeesDialogOpen}
        title="Waive Fees?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleDeleteSelectedFees}
        declineButtonAction={handleCancelDeleteSelectedFees}
        deletedNoteInputProps={isDeletingSelectedFees ? undefined : deleteNoteField}
        description={isDeletingSelectedFees ? (
          <Typography paragraph>
            Deleting {selectedFeeIds.length} {pluralize(selectedFeeIds.length, 'fee')}...
          </Typography>
        ) : (
          <>
            <Typography>
              Would you like to delete the selected fees ({selectedFeeIds.length})?
            </Typography>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        handleClose={toggleDeleteSelectedFeesDialog}
        isSubmitting={isDeletingSelectedFees}
        open={isDeleteSelectedFeesDialogOpen}
        title="Delete Fees?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleUnwaiveFee}
        description="This member will be reassigned the fee."
        handleClose={toggleUnwaiveDialog}
        isSubmitting={isUnwaivingFinancialFee}
        open={isUnwaiveDialogOpen}
        title="Unwaive Fee?"
      />

      <DeleteDialog
        clearCachePredicates={['financialFeesIndex']}
        context={['fee']}
        isOpen={isDeleteDialogOpen}
        mutation={DELETE_FINANCIAL_FEE}
        onClose={toggleDeleteDialog}
        reduxTableKey={`financialFees-${schoolYearEnding}`}
        singleItemId={singleItemDeleteId}
        size={data?.length ?? 0}
        withNote
      />
    </>
  );
};

export default FinancialFeesTable;
