// External Dependencies
import { FC, useCallback } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import styled from 'styled-components';

// Internal Dependencies
import {
  Flex,
  Heading1,
  PrestoLogoHorizontalShortSvg,
  PrestoLogoHorizontalSvg,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getGradientBackground } from 'utils/lib/getGradientBackground';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Variables
const StyledAppBar = styled(AppBar)<{ $fontColor: string; }>(({
  $fontColor,
  theme,
}) => ({
  '.MuiButton-outlinedSecondary': {
    '&:hover': {
      backgroundColor: alpha($fontColor, 0.2),
      border: `1px solid ${$fontColor}`,
    },
    backgroundColor: alpha($fontColor, theme.palette.action.hoverOpacity),
    border: `1px solid ${$fontColor}`,
    color: $fontColor,
  },

  '.logo': {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: theme.spacing(18),
    },
    margin: 'inherit',
    width: theme.spacing(25),
  },

  '.logo-long': {
    margin: 'inherit',
    width: theme.spacing(30),
  },

  background: getGradientBackground(theme),
  left: 0,
  padding: theme.spacing(2),
  position: 'absolute',
  top: 0,
}));

// Component Definition
const SignupAppBar: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleReturnToLoginPage = useCallback(() => {
    navigate(`/${PATHS.LOGIN}`);
  }, [navigate]);

  const fontColor = theme.palette.mode === 'dark'
    ? theme.palette.common.black : theme.palette.common.white;

  return (
    <StyledAppBar
      $fontColor={fontColor}
      className="appBar"
      elevation={0}
    >
      <Flex justifyContent="space-between">
        <Heading1>
          {isMobileOrTabletScreen
            ? (
              <PrestoLogoHorizontalShortSvg
                htmlColor={fontColor}
                rootClasses="logo"
              />
            ) : (
              <PrestoLogoHorizontalSvg
                htmlColor={fontColor}
                rootClasses="logo-long"
              />
            )}
        </Heading1>
        <Button
          color="secondary"
          onClick={handleReturnToLoginPage}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          startIcon={<ArrowBackIcon fontSize="small" />}
          variant="outlined"
        >
          Back to Login
        </Button>
      </Flex>
    </StyledAppBar>
  );
};

export default SignupAppBar;
