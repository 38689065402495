// External Dependencies
import * as Sentry from '@sentry/react';
import {
  checkIsCurrentVersionValid,
} from '@presto-assistant/api_types/utils/checkCurrentVersionValid';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { ContextData } from 'types/context';
import { FullAppMessageBanner } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { addSelfData } from 'state/self/actions';
import {
  bannerAlertItems as bannerAlertItemsSelector,
  hasBannerAlerts as hasBannerAlertsSelector,
} from 'state/bannerAlerts/selectors';
import { getAdminCookie, getToken } from 'utils/cookies';
import {
  isDesktopScreenSize,
  isMobileScreenSize,
} from 'state/device/selectors';
import { minimumWebAppVersion as minimumWebAppVersionSelector } from 'state/self/selectors';
import { onboardingRedirectUser } from 'utils/lib/onboarding_redirect_user';
import { updateDocumentTitle } from 'utils';
import { useIsAliasUser } from 'hooks/useIsAliasUser';
import AlertBanner from 'components/shared/AlertBanner';
import Footer from 'components/shared/Footer';
import UpdatePasswordInterstitial from 'components/shared/Interstitials/UpdatePasswordInterstitial';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { useLocation, useNavigate } from '@reach/router';
import ReadonlyBanner from './ReadonlyBanner';
import SideNav from './SideNav';
import TitleBar from './TitleBar';
import packageJson from '../../../../package.json';

// Local Typings
interface Props {
  children: React.ReactNode;
  title: string;
}

// Local Variables
const StyledRoot = styled.main(({ theme }) => ({
  '.childrenContainer': {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1.5, 3),
    },
    borderRadius: `0 0 ${theme.shape.borderRadius}px 0`,
    flex: '1 0 auto',
    overflowY: 'auto',
    padding: '16px 16px 24px',
  },
  '.innerContainer': {
    display: 'flex',
    margin: '0 auto',
  },
  '.innerDesktopContainer': {
    boxSizing: 'border-box',
    flexGrow: 1,
    padding: '16px 16px 4px',
    width: '100%',
    zIndex: 2,
  },
  '.innerMobileContainer': {
    boxSizing: 'border-box',
    flexGrow: 1,
    width: '100%',
  },
  '.paper': {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  '.rightPanel': {
    backgroundColor: theme.palette.mainContentBackground,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  background: theme.palette.appBackground,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

// Component Definition
const PrivateRouteContainer = ({
  children,
  title,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bannerAlertItems = useSelector(bannerAlertItemsSelector);
  const hasBannerAlerts = useSelector(hasBannerAlertsSelector);
  const isMobileScreen = useSelector(isMobileScreenSize);
  const isDesktopScreen = useSelector(isDesktopScreenSize);

  const isAliasUser = useIsAliasUser();

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const { loading: isLoadingSelf, self } = useSelfQuery();

  const { pathname } = useLocation();

  const minimumWebAppVersion = useSelector(minimumWebAppVersionSelector);

  useEffect(() => {
    updateDocumentTitle(title);
  }, [title]);

  useEffect(() => {
    if (self) {
      dispatch(addSelfData(self));

      // Set the member id in Sentry
      Sentry.setUser({ id: self.id });
    } else {
      // Clear the member id in Sentry
      Sentry.setUser(null);
    }

    // Check to see if user should be redirected to onboarding
    // This could happen if the user is signed in
    //  and their data has been changed
    if (self && !self.onboarding.isComplete) {
      onboardingRedirectUser(
        navigate,
        self.id,
        self.currentOrgId,
        self.hasDistrict,
        self.onboarding.stages,
        self.alertFlagId,
        pathname,
      );
    }
  }, [dispatch, navigate, pathname, self]);

  const { version } = packageJson;

  useEffect(() => {
    if (minimumWebAppVersion !== '' && version) {
      const isCurrentVersionValid = checkIsCurrentVersionValid(
        {
          currentVersion: version as `${string}.${string}.${string}`,
          minRequiredVersion: minimumWebAppVersion as `${string}.${string}.${string}`,
        },
      );

      if (!isCurrentVersionValid) {
        dispatch(addNotification('A new version of the web app is available. Please refresh your browser.', 'info', null));
      }
    }
  }, [minimumWebAppVersion, version, dispatch]);

  const isLoggedIn = Boolean(getToken());

  const toggleSideNav = useCallback(() => {
    setIsSideNavOpen((v) => !v);
  }, []);

  if (!isLoggedIn || !self || isLoadingSelf) {
    return null;
  }

  const renderAlerts = () => {
    // We only show the app-level alerts on dashboard and settings pages
    if ([`/${PATHS.DASHBOARD}`, `/${PATHS.SETTINGS}`].includes(window.location.pathname)) {
      return (
        hasBannerAlerts && (
          <>
            {bannerAlertItems.map((alert: ContextData.IAlertDetail) => (
              <AlertBanner
                alert={alert}
                key={alert.id}
              />
            ))}
          </>
        )
      );
    }
    return null;
  };

  const isPrestoAdmin = Boolean(getAdminCookie());

  const isActingAsAnotherUser = isPrestoAdmin || isAliasUser;

  const deviceContainerClass = isMobileScreen
    ? 'innerMobileContainer'
    : 'innerDesktopContainer';

  return (
    <>
      {!self.hasCustomPassword && !isActingAsAnotherUser && <UpdatePasswordInterstitial />}

      {isActingAsAnotherUser && <ReadonlyBanner isPrestoAdmin={isPrestoAdmin} />}

      <StyledRoot>
        <FullAppMessageBanner />

        <div className={clsx('innerContainer', deviceContainerClass)}>
          <Paper
            className="paper"
            variant="outlined"
          >
            {isDesktopScreen ? (
              <SideNav />
            ) : (
              <Drawer
                onClose={toggleSideNav}
                open={isSideNavOpen}
              >
                <SideNav onLinkClick={toggleSideNav} />
              </Drawer>
            )}

            <section className="rightPanel">
              <TitleBar
                onClickHamburger={toggleSideNav}
                self={self}
                title={title}
              />

              {renderAlerts()}

              <div className="childrenContainer">
                {children}
              </div>
            </section>
          </Paper>
        </div>

        <Footer hideLinks />
      </StyledRoot>
    </>
  );
};

export default PrivateRouteContainer;
