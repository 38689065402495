// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from '@reach/router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Internal Dependencies
import { DELETE_CHECKLIST } from 'gql/mutations';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { useGetChecklists } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Dependencies
import { useColumns } from './hooks';
import ChecklistsTableZeroState from './ChecklistsTableZeroState';

// Local Variables
const deleteChecklistContext: [string] = ['checklist'];

// TODO: Get checklist permissions from the token once we build the permissions on the back end
const canDeleteChecklists = true;

// Component Definition
const ChecklistsTable = () => {
  const navigate = useNavigate();

  const [checklistToDeleteId, setChecklistToDeleteId] = useState<string | null>(null);

  const {
    isOpen: isDeleteChecklistDialogOpen,
    toggleIsOpen: toggleDeleteChecklistDialog,
  } = useIsOpen();

  const {
    data,
    isLoading,
  } = useGetChecklists();

  const handleOpenDeleteChecklistDialog = useCallback((row: GQL.IChecklist) => {
    setChecklistToDeleteId(row.id);
    toggleDeleteChecklistDialog();
  }, [toggleDeleteChecklistDialog]);

  const formatDeleteChecklistPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteChecklistItemOnMutationArguments>['formatPayload']>((note) => ({
    input: {
      id: checklistToDeleteId ?? '',
      note: note ?? '',
    },
  }), [checklistToDeleteId]);

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const handleNavigateToEditChecklist = (row: GQL.IChecklist) => {
        navigate(`/${PATHS.CHECKLISTS}/${row.id}/edit?step=1`);
      };

      const actionsColumn = createDataGridActionsColumn<GQL.IChecklist>([
        {
          action: handleNavigateToEditChecklist,
          icon: <EditIcon />,
          text: 'Edit checklist',
        },
        ...(canDeleteChecklists ? [{
          action: handleOpenDeleteChecklistDialog,
          icon: <DeleteIcon />,
          text: 'Delete checklist',
        }] : []),
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [handleOpenDeleteChecklistDialog, navigate],
  );

  const columns = useColumns(extraColumns);

  const handleTableClickRow = useCallback((id: string) => `/${PATHS.CHECKLISTS}/${id}`, []);

  const tableElement = useMemo(() => (
    <TableDataGrid
      addButtonProps={{
        label: 'Checklist',
        to: `/${PATHS.CHECKLISTS}/new?step=1`,
      }}
      clickRowTo={handleTableClickRow}
      columns={columns}
      components={{
        NoRowsOverlay: ChecklistsTableZeroState,
      }}
      loading={isLoading}
      rows={data}
      tableResource="checklists"
      withSearch
    />
  ), [columns, data, handleTableClickRow, isLoading]);

  return (
    <>
      <DataGridContainer>
        {tableElement}
      </DataGridContainer>

      <DeleteDialogV2<GQL.IDeleteChecklistOnMutationArguments>
        actionVerb="Delete"
        clearCachePredicates={['checklists']}
        context={deleteChecklistContext}
        descriptionText="The checklist will be marked as deleted."
        formatPayload={formatDeleteChecklistPayload}
        isOpen={isDeleteChecklistDialogOpen}
        mutation={DELETE_CHECKLIST}
        onClose={toggleDeleteChecklistDialog}
        withNote
      />
    </>
  );
};

export default ChecklistsTable;
