// External Dependencies
import { FC, useCallback } from 'react';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useDistrictCreateUniform } from 'gql/mutations';
import UniformForm,
{
  AdminUniformFormValues,
  UniformFormValues,
} from 'pages/Uniforms/shared/UniformForm';

// Component Definition
const AdminUniformsNew: FC = () => {
  const navigate = useNavigate();

  const navigateToShow = useCallback((uniformId: string) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${uniformId}`);
  }, [navigate]);

  const [handleCreateUniform] = useDistrictCreateUniform(
    {
      clearCachePredicates: ['districtUniformItemsIndex', 'uniform'],
      onCompleted: (data) => navigateToShow(data.districtCreateUniform.id),
    },
  );

  const districtUniformsParams = useSelector(tableQueryParams('districtUniforms'));

  const handleSubmit = async (
    values: UniformFormValues | AdminUniformFormValues,
  ) => {
    const updatedValues = {
      ...values,
      organizationTypeId: values.orgTypeId!,
      purchaseValueInCents: values.purchaseValueInCents
        ? convertDollarsToCents(Number(values.purchaseValueInCents))
        : undefined,
      uniformTypeId: values.uniformTypeId.toString(),
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleCreateUniform({
      variables: {
        input: {
          ...updatedValues,
          ...applyDynamicFields(updatedValues as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'All Uniforms',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`,
      }}
    >
      <UniformForm
        canUseDynamicFields
        isAdmin
        onSubmit={handleSubmit}
        title="New Uniform"
      />
    </Page>
  );
};

export default AdminUniformsNew;
