// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { StyledLink } from 'components/shared';
import { isMobileScreenSize } from 'state/device/selectors';
import { useGetOrganization } from 'gql/queries';
import { vancoUrl } from 'utils/constants/urls';

// Component Definition
const VancoSettings: FC = () => {
  const navigate = useNavigate();
  const {
    data: orgData,
  } = useGetOrganization();

  const hasVancoPcct = orgData?.organization.hasVancoPcct;

  const isMobileScreen = useSelector(isMobileScreenSize);

  const handleNavigateToVancoSettings = useCallback(() => {
    navigate(`/${PATHS.VANCO_SETTINGS}`);
  }, [navigate]);

  const editVancoSettingsButton = useMemo(() => {
    let buttonText = 'Manage';

    if (hasVancoPcct) {
      buttonText = isMobileScreen ? 'Edit' : 'Edit Settings';
    }

    return (
      <Button
        color="primary"
        onClick={handleNavigateToVancoSettings}
        size={isMobileScreen ? 'small' : 'medium'}
        variant="text"
      >
        {buttonText}
      </Button>
    );
  }, [handleNavigateToVancoSettings, hasVancoPcct, isMobileScreen]);

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      marginY={2}
    >
      <Box>
        <Typography>
          Vanco/RevTrak: <strong>{hasVancoPcct ? 'ON' : 'OFF'}</strong>
        </Typography>

        <Typography
          color="textSecondary"
          sx={{ m: 1 }}
        >
          <StyledLink
            href={vancoUrl}
            openInNewTab
          >
            Vanco
          </StyledLink>
          {' '} enables secure, online payments for your district.
        </Typography>
      </Box>

      {editVancoSettingsButton}
    </Box>
  );
};

export default VancoSettings;
