// External Dependencies
import { CheckIfMemberExistsRequest } from '@presto-assistant/api_types/api/v1/member';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useCheckIfMemberExists = () => {
  const mutationFn = useCallback((payload: CheckIfMemberExistsRequest['query']) => {
    return apiClient.v1.member.checkIfMemberExists(payload);
  }, []);

  // TODO: Since this is a GET request, should it be a query?
  return useTanstackMutation({
    mutationFn,
  });
};
