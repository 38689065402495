// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialItem } from 'gql/queries';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateFinancialItem } from 'gql/mutations';

// Local Dependencies
import FinancialItemsForm from '../shared/FinancialItemsForm';

// Component Definition
const FinancialItemsEdit: FC = () => {
  const navigate = useNavigate();

  const { id } = useParamsWithId();

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));
  const { data, loading } = useGetFinancialItem(id!);

  const navigateToIndex = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`);
  }, [financialItemsParams, navigate]);

  const [updateFinancialItem] = useUpdateFinancialItem(
    {
      clearCachePredicates: ['financial', 'history', 'itemsNeedingAttention'],
      onCompleted: navigateToIndex,
    },
  );

  const handleSubmit = async (values: GQL.IUpdateFinancialItemInput) => {
    await updateFinancialItem({
      variables: {
        id: id!,
        input: {
          ...values,
          priceInCents: convertDollarsToCents(values.priceInCents),
          // The CustomSelect turns the values into a string,
          //  but the API expects a number here
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Financial Item Detail',
        path: `/${PATHS.FINANCIAL_ITEMS}/${id}`,
      }}
      isLoading={loading}
      notFound={!data?.financialItem}
    >
      {data?.financialItem && (
        <FinancialItemsForm
          initialValues={{
            canApplyFundraiserCredits: data.financialItem.canApplyFundraiserCredits,
            financialAccountId: data.financialItem.financialAccount?.id,
            isActive: data.financialItem.isActive,
            isOrganizationCoveringStripeFee: data.financialItem.isOrganizationCoveringStripeFee,
            label: data.financialItem.label,
            priceInCents: convertCentsToDollars(data.financialItem.priceInCents),
            schoolYearEnding: data.financialItem.schoolYearEnding,
          }}
          onSubmit={handleSubmit}
          title="Edit Financial Item"
        />
      )}
    </Page>
  );
};

export default FinancialItemsEdit;
