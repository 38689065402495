// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { updateUserSchema } from '@presto-assistant/api_types/schemas/user';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useGetAdult, useMemberLookup } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateUser } from 'gql/mutations';
import DialogConfirmExistingMember from 'pages/People/shared/DialogConfirmExistingMember';

// Local Dependencies
import AdultForm from '../../shared/AdultForm';

// Component Definition
const DirectorsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const [payload, setPayload] = useState<GQL.IUpdateUserOnMutationArguments | null>(null);

  const originalEmailRef = useRef('');

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const { data, loading } = useGetAdult(id);

  const directorParams = useSelector(tableQueryParams('directors'));

  const directorIndexPath = useMemo(() => {
    return `/${PATHS.DIRECTORS}${directorParams}`;
  }, [directorParams]);

  const navigateToIndex = useCallback(() => {
    navigate(directorIndexPath);
  }, [directorIndexPath, navigate]);

  // We track the original email value and only do a member lookup if it was changed
  useEffect(() => {
    if (data) {
      originalEmailRef.current = data.user.email;
    }
  }, [data]);

  const [updateDirector] = useUpdateUser(
    {
      clearCachePredicates: ['directorsIndex'],
      onCompleted: navigateToIndex,
    },
  );

  const checkForExistingMemberInThisOrganization = useMemberLookup();

  const handleEditDirector = () => {
    if (payload) {
      updateDirector({
        variables: payload,
      });
    }
  };

  const handleSubmit = async (values: GQL.ICreateUserInput) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      admin,
      primarySpokenLanguageId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      roleId,
      ...otherValues
    } = values;

    const localPayload = id
      ? {
        id,
        input: {
          ...trimValues(otherValues) as GQL.ICreateUserInput,
          primarySpokenLanguageId: primarySpokenLanguageId ? Number(primarySpokenLanguageId) : 1,
        },
      }
      : null;

    if (id) {
      setPayload(localPayload);
    }

    const emailWasUpdated = values.email !== originalEmailRef.current;

    let isExistingMember = true;

    if (emailWasUpdated) {
      const { data: existingMemberData } = await checkForExistingMemberInThisOrganization({
        memberEmail: values.email,
      });

      isExistingMember = Boolean(existingMemberData.memberLookup.length);
    }

    if (emailWasUpdated && isExistingMember) {
      handleToggleIsConfirmationDialogOpen();
    } else if (id && localPayload) {
      updateDirector({
        variables: localPayload,
      });
    }
  };

  return (
    <Page
      backButtonProps={{
        label: 'Director Detail',
        path: `/${PATHS.DIRECTORS}/${id}`,
      }}
      isLoading={loading}
      notFound={!data?.user}
    >
      {data?.user && (
        <AdultForm
          initialValues={{
            addressOne: data.user.addressOne ?? '',
            addressTwo: data.user.addressTwo ?? '',
            city: data.user.city ?? '',
            dateOfBirth: data.user.dateOfBirth ?? '',
            email: data.user.email ?? '',
            firstName: data.user.firstName ?? '',
            genderId: data.user.gender?.id ?? '',
            lastName: data.user.lastName ?? '',
            middleName: data.user.middleName ?? '',
            phoneNumber: data.user.phoneNumber ?? '',
            primarySpokenLanguageId:
              Number(data.user.primarySpokenLanguage?.id) ?? 1,
            secondaryEmail: data.user.secondaryEmail ?? '',
            secondaryPhoneNumber: data.user.secondaryPhoneNumber ?? '',
            shirtSize: data.user.shirtSize ?? '',
            stateId: data.user.stateId ?? '',
            title: data.user.title ?? '',
            zipcode: data.user.zipcode ?? '',
          }}
          isDirector
          onSubmit={handleSubmit}
          returnToOnRemoveUser={directorIndexPath}
          user={data.user}
          validationSchema={updateUserSchema}
        />
      )}

      <DialogConfirmExistingMember
        isEditing
        isOpen={isConfirmationDialogOpen}
        onSubmit={handleEditDirector}
        onToggle={handleToggleIsConfirmationDialogOpen}
      />
    </Page>
  );
};

export default DirectorsEdit;
