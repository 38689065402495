// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HandshakeIcon from 'mdi-material-ui/Handshake';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const DistrictAssistantDashboardCard = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      history,
      mode,
    },
  } = useTheme();

  const districtAssistantsParams = useSelector(tableQueryParams('districtAssistants'));

  const handlePressViewDistrictAssistants = useCallback(
    () =>
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DISTRICT_ASSISTANTS}${districtAssistantsParams}`),
    [districtAssistantsParams, navigate],
  );

  const content = (
    <>
      <ZeroStateIcon
        Icon={HandshakeIcon}
        htmlColor={mode === 'dark'
          ? history.timelineDot
          : history.showMoreButton}
      />
      <Box
        px={2}
        textAlign="center"
      >
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Add district assistants to help you manage your {APP_NAME} district.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handlePressViewDistrictAssistants}
        size="small"
        variant="outlined"
      >
        View District Assistants
      </Button>
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="District Assistants"
      verticallyCenterContent
    />
  );
};

export default DistrictAssistantDashboardCard;
