// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeAccountIcon from 'mdi-material-ui/BadgeAccount';
import Box from '@mui/material/Box';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';
import Grid from '@mui/material/Grid';

// Internal Dependencies
import {
  Container,
  DynamicFormFields,
  EnhancedContainer,
  History,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { hasPermission, isParent } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetStudent, useIsFamilyMember } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import ActivityCard from '../../shared/ActivityCard';
import BouncedEmailAlert from '../../shared/BouncedEmailAlert';
import FeesCard from '../../shared/FeesCard';
import GroupsCard from '../../shared/GroupsCard';
import RelationshipsCard from '../../shared/RelationshipsCard';
import ShowStudentInventoryCheckout from './ShowStudentInventoryCheckout';
import ShowStudentProfileData from './ShowStudentProfileData';
import ShowStudentRoleData from './ShowStudentRoleData';
import ShowStudentUniformCheckout from './ShowStudentUniformCheckout';
import ShowUserContactData from '../../shared/ShowUserContactData';

// Component Definition
const StudentsShow: FC = () => {
  const { id } = useParamsWithId();

  const isFamilyMember = useIsFamilyMember(id ?? '');

  const canReadUsers = useSelector(hasPermission('users', 'read'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'read'));
  const canReadInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'read'));
  const isUserAdult = useSelector(isParent);

  const canEditFamilyMember = isFamilyMember && isUserAdult;

  const { data, loading } = useGetStudent(id);

  const studentParams = useSelector(tableQueryParams('students'));

  const hasBouncedEmail = Boolean(data?.user?.bouncedEmail?.id);

  return (
    <Page
      backButtonProps={{
        label: canReadUsers ? 'Students' : 'Profile',
        path: canReadUsers ? `/${PATHS.STUDENTS}${studentParams}` : `/${PATHS.PROFILE}`,
      }}
      editButtonPath={(canEditUsers || canEditFamilyMember) ? `/${PATHS.STUDENTS}/${id}/edit` : ''}
      isLoading={loading}
      notFound={!data?.user}
    >
      {hasBouncedEmail && (
        <EnhancedContainer>
          <BouncedEmailAlert bouncedEmailData={data?.user.bouncedEmail} />
        </EnhancedContainer>
      )}

      <Container maxWidth={1200}>

        <Grid container>
          {data?.user && (
            <>
              <EnhancedContainer>
                <Box mb={2}>
                  <ShowCard
                    canEdit={canEditUsers || canEditFamilyMember}
                    icon={AccountCircleIcon}
                    readOnly
                    title="Profile Info"
                  >
                    <ShowStudentProfileData userData={data.user} />
                  </ShowCard>
                </Box>
              </EnhancedContainer>

              <EnhancedContainer>
                <Box mb={2}>
                  <ShowCard
                    canEdit={canEditUsers || canEditFamilyMember}
                    icon={CellphoneWirelessIcon}
                    readOnly
                    title="Contact Info"
                  >
                    <ShowUserContactData userData={data.user} />
                  </ShowCard>
                </Box>
              </EnhancedContainer>
            </>
          )}

          {data?.user && (
            <EnhancedContainer>
              <DynamicFormFields
                isAdmin={false}
                item={data?.user}
                showCardProps={{
                  canEdit: canEditUsers,
                  readOnly: true,
                }}
                tableRef="members"
              />
            </EnhancedContainer>
          )}

          {data?.user?.userOrgData && (
            <EnhancedContainer>
              <Box mb={2}>
                <ShowCard
                  canEdit={canEditUsers}
                  icon={BadgeAccountIcon}
                  readOnly
                  title="Role"
                >
                  <ShowStudentRoleData roles={data.user.userOrgData.roles} />
                </ShowCard>
              </Box>
            </EnhancedContainer>
          )}

          {canReadFinances && data?.user && (
            <EnhancedContainer>
              <FeesCard
                hasMemberOrgData={Boolean(data?.user.userOrgData)}
                isStudent
                memberEmail={data.user.email}
                userId={data.user.id}
                userName={getFullName(data.user)}
              />
            </EnhancedContainer>
          )}

          {data?.user.groups && (
            <EnhancedContainer>
              <GroupsCard
                groups={data?.user.groups}
                name={getFullName(data.user)}
                userId={data?.user.id}
              />
            </EnhancedContainer>
          )}

          {id && data?.user && (
            <EnhancedContainer>
              <RelationshipsCard
                memberId={id}
                memberRoleId={data.user.role.id}
              />
            </EnhancedContainer>
          )}

          {canReadInventoryCheckouts && data?.user?.id && (
            <EnhancedContainer>
              <ShowStudentInventoryCheckout
                memberId={data.user.id}
                name={getFullName(data.user)}
              />
            </EnhancedContainer>
          )}

          {canReadUniformCheckouts && data?.user?.id && (
            <EnhancedContainer>
              <ShowStudentUniformCheckout
                name={getFullName(data.user)}
                userId={data.user.id}
              />
            </EnhancedContainer>
          )}

          {data?.user?.userOrgData && (
            <EnhancedContainer>
              <ActivityCard
                authUserEmail={data.user.authUserEmail}
                authUserId={data.user.authUserId}
                loggedInAt={data.user.loggedInAt}
                memberEmail={data.user.email}
                name={getFullName(data.user)}
                receivedWelcomeEmailAt={data.user.userOrgData.receivedWelcomeEmailAt}
                userId={data.user.id}
                userOrgId={data.user.userOrgData.id}
                userOrgLoggedInAt={data.user.userOrgData.member?.loggedInAt}
              />
            </EnhancedContainer>
          )}
        </Grid>
      </Container>
      {data?.user && (
        <History
          id={data.user.id}
          resource="members"
        />
      )}
    </Page>
  );
};

export default StudentsShow;
