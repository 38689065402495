// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TieIcon from 'mdi-material-ui/Tie';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import {
  useGetMyUniformCheckouts,
} from 'gql/queries';

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const UniformCheckoutsCard = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      stripeBlue,
    },
  } = useTheme();

  const handleNavigateToMyUniformCheckouts = useCallback(() => {
    navigate(`/${PATHS.STUDENT_UNIFORMS}`);
  }, [navigate]);

  const {
    data,
  } = useGetMyUniformCheckouts();

  const uniformCheckoutsCount = data?.myUniformCheckouts.uniformCheckouts.length;

  const zeroStateIcon = useMemo(() => (
    <ZeroStateIcon
      Icon={TieIcon}
      htmlColor={stripeBlue['400']}
    />
  ), [stripeBlue]);

  const content = useMemo(() => (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          You have
          {' '}
          <StyledStrong>{uniformCheckoutsCount}</StyledStrong>
          {' '}
          {pluralize(uniformCheckoutsCount, 'uniform')}
          {' '}
          checked out.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToMyUniformCheckouts}
        size="small"
        variant="outlined"
      >
        See checkouts
      </Button>
    </>
  ), [handleNavigateToMyUniformCheckouts, uniformCheckoutsCount, zeroStateIcon]);

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Uniform Checkouts"
      verticallyCenterContent
    />
  );
};

export default UniformCheckoutsCard;
