// External Dependencies
import Box from '@mui/material/Box';

// Internal Dependencies
import { BackToButton, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrictInventoryAudit } from 'utils/api/district';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import NotFoundItemsTable from './NotFoundItemsTable';

// Component Definition
const NotFoundItems = (): JSX.Element => {
  const { id } = useParamsWithId();

  const pathToAuditShowPage = `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}/${id}`;

  const {
    data: districtInventoryAuditData,
    isLoading,
  } = useGetDistrictInventoryAudit(id);

  const districtInventoryAudit = districtInventoryAuditData?.data.data;

  return (
    <>
      <Box marginBottom={3}>
        <BackToButton
          context="Inventory Audit Details"
          navigateTo={pathToAuditShowPage}
        />
      </Box>

      <Subtitle>
        Inventory Audit Not Found Items
      </Subtitle>

      <NotFoundItemsTable
        isLoading={isLoading}
        notFoundItems={districtInventoryAudit?.inventoryItemsNotFound}
      />
    </>
  );
};

export default NotFoundItems;
