// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared/index';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { useFlatFileTypes } from 'hooks/useFlatFileTypes';

// Local Typings
interface Props {
  acceptedFileTypes?: string[];
  disabled?: boolean;
  handleDrop: (acceptedFiles: File[]) => void;
  helperText?: string;
  marginBottom?: number;
  marginTop?: number;
  maxSizeInMb?: number;
  multiple?: boolean;
  rejectedDropErrorMessage: string;
}

// Local Variables
const StyledSection = styled.section<{
  marginBottom: number;
  marginTop: number;
}>(({
  marginBottom,
  marginTop,
  theme,
}) => ({
  '&.disabledDropzone': {
    backgroundColor: 'rgba(150, 150, 150, 0.1)', // a disabled color that should work for any theme
  },
  '.dropzoneHover': {
    backgroundColor: theme.palette.dropzoneHover,
    border: `3px dashed ${theme.palette.dropzoneBorder}`,
  },
  '.icon': {
    height: 144,
    width: 144,
  },
  border: `3px dashed ${theme.palette.divider}`,
  borderRadius: 3,
  marginBottom: theme.spacing(marginBottom),
  marginTop: theme.spacing(marginTop),
  textAlign: 'center',
}));

// Component Definition
const UploadDropzone: FC<Props> = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  disabled,
  handleDrop,
  helperText,
  marginBottom = 0,
  marginTop = 3,
  maxSizeInMb,
  multiple = false,
  rejectedDropErrorMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles) =>
      handleDrop(acceptedFiles),
    [handleDrop],
  );

  const acceptedFileTypesArray = useFlatFileTypes(acceptedFileTypes);

  const shouldAcceptAll = useMemo(
    () => acceptedFileTypesArray.every((type) => type === '*'),
    [acceptedFileTypesArray],
  );

  const maxSize = useMemo(
    () => (maxSizeInMb ? maxSizeInMb * 1024 * 1024 : undefined),
    [maxSizeInMb],
  );

  const {
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: shouldAcceptAll ? undefined : acceptedFileTypesArray,
    maxSize,
    multiple,
    onDrop,
    onDropAccepted: () => setErrorMessage(''),
    onDropRejected: (err, arg) => {
      console.log('rej err : ', err);
      console.log('rej arg : ', arg);

      if (maxSize && err.some((file) => file.size > maxSize)) {
        setErrorMessage(`File size must be less than ${maxSizeInMb}MB`);
      } else {
        setErrorMessage(rejectedDropErrorMessage);
      }
    },
  });

  const rootProps = getRootProps({
    onChange: () => { },
    onClick: () => { },
    tabIndex: -1,
  });

  return (
    <>
      <StyledSection
        {...rootProps}
        className={clsx(isDragActive && 'dropzoneHover', disabled && 'disabledDropzone')}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        <input
          {...getInputProps({
            accept: acceptedFileTypes.join(', '),
            disabled,
          })}
        />

        <CloudUploadIcon
          className="icon"
          htmlColor={theme.palette.divider}
        />

        <Typography
          color={disabled ? theme.palette.grey[500] : theme.palette.grey[600]}
          fontSize={18}
          fontWeight={400}
          gutterBottom
        >
          {isDragActive ? 'Drop your file here' : 'Drop file or click to upload'}
        </Typography>

        {helperText && (
          <Typography
            color="textSecondary"
            fontSize="small"
            gutterBottom
          >
            {helperText}
          </Typography>
        )}
      </StyledSection>

      <Collapse in={Boolean(errorMessage)}>
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 1 }}
        >
          {errorMessage}
        </EnhancedAlert>
      </Collapse>
    </>
  );
};

export default UploadDropzone;
