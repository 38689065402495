// External Dependencies
import { FC } from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import { EnhancedContainer, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useCreateFinancialAccount } from 'gql/mutations';

// Local Dependencies
import AccountForm from '../shared/AccountForm';

// Component Definition
const FinancialAccountsNew: FC = () => {
  const navigate = useNavigate();

  const [createFinancialAccount] = useCreateFinancialAccount(
    {
      onCompleted: (data) => {
        navigate(`/${PATHS.FINANCIAL_ACCOUNTS}/${data.createFinancialAccount.id}`);
      },
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateFinancialAccountInput,
  ) =>
    createFinancialAccount({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'Financial Overview',
        path: `/${PATHS.FINANCIAL_OVERVIEW}`,
      }}
    >
      <EnhancedContainer>
        <AccountForm onSubmit={handleSubmitForm} />
      </EnhancedContainer>
    </Page>
  );
};

export default FinancialAccountsNew;
