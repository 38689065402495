// External Dependencies
import { FC } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import { createUniformSchema } from '@presto-assistant/api_types/schemas/uniform';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
  DynamicFormFields,
  EnhancedContainer,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { UniformShowResponse } from 'gql/queries';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import { createDistrictUniformSchema } from './schema';
import ShowUniformData from '../Show/ShowUniformData';
import UniformDangerZone from './UniformDangerZone';
import UniformInfoFormFields from './UniformInfoFormFields';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  isAdmin?: boolean;
  onSubmit?: (values: UniformFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
  uniform?: UniformShowResponse;
}
export interface UniformFormValues {
  categoryId: string;
  color: string;
  comments: string;
  conditionId: string | null;
  customBarcode?: string;
  orgTypeId?: string;
  purchaseValueInCents: number | null;
  purchasedAt: string | null;
  size: string;
  uniformNumber: string;
  uniformStyleId: string;
  uniformTypeId: number | string;
}
export interface AdminUniformFormValues extends UniformFormValues {
  organizationId: string;
}

// Local Variables
const StyledGrid = styled(Grid)({
  '.MuiContainer-root': {
    width: CONTAINER_WIDTH,
  },
});

// Component Definition
const UniformForm: FC<Props> = ({
  canUseDynamicFields,
  isAdmin,
  onSubmit,
  readOnly,
  title,
  uniform,
}) => {
  const navigate = useNavigate();

  const canEditUniforms = useSelector(hasPermission('uniforms', 'edit'));
  const canDeleteUniforms = useSelector(hasPermission('uniforms', 'delete'));
  const isDFA = useSelector(isDistrictAdmin);

  const canEdit = isDFA || canEditUniforms;
  const canDelete = isDFA || canDeleteUniforms;

  const districtUniformsParams = useSelector(tableQueryParams('districtUniforms'));
  const uniformsParams = useSelector(tableQueryParams('uniforms'));

  const uniformIndexUrl = isDFA
    ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`
    : `/${PATHS.UNIFORMS}${districtUniformsParams}`;

  const handlePressCancelOrBackButton = () => {
    if (isAdmin) {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`);
    } else {
      navigate(`/${PATHS.UNIFORMS}${uniformsParams}`);
    }
  };

  if (readOnly && !uniform) {
    return null;
  }

  const initialValues: UniformFormValues = {
    categoryId: uniform?.category?.id ?? '',
    color: uniform?.color ?? '',
    comments: uniform?.comments ?? '',
    conditionId: uniform?.condition?.id ?? null,
    customBarcode: uniform?.customBarcode ?? '',
    orgTypeId: uniform?.organization?.organizationType?.id ?? '1',
    purchaseValueInCents: uniform?.purchaseValueInCents
      ? convertCentsToDollars(uniform.purchaseValueInCents)
      : null,
    purchasedAt: uniform?.purchasedAt ?? '',
    size: uniform?.size ?? '',
    uniformNumber: uniform?.uniformNumber ?? '',
    uniformStyleId: uniform?.uniformStyle?.id ?? '',
    uniformTypeId: uniform?.uniformType.id ?? '',
    ...(canUseDynamicFields ? applyDynamicFields(uniform) : {}),
  };

  const adminInitialValues: AdminUniformFormValues = {
    ...initialValues,
    organizationId: uniform?.organization?.id ?? '',
  };

  const handleFormikSubmit = async (
    values: UniformFormValues | AdminUniformFormValues,
    formikProps: FormikHelpers<UniformFormValues>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  return (
    <>
      <Formik<UniformFormValues | AdminUniformFormValues>
        initialValues={isAdmin ? adminInitialValues : initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={isAdmin
          ? createDistrictUniformSchema
          : createUniformSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Container maxWidth={1200}>
                <StyledGrid container>
                  <EnhancedContainer>
                    <Box mb={2}>
                      <ShowCard
                        canEdit={canEdit}
                        icon={InfoIcon}
                        readOnly={readOnly}
                        title={title}
                      >
                        {readOnly && uniform ? (
                          <ShowUniformData
                            isAdmin={isAdmin}
                            uniform={uniform}
                          />
                        ) : (
                          <UniformInfoFormFields
                            isAdmin={isAdmin}
                            orgTypeId={values.orgTypeId}
                          />
                        )}
                      </ShowCard>
                    </Box>
                  </EnhancedContainer>

                  <EnhancedContainer>
                    <DynamicFormFields
                      isAdmin={Boolean(isAdmin)}
                      item={uniform}
                      organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                      showCardProps={{
                        canEdit: canEditUniforms,
                        readOnly,
                      }}
                      tableRef="uniforms"
                    />
                  </EnhancedContainer>
                </StyledGrid>
              </Container>

              {!readOnly && (
                <EnhancedContainer>
                  <FormRouteActions
                    context="Uniform"
                    isEditing={!!uniform}
                    isFormTouched={isFormTouched}
                    isSubmitting={isSubmitting}
                    onPressCancelOrBackButton={handlePressCancelOrBackButton}
                  />
                </EnhancedContainer>
              )}
            </Form>
          );
        }}
      </Formik>

      {canDelete && !readOnly && uniform?.id && uniform?.organization?.label && (
        <EnhancedContainer>
          <UniformDangerZone
            organizationLabel={uniform?.organization?.label}
            uniformId={uniform.id}
            uniformIndexUrl={uniformIndexUrl}
          />
        </EnhancedContainer>
      )}
    </>
  );
};

export default UniformForm;
