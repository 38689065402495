// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import styled from 'styled-components';

// Local Typings
interface Props {
  children: any;
  navigateTo?: string;
  onClickButton?: () => void;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  backgroundColor: theme.palette.common.white,
}));

const StyledArrowForwardIcon = styled(ArrowForwardIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2),
  },
  marginLeft: theme.spacing(1),
}));

// Component Definition
const ForwardButton: FC<Props> = ({
  children,
  navigateTo,
  onClickButton,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    } else if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo, onClickButton]);

  return (
    <StyledButton
      color="primary"
      onClick={handleClick}
      onKeyPress={handleClick}
      size="small"
      variant="outlined"
    >
      {children}
      <StyledArrowForwardIcon fontSize="small" />
    </StyledButton>
  );
};

export default ForwardButton;
