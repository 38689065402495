// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import GroupIcon from 'mdi-material-ui/Group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  RemoveFromGroupButton,
  ShowCard,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import AddToGroup from './AddToGroup';

// Local Typings
interface Props {
  groups: GQL.IUser['groups'];
  name: string;
  userId: string;
}

// Local Variables
const StyledShowCard = styled(ShowCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.showPage.groups,
}));

// Component Definition
const GroupsCard: FC<Props> = ({
  groups,
  name,
  userId,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useSelector(isMobileScreenSize);
  const groupParams = useSelector(tableQueryParams('group'));
  const canReadGroups = useSelector(hasPermission('groups', 'read'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));

  const handleClickGroup = useCallback((groupId: string) => {
    if (!canReadGroups) {
      return null;
    }

    return navigate(`/groups/${groupId}${groupParams}`);
  }, [canReadGroups, groupParams, navigate]);

  return (
    <Box mb={2}>
      <StyledShowCard
        icon={AccountGroupIcon}
        title="Groups"
      >
        {groups.length > 0
          ? (
            <List dense={isMobileScreen}>
              {groups?.map((group) => (
                <ListItem
                  button={canReadGroups as false | undefined}
                  key={group.id}
                  onClick={() => handleClickGroup(group.id)}
                >
                  <ListItemAvatar>
                    <StyledAvatar
                      className="avatar-prime"
                      variant="rounded"
                    >
                      <GroupIcon htmlColor={theme.palette.relationships.icon} />
                    </StyledAvatar>
                  </ListItemAvatar>

                  <ListItemText primary={group?.label} />

                  {canEditGroups && (
                    <ListItemSecondaryAction>
                      <RemoveFromGroupButton
                        groupId={group?.id}
                        groupName={group?.label}
                        memberName={name}
                        userId={userId}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          )
          : (
            <Typography color="textSecondary">
              Not added to any groups yet
            </Typography>
          )}

        {canEditGroups && (
          <AddToGroup
            activeGroupIds={groups.map((group) => group.id)}
            memberId={userId}
          />
        )}
      </StyledShowCard>
    </Box>
  );
};

export default GroupsCard;
