// External Dependencies
import { Container } from '@mui/material';
import { FC, useCallback } from 'react';

// Internal Dependencies
import VancoPaymentForm from 'components/shared/VancoPaymentForm';

// Local Dependencies
import PaymentMethodCard, { CardDetails } from '../shared/PaymentMethodCard';

// Local Typings
interface Props {
  cardDetails: CardDetails | null;
  onSetCardDetails: (cardDetails: CardDetails | null) => void;
}

// Component Definition
const EnterVancoPaymentMethod: FC<Props> = ({
  cardDetails,
  onSetCardDetails,
}) => {
  const handleClear = useCallback(() => {
    onSetCardDetails(null);
  }, [onSetCardDetails]);

  return cardDetails ? (
    <Container maxWidth="sm">
      <PaymentMethodCard
        cardDetails={cardDetails}
        onEdit={handleClear}
      />
    </Container>
  ) : (
    <VancoPaymentForm
      onSetCardDetails={onSetCardDetails}
    />
  );
};

export default EnterVancoPaymentMethod;
