// External Dependencies
import { FC, useCallback } from 'react';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

// Internal Dependencies
import {
  AddButton,
  DialogPeoplePicker,
  StyledLink,
  Users,
} from 'components/shared';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';

// Local Typings
interface Props {
  userId?: string;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.addButton': {
    fontSize: 15,
    padding: '5px 15px 5px 12px',
  },
  '.selectOneUserChip': {
    backgroundColor: alpha(theme.palette.stripeBlue['300'], 0.1),
    fontSize: theme.spacing(2),
    margin: theme.spacing(1.5, 0, 1.5),
    padding: theme.spacing(0.5),
  },
}));

// Component Definition
const SelectOneUser: FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPeoplePickerDialogOpen = useSelector(isOpen);

  // This component will always select one user, but the
  // DialogPeoplePicker is set up to send an array of user ids
  const handleUpdatePeople = useCallback((newUserIds: string[]) => {
    const [newUserId] = newUserIds;
    navigate(`?userId=${newUserId}`);
  }, [navigate]);

  const handlePressAddOrChangePerson = useCallback((
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    event.preventDefault();

    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  const hasAddedOneUser = Boolean(userId);

  return (
    <>
      <StyledRoot
        alignItems="baseline"
        display="flex"
      >
        {hasAddedOneUser && (
          <Users ids={[userId!]}>
            {({ data, loading }) => {
              if (loading) {
                return (
                  <Box
                    ml={1}
                    textAlign="center"
                    width={12}
                  >
                    <CircularProgress
                      size={24}
                      thickness={6}
                    />
                  </Box>
                );
              }

              const studentChips = data.map((student) => {
                const {
                  firstName,
                  id,
                  lastName,
                } = student;

                return (
                  <Chip
                    className="selectOneUserChip"
                    key={`${id}`}
                    label={`${firstName} ${lastName}`}
                    variant="outlined"
                  />
                );
              });

              return (
                <>
                  {studentChips}
                  <Box ml={1}>
                    <StyledLink
                      onClick={handlePressAddOrChangePerson}
                    >
                      Change
                    </StyledLink>
                  </Box>
                </>
              );
            }}
          </Users>
        )}

        {!hasAddedOneUser && (
          <AddButton
            className="addButton"
            label="Member"
            onClick={handlePressAddOrChangePerson}
          />
        )}
      </StyledRoot>

      <DialogPeoplePicker
        hideCheckAll
        isOpen={isPeoplePickerDialogOpen}
        maxSelectionCount={1}
        onAddSelectedIds={handleUpdatePeople}
        tableResource="peoplePickerSelectOneUser"
        title="Add Person"
      />
    </>
  );
};

export default SelectOneUser;
