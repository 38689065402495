// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  count?: number;
}

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const PendingMembersCard = ({ count }: Props): JSX.Element | null => {
  const navigate = useNavigate();
  const theme = useTheme();

  const pendingMembersParams = useSelector(tableQueryParams('pendingMembers'));

  const handleNavigateToPendingMembersPage = useCallback(() => {
    navigate(`/${PATHS.PENDING_MEMBERS}${pendingMembersParams}`);
  }, [navigate, pendingMembersParams]);

  const zeroStateIcon = useMemo(() => (
    <ZeroStateIcon
      Icon={ThumbsUpDownIcon}
      htmlColor={theme.palette.prestoPrimaryLight}
    />
  ), [theme.palette.prestoPrimaryLight]);

  const content = useMemo(() => (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          You have{' '}
          <StyledStrong>{count}</StyledStrong>{' '}
          pending {pluralize(count, 'member')} to review.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToPendingMembersPage}
        size="small"
        variant="outlined"
      >
        View Pending Members
      </Button>
    </>
  ), [count, handleNavigateToPendingMembersPage, zeroStateIcon]);

  if (!count) {
    return null;
  }

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Pending Members"
      verticallyCenterContent
    />
  );
};

export default PendingMembersCard;
