// External Dependencies
import { GlobalEditField } from '@presto-assistant/api_types/api/v1/globalEdit';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// Internal Dependencies
import { useCallback } from 'react';
import Select, { SelectProps } from 'components/shared/Select';

// Local Typings
interface Props {
  field: GlobalEditField;
  onChangeValue: (value: string) => void;
  value: string;
}

// Component Definition
const GlobalEditInput = ({
  field,
  onChangeValue,
  value,
}: Props): JSX.Element | null => {
  const handleTextFieldChange: TextFieldProps['onChange'] = useCallback((evt) => {
    onChangeValue(evt.target.value);
  }, [onChangeValue]);

  const handleSelectChange: SelectProps['onChange'] = useCallback((evt) => {
    onChangeValue(evt.target.value as string);
  }, [onChangeValue]);

  switch (field.type) {
    case 'text':
      return (
        <TextField
          fullWidth
          id={field.id}
          label={field.label}
          name={field.label}
          onChange={handleTextFieldChange}
          placeholder={field.label}
          value={value}
        />
      );
    case 'select':
      return (
        <Select
          id={field.id}
          label={field.label}
          name={field.label}
          onChange={handleSelectChange}
          options={field.options}
          placeholder={field.label}
          value={value}
        />
      );
    default:
      return null;
  }
};

export default GlobalEditInput;
