// External Dependencies
import { LazyQueryResult, WatchQueryFetchPolicy } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { parseSearch } from 'utils';
import { useCanQueryFinancialAccounts } from 'hooks/useCanQueryFinancialAccounts';
import {
  useLazyQueryEnhanced,
  useQueryEnhanced,
} from 'utils/lib/graphql';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface FinancialFeeIndexResponse {
  financialFeesIndex: GQL.IFinancialFeeIndexItemAll;
}
interface FinancialCreditIndexResponse {
  financialCreditsIndex: GQL.IFinancialCreditIndexItemAll;
}
interface FinancialItemsIndexResponse {
  financialItemsIndex: GQL.IFinancialItemIndexItemAll;
}
interface MyFinancialFeesAllResponse {
  myFinancialFeesAll: GQL.IFinancialFeeIndexItem[];
}
interface MyFinancialFeeIndexResponse {
  myFinancialFeesIndex: GQL.IFinancialFeeIndexItemAll;
}
interface MyFinancialCreditsIndexResponse {
  myFinancialCreditsIndex: GQL.IFinancialCreditIndexItemAll;
}
interface FinancialItemsResponse {
  financialItems: GQL.IFinancialItemAll;
}
interface FinancialFeeResponse {
  financialFee: GQL.IFinancialFee;
}
interface FinancialFeesOverviewResponse {
  financialFeesOverview: GQL.IFinancialFeeOverview;
}

// Local Variables
const getAdditionalMyFeesVariables = (
  search: Record<string, any>,
  selfId: string,
) => {
  const where: GQL.IMyFinancialFeeIndexWhereConditions = {
    schoolYearEnding: search.schoolYearEnding,
    userId: search.userId ?? selfId,
  };

  return { where };
};

const getAdditionalMyCreditsVariables = (
  search: Record<string, any>,
  selfId: string,
) => {
  const where: GQL.IMyFinancialCreditIndexWhereConditions = {
    userId: search.userId ?? selfId,
  };

  return { where };
};

const GET_FINANCIAL_CREDITS_BY_USER_ID = gql`
  query FinancialCreditsByUserId(
    $userId: ID!
  ) {
    financialCreditsByUserId(
      userId: $userId
    ) {
      amountInCents
      closedAt
      creditRemainingInCents
      financialAccount {
        id
        label
      }
      id
      isRefunded
    }
  }
`;

export const GET_FINANCIAL_ITEM = gql`
  query FinancialItem($id: ID!) {
    financialItem(id: $id) {
      canApplyFundraiserCredits
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      id
      isActive
      isOrganizationCoveringStripeFee
      feesAssigned {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
      financialAccount {
        id
        label
      }
      label
      priceInCents
      schoolYearEnding
    }
  }
`;

export const GET_FINANCIAL_ITEMS = gql`
  query FinancialItems(
    $queryParams: QueryParams
    $where: FinancialItemsWhere
  ) {
    financialItems(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
      data {
        canApplyFundraiserCredits
        createdBy {
          id
          firstName
          lastName
        }
        id
        isActive
        isOrganizationCoveringStripeFee
        financialAccount {
          id
          label
        }
        label
        priceInCents
        schoolYearEnding
      }
    }
  }
`;

export const GET_FINANCIAL_FEES = gql`
  query FinancialFees(
    $queryParams: QueryParams
    $where: FinancialFeesWhere
  ) {
    financialFees(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        financialItem {
          financialAccount {
            id
            label
          }
          id
          label
          priceInCents
          schoolYearEnding
        }
        financialPayments {
          amountInCents
          id
          financialPaymentType {
            id
            label
          }
        }
        id
        remainingBalanceInCents
        user {
          addressOne
          email
          id
          firstName
          lastName
        }
      }
      fullCount
    }
  }
`;

export const GET_FINANCIAL_FEES_INDEX = gql`
  query FinancialFeesIndex(
    $queryParams: IndexQueryParams
    $where: FinancialFeeIndexWhereConditions!
  ) {
    financialFeesIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        balanceDueInCents
        canBeDeleted
        financialAccountId
        financialAccountLabel
        financialItemId
        financialItemLabel
        groups {
          id
          label
        }
        id
        isWaived
        schoolYearEnding
        userFirstName
        userId
        userLastName
        userRoleId
      }
      fullCount
    }
  }
`;

export const getFinancialFeesIndexQuery = (schoolYearEnding: number) => gql`
  query FinancialFeesIndex(
    $queryParams: IndexQueryParams
  ) {
    financialFeesIndex(
      queryParams: $queryParams
      where: {
        schoolYearEnding: ${schoolYearEnding}
      }
    ) {
      data {
        balanceDueInCents
        canBeDeleted
        financialAccountId
        financialAccountLabel
        financialItemId
        financialItemLabel
        groups {
          id
          label
        }
        id
        isActiveMember
        isWaived
        priceInCents
        schoolYearEnding
        userFirstName
        userId
        userLastName
        userRoleId
      }
      fullCount
    }
  }
`;

export const getFinancialItemsIndexQuery = (schoolYearEnding: number) => gql`
  query FinancialItemsIndex {
    financialItemsIndex(
      where: {
        schoolYearEnding: ${schoolYearEnding}
      }
    ) {
      data {
        canApplyFundraiserCredits
        financialAccountId
        financialAccountLabel
        id
        isActive
        isOrganizationCoveringStripeFee
        label
        priceInCents
        schoolYearEnding
      }
      fullCount
    }
  }
`;

export const GET_FINANCIAL_CREDITS_INDEX = gql`
  query FinancialCreditsIndex(
    $queryParams: IndexQueryParams
  ) {
    financialCreditsIndex(
      queryParams: $queryParams
      where: {}
    ) {
      data {
        amountAppliedInCents
        amountInCents
        amountTransferredInCents
        creditRemainingInCents
        createdAt
        createdByFirstName
        createdById
        createdByLastName
        financialAccountId
        financialAccountLabel
        id
        isRefunded
        note
        status
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

export const GET_MY_FINANCIAL_CREDITS_INDEX = gql`
  query MyFinancialCreditsIndex(
    $queryParams: IndexQueryParams
    $where: MyFinancialCreditIndexWhereConditions!
  ) {
    myFinancialCreditsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        amountAppliedInCents
        amountInCents
        amountTransferredInCents
        creditRemainingInCents
        createdAt
        createdByFirstName
        createdById
        createdByLastName
        financialAccountId
        financialAccountLabel
        id
        isRefunded
        note
        status
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

export const FRAGMENT_FINANCIAL_CREDIT_SHOW = gql`
  fragment FinancialCreditShowFragment on FinancialCredit {
    amountInCents
    closedAt
    closedByMember {
      id
      email
      firstName
      lastName
    }
    closedNote
    closureType {
      id
      label
    }
    createdAt
    createdBy {
      firstName
      id
      lastName
    }
    creditApplications {
      amountInCents
      createdBy {
        email
        id
        firstName
        lastName
      }
      id
      financialPayment {
        id
        financialFee {
          id
          financialItem {
            id
            label
          }
        }
        refundedAt
      }
    }
    creditRemainingInCents
    creditTransfers {
      amountInCents
      createdAt
      createdBy {
        email
        id
        firstName
        lastName
      }
      destinationCredit {
        id
        user {
          email
          id
          firstName
          lastName
        }
      }
      id
    }
    financialAccount {
      id
      label
    }
    id
    isEditable {
      reason
      status
    }
    isRefunded
    note
    originalFinancialPayment {
      id
      refundedAt
      refundedBy {
        email
        firstName
        id
        lastName
      }
      refundedNote
      stripeRefundStatusId
    }
    transferredFromCredit {
      id
      user {
        email
        id
        firstName
        lastName
      }
    }
    status
    user {
      id
      firstName
      lastName
      role {
        id
        label
      }
    }
  }
`;

const GET_FINANCIAL_CREDIT = gql`
  query FinancialCredit($id: ID!) {
    financialCredit(id: $id) {
      ...FinancialCreditShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_CREDIT_SHOW}
`;

const GET_FINANCIAL_FEE = gql`
  query FinancialFee(
    $id: ID!
  ) {
    financialFee(id: $id) {
      financialItem {
        id
        label
      }
      id
      remainingBalanceInCents
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const GET_MY_FINANCIAL_FEES_ALL = gql`
  query MyFinancialFeesAll {
    myFinancialFeesAll {
      balanceDueInCents
      financialAccountId
      financialAccountLabel
      financialItemId
      financialItemLabel
      id
      isOrganizationCoveringStripeFee
      onlinePaymentsEnabled
      userFirstName
      userId
      userLastName
    }
  }
`;

export const GET_MY_FINANCIAL_FEES_INDEX = gql`
  query MyFinancialFeesIndex(
    $queryParams: IndexQueryParams
    $where: MyFinancialFeeIndexWhereConditions!
  ) {
    myFinancialFeesIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        balanceDueInCents
        financialAccountId
        financialAccountLabel
        financialItemId
        financialItemLabel
        id
        isOrganizationCoveringStripeFee
        isWaived
        onlinePaymentsEnabled
        schoolYearEnding
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

const GET_FINANCIAL_FEES_OVERVIEW = gql`
  query FinancialFeesOverview(
    $schoolYearEnding: Int!
  ) {
    financialFeesOverview(
      schoolYearEnding: $schoolYearEnding
    ) {
      previousYearsTotalFeesInCents
      totalNumberOfOutstandingFees
      totalNumberOfUsersWithOutstandingBalance
      totalOutstandingBalanceInCents
    }
  }
`;

const GET_STRIPE_FEE_IN_CENTS = gql`
  query StripeFeeInCents($subTotalInCents: Int!) {
    stripeFeeInCents(subTotalInCents: $subTotalInCents)
  }
`;

const GET_FINANCIAL_ACCOUNTS = gql`
  query FinancialAccounts {
    financialAccounts {
      canApplyFundraiserCredits
      id
      label
      onlinePaymentsEnabled
    }
  }
`;

const GET_FINANCIAL_ACCOUNT = gql`
  query FinancialAccount($id: ID!) {
    financialAccount(id: $id) {
      canApplyFundraiserCredits
      id
      label
      onlinePaymentsEnabled
    }
  }
`;

export interface FinancialFeesResponse {
  financialFees: GQL.IFinancialFeeAll;
}

interface FinancialFeesVariables {
  where: GQL.IFinancialFeesWhere;
}

export const useGetFinancialItem = (id: string | undefined) =>
  useQueryEnhanced<{
    financialItem: GQL.IFinancialItem
  }>(
    GET_FINANCIAL_ITEM,
    {
      skip: !id,
      variables: { id },
    },
  );

export const useGetFinancialItems = (schoolYearEnding: number | null) =>
  useQueryEnhanced<FinancialItemsResponse, GQL.IFinancialItemsOnQueryArguments>(
    GET_FINANCIAL_ITEMS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        queryParams: {
          orderBy: 'label',
        },
        where: {
          isActive: true,
          schoolYearEnding,
        },
      },
    },
  );

export const useGetFinancialItemsIndex = (schoolYearEnding: number) => usePaginatedListQuery<
  FinancialItemsIndexResponse,
  GQL.IFinancialItemIndexItem
>({
  dataSelector: (res) => res.financialItemsIndex.data,
  fullCountSelector: (res) => res.financialItemsIndex.fullCount,
  query: getFinancialItemsIndexQuery(schoolYearEnding),
  uniqueKey: schoolYearEnding.toString(),
});

export const useGetFinancialFeesIndexWhere = (where: GQL.IFinancialFeesIndexOnQueryArguments['where']) =>
  useQueryEnhanced<
    FinancialFeeIndexResponse,
    GQL.IFinancialFeesIndexOnQueryArguments
  >(
    GET_FINANCIAL_FEES_INDEX,
    {
      skip: !where.userId,
      variables: {
        where,
      },
    },
  );

export const useGetFinancialFeesIndex = (schoolYearEnding: number) => usePaginatedListQuery<
    FinancialFeeIndexResponse, GQL.IFinancialFeeIndexItem>({
      dataSelector: (res) => res.financialFeesIndex.data,
      fullCountSelector: (res) => res.financialFeesIndex.fullCount,
      query: getFinancialFeesIndexQuery(schoolYearEnding),
      uniqueKey: schoolYearEnding.toString(),
    });

export const useGetFees = (options?: { fetchPolicy?: WatchQueryFetchPolicy; }) => {
  const [
    getFees,
    results,
  ] = useLazyQueryEnhanced<
    FinancialFeesResponse,
    FinancialFeesVariables
  >(
    GET_FINANCIAL_FEES,
    {
      fetchPolicy: options?.fetchPolicy ?? 'network-only',
    },
  );

  return {
    getFees,
    results,
  };
};

export const useGetFeesOverview = (schoolYearEnding: number) =>
  useQueryEnhanced<
    FinancialFeesOverviewResponse,
    GQL.IFinancialFeesOverviewOnQueryArguments
  >(
    GET_FINANCIAL_FEES_OVERVIEW,
    {
      skip: !schoolYearEnding,
      variables: {
        schoolYearEnding,
      },
    },
  );

const GET_FINANCIAL_OVERVIEW = gql`
  query FinancialOverview(
    $schoolYearEnding: Int!
  ) {
    financialFeesOverview(
      highItemCount: 10
      highUserCount: 10
      schoolYearEnding: $schoolYearEnding
    )  {
      itemsWithHighestBalance {
        balanceDueInCents
        balancePaidInCents
        id
        label
      }
      previousYearsTotalFeesInCents
      totalFeesInCents
      totalNumberOfOutstandingFees
      totalNumberOfUsersWithOutstandingBalance
      totalOutstandingBalanceInCents
      usersWithHighestBalance {
        balanceDueInCents
        email
        firstName
        id
        lastName
      }
    }
    financialCreditOverview(
      where: {
        schoolYearEnding: $schoolYearEnding
      }
    ) {
      totalCreditFromManualEntry
      totalCreditFromOverpayment
    }
  }
`;

export const useGetFinancialOverview = (schoolYearEnding: number) =>
  useQueryEnhanced<
    {
      financialCreditOverview: GQL.IFinancialCreditOverview;
      financialFeesOverview: GQL.IFinancialFeeOverview;
    },
    { schoolYearEnding: number }
  >(
    GET_FINANCIAL_OVERVIEW,
    {
      skip: !schoolYearEnding,
      variables: {
        schoolYearEnding,
      },
    },
  );

const GET_MY_FINANCIAL_FEES_OVERVIEW = gql`
  query MyFinancialOverview {
    myFinancialFeesOverview {
      balanceDueInCentsThisYear
      balanceDueInCentsPreviousYears
    }
  }
`;

export const useGetMyFinancialFeesOverview = () =>
  useQueryEnhanced<
    {
      myFinancialFeesOverview: GQL.IMyFinancialFeesOverview;
    }
  >(
    GET_MY_FINANCIAL_FEES_OVERVIEW,
  );

export const useGetFinancialCreditsIndex = () => usePaginatedListQuery<
    FinancialCreditIndexResponse, GQL.IFinancialCreditIndexItem>({
      dataSelector: (res) => res.financialCreditsIndex.data,
      fullCountSelector: (res) => res.financialCreditsIndex.fullCount,
      query: GET_FINANCIAL_CREDITS_INDEX,
    });

export const useGetMyFinancialCreditsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      MyFinancialCreditsIndexResponse,
      GQL.IFinancialCreditsIndexOnQueryArguments
    >
  ] => {
  const { self } = useSelfQuery();

  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    MyFinancialCreditsIndexResponse,
    GQL.IFinancialCreditsIndexOnQueryArguments
  >({
    getAdditionalVariables: (search) => getAdditionalMyCreditsVariables(search, self?.id ?? ''),
    gqlQuery,
    options,
    tableResource: 'myFinancialCredits',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetMyFinancialCreditsIndex = () => {
  const [query, values] = useGetMyFinancialCreditsIndexQuery(
    GET_MY_FINANCIAL_CREDITS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetFinancialCredit = (
  id: string,
) =>
  useQueryEnhanced<{
    financialCredit: GQL.IFinancialCredit
  }>(
    GET_FINANCIAL_CREDIT,
    {
      variables: { id },
    },
  );

export const useGetMyFinancialFeesIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      MyFinancialFeeIndexResponse,
      GQL.IMyFinancialFeesIndexOnQueryArguments
    >
  ] => {
  const { search: locationSearch } = useLocation();
  const parsedSearch = parseSearch(locationSearch);

  const { self } = useSelfQuery();

  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    MyFinancialFeeIndexResponse,
    GQL.IMyFinancialFeesIndexOnQueryArguments
  >({
    getAdditionalVariables: (search) => getAdditionalMyFeesVariables(search, self?.id ?? ''),
    gqlQuery,
    options,
    skipQuery: !parsedSearch.schoolYearEnding,
    tableResource: 'myFinancialFees',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetMyFinancialFeesIndex = () => {
  const [query, values] = useGetMyFinancialFeesIndexQuery(
    GET_MY_FINANCIAL_FEES_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetCreditsByUserId = (userId: string) => useQueryEnhanced<
  { financialCreditsByUserId: GQL.IFinancialCredit[] },
  GQL.IFinancialCreditsByUserIdOnQueryArguments
>(
  GET_FINANCIAL_CREDITS_BY_USER_ID,
  {
    skip: !userId,
    variables: {
      userId,
    },
  },
);

export const useGetFinancialFee = (
  id?: string,
) =>
  useQueryEnhanced<FinancialFeeResponse>(
    GET_FINANCIAL_FEE,
    {
      skip: !id,
      variables: { id },
    },
  );

export const useGetMyFinancialFeesAll = () =>
  useQueryEnhanced<MyFinancialFeesAllResponse>(
    GET_MY_FINANCIAL_FEES_ALL,
  );

export const useGetStripeFeeInCents = (subTotalInCents: number) =>
  useQueryEnhanced<{ stripeFeeInCents: number }, GQL.IStripeFeeInCentsOnQueryArguments>(
    GET_STRIPE_FEE_IN_CENTS,
    {
      variables: { subTotalInCents },
    },
  );

export const useGetFinancialAccounts = () => {
  const canQueryFinancialAccounts = useCanQueryFinancialAccounts();

  return useQueryEnhanced<{ financialAccounts: GQL.IFinancialAccount[] }>(
    GET_FINANCIAL_ACCOUNTS,
    {
      skip: !canQueryFinancialAccounts,
    },
  );
};

export const useGetFinancialAccount = (id: string) =>
  useQueryEnhanced<
    { financialAccount: GQL.IFinancialAccount },
    GQL.IFinancialAccountOnQueryArguments
  >(
    GET_FINANCIAL_ACCOUNT,
    { variables: { id } },
  );
