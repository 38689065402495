// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateManyFinancialFees } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import FeeForm from '../shared/FeeForm';

// Component Definition
const FinancialFeesNew: FC = () => {
  const navigate = useNavigate();

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));

  const pathToFeesIndexTable = `/${PATHS.FINANCIAL_FEES}${financialFeesParams}`;

  const navigateToShow = useCallback(() => {
    navigate(pathToFeesIndexTable);
  }, [navigate, pathToFeesIndexTable]);

  const [createFinancialFees] = useCreateManyFinancialFees(
    {
      clearCachePredicates: [
        'financialFees',
        'financialFeesIndex',
        'financialFeesOverview',
        'financialItem',
      ],
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateManyFinancialFeesInput,
  ) =>
    createFinancialFees({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'All Fees',
        path: pathToFeesIndexTable,
      }}
    >
      <FeeForm onSubmit={handleSubmitForm} />
    </Page>
  );
};

export default FinancialFeesNew;
