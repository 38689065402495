// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { FormikErrors } from 'formik';
import { useDebounce } from 'use-debounce';
import { useLocation, useNavigate } from '@reach/router';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { ApiData } from 'types/data';
import {
  EnhancedCard,
  EnhancedContainer,
  Flex,
  SkewedBackground,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  checkEmail,
  signUp,
} from 'utils/api';
import { isPasswordValid } from 'utils/lib/validate_password';
import { onboardingRedirectUser } from 'utils/lib/onboarding_redirect_user';
import { trimValues } from 'utils/lib/trimValues';
import { useParsedSearch } from 'hooks/useParsedSearch';
import { validateEmail } from 'utils';

// Local Dependencies
import CreateUserProfileForm from './CreateUserProfileForm';
import SignupContainer from './SignupContainer';

// Local Typings
export interface CreateValues {
  confirmPassword: string;
  email: string;
  hasAcceptedTerms: boolean;
  onboardingRole: DB.User.OnboardingRole;
  password: string;
}

// Local Variables
const StyledContainer = styled(EnhancedContainer)(({ theme }) => ({
  '.card-container': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    zIndex: 1,
  },

  '.title': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    zIndex: 1,
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(9),
  },
  paddingTop: theme.spacing(11),
}));

const initialValues: CreateValues = {
  confirmPassword: '',
  email: '',
  hasAcceptedTerms: false,
  onboardingRole: 'student',
  password: '',
};

// Component Definition
const CreateUserProfile: FC = () => {
  const navigate = useNavigate();
  const search = useParsedSearch();

  const updatedInitialValues: CreateValues = {
    ...initialValues,
    email: search.email ?? '',
    onboardingRole: search.onboardingRole,
  };

  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [submitError, setSubmitError] = useState('');
  const [hasHighGraceNotesValue, setHasHighGraceNotesValue] = useState('');
  const [emailValue, setEmailValue] = useState(search.email ?? '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [debouncedEmailValue] = useDebounce(emailValue, 500);

  const { pathname } = useLocation();

  // If there is no `onboardingRole` in the search params,
  //  we redirect to the `/signup` page
  useEffect(() => {
    if (!search.onboardingRole) {
      navigate(`/${PATHS.SIGNUP}`);
    }
  }, [navigate, search]);

  useEffect(() => {
    async function handleCheckEmail() {
      const response = await checkEmail({ email: debouncedEmailValue });

      const emailAvailableRes = response?.data?.emailAvailable;
      setIsEmailAvailable(emailAvailableRes);
    }

    if (debouncedEmailValue) {
      handleCheckEmail();
    }
  }, [debouncedEmailValue, setIsEmailAvailable]);

  const validate = useCallback((formValues: any): any => {
    const errors: {
      [key: string]: string | undefined
    } = {};

    // Map over values, make missing ones required
    Object.entries(formValues).forEach(([k, v]) => {
      if (Object.keys(initialValues).includes(k) && !v) {
        errors[k] = 'Required';
      }
    });

    /* Email */
    if (!validateEmail(formValues.email.trim())) {
      errors.email = 'Please enter a valid email';
    } else if (!isEmailAvailable) {
      errors.email = 'That username already exists';
    }

    /* Password */
    if (!isPasswordValid(formValues.password).isValid) {
      errors.password = 'Must contain a letter, number, no spaces, and be at least 8 characters';
    }

    /* Confirm password */
    if (formValues.password !== formValues.confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
    }

    return errors;
  }, [isEmailAvailable]);

  // We need onboardingRole to correctly use these components
  if (!search.onboardingRole) {
    return null;
  }

  const handleSubmitForm = async (
    values: CreateValues,
    setErrors: (errors: FormikErrors<CreateValues>) => void,
  ) => {
    // If the honeypot was filled out, then we exit early
    if (hasHighGraceNotesValue) {
      setSubmitError('Only humans may join this organization.');
      throw new Error('Only humans may join this organization.');
      // If we have a submitPayload, then we attempt to send it to the API
    } else {
      try {
        setIsSubmitting(true);
        const response = await signUp(trimValues(values) as unknown as ApiData.SignupPayload);

        if (response.data?.errors) {
          setIsSubmitting(false);
          setErrors(response.data.errors);
          setSubmitError(response.data.errors[0]);
        } else if (response.data && !response.data.error) {
          const {
            currentOrgId,
            onboarding: {
              stages,
            },
          } = response.data;

          setIsSubmitting(false);

          onboardingRedirectUser(
            navigate,
            null,
            currentOrgId,
            null,
            stages,
            null,
            pathname,
            { ignoreQueryString: true },
          );
        } else if (response.data?.error) {
          console.log(
            'Error from axios while submitting create user profile form',
            response.data.error,
          );
          throw new Error(response.data.error);
        }
      } catch (err: any) {
        // If we catch the error above, it is an object with this shape
        //  { name: 'Error', message: errorTextFromApi }
        setIsSubmitting(false);
        setSubmitError(typeof err === 'object' ? err.message : err);
      }
    }
  };

  return (
    <SignupContainer>
      <StyledContainer>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="space-around"
        >
          <SkewedBackground withGrid />

          <Typography
            className="title"
            component="h1"
            variant="h4"
          >
            Create Your User Profile
          </Typography>

          <section className="card-container">
            <EnhancedCard>
              <CardContent>
                <CreateUserProfileForm
                  hasHighGraceNotesValue={hasHighGraceNotesValue}
                  initialValues={updatedInitialValues}
                  isSubmitting={isSubmitting}
                  onSubmitForm={handleSubmitForm}
                  onUpdateEmail={setEmailValue}
                  setHasHighGraceNotesValue={setHasHighGraceNotesValue}
                  submitError={submitError}
                  validate={validate}
                />
              </CardContent>
            </EnhancedCard>
          </section>
        </Flex>
      </StyledContainer>
    </SignupContainer>
  );
};

export default CreateUserProfile;
