// External Dependencies
import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { alpha } from '@mui/material/styles';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import BarcodeScanIcon from 'mdi-material-ui/BarcodeScan';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled, {
  css, keyframes, useTheme,
} from 'styled-components';

// Internal Dependencies
import {
  Container,
  EnhancedCard,
  InventoryDataCard,
  RefreshingIcon,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { StyledStrong } from 'pages/EndOfYear/shared/styles';
import { getFullName, pluralize } from 'utils';
import { lightTheme } from 'components/shared/theme';
import { tableQueryParams } from 'state/table/selectors';
import { useCheckOutInventoryFromBarcode } from 'gql/mutations';
import {
  useGetInventoryCheckoutsByMemberId,
  useGetStudent,
} from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import usePrevious from 'hooks/usePrevious';

// Local Dependencies
import ToggleCheckoutModeCard from '../shared/ToggleCheckoutModeCard';

// Local Variables
const pulse = keyframes`
  0% {
    box-shadow: 0 0 12px rgba(51, 204, 255, 0.1);
  }
  50% {
    box-shadow: 0 0 12px ${lightTheme.palette.prestoPrimaryLight};
  }
  100% {
    box-shadow: 0 0 12px rgba(51, 204, 255, 0.1);
  }
`;

const animation = () => css`
  ${pulse} 1.5s infinite ease-in-out;
`;

const StyledContainer = styled(Container)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },

  '.barcode-scan-icon': {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    height: theme.spacing(15),
    width: theme.spacing(15),
  },

  '.pseudo-hidden-input': {
    opacity: 0,
  },
}));

const StyledFocusedActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    border: `0.15em solid ${theme.palette.prestoPrimaryLight}`,
    fontSize: 16,
    padding: `${theme.spacing(1)} 0`,
  },
  backgroundColor: alpha(theme.palette.prestoPrimaryLight, 0.05),
  border: `0.2em solid ${theme.palette.prestoPrimaryLight}`,
  // a high border-radius number will ensure round corners regardless of button height
  borderRadius: 100,
  fontSize: 18,
  padding: `${theme.spacing(1.5)} 0`,
  textAlign: 'center',
  width: '60%',
}));

const StyledUnfocusedActionButton = styled(StyledFocusedActionButton)`
  animation: ${animation};

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

// Component Definition
const InventoryCheckoutsBarcodeScan: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const parsedSearch = useParsedSearch();

  const {
    memberId,
  } = parsedSearch;

  const studentParams = useSelector(tableQueryParams('students'));

  const handleNavigateToStudentsTable = useCallback(() => {
    navigate(`/${PATHS.STUDENTS}${studentParams}`);
  }, [navigate, studentParams]);

  const [barcode, setBarcode] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  const previousInputRef = usePrevious(inputRef);
  let submitTimeout: ReturnType<typeof setTimeout>;

  const {
    data: studentData,
    loading: loadingStudentData,
  } = useGetStudent(memberId);

  const {
    data,
    loading,
  } = useGetInventoryCheckoutsByMemberId(memberId);

  const handleResetBarcodeState = () => {
    if (inputRef !== null && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
    setBarcode('');
    setHasSubmitted(false);
  };

  const [
    checkOutInventoryFromBarcode,
    {
      error: submitBarcodeError,
      loading: isSubmitting,
    },
  ] = useCheckOutInventoryFromBarcode({
    onCompleted: handleResetBarcodeState,
  });

  const previousSubmitBarcodeError = usePrevious(submitBarcodeError);

  // We need to focus the hidden input the ref is set
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();

      inputRef.current.addEventListener('focusin', () => {
        setInputHasFocus(true);
      });
      inputRef.current.addEventListener('focusout', () => {
        setInputHasFocus(false);
      });
    }
  // unsure if inputRef.current will ever change, but just in case
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, previousInputRef]);

  useEffect(() => {
    // If we received a barcode error message, then we clear
    //  out the barcode state and the HTML input value
    if (!previousSubmitBarcodeError && submitBarcodeError?.message) {
      handleResetBarcodeState();
    }
  }, [previousSubmitBarcodeError, submitBarcodeError]);

  // We add a bit of buffer time to show the refreshing icon
  // animating and delight the user
  useEffect(() => {
    if (hasSubmitted) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      submitTimeout = setTimeout(() => {
        checkOutInventoryFromBarcode({
          variables: {
            input: {
              barcode,
              memberId,
            },
          },
        });
      }, 1000);
    }

    // Clear the timeout when the component unmounts
    return () => clearTimeout(submitTimeout);
  }, [checkOutInventoryFromBarcode, hasSubmitted]);

  if (loading || loadingStudentData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        mt={3}
      >
        <CircularProgress size={72} />
      </Box>
    );
  }

  if (!data || !studentData) {
    return null;
  }

  const {
    firstName,
    lastName,
    middleName,
  } = studentData.user;

  const name = getFullName({
    firstName,
    lastName,
    middleName,
  });

  const { inventoryCheckouts } = data.inventoryCheckoutsByMemberId;

  const inventoryCheckoutsCount = inventoryCheckouts.length;
  const hasInventoryData = inventoryCheckoutsCount > 0;

  const handleBarcodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value);
  };

  // A barcode scanner will hit "return" after a scan
  // We prevent the default form submit here
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setHasSubmitted(true);
  };

  // These ternary statements are easier to read
  //  than switching around the JSX below
  const LargeIcon = isSubmitting || hasSubmitted
    ? RefreshingIcon
    : BarcodeScanIcon;

  const ActionButton = inputHasFocus
    ? StyledFocusedActionButton
    : StyledUnfocusedActionButton;

  return (
    <StyledContainer maxWidth={600}>
      <Box my={2}>
        <Box mb={2}>
          <EnhancedCard>
            {/* Hidden input for instant scanning goodness */}
            <form onSubmit={handleSubmit}>
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                className="pseudo-hidden-input"
                onChange={handleBarcodeChange}
                ref={inputRef}
                type="text"
              />
            </form>

            <CardContent>
              <Collapse
                in={inputHasFocus}
                mountOnEnter
              >
                <Box textAlign="center">
                  <Typography
                    component="h2"
                    variant="h4"
                  >
                    Ready to scan an inventory item
                  </Typography>
                </Box>
              </Collapse>

              <Collapse
                in={!inputHasFocus}
                mountOnEnter
              >
                <Box textAlign="center">
                  <Typography
                    color="error"
                    component="h2"
                    variant="h5"
                  >
                    Press the big purple button to scan an item.
                  </Typography>
                </Box>
              </Collapse>

              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                mt={4}
              >
                <ActionButton
                  onClick={handleResetBarcodeState}
                >
                  <LargeIcon
                    className="barcode-scan-icon"
                    htmlColor={theme.palette.grey[600]}
                  />
                </ActionButton>
              </Box>

              <Box
                mt={2}
                textAlign="center"
              >
                <Typography>
                  Mode: check out
                </Typography>
              </Box>
            </CardContent>

            <Divider />

            <ToggleCheckoutModeCard currentMode="scanner" />
          </EnhancedCard>
        </Box>

        <Box
          mb={2}
          mt={4}
        >
          <Subtitle>Current Inventory Checkout</Subtitle>

          <EnhancedCard>
            <CardContent>
              <Box mb={2}>
                <Typography>
                  {name} has{' '}
                  <StyledStrong>{inventoryCheckoutsCount}</StyledStrong>{' '}
                  inventory items {pluralize(inventoryCheckoutsCount, 'item')}{' '}
                  checked out.
                </Typography>
              </Box>

              <Collapse
                in={hasInventoryData}
                mountOnEnter
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {inventoryCheckouts.map((inventoryCheckout) => inventoryCheckout.active && (
                    <InventoryDataCard
                      inventoryCheckoutData={inventoryCheckout}
                      key={inventoryCheckout.id}
                    />
                  ))}
                </Box>
              </Collapse>

              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  color="primary"
                  onClick={handleNavigateToStudentsTable}
                  variant="contained"
                >
                  All Done
                </Button>
              </Box>
            </CardContent>
          </EnhancedCard>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default InventoryCheckoutsBarcodeScan;
