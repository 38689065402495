// External Dependencies
import {
  DataGridProProps,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {
  Ref, forwardRef, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { PeoplePickerTableResource, updateTableQueryParams } from 'state/table/actions';
import { close } from 'state/ui/peoplePickerDialog/actions';
import { parseSearch } from 'utils';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { tableQueryParams } from 'state/table/selectors';
import PeoplePickerTable from 'components/shared/PeoplePickerTable';

// Local Dependencies
import EnhancedDialog from './EnhancedDialog';
import SlideUpTransition from './SlideUpTransition';

// Local Typings
interface Props {
  canSeeAllMembers?: boolean;
  hideCheckAll?: boolean;
  isOpen: boolean;
  isRowSelectable?: DataGridProProps['isRowSelectable'];
  maxSelectionCount?: number;
  onAddSelectedIds: (selectedIds: string[]) => void;
  preSelectedIds?: string[];
  tableResource: PeoplePickerTableResource;
  title: string;
}

// Local Variables
const StyledEnhancedDialog = styled(EnhancedDialog)(({ theme }) => ({
  '.dialogPeoplePickerContent': {
    padding: 0,
  },
  '.dialogPeoplePickerPaper': {
    borderRadius: theme.spacing(2, 2, 0, 0),
    bottom: 0,
    margin: theme.spacing(0, 1.5),
    maxHeight: '94%',
    maxWidth: 'max(95vw, 1650px)',
    position: 'absolute',
  },
  '.dialogTitle': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// Component Definition
const DialogPeoplePicker = forwardRef((
  props: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    canSeeAllMembers = true,
    hideCheckAll,
    isOpen,
    isRowSelectable,
    maxSelectionCount,
    onAddSelectedIds,
    preSelectedIds,
    tableResource,
    title,
  } = props;

  const [
    selectedMemberIds,
    setSelectedMemberIds,
  ] = useState<GridRowSelectionModel>(preSelectedIds ?? []);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(close());
    setSelectedMemberIds([]);
  }, [dispatch]);

  useEffect(() => {
    // We check isOpen here to avoid unsetting selection ids when the "Discard" button is pressed
    if (isOpen) {
      setSelectedMemberIds(preSelectedIds ?? []);
    }
  }, [isOpen, preSelectedIds]);

  // Sometimes we allow a user to select a fixed number of people
  // This will update the selected id and close when
  //  the max selected count is reached.
  useEffect(() => {
    if (maxSelectionCount === selectedMemberIds.length) {
      onAddSelectedIds(selectedMemberIds as string[]);
      handleClose();
    }
  }, [handleClose, maxSelectionCount, onAddSelectedIds, selectedMemberIds]);

  useEffect(() => {
    if (isOpen && maxSelectionCount) {
      setSelectedMemberIds([]);
    }
  }, [isOpen, maxSelectionCount]);

  const handleAddIds = useCallback(() => {
    onAddSelectedIds(selectedMemberIds as string[]);
    handleClose();
  }, [
    handleClose,
    onAddSelectedIds,
    selectedMemberIds,
  ]);

  // Each PeoplePickerTable will track its own Redux params separately
  // This will help users get a workflow set up for different parts of the app
  const peoplePickerEmailParams = useSelector(tableQueryParams(tableResource));

  const parsedParams = parseSearch(peoplePickerEmailParams);

  // This table doesn't use URL query params,
  //  but we still track the param values in Redux
  const handleUpdatePeoplePickerTableQueryParams = useCallback((
    updatedQueryParams: object,
  ) => {
    dispatch(updateTableQueryParams({
      key: tableResource,
      value: stringifyQueryParams({
        ...parsedParams,
        ...updatedQueryParams,
      }),
    }));
  }, [dispatch, parsedParams, tableResource]);

  return (
    <StyledEnhancedDialog
      TransitionComponent={SlideUpTransition}
      classes={{
        paper: 'dialogPeoplePickerPaper',
      }}
      fullScreen
      maxWidth={false}
      onClose={handleClose}
      open={isOpen}
      ref={ref}
      scroll="paper"
    >
      <DialogTitle className="dialogTitle">
        <Typography
          component="span"
          variant="h6"
        >
          {title}
        </Typography>

        <IconButton
          aria-label={`Close ${title} dialog`}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialogPeoplePickerContent">
        <PeoplePickerTable
          canSeeAllMembers={canSeeAllMembers}
          hideCheckAll={hideCheckAll}
          isRowSelectable={isRowSelectable}
          onSetSelectedMemberIds={setSelectedMemberIds}
          onUpdateParams={handleUpdatePeoplePickerTableQueryParams}
          params={peoplePickerEmailParams}
          selectedMemberIds={selectedMemberIds}
          tableResource={tableResource}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          Discard
        </Button>

        <Button
          color="primary"
          onClick={handleAddIds}
          variant="contained"
        >
          {title}
        </Button>
      </DialogActions>
    </StyledEnhancedDialog>
  );
});

export default DialogPeoplePicker;
