// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from '@reach/router';

// Internal Dependencies
import {
  getLatestActivity,
  getToken,
  setAdminCookie,
  setLatestActivity,
  setTempCookie,
} from 'utils/cookies';
import {
  isTokenAboutToExpire,
  refreshTokenIfAboutToExpire,
} from 'utils/lib/token';
import { logoutCurrentUser } from 'state/self/actions';
import { parseSearch } from 'utils';
import {
  refreshToken,
  useLogout,
} from 'utils/api';
import Loader from 'components/shared/Loader';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import LogoutWarningDialog from './LogoutWarningDialog';

// Local Typings
interface Props {
  children: any;
}

// Local Variables
const initCookies = () => {
  const search = parseSearch(window.location.search);

  const searchToken = search.token;

  if (searchToken) {
    setTempCookie(searchToken);
  }

  if (document.referrer.includes(process.env.REACT_APP_ADMIN_URL ?? 'this-should-fail-if-env-var-is-missing')) {
    setAdminCookie();

    window.location.href = '/dashboard';
  }
};

let timeout: number;

const interactionListener = () => (logout: ReturnType<typeof useLogout>) => {
  if (timeout) {
    window.clearTimeout(timeout);
  }

  timeout = window.setTimeout(async () => {
    setLatestActivity();

    await refreshTokenIfAboutToExpire(logout);
  }, 500);
};

const checkIsIdle = (minutes: number) => {
  const latestActivity = getLatestActivity();

  if (!latestActivity) {
    return true;
  }

  const now = Date.now();

  const thresholdInMs = minutes * 60 * 1000;
  const diff = now - Number(latestActivity);

  if (thresholdInMs < diff) {
    return true;
  }

  return false;
};

const skippablePathnames = [
  '/verify',
];

// Component Definition
const CookieWrapper: FC<Props> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [isAboutToBeLoggedOut, setIsAboutToBeLoggedOut] = useState(false);
  const { 1: setLastRefreshTimestamp } = useState(Date.now());

  const dispatch = useDispatch();

  const logout = useLogout();

  // The useCallback here prevents a possible infinite loop
  const handleLogout = useCallback(async () => {
    setIsAboutToBeLoggedOut(false);
    await logout();
    dispatch(logoutCurrentUser());
    navigate('/');
  }, [dispatch, logout, navigate]);

  const refreshAuthToken = async () => {
    setIsAboutToBeLoggedOut(false);
    await refreshToken();

    // We want to be sure we re-render the app after the token is refreshed
    // The token is stored in a cookie, so we can't expect a re-render automatically
    setLastRefreshTimestamp(Date.now());
  };

  // This is outside the location provider for reach router
  const { pathname } = useLocation();
  const isVerifyingEmail = skippablePathnames.includes(pathname);

  const { self } = useSelfQuery({
    skip: isVerifyingEmail,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (self) {
        refreshAuthToken();
      }
    }, 1_800_000); // refresh token every 30 minutes

    return (() => {
      clearInterval(interval);
    });
  // We do not want to pass refreshAuthToken as a dependency here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self]);

  useEffect(() => {
    // when the app first loads, set the initial latest activity
    setLatestActivity();

    const interval = setInterval(async () => {
      const isIdle = checkIsIdle(2);

      if (isIdle && await isTokenAboutToExpire(logout)) {
        setIsAboutToBeLoggedOut(true);
      }
    }, 15000);

    return (() => {
      clearInterval(interval);
    });
  }, [logout]);

  useEffect(() => {
    initCookies();

    if (getToken() && !isVerifyingEmail) {
      refreshAuthToken();
    }

    document.addEventListener('click', interactionListener, false);
    document.addEventListener('keydown', interactionListener, false);

    return (() => {
      document.removeEventListener('click', interactionListener, false);
      document.removeEventListener('keydown', interactionListener, false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!self && getToken() && !isVerifyingEmail) {
    return <Loader />;
  }

  return (
    <>
      <LogoutWarningDialog
        isOpen={isAboutToBeLoggedOut}
        onLogout={handleLogout}
        onStayLoggedIn={refreshAuthToken}
      />
      {children}
    </>
  );
};

export default CookieWrapper;
