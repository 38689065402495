// External Dependencies
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import SignupCard from './SignupCard';
import SignupContainer from './SignupContainer';
import SignupRoleCardsContainer from './SignupRoleCardsContainer';
import StudentParentSignUpCards from './StudentParentSignUpCards';

// Component Definition
const ChooseRole: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const search = useParsedSearch();

  const TRANSITION_DURATION = theme.transitions.duration.standard;

  const handlePressButton = useCallback((role: DB.User.OnboardingRole) => {
    navigate(`/${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}?onboarding_role=${role}`);
  }, [navigate]);

  // If there is no `onboardingRole` in the search params,
  //  we redirect to the `/signup` page
  useEffect(() => {
    if (!search.onboardingRole) {
      navigate(`/${PATHS.SIGNUP}`);
    }
  }, [navigate, search]);

  const isDistrictRole = search.onboardingRole === 'administrator';
  const isOrganizationRole = search.onboardingRole === 'director';
  const isStudentOrParentRole = search.onboardingRole === 'student_parent';

  const adminChoices = (
    <>
      <SignupCard
        buttonText="Choose Role"
        isChooseRoleView
        listItem1="You're a Fine Arts Administrator for the district"
        onClickButton={() => handlePressButton(
          'administrator',
        )}
        subheader={`Set up your ${APP_NAME} district`}
        title="Administrator"
        transitionTimeout={TRANSITION_DURATION}
      />
      <SignupCard
        buttonText="Choose Role"
        isChooseRoleView
        listItem1={`An Administrator invited you to join a ${APP_NAME} district`}
        onClickButton={() => handlePressButton(
          'district_assistant',
        )}
        subheader={`Join your ${APP_NAME} district`}
        title="District Assistant"
        transitionTimeout={TRANSITION_DURATION * 3}
      />
    </>
  );

  const directorChoices = (
    <>
      <SignupCard
        buttonText="Choose Role"
        isChooseRoleView
        listItem1="You're a director for band, choir, dance, guitar, mariachi, orchestra, or theater"
        onClickButton={() => handlePressButton(
          'director',
        )}
        subheader={`Set up your ${APP_NAME} organization`}
        title="Director"
        transitionTimeout={TRANSITION_DURATION}
      />
      <SignupCard
        buttonText="Choose Role"
        isChooseRoleView
        listItem1={`A director has invited you to join an existing ${APP_NAME} organization`}
        onClickButton={() => handlePressButton(
          'assistant_director',
        )}
        subheader={`Join your ${APP_NAME} organization`}
        title="Assistant Director"
        transitionTimeout={TRANSITION_DURATION * 3}
      />
    </>
  );

  return (
    <SignupContainer>
      <SignupRoleCardsContainer>
        <Typography
          className="title"
          component="h1"
          gutterBottom
          variant="h4"
        >
          Choose Your {isDistrictRole ? 'District' : 'Organization'} Role
        </Typography>

        <section className="card-container">
          {isDistrictRole && adminChoices}

          {isOrganizationRole && directorChoices}

          {isStudentOrParentRole && <StudentParentSignUpCards isChooseRoleView />}
        </section>
      </SignupRoleCardsContainer>
    </SignupContainer>
  );
};

export default ChooseRole;
