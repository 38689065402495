// External Dependencies
import {
  GlobalEditField,
  GlobalEditSelectField,
  SelectOption,
  globalEditResources,
} from '@presto-assistant/api_types/api/v1/globalEdit';
import { GridRowId } from '@mui/x-data-grid-pro';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Internal Dependencies
import { EnhancedCard } from 'components/shared/index';
import { addNotification } from 'state/notifications/actions';
import { evictQueryCache } from 'utils/lib/graphql';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useUpdateGlobalEdit } from 'utils/api/globalEdit';
import SaveButton from 'components/shared/SaveButton';

// Local Typings
interface Props {
  fieldValues: Record<string, string>;
  globalEditResource: typeof globalEditResources[number];
  onClickGoBack: () => void;
  onClose: () => void;
  rowIds: GridRowId[];
  selectedFields: GlobalEditField[];
}

// Component Definition
const ReviewUI = ({
  fieldValues,
  globalEditResource,
  onClickGoBack,
  onClose,
  rowIds,
  selectedFields,
}: Props): JSX.Element => {
  const {
    isLoading: isUpdating,
    mutate: updateGlobalEdit,
  } = useUpdateGlobalEdit();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const rowCount = rowIds.length;

  const handleSubmit = useCallback(() => {
    updateGlobalEdit({
      changes: fieldValues,
      ids: rowIds as string[],
      resource: globalEditResource,
    }, {
      onSuccess: () => {
        onClose();

        let message = 'Success';

        if (globalEditResource === 'student') {
          message = 'Students updated successfully';

          evictQueryCache(queryClient, ['student']);
        }

        if (globalEditResource === 'uniform') {
          message = 'Uniforms updated successfully';

          evictQueryCache(queryClient, ['uniform']);
        }

        if (globalEditResource === 'inventory_item') {
          message = 'Inventory items updated successfully';

          evictQueryCache(queryClient, ['inventory']);
        }

        if (globalEditResource === 'library_item') {
          message = 'Library items updated successfully';

          evictQueryCache(queryClient, ['library']);
        }

        dispatch(updateIsPaginatedListDataLoaded({
          isPaginatedListDataLoaded: false,
        }));

        dispatch(addNotification(message, 'success'));
      },
    });
  }, [
    dispatch,
    fieldValues,
    globalEditResource,
    onClose,
    queryClient,
    rowIds,
    updateGlobalEdit,
  ]);

  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Confirm the changes for {rowCount} {pluralize(rowCount, toTitleCase(globalEditResource))}
        </DialogContentText>

        <EnhancedCard>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>New Value</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(fieldValues).map(([fieldId, fieldValue]) => {
                const selectedField = selectedFields.find((field) => field.id === fieldId);
                const value = (selectedField as GlobalEditSelectField)?.options?.find(
                  (option: SelectOption) => option.id.toString() === fieldValue.toString(),
                )?.label ?? fieldValue;

                return (
                  <TableRow key={fieldId}>
                    <TableCell>{selectedField?.label ?? 'Something went wrong'}</TableCell>
                    <TableCell>{value || '(Removing)'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </EnhancedCard>
      </DialogContent>

      <CardActions
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          onClick={onClickGoBack}
        >
          Go Back
        </Button>
        <SaveButton
          isSaving={isUpdating}
          onClick={handleSubmit}
        >
          Confirm
        </SaveButton>
      </CardActions>
    </>
  );
};

export default ReviewUI;
