// External Dependencies
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApolloProvider } from '@apollo/client';
import { FC, useMemo } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LocationProvider } from '@reach/router';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import {
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';

// Internal Dependencies
import {
  ErrorBoundary,
  darkTheme,
  lightTheme,
  midnightTheme,
  slateTheme,
} from 'components/shared';
import { ValidationError } from 'yup';
import { addNotification } from 'state/notifications/actions';
import { formatYupError } from '@presto-assistant/api_types/utils';
import { initializeStore } from 'state/configureStore';
import { useThemePreference } from 'state/ui/quickSettingsMenu/selectors';
import CookieWrapper from 'components/shared/CookieWrapper';
import DarkTheme from 'components/shared/DarkTheme';
import HealthStatusProvider from 'components/shared/HealthStatusProvider';
import LightTheme from 'components/shared/LightTheme';
import NotificationHandler from 'components/shared/MainContentContainer/NotificationHandler';
import Routes from 'routes';
import StripeProvider from 'components/shared/StripePaymentForm/StripeProvider';
import SuccessDialog from 'components/shared/SuccessDialog';
import client from 'gql/client';

// Local Dependencies
import { initializeSentry } from './utils/sentry';

// Local Typings
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

// Enable sentry monitoring
initializeSentry();

const TanStackProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        mutations: {
          onSuccess: (res) => {
            const downloadUrlHeader = (res as any).headers?.['x-download-url'];

            if (downloadUrlHeader) {
              window.open(downloadUrlHeader, '_blank');
            }
          },
        },
        queries: {
          cacheTime: 1000 * 60 * 5, // 5 minutes
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5, // 5 minutes
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          console.log('here is the error : ', error);

          const responseError = (error as any)?.response?.data?.error;

          const errorMessage = responseError
          || (error as any).message;

          if ((responseError as ValidationError)?.inner) {
            const formattedErrors = formatYupError(responseError as unknown as ValidationError);

            const errorMessages = Object.values(formattedErrors);

            errorMessages.forEach((msg) => {
              dispatch(addNotification(msg, 'error'));
            });
          } else {
            dispatch(addNotification(errorMessage || 'Something went wrong', 'error'));
          }
        },
      }),
    });
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      {children}
    </QueryClientProvider>
  );
};

const GlobalStateProviders: FC = ({ children }) => (
  <ApolloProvider client={client}>
    <ReduxProvider store={initializeStore()}>
      <TanStackProvider>
        {children}
      </TanStackProvider>
    </ReduxProvider>
  </ApolloProvider>
);

const StyleProviders: FC = ({ children }) => {
  const themePreference = useSelector(useThemePreference);

  const theme = useMemo(() => {
    switch (themePreference) {
      case 'dark':
        return darkTheme;
      case 'midnight':
        return midnightTheme;
      case 'slate':
        return slateTheme;
      default:
        return lightTheme;
    }
  }, [themePreference]);

  const isAppDarkMode = themePreference !== 'light';

  return (
    <StylesProvider
      // This ensures that MUI styles occur at the beginning of <head>
      // So styled-components will win when specificity is otherwise equal
      injectFirst
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <StyledComponentsThemeProvider theme={theme}>
            {isAppDarkMode ? <DarkTheme /> : <LightTheme />}

            {children}
          </StyledComponentsThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

// Component Definition
const App: FC = () => (
  <GlobalStateProviders>
    <StyleProviders>
      <HealthStatusProvider>
        <ErrorBoundary>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
          >
            <LocationProvider>
              <CookieWrapper>
                <StripeProvider>
                  <NotificationHandler />

                  <Routes />

                  <SuccessDialog />
                </StripeProvider>
              </CookieWrapper>
            </LocationProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </HealthStatusProvider>
    </StyleProviders>
  </GlobalStateProviders>
);

export default App;
