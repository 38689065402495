// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { parseSearch } from 'utils';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import {
  useLazyQueryEnhanced,
  useQueryEnhanced,
} from 'utils/lib/graphql';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
export interface FinancialPaymentIndexResponse {
  financialPaymentsIndex: GQL.IFinancialPaymentIndexItemAll;
}
interface MyFinancialPaymentIndexResponse {
  myFinancialPaymentsIndex: GQL.IFinancialPaymentIndexItemAll;
}
interface FinancialPaymentTotalsByDateResponse {
  financialPaymentTotalsByDate: GQL.IFinancialPaymentTotal[];
}
interface FinancialPaymentResponse {
  financialPayment: GQL.IFinancialPayment;
}
interface FinancialPaymentVariables {
  id: string;
}

// Local Variables
const getAdditionalMyPaymentVariables = (
  search: Record<string, any>,
  selfId: string,
) => {
  const where: GQL.IMyFinancialPaymentIndexWhereConditions = {
    financialItemId: search.financialItemId,
    schoolYearEnding: search.schoolYearEnding,
    userId: search.userId ?? selfId,
  };

  return { where };
};

// GraphQL Document Definitions
export const GET_FINANCIAL_PAYMENTS = gql`
  query FinancialPaymentsIndex(
    $queryParams: IndexQueryParams
    $where: FinancialPaymentIndexWhereConditions!
  ) {
    financialPaymentsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        amountInCents
        checkNumber
        creditAppliedInCents
        creditGeneratedInCents
        createdAt
        datePaid
        financialAccountId
        financialAccountLabel
        financialItemId
        financialItemLabel
        financialPaymentTypeId
        financialPaymentTypeLabel
        id
        refundedAt
        stripePaymentStatusId
        stripeRefundStatusId
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

export const GET_MY_FINANCIAL_PAYMENTS = gql`
  query MyFinancialPaymentsIndex(
    $queryParams: IndexQueryParams
    $where: MyFinancialPaymentIndexWhereConditions!
  ) {
    myFinancialPaymentsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        amountInCents
        checkNumber
        creditAppliedInCents
        creditGeneratedInCents
        createdAt
        financialItemId
        financialItemLabel
        financialPaymentTypeId
        financialPaymentTypeLabel
        id
        refundedAt
        stripePaymentStatusId
        stripeRefundStatusId
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

export const FRAGMENT_FINANCIAL_PAYMENT_SHOW = gql`
  fragment FinancialPaymentShowFragment on FinancialPayment {
    amountInCents
    amountWithStripeFeeInCents
    checkNumber
    createdAt
    createdBy {
      firstName
      id
      lastName
    }
    creditCardAccountHolder
    creditCardExp
    creditCardLastFour
    datePaid
    financialAccount {
      id
      label
    }
    financialCreditApplications {
      amountInCents
      createdBy {
        email
        id
        firstName
        lastName
      }
      financialCredit {
        amountInCents
        id
      }
    }
    id
    isEditable {
      reason
      status
    }
    isManualRefund
    financialCreditApplications {
      id
      amountInCents
    }
    financialFee {
      id
      financialItem {
        id
        label
        priceInCents
        schoolYearEnding
      }
      remainingBalanceInCents
    }
    financialPaymentType {
      id
      label
    }
    financialTransactionId
    note
    otherLabel
    overpaymentCredit {
      id
      amountInCents
    }
    referenceNumber
    refundedAt
    refundedBy {
      email
      firstName
      id
      lastName
    }
    refundedNote
    stripePaymentStatusId
    stripeRefundStatusId
    user {
      id
      firstName
      lastName
      role {
        id
        label
      }
    }
  }
`;

export const GET_FINANCIAL_PAYMENT = gql`
  query FinancialPayment($id: ID!) {
    financialPayment(id: $id) {
      ...FinancialPaymentShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_PAYMENT_SHOW}
`;

const GET_FINANCIAL_PAYMENTS_BY_DATE = gql`
  query FinancialPaymentTotalsByDate(
    $where: FinancialPaymentTotalsByDateWhereConditions!
  ) {
    financialPaymentTotalsByDate(
      where: $where
    ) {
      count
      date
      totalAmountInCents
    }
  }
`;

const GET_FINANCIAL_PAYMENT_TYPES = gql`
  query FinancialPaymentTypes {
    financialPaymentTypes {
      id
      label
    }
  }
`;

// Query Hook Definitions
export const useGetPayment = () => {
  const [
    getPayment,
    results,
  ] = useLazyQueryEnhanced<
    FinancialPaymentResponse,
    FinancialPaymentVariables
  >(GET_FINANCIAL_PAYMENT);
  return {
    getPayment,
    results,
  };
};

export const useGetFinancialPayment = (
  id: string,
) =>
  useQueryEnhanced<{
    financialPayment: GQL.IFinancialPayment
  }>(
    GET_FINANCIAL_PAYMENT,
    {
      variables: { id },
    },
  );

// Used in PaymentPickerTable for TableDataGrid
export const useGetFinancialAllPayments = (
  params: object,
) =>
  useQueryEnhanced<FinancialPaymentIndexResponse>(
    GET_FINANCIAL_PAYMENTS,
    {
      variables: {
        where: {
          ...params,
          schoolYearEnding: useGetOrganizationSchoolYear(),
        },
      },
    },
  );

export const useGetPaymentsByDate = (
  where: GQL.IFinancialPaymentTotalsByDateWhereConditions,
) => useQueryEnhanced<
  FinancialPaymentTotalsByDateResponse,
  GQL.IFinancialPaymentTotalsByDateOnQueryArguments
>(
  GET_FINANCIAL_PAYMENTS_BY_DATE,
  { variables: { where } },
);

export const getFinancialPaymentsIndexQuery = (schoolYearEnding: number) => gql`
  query FinancialPaymentsIndex(
    $queryParams: IndexQueryParams
  ) {
    financialPaymentsIndex(
      queryParams: $queryParams
      where: {
        schoolYearEnding: ${schoolYearEnding}
      }
    ) {
      data {
        amountInCents
        checkNumber
        creditAppliedInCents
        creditGeneratedInCents
        createdAt
        datePaid
        financialAccountId
        financialAccountLabel
        financialItemId
        financialItemLabel
        financialPaymentTypeId
        financialPaymentTypeLabel
        id
        isMemberActive
        note
        refundedAt
        stripePaymentStatusId
        stripeRefundStatusId
        userFirstName
        userId
        userLastName
      }
      fullCount
    }
  }
`;

export const useGetFinancialPaymentsIndex = (schoolYearEnding: number) => usePaginatedListQuery<
  FinancialPaymentIndexResponse, GQL.IFinancialPaymentIndexItem>({
    dataSelector: (res) => res.financialPaymentsIndex.data,
    fullCountSelector: (res) => res.financialPaymentsIndex.fullCount,
    query: getFinancialPaymentsIndexQuery(schoolYearEnding),
    uniqueKey: schoolYearEnding.toString(),
  });

export const useGetMyFinancialPaymentsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<MyFinancialPaymentIndexResponse,
      GQL.IMyFinancialPaymentsIndexOnQueryArguments>
  ] => {
  const { search: locationSearch } = useLocation();
  const parsedSearch = parseSearch(locationSearch);

  const { self } = useSelfQuery();

  const {
    apiRequest,
    values,
  } = useGetTableQuery<MyFinancialPaymentIndexResponse,
    GQL.IMyFinancialPaymentsIndexOnQueryArguments>({
      getAdditionalVariables: (search) => getAdditionalMyPaymentVariables(search, self?.id ?? ''),
      gqlQuery,
      options,
      skipQuery: !parsedSearch.schoolYearEnding,
      tableResource: 'myFinancialPayments',
    });

  return [
    apiRequest,
    values,
  ];
};

export const useGetMyFinancialPaymentsIndex = () => {
  const [query, values] = useGetMyFinancialPaymentsIndexQuery(
    GET_MY_FINANCIAL_PAYMENTS,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetPaymentTypes = () =>
  useQueryEnhanced<{
    financialPaymentTypes: GQL.IFinancialPaymentType[]
  }>(GET_FINANCIAL_PAYMENT_TYPES);
