// External Dependencies
import {
  GridRowId,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import AccountRemove from 'mdi-material-ui/AccountRemove';
import EmailIcon from 'mdi-material-ui/Email';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import TieIcon from 'mdi-material-ui/Tie';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { computeDataMutation } from 'components/shared/TableDataGrid/helpers';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { formatPhoneNumber } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useGetGenderOptions } from 'hooks/useGetGenderOptions';
import { useGetGradeOptions } from 'hooks/useGetGradeOptions';
import {
  useGetOrganization,
  useGetOrganizationRoles,
  useGetPossibleFeederOrganizations,
  useGetStudentsIndex,
  useGetStudentsIndexQuery,
} from 'gql/queries';
import { useGetStateOptions } from 'hooks/useGetStateOptions';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateUser } from 'gql/mutations';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import RemoveMemberDialog from 'pages/People/shared/PeopleDangerZone/RemoveMemberDialog';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import MemberToolbarActionDialogs, { useMemberToolbarActions } from './MemberToolbarActionDialogs';

// Local Variables
const tableResource = 'students';
const pinnedColumns = ['firstName', 'lastName'];

const REQUIRED_FIELDS = [
  'email',
  'firstName',
  'lastName',
];
// Include any optional fields that are strings or numbers
const OPTIONAL_FIELDS = [
  'addressOne',
  'addressTwo',
  'allergies',
  'city',
  'dateOfBirth',
  'gender',
  'grade',
  'isEligible',
  'middleName',
  'phoneNumber',
  'primaryRole',
  'privateLessonTeacher',
  'shirtSize',
  'state',
  'studentId',
  'successorOrganization',
  'zipcode',
];

// Component Definition
const StudentsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedMemberIds,
    setSelectedMemberIds,
  ] = useState<GridRowSelectionModel>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const canCreateFinancialPayments = useSelector(hasPermission('payments', 'write'));
  const canCreateUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'write'));
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const canCreateUsers = useSelector(hasPermission('users', 'write'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canRemoveMembers = useSelector(hasPermission('users', 'delete'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));

  const studentIndexQuery = useGetStudentsIndexQuery();

  const {
    isOpen: isRemoveUserConfirmationDialogOpen,
    toggleIsOpen: toggleRemoveUserConfirmationDialogIsOpen,
  } = useIsOpen();

  const {
    data,
    isLoading: isLoadingStudentsData,
    refetch,
  } = useGetStudentsIndex();

  const {
    data: organizationData,
    loading: isLoadingOrgData,
  } = useGetOrganization();

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  const {
    data: possibleFeederOrganizationData,
    loading: isLoadingFeederOrgData,
  } = useGetPossibleFeederOrganizations();

  const genderOptions = useGetGenderOptions();
  const gradeOptions = useGetGradeOptions();
  const stateOptions = useGetStateOptions();
  const mappedStateOptions = stateOptions?.options.map((option) => ({
    id: option.id.toString(),
    label: option.label,
    value: option.label,
  }));

  const isCollegeOrUniversity = organizationData?.organization.entityType.id
    === OrganizationEntityTypes.College.toString();

  const maxNumberOfAdults = useMemo(() => {
    if (!data?.length) {
      return 1;
    }

    const numberOfAdults = [
      ...new Set(data.map((student) => student.adults.length)),
    ];

    const defaultAdultColumnCount = isCollegeOrUniversity ? 0 : 1;

    // If nobody has adults, the director should at least see the empty columns, so we pass 1
    return Math.max(...numberOfAdults, defaultAdultColumnCount);
  }, [data, isCollegeOrUniversity]);

  const orgRolesData = rolesData?.organizationRoles;
  const feederOrgData = possibleFeederOrganizationData?.possibleFeederOrganizations;

  const isLoading = isLoadingStudentsData || isLoadingOrgData || isLoadingFeederOrgData;

  const [updateUser] = useUpdateUser(
    {
      refetchQueries: [{ query: studentIndexQuery }],
    },
  );

  const handleNavigateToNewPayment = useCallback((row: GQL.IStudentIndex) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_NEW}?userId=${row.id}`);
  }, [navigate]);

  const handleNavigateToUniformCheckoutBarcodeScan = useCallback((row: GQL.IStudentIndex) => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/${PATHS.BARCODE_SCAN}?userId=${row.id}`);
  }, [navigate]);

  const extraColumns = useMemo<DataGridColDef<GQL.IStudentIndex>[]>(
    () => {
      const handleClickEmailStudent = (row: GQL.IStudentIndex) => {
        // Add user id to the recipient ids for a new email
        dispatch(updateRecipients([row.id]));
        navigate(`/${PATHS.EMAIL_NEW}`);
      };

      const handleRemoveStudentClick = (row: GQL.IStudentIndex) => {
        setSelectedMemberId(row.id);
        toggleRemoveUserConfirmationDialogIsOpen();
      };

      const actionsColumn = createDataGridActionsColumn<GQL.IStudentIndex>([
        ...(canCreateFinancialPayments ? [{
          action: handleNavigateToNewPayment,
          icon: <LocalAtmIcon />,
          text: 'Add payment',
        }] : []),
        ...(canCreateUniformCheckouts ? [{
          action: handleNavigateToUniformCheckoutBarcodeScan,
          icon: <TieIcon />,
          text: 'Add uniform checkout',
        }] : []),
        ...(canEmailMembers ? [{
          action: handleClickEmailStudent,
          icon: <EmailIcon />,
          text: 'Email Student',
        }] : []),
        ...(canRemoveMembers ? [{
          action: handleRemoveStudentClick,
          icon: <AccountRemove />,
          text: 'Remove Student',
        }] : []),
      ]) as DataGridColDef<GQL.IStudentIndex>;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canCreateFinancialPayments,
      canCreateUniformCheckouts,
      canEmailMembers,
      canRemoveMembers,
      dispatch,
      handleNavigateToNewPayment,
      handleNavigateToUniformCheckoutBarcodeScan,
      navigate,
      toggleRemoveUserConfirmationDialogIsOpen,
    ],
  );

  const columns = useColumns({
    extraColumns,
    maxNumberOfAdults,
    tableResource,
  });

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        // We check if any edits were made. If not, we resolve
        //  the promise with the `oldRow` and avoid calling the API.
        const dataWasMutated = computeDataMutation({
          newRow,
          oldRow,
          optionalKeysToCompare: OPTIONAL_FIELDS,
          requiredKeysToCompare: REQUIRED_FIELDS,
        });

        if (dataWasMutated) {
          const {
            addressOne,
            addressTwo,
            city,
            dateOfBirth,
            email,
            firstName,
            gender,
            grade,
            id,
            isEligible,
            lastName,
            middleName,
            phoneNumber,
            primaryRole,
            privateLessonTeacher,
            shirtSize,
            state,
            studentId,
            successorOrganization,
            zipcode,
          } = newRow;

          const updatePrimaryRoleId = orgRolesData?.find((role) =>
            role.label === primaryRole?.label)?.id;

          const updatedSuccessorOrgId = feederOrgData?.find((feederOrg) =>
            feederOrg.id === successorOrganization?.id)?.id;

          const updatedGenderId = genderOptions.options.find((genderOption) =>
            genderOption.label === gender?.label)?.id;

          // To handle college and non-college grades, we have to coerce
          //  different parts of the statement to be typeof number
          const updatedGradeId = grade
            ? Number(gradeOptions.options.find((gradeOption) =>
              parseInt(gradeOption.id, 10) === Number(grade))?.id)
            : null;

          const updatedStateId = mappedStateOptions?.find((stateOption) =>
            stateOption.label === state?.label)?.id;

          const inputPayload: GQL.IUpdateUserInput = {
            addressOne,
            addressTwo,
            city,
            dateOfBirth,
            email,
            firstName,
            grade: updatedGradeId,
            isEligible,
            lastName,
            middleName: middleName ?? '',
            phoneNumber: formatPhoneNumber(phoneNumber),
            primaryRoleId: updatePrimaryRoleId,
            privateLessonTeacher,
            stateId: updatedStateId,
            studentInfo: {
              studentId: studentId ?? '',
            },
            successorOrganizationId: updatedSuccessorOrgId,
            zipcode,
          };

          if (updatedGenderId) {
            inputPayload.genderId = updatedGenderId;
          }

          if (shirtSize) {
            inputPayload.shirtSize = shirtSize;
          }

          updateUser({
            variables: {
              id,
              input: inputPayload,
            },
          });

          resolve(newRow); // Update the row with the new values
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [
      feederOrgData,
      genderOptions.options,
      gradeOptions.options,
      mappedStateOptions,
      orgRolesData,
      updateUser,
    ],
  );

  const handleTableClickRow = (id: string) => `/${PATHS.STUDENTS}/${id}`;

  const {
    isSendStatementDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    onCloseAssignMembersToGroupDialog,
    onCloseSendStatementDialog,
    toolbarActions,
  } = useMemberToolbarActions({
    filteredMemberIds: filteredRowIds as string[],
    selectedMemberIds: selectedMemberIds as string[],
    withImports: true,
  });

  const showSelection = canReadFinances || canEmailMembers || canEditGroups;

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canCreateUsers ? {
            label: 'Student',
            to: `/${PATHS.STUDENTS}/new`,
          } : null}
          checkboxSelection={showSelection}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          globalEditResource="student"
          leftPinnedColumns={pinnedColumns}
          loading={isLoading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedMemberIds}
          processRowUpdate={processRowUpdate}
          rows={data}
          selectionModel={selectedMemberIds}
          tableResource={tableResource}
          toolbarActions={toolbarActions}
          withEditMode={canEditUsers}
          withSearch
        />
      </DataGridContainer>

      {showSelection && (
        <MemberToolbarActionDialogs
          isSendStatementDialogOpen={isSendStatementDialogOpen}
          isUpdatingStudents={isUpdatingStudents}
          memberIdsToAssignToGroup={memberIdsToAssignToGroup}
          memberIdsToSendStatementsTo={memberIdsToSendStatementsTo}
          onCloseAssignMembersToGroupDialog={onCloseAssignMembersToGroupDialog}
          onCloseSendStatementDialog={onCloseSendStatementDialog}
        />
      )}

      {selectedMemberId && (
        <RemoveMemberDialog
          isOpen={isRemoveUserConfirmationDialogOpen}
          memberId={selectedMemberId}
          onClose={toggleRemoveUserConfirmationDialogIsOpen}
          onMemberDeleted={refetch}
        />
      )}
    </>
  );
};

export default StudentsTable;
