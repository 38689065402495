// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import AlertButton from './AlertButton';

// Component Definition
const StripeOrgSignUpButton: FC = () => {
  const navigate = useNavigate();

  const handlePressButton = useCallback(() => {
    navigate(`/${PATHS.STRIPE_VERIFICATION}`);
  }, [navigate]);

  return (
    <AlertButton
      buttonText="Manage"
      onClick={handlePressButton}
    />
  );
};

export default StripeOrgSignUpButton;
