// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateFinancialCredit } from 'gql/mutations';

// Local Dependencies
import CreditForm from '../shared/CreditForm';

// Component Definition
const FinancialCreditsNew = (): JSX.Element => {
  const navigate = useNavigate();
  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));

  const pathToCreditsIndexTable = `/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`;

  const navigateToShow = useCallback(() => {
    navigate(pathToCreditsIndexTable);
  }, [navigate, pathToCreditsIndexTable]);

  const [
    createFinancialCredit,
    {
      loading: isSubmitting,
    },
  ] = useCreateFinancialCredit(
    {
      clearCachePredicates: ['financialCredits', 'financialCreditsIndex'],
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateFinancialCreditInput,
  ) =>
    createFinancialCredit({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'All Credits',
        path: pathToCreditsIndexTable,
      }}
    >
      <CreditForm
        isLoading={isSubmitting}
        onSubmit={handleSubmitForm}
      />
    </Page>
  );
};

export default FinancialCreditsNew;
