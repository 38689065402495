// External Dependencies
import { FC, useEffect } from 'react';
import { Page } from 'components/shared';
import { RouteComponentProps, useParams } from '@reach/router';

// Internal Dependencies
import { redeemSso } from 'utils/api';

// Component Definition
const Sso: FC<RouteComponentProps> = () => {
  const { token } = useParams<{ token: string; }>();

  useEffect(() => {
    if (token) {
      redeemSso({ ssoToken: token });
    }
  }, [token]);

  return (
    <Page
      isLoading
    >
      <h2>Loading...</h2>
    </Page>
  );
};

export default Sso;
