// External Dependencies
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useEffect } from 'react';

// Internal Dependencies
import { Page } from 'components/shared';
import { useClassLinkToken } from 'utils/api/auth';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Component Definition
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const SsoClassLink = (_props: RouteComponentProps): JSX.Element => {
  const parsedSearch = useParsedSearch();

  const navigate = useNavigate();

  const code = parsedSearch.code as string;

  const {
    mutate: getClassLinkToken,
  } = useClassLinkToken();

  useEffect(() => {
    if (code) {
      getClassLinkToken({
        code,
      }, {
        onError: () => {
          navigate('/');
        },
      });
    }
  }, [code, getClassLinkToken, navigate]);

  return (
    <Page isLoading>
      Loading...
    </Page>
  );
};

export default SsoClassLink;
