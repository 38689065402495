// External Dependencies
import { FC } from 'react';
import { Genders } from '@presto-assistant/api_types';

// Internal Dependencies
import { ALL_GRADE_OPTIONS } from 'utils/constants/entityTypeOptions';
import { ShowPageDataDisplay } from 'components/shared';
import { StudentData, useGetOrganization } from 'gql/queries';
import { getFullName } from 'utils';

// Local Typings
interface Props {
  userData: StudentData | undefined;
}

// Component Definition
const ShowStudentProfileData: FC<Props> = ({ userData }) => {
  const {
    data: organizationData,
  } = useGetOrganization();

  const successorOrganization = organizationData?.organization.successorOrganization;

  if (!userData) {
    return null;
  }

  const {
    allergies,
    dateOfBirth,
    gender,
    grade,
    isEligible,
    otherGenderLabel,
    previousCampus,
    primarySpokenLanguage,
    privateLessonTeacher,
    shirtSize,
    studentInfo,
    successorOrganization: studentSuccessorOrganization,
  } = userData;

  const semanticGrade = ALL_GRADE_OPTIONS.find((option) =>
    option.id === grade?.toString());

  const defaultGenderLabel = gender?.id ? Genders[Number(gender.id)] : '';

  const genderLabel = gender?.id === Genders.Other.toString() && otherGenderLabel
    ? `${defaultGenderLabel} — ${otherGenderLabel}`
    : defaultGenderLabel;

  return (
    <>
      <ShowPageDataDisplay
        label="Name"
        value={getFullName(userData)}
      />
      <ShowPageDataDisplay
        label="Gender"
        value={genderLabel}
      />
      <ShowPageDataDisplay
        label="Grade"
        value={semanticGrade?.label}
      />
      <ShowPageDataDisplay
        label="Date of Birth"
        type="date"
        value={dateOfBirth}
      />
      <ShowPageDataDisplay
        label="Primary Spoken Language"
        value={primarySpokenLanguage?.label}
      />
      <ShowPageDataDisplay
        label="Shirt Size"
        value={shirtSize}
      />
      <ShowPageDataDisplay
        label="Student ID"
        value={studentInfo?.studentId}
      />
      <ShowPageDataDisplay
        label="Previous Campus"
        value={previousCampus}
      />
      <ShowPageDataDisplay
        label="Is Eligible?"
        type="boolean"
        value={isEligible}
      />

      {successorOrganization && (
        <ShowPageDataDisplay
          label="Successor Organization"
          value={studentSuccessorOrganization?.label ?? successorOrganization.label}
        />
      )}

      <ShowPageDataDisplay
        label="Lesson Teacher"
        value={privateLessonTeacher}
      />
      <ShowPageDataDisplay
        label="Allergies"
        value={allergies}
      />
    </>
  );
};

export default ShowStudentProfileData;
