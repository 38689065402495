// External Dependencies
import {
  FC, ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ImportOriginalSources, ImportResources } from '@presto-assistant/api_types';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';

// Internal Dependencies
import { DialogFileUpload, SaveButton } from 'components/shared';
import { MutationEnhancedOptions, useMutationEnhanced } from 'utils/lib/graphql';
import { capitalize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useCreateFilePresignedUrlForResource, useCreateImport } from 'gql/mutations';
import { useParsedSearch } from 'hooks/useParsedSearch';
import SuccessStep from 'components/CSVImporter/SuccessStep';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import FilePreview from './FilePreview';
import FileUpload, { FileUploadProps, ImportMethod } from './FileUpload';
import OrganizationTypeSelect from './OrganizationTypeSelect';

// Local Typings
interface Props extends FileUploadProps {
  context: string;
  description?: ReactNode;
  dialogTitle: string;
  fileUploadTipMessage?: ReactNode;
  fileUploadTipTitle?: string;
  importResourceId?: number;
  insertMutationName: string;
  insertMutationOptions?: Partial<MutationEnhancedOptions>;
  isAdmin?: boolean;
  isOpen: boolean;
  onClose: () => void;
  uploadMutationName: string;
}

enum Step {
  SelectOrganizationType = 1,
  UploadFile = 2,
  PreviewFile = 3,
  Success = 4,
}

// Local Variables
const importResourceIdMap: Record<number, string> = {
  [ImportResources.Members]: 'import-members',
  [ImportResources.Inventory]: 'import-inventory',
  [ImportResources.Library]: 'import-library',
  [ImportResources.Uniforms]: 'import-uniforms',
};

// Component Definition
const CSVImporter: FC<Props> = ({
  canMigrateFromAnotherSystem: canMigrateFromAnotherSystemProp,
  context,
  csvFileInfoResource,
  description,
  dialogTitle,
  fileUploadTipMessage,
  fileUploadTipTitle,
  importResourceId,
  insertMutationName,
  insertMutationOptions,
  isAdmin,
  isOpen,
  onClose,
  requiredColumnsHelperText,
  uploadMutationName,
}) => {
  const canMigrateFromAnotherSystem = canMigrateFromAnotherSystemProp && Boolean(importResourceId);

  const dispatch = useDispatch();

  const parsedSearch = useParsedSearch();

  const initialOrgTypeId = parsedSearch.organizationTypeId?.toString() ?? '1';

  const [organizationTypeId, setOrganizationTypeId] = useState<string>(
    parsedSearch.organizationTypeId?.toString() ?? '1',
  );

  const initialStep = isAdmin ? Step.SelectOrganizationType : Step.UploadFile;

  const [fileS3Url, setFileS3Url] = useState<string | null>(null);
  const [step, setStep] = useState<Step>(initialStep);
  const [fileName, setFileName] = useState('');
  const [isUploadingS3File, setIsUploadingS3File] = useState(false);

  const [importMethod, setImportMethod] = useState<ImportMethod>('template');
  const [selectedImportSourceId, setSelectedImportSourceId] = useState<number | null>(null);
  const otherImportSourceLabelField = useTextField();

  useEffect(() => {
    setOrganizationTypeId(initialOrgTypeId);
  }, [initialOrgTypeId]);

  const insertMutationArgs = isAdmin
    ? `
      fileId: $fileId
      headers: $headers
      organizationTypeId: $organizationTypeId
    `
    : `
      fileId: $fileId
      headers: $headers
    `;

  const insertMutationParams = isAdmin
    ? `
      $fileId: String!
      $headers: [String!]!
      $organizationTypeId: ID!
    `
    : `
      $fileId: String!
      $headers: [String!]!
    `;

  const titleCaseUploadMutationName = capitalize(uploadMutationName, { pascalCase: true });
  const titleCaseInsertMutationName = capitalize(insertMutationName, { pascalCase: true });

  const handleClose = () => {
    setStep(initialStep);
    setImportMethod('template');
    setFileName('');

    onClose();
  };

  const uploadMutationString = isAdmin
    ? `
      mutation ${titleCaseUploadMutationName}(
        $file: Upload!
        $organizationTypeId: ID!
      ) {
        ${uploadMutationName}(
          file: $file
          organizationTypeId: $organizationTypeId
        ) {
            acceptableCSVHeaders
            badRows {
              errorMessages
              row
              rowNumber
            }
            fileId
            goodRowCount
            headers
            message
            rows
        }
      }
    `
    : `
      mutation ${titleCaseUploadMutationName}(
        $file: Upload!
      ) {
        ${uploadMutationName}(
          file: $file
        ) {
            acceptableCSVHeaders
            badRows {
              errorMessages
              row
              rowNumber
            }
            fileId
            goodRowCount
            headers
            message
            rows
        }
      }
    `;

  const insertMutationString = `
    mutation ${titleCaseInsertMutationName}(
      ${insertMutationParams}
    ) {
      ${insertMutationName}(
        ${insertMutationArgs}
      )
    }
  `;

  /* eslint-disable graphql/template-strings */
  const csvUploadMutation = gql`${uploadMutationString}`;
  const csvInsertMutation = gql`${insertMutationString}`;
  /* eslint-enable graphql/template-strings */

  const [
    createImport,
    {
      loading: isCreatingImport,
    },
  ] = useCreateImport({
    onCompleted: () => setStep((s) => s + 1),
    ...insertMutationOptions,
  });

  const handleSuccess = useCallback(() => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));
  }, [dispatch]);

  const [uploadFile, { data: uploadPreviewData, loading: isUploadingFile }] = useMutationEnhanced(
    csvUploadMutation,
    {
      onCompleted: () => setStep((s) => s + 1),
    },
  );

  const [insertFile, { loading: isInsertingFile }] = useMutationEnhanced(
    csvInsertMutation,
    {
      onCompleted: () => setStep((s) => s + 1),
      ...insertMutationOptions,
    },
  );

  const [
    createFilePresignedUrlForResource,
  ] = useCreateFilePresignedUrlForResource();

  useEffect(() => {
    if (step === Step.Success) {
      handleSuccess();
    }
  }, [handleSuccess, step]);

  const isMigration = Boolean(canMigrateFromAnotherSystem && importMethod === 'migration');

  const handleUploadFile = async (file: File) => {
    setIsUploadingS3File(true);

    try {
      const presignedUrl = isMigration && importResourceId
        // eslint-disable-next-line no-async-promise-executor
        ? await new Promise<GQL.IPresignedUrl>(async (resolve, reject) => {
          try {
            const result = await createFilePresignedUrlForResource({
              variables: {
                input: {
                  filename: file.name,
                  filetype: file.type,
                  resource: importResourceIdMap[importResourceId],
                },
              },
            });

            if (!result.data?.createFilePresignedUrlForResource) {
              throw new Error('Could not get presigned URL');
            }

            resolve(result.data.createFilePresignedUrlForResource);
            return;
          } catch (error) {
            reject(error);
          }
        })
        : null;

      // eslint-disable-next-line no-await-in-loop
      const s3Response = presignedUrl ? await fetch(presignedUrl.putUrl, {
        body: file,
        method: 'PUT',
      }) : null;

      // Do not do optional chaining here
      // If there is no response, it is fine to continue
      if (s3Response && !s3Response.ok) {
        throw new Error('Unable to upload file.');
      }

      if (presignedUrl && s3Response?.ok) {
        setFileS3Url(presignedUrl.getUrl);
      }

      if (isMigration) {
        setStep((s) => s + 1);
      }

      setFileName(file.name);

      if (!isMigration) {
        uploadFile({ variables: isAdmin ? { file, organizationTypeId } : { file } });
      }
    } finally {
      setIsUploadingS3File(false);
    }
  };

  const previewData: GQL.ICSVUploadPreview = uploadPreviewData?.[uploadMutationName];

  const handleInsertFileToDb = useCallback(() => {
    if (isMigration) {
      if (fileS3Url && selectedImportSourceId && importResourceId) {
        createImport({
          variables: {
            input: {
              importOriginalSourceId: selectedImportSourceId,
              importOriginalSourceOtherLabel: otherImportSourceLabelField.value,
              importResourceId,
              s3Url: fileS3Url,
            },
          },
        });
      }
    } else {
      insertFile({
        variables: {
          fileId: previewData?.fileId,
          headers: previewData?.headers,
          ...(isAdmin ? { organizationTypeId } : {}),
        },
      });
    }
  }, [
    createImport,
    fileS3Url,
    importResourceId,
    insertFile,
    isAdmin,
    isMigration,
    organizationTypeId,
    otherImportSourceLabelField.value,
    previewData?.fileId,
    previewData?.headers,
    selectedImportSourceId,
  ]);

  const handleSelectOrgType = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const submitButton = useMemo(() => {
    const selectOrgTypeButton = (
      <SaveButton
        disabled={!organizationTypeId}
        onClick={handleSelectOrgType}
      >
        Next
      </SaveButton>
    );

    const importButton = (
      <SaveButton
        disabled={previewData?.goodRowCount === 0}
        isSaving={isInsertingFile}
        onClick={handleInsertFileToDb}
      >
        Import
      </SaveButton>
    );

    if (step === Step.SelectOrganizationType) {
      return selectOrgTypeButton;
    }

    if (step === Step.PreviewFile) {
      return importButton;
    }

    return null;
  }, [
    organizationTypeId,
    handleSelectOrgType,
    previewData?.goodRowCount,
    isInsertingFile,
    handleInsertFileToDb,
    step,
  ]);

  const shouldShowSmallDialog = step === Step.SelectOrganizationType
    || step === Step.Success
    || (step === Step.PreviewFile && isMigration);

  const isMigrationFormValid = useMemo(() => {
    if (!isMigration) {
      return true;
    }

    if (!selectedImportSourceId) {
      return false;
    }

    if (selectedImportSourceId === ImportOriginalSources.Other
      && !otherImportSourceLabelField.value.trim()) {
      return false;
    }

    return true;
  }, [
    isMigration,
    selectedImportSourceId,
    otherImportSourceLabelField.value,
  ]);

  const isMigrationStepWithInvalidForm = isMigration && !isMigrationFormValid;

  return (
    <DialogFileUpload
      description={description}
      dialogTitle={dialogTitle}
      disabled={isMigrationStepWithInvalidForm}
      hideDialogActions={step === Step.Success}
      hideDialogTitle={step === Step.Success}
      hideDropzone={step !== Step.UploadFile}
      id={`${context}-file-upload-dialog`}
      isOpen={isOpen}
      isUploading={isCreatingImport || isUploadingFile || isInsertingFile || isUploadingS3File}
      key={isOpen ? 'open' : 'closed'}
      maxWidth={shouldShowSmallDialog ? 'sm' : 'lg'}
      onClose={handleClose}
      onFileUpload={step === Step.UploadFile ? handleUploadFile : handleInsertFileToDb}
      submitButton={submitButton}
      /*
      This is a hack to force the dialog to re-render when the migration state changes
      The useDropzone hook is caching the onDrop callback and not updating it when the
      isMigration state changes
      */
      uploadDropzoneKey={isMigration ? 'migration' : 'default'}
    >
      {step === Step.SelectOrganizationType && (
        <OrganizationTypeSelect
          context={context}
          initialValue={organizationTypeId}
          onSetValue={setOrganizationTypeId}
        />
      )}

      {step === Step.UploadFile && (
        <FileUpload
          canMigrateFromAnotherSystem={canMigrateFromAnotherSystem}
          csvFileInfoResource={csvFileInfoResource}
          importMethod={importMethod}
          organizationTypeId={isAdmin ? organizationTypeId : undefined}
          otherImportSourceLabelField={otherImportSourceLabelField}
          requiredColumnsHelperText={requiredColumnsHelperText}
          selectedImportSourceId={selectedImportSourceId}
          setImportMethod={setImportMethod}
          setSelectedImportSourceId={setSelectedImportSourceId}
          tipMessage={fileUploadTipMessage}
          tipTitle={fileUploadTipTitle}
        />
      )}

      {step === Step.PreviewFile && (
        <FilePreview
          context={context}
          fileName={fileName}
          importResourceId={importResourceId}
          isMigration={isMigration}
          previewData={previewData}
        />
      )}

      {step === Step.Success && (
        <SuccessStep
          isMigration={isMigration}
          onClickDone={handleClose}
        />
      )}
    </DialogFileUpload>
  );
};

export default CSVImporter;
