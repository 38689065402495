// External Dependencies
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import {
  useAdvanceEndOfYearStep,
  useCreateNonReturningUsers,
} from 'gql/mutations';
import {
  useGetNonReturningUsers,
  useGetOrganization,
} from 'gql/queries';

// Local Dependencies
import EndOfYearActionButtons from '../shared/EndOfYearActionButtons';
import EndOfYearGradutatingGrade from './EndOfYearGraduatingGrade';
import EndOfYearMembersInfoAlert from './EndOfYearMembersInfoAlert';
import EndOfYearNonReturningStudents from './EndOfYearNonReturningStudents';
import EndOfYearReturningStudents from './EndOfYearReturningStudents';
import EndOfYearSuccessorOrg from './EndOfYearSuccessorOrg';

// Component Definition
const EndOfYearMembers = (): JSX.Element | null => {
  const navigate = useNavigate();

  // Error will only be used if the director tries to go
  //  to the "next" step but has no `graduatingGrade`
  const [error, setError] = useState('');

  const { data: orgData } = useGetOrganization();

  // Get non-returning students in case user has not completed entire process
  const { data: nonReturningUsersData } = useGetNonReturningUsers();

  const [
    advanceEndOfYearStep,
    {
      loading: isAdvancingToNextStep,
    },
  ] = useAdvanceEndOfYearStep(
    {
      onCompleted: (response) => {
        // We send the user back to their current step
        navigate(`?step=${response.advanceEndOfYearStep.maxCompletedEndOfYearStep + 1}`);
      },
    },
  );

  const [
    createNonReturningUsers,
    {
      loading: isCreatingNonReturningUsers,
    },
  ] = useCreateNonReturningUsers();

  useEffect(() => {
    const orgGraduatingGrade = orgData?.organization?.graduatingGrade;

    // We remove the error if the orgData now has the graduatingGrade
    if (error && (orgGraduatingGrade || orgGraduatingGrade === 0)) {
      setError('');
    }
  }, [error, orgData]);

  if (!orgData || !nonReturningUsersData) {
    return null;
  }

  const {
    entityType,
    graduatingGrade,
    label,
    organizationType,
    successorOrganization,
  } = orgData.organization;

  const isCollegeOrUniversity = entityType.id === OrganizationEntityTypes.College.toString();
  const hasHighSchoolSeniors = graduatingGrade === 12;

  const handleAdvanceToNextStep = () => {
    if (!isCollegeOrUniversity && !graduatingGrade && graduatingGrade !== 0) {
      setError("Please select your organization's graduating grade.");
    } else {
      advanceEndOfYearStep({
        variables: {
          step: 2,
        },
      });
    }
  };

  return (
    <>
      <EndOfYearMembersInfoAlert
        hasHighSchoolSeniors={hasHighSchoolSeniors}
        isCollegeOrUniversity={isCollegeOrUniversity}
      />

      {!isCollegeOrUniversity && (
        <>
          <EndOfYearGradutatingGrade
            graduatingGrade={graduatingGrade}
            label={label}
          />
          {!hasHighSchoolSeniors && (
            <EndOfYearSuccessorOrg
              successorOrganization={successorOrganization}
            />
          )}
        </>
      )}

      <EndOfYearNonReturningStudents
        graduatingGrade={graduatingGrade}
        isCollegeOrHasSeniors={isCollegeOrUniversity || hasHighSchoolSeniors}
        onCreateNonReturningUsers={createNonReturningUsers}
        organizationType={organizationType}
      />

      <EndOfYearReturningStudents organizationType={organizationType.label} />

      <EndOfYearActionButtons
        error={error}
        isSubmitting={isCreatingNonReturningUsers || isAdvancingToNextStep}
        onPressNextButton={handleAdvanceToNextStep}
      />
    </>
  );
};

export default EndOfYearMembers;
