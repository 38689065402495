// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { UserAvatar } from 'components/shared';
import {
  formatPhoneNumber,
  getFullName,
} from 'utils';

// Local Dependencies
import DeleteRelationshipButton from './DeleteRelationshipButton';

// Local Typings
interface Props {
  canDelete: boolean;
  relationshipId: string;
  relationshipType: GQL.IRelationshipType;
  userData: Pick<GQL.IUser, 'id' | 'email' | 'firstName' | 'middleName' | 'lastName' | 'phoneNumber'> | null;
}

// Local Variables
const StyledTypography = styled(Typography)({
  // We are setting a `<span>` to block to avoid HTML warnings
  //  since a `<p>` cannot have a nested `<div>`
  display: 'block',
  lineHeight: 1.6,
}) as typeof Typography;

// Component Definition
const UserRelationshipListItem: FC<Props> = ({
  canDelete,
  relationshipId,
  relationshipType,
  userData,
}) => {
  const navigate = useNavigate();

  // This works fine even when the parent is a director in the current org
  // However, that director's "parent show page" is not accessible via the
  //  normal UI in the app
  const handleClick = useCallback(() => {
    if (userData) {
      navigate(`/members/${userData.id}`);
    }
  }, [navigate, userData]);

  if (!userData) {
    return null;
  }

  const {
    email,
    id,
    phoneNumber,
  } = userData;

  return (
    <ListItem
      alignItems="flex-start"
      button
      key={id}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <UserAvatar iconName="face" />
      </ListItemAvatar>

      <ListItemText
        primary={relationshipType.label}
        secondary={(
          <>
            <StyledTypography
              color="textPrimary"
              component="span"
              variant="body2"
            >
              {getFullName(userData)}
            </StyledTypography>
            <StyledTypography
              component="span"
              variant="body2"
            >
              {email}
            </StyledTypography>
            {phoneNumber && formatPhoneNumber(phoneNumber)}
          </>
        )}
      />

      {canDelete && (
        <ListItemSecondaryAction>
          <DeleteRelationshipButton relationshipId={relationshipId} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default UserRelationshipListItem;
