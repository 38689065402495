// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

// Internal Dependencies
import {
  EnhancedCard,
  ListItemWithSecondaryAction,
  Subtitle,
} from 'components/shared';

// Local Typings
interface Props {
  itemsNeedingAttention: GQL.IItemNeedingAttention[];
}

// Component Definition
const ItemsNeedingAttentionCard = ({
  itemsNeedingAttention,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigate = useCallback((url: string) => () => {
    navigate(url);
  }, [navigate]);

  return (
    <Box marginTop={2}>
      <Subtitle>
        Items Needing Attention
      </Subtitle>

      <EnhancedCard>
        <List>
          {itemsNeedingAttention.map((item) => (
            <ListItemWithSecondaryAction
              key={item.message}
              primaryText={item.message}
              secondaryAction={item.url ? ({
                buttonIcon: <ArrowRightIcon />,
                buttonText: 'View',
                onClick: handleNavigate(item.url),
              }) : undefined}
            />
          ))}
        </List>
      </EnhancedCard>
    </Box>
  );
};

export default ItemsNeedingAttentionCard;
