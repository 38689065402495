// External Dependencies
import { ReportTypes } from '@presto-assistant/api_types';
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import ArrowRightIcon from 'mdi-material-ui/ArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormatListChecksIcon from 'mdi-material-ui/FormatListChecks';

// Internal Dependencies
import {
  EnhancedContainer,
  Page,
  ShowCard,
  ShowCardHeader,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetDistrictInventoryAudit } from 'utils/api/district';
import { useParamsWithId } from 'hooks/useParamsWithId';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';

// Local Dependencies
import AssignedOrganizationsTable from './AssignedOrganizationsTable';

// Component Definition
const AdminInventoryAuditsShow = (): JSX.Element => {
  const navigate = useNavigate();

  const { id } = useParamsWithId();

  const inventoryAuditsParams = useSelector(tableQueryParams('inventoryAudits'));

  const {
    data: districtInventoryAuditData,
    isLoading,
  } = useGetDistrictInventoryAudit(id);

  const districtInventoryAudit = districtInventoryAuditData?.data.data;

  const numberOfOrganizationsAssigned = districtInventoryAudit?.assignments?.length;
  const numberOfOrganizationAuditsCompleted = districtInventoryAudit?.assignments?.filter(
    (assignment) =>
      assignment.completedAt,
  ).length;

  const handlePressNotFoundItems = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}/${id}/${PATHS.INVENTORY_AUDITS_NOT_FOUND_ITEMS}`);
  }, [id, navigate]);

  const handlePressGetReport = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}/new?report_type_id=${ReportTypes.DistrictInventoryAuditReport}`);
  }, [navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'All District Inventory Audits',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}${inventoryAuditsParams}`,
      }}
      isLoading={isLoading}
    >
      {districtInventoryAudit && (
      <>
        <EnhancedContainer>
          <Box mb={2}>
            <ShowCard
              icon={FormatListChecksIcon}
              title="District Inventory Audit Details"
            >
              <ShowPageDataDisplay
                label="Title"
                value={districtInventoryAudit.label}
              />
              <ShowPageDataDisplay
                label="Due Date"
                type="date"
                value={districtInventoryAudit.dueDate}
              />

              <Divider sx={{ marginY: 2 }} />

              <PercentageProgressBar
                denominator={numberOfOrganizationsAssigned ?? 0}
                label="Audits Completed"
                numerator={numberOfOrganizationAuditsCompleted ?? 0}
              />

              <ShowPageDataDisplay
                label="Inventory Items Not Found"
                type="number"
                value={districtInventoryAudit?.inventoryItemsNotFound.length ?? 0}
              />

              <Box
                display="flex"
                gap={1}
                justifyContent="flex-end"
              >
                <Button
                  endIcon={<ArrowRightIcon />}
                  onClick={handlePressNotFoundItems}
                  size="small"
                  variant="outlined"
                >
                  Absent Items
                </Button>

                <Button
                  onClick={handlePressGetReport}
                  size="small"
                  variant="contained"
                >
                  Get Report
                </Button>
              </Box>
            </ShowCard>
          </Box>
        </EnhancedContainer>

        <Container maxWidth="lg">
          <Box marginTop={2}>
            <ShowCardHeader
              sx={{ marginBottom: 1 }}
              title="Assigned Organizations"
            />

            <AssignedOrganizationsTable
              assignedOrganizations={districtInventoryAudit.assignments}
              isLoading={isLoading}
            />
          </Box>
        </Container>
      </>
      )}
    </Page>
  );
};

export default AdminInventoryAuditsShow;
