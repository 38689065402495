// External Dependencies
import { useCallback, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import moment from 'moment';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { useGetCalendarList, useGetGoogleCalendarEvents } from 'gql/queries';

// Local Dependencies
import { Flex } from 'components/shared';
import { getDefaultErrorMessage } from 'utils/lib/graphql_errors';
import EventList from './EventList';

// Local Variables
const format = 'YYYY-MM-DD';
const thisWeekStart = Date.now();
const nextWeekEnd = new Date(
  moment().add(7, 'days').endOf('week').format(format),
).getTime();

// Component Definition
const Events = (): JSX.Element => {
  const navigate = useNavigate();

  const [
    getCalendarList,
    {
      called: hasCalledCalendarList,
      data: calendarList,
      error: calendarListError,
      loading: isLoadingCalendarList,
    },
  ] = useGetCalendarList();

  const [queryThisWeekEvents, {
    called: hasCalledThisWeekData,
    data: thisWeekData,
    error: thisWeekError,
    loading: isThisWeekLoading,
  }] = useGetGoogleCalendarEvents({
    endDate: nextWeekEnd,
    startDate: thisWeekStart,
  });

  useEffect(() => {
    getCalendarList();
  }, [getCalendarList]);

  useEffect(() => {
    if (calendarList?.googleCalendarList) {
      queryThisWeekEvents();
    }
  }, [calendarList, queryThisWeekEvents]);

  const handleClickButton = useCallback(() => navigate(PATHS.CALENDAR), [navigate]);

  const apiError = calendarListError ?? thisWeekError;

  const isAboutToQueryThisWeekData = !hasCalledThisWeekData && !!calendarList?.googleCalendarList;

  const isLoading = [
    isLoadingCalendarList,
    isThisWeekLoading,
    !hasCalledCalendarList,
    isAboutToQueryThisWeekData,
  ].some(Boolean);

  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress
          size={28}
          sx={{ m: 2 }}
        />
      </Flex>
    );
  }

  return (
    <Box
      alignContent="space-between"
      display="grid"
      height="100%"
    >
      {apiError || !calendarList?.googleCalendarList
        ? (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {getDefaultErrorMessage(apiError, 'Unable to load events.')}
          </Typography>
        )
        : (
          <>
            <List dense>
              <EventList
                calendarList={calendarList?.googleCalendarList}
                events={thisWeekData?.googleCalendarEventsRange ?? []}
              />
            </List>
            <Button
              color="primary"
              onClick={handleClickButton}
              size="small"
            >
              See More
            </Button>
          </>
        )}
    </Box>
  );
};

export default Events;
