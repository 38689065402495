// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { DELETE_LIBRARY_ITEMS } from 'gql/mutations';
import { GET_LIBRARY_INDEX_ITEMS } from 'gql/queries';
import { SELECTION_TYPES } from 'utils/constants';
import { getOperationName } from '@apollo/client/utilities';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DangerZone, { DangerZoneItemProps } from 'components/shared/DangerZone';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Typings
interface Props {
  libraryIndexUrl: string;
  libraryItemId?: string;
  organizationLabel: string;
}

// Local Variables
const deleteLibraryItemContext: [string] = ['library item'];

// Component Definition
const LibraryDangerZone: FC<Props> = ({
  libraryIndexUrl,
  libraryItemId,
  organizationLabel,
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isRemoveLibraryConfirmationDialogOpen,
    toggleIsOpen: toggleLibraryConfirmationDialogIsOpen,
  } = useIsOpen();

  const canRemoveLibraryItem = useSelector(hasPermission('library', 'delete'));
  const isMobileWidth = useSelector(isMobileScreenSize);

  const handleSuccess = useCallback(() => {
    navigate(libraryIndexUrl);
  }, [libraryIndexUrl, navigate]);

  const handleCloseDeleteLibraryItemDialog = () => {
    toggleLibraryConfirmationDialogIsOpen();
  };

  const handleOpenDeleteLibraryDialog = () => {
    toggleLibraryConfirmationDialogIsOpen();
  };

  const formatDeleteLibraryItemsPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteLibraryItemsOnMutationArguments>['formatPayload']>((note) => ({

    deletedNote: note ?? '',
    selection: {
      ids: [libraryItemId ?? ''],
      mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
      queryParams: {},
    },

  }), [libraryItemId]);

  const items: DangerZoneItemProps[] = [];
  if (canRemoveLibraryItem) {
    items.push({
      buttonText: isMobileWidth ? 'Remove' : 'Remove item',
      description: `Remove this library item from the ${organizationLabel} organization. This does not delete the item from your district.`,
      onClick: handleOpenDeleteLibraryDialog,
      title: 'Remove library item',
    });
  }

  return (
    <>
      <Box mt={4}>
        <DangerZone items={items} />
      </Box>

      <DeleteDialogV2<GQL.IDeleteLibraryItemsOnMutationArguments>
        actionVerb="Remove"
        clearCachePredicates={['library']}
        context={deleteLibraryItemContext}
        formatPayload={formatDeleteLibraryItemsPayload}
        hasSelection={false}
        isOpen={isRemoveLibraryConfirmationDialogOpen}
        mutation={DELETE_LIBRARY_ITEMS}
        onClose={handleCloseDeleteLibraryItemDialog}
        onSuccess={handleSuccess}
        refetchQueries={() => [getOperationName(GET_LIBRARY_INDEX_ITEMS) as string]}
        withNote
      />
    </>
  );
};

export default LibraryDangerZone;
