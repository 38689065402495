// External Dependencies
import { Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';

// Internal Dependencies
import {
  NoAuth,
  OnboardingWrapper,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isLoggedIn } from 'state/self/selectors';
import { updateDeviceInnerWidth } from 'state/device/actions';
import ChooseRole from 'pages/Signup/ChooseRole';
import CreateUserProfile from 'pages/Signup/CreateUserProfile';
import FourOhFour from 'pages/FourOhFour';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import MainContentContainer from 'components/shared/MainContentContainer';
import PasswordReset from 'pages/PasswordReset';
import RoleRouter from 'pages/RoleRouter';
import Signup from 'pages/Signup';
import Sso from 'pages/Sso';
import SsoClassLink from 'pages/Sso/ClassLink';
import Verify from 'pages/Verify';
import usePrevious from 'hooks/usePrevious';
import useWindowSize from 'hooks/useWindowSize';

// Local Dependencies
import { renderRoutes } from './helpers';
import {
  useGetDistrictAdminRoutes,
  useGetOnboardingRoutes,
  useGetOrganizationUserRoutes,
  useGetOrphanUserRoutes,
} from './hooks';

// Component Definition
const Routes = (): JSX.Element => {
  const dispatch = useDispatch();

  // Ensure this component re-renders on login/logout
  useSelector(isLoggedIn);

  // Get the current device width to store in the Redux state tree
  const { innerWidth } = useWindowSize();

  const previousInnerWidth = usePrevious(innerWidth);

  const organizationUserRoutes = useGetOrganizationUserRoutes();
  const districtAdminRoutes = useGetDistrictAdminRoutes();
  const onboardingRoutes = useGetOnboardingRoutes();
  const orphanUserRoutes = useGetOrphanUserRoutes();

  useLayoutEffect(() => {
    if (previousInnerWidth !== innerWidth) {
      dispatch(updateDeviceInnerWidth(innerWidth));
    }
  }, [dispatch, innerWidth, previousInnerWidth]);

  return (
    <Router>
      <Verify path={PATHS.VERIFY} />

      <SsoClassLink path={`/${PATHS.SSO}/classlink`} />

      <Sso path={`/${PATHS.SSO}/:token`} />

      <Logout path={PATHS.LOGOUT} />

      <MainContentContainer path="/">
        <>
          {/* Simple Layout components */}
          <Login path="/" />
          <Login path="/login" />
          <NoAuth
            component={Signup}
            path={PATHS.SIGNUP}
          />
          <NoAuth
            component={ChooseRole}
            path={PATHS.SIGNUP_CHOOSE_ROLE}
          />
          <NoAuth
            component={CreateUserProfile}
            path={`${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}`}
          />
          <NoAuth
            component={PasswordReset}
            path={PATHS.PASSWORD_RESET}
          />

          <RoleRouter
            path={PATHS.DISTRICT_ADMIN}
            userRole="districtAdmin"
          >
            {renderRoutes(districtAdminRoutes)}
          </RoleRouter>

          <RoleRouter
            path="/"
            userRole="organizationUser"
          >
            {renderRoutes(organizationUserRoutes)}
          </RoleRouter>

          <RoleRouter
            path="/missing_organization"
            userRole="orphanUser"
          >
            {renderRoutes(orphanUserRoutes)}
          </RoleRouter>

          {/* Default 404 page */}
          <FourOhFour default />
        </>
      </MainContentContainer>

      {/* Onboarding */}
      <OnboardingWrapper path={PATHS.ONBOARDING}>
        <>
          {renderRoutes(onboardingRoutes)}
        </>
      </OnboardingWrapper>
    </Router>
  );
};

export default Routes;
