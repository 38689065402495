// External Dependencies
import {
  FC, ReactElement, useCallback, useMemo,
} from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, darken, lighten } from '@mui/material/styles';
import { useNavigate } from '@reach/router';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styled, { useTheme } from 'styled-components';

// Local Dependencies
import { Theme } from 'types/styled';
import LeftIcon from './LeftIcon';

// Local Typings
interface Props {
  icon?: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  isActive?: boolean;
  isFullWidth?: boolean;
  isOrgName?: boolean;
  onClick?: () => void;
  secondaryText?: string | ReactElement;
  showNewChip?: boolean;
  text?: string | ReactElement;
  to?: string;
}

interface StyledMenuItemProps {
  $isActive?: boolean;
  $isFullWidth?: boolean;
  $isOrgName?: boolean;
}

const getHoverBackground = (theme: Theme, isOrgName: boolean | undefined) => {
  if (!isOrgName) {
    return '';
  }

  const isDarkMode = theme.palette.mode === 'dark';

  return isDarkMode
    ? darken(theme.palette.prestoSecondary, 0.8)
    : lighten(theme.palette.prestoSecondary, 0.8);
};

const getActiveBackground = (theme: Theme) =>
  alpha(theme.palette.prestoSecondary, theme.palette.action.disabledOpacity);

const getColor = ({
  isActive,
  isOrgName,
  theme,
}: {
  isActive: boolean | undefined;
  isOrgName: boolean | undefined;
  theme: Theme;
}) => {
  const isDarkMode = theme.palette.mode === 'dark';

  if (isOrgName && isActive) {
    return theme.palette.navMenuItemText;
  }

  if (!isDarkMode) {
    return isActive
      ? theme.palette.prestoPrimary
      : '';
  }

  return isActive
    ? theme.palette.common.black
    : theme.palette.text.secondary;
};

/* eslint-disable no-nested-ternary */
const StyledListItemButton = styled(ListItemButton)<StyledMenuItemProps>(({
  $isActive,
  $isFullWidth,
  $isOrgName,
  theme,
}) => ({
  '& .MuiListItemText-colorTextSecondary': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  '& .MuiListItemText-primary': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
    },
  },
  '& .MuiListItemText-secondary': {
    color: $isActive ? lighten(theme.palette.navMenuItemText, 0.15) : 'initial',
  },

  '&:focus, &:hover': {
    backgroundColor: getHoverBackground(theme, $isOrgName),
  },
  '.MuiChip-label': {
    fontWeight: 500,
  },
  '.MuiChip-root': {
    backgroundColor: theme.palette.prestoPrimaryLight,
    borderRadius: 8,
    fontSize: 12,
  },

  background: $isOrgName && $isActive
    ? getActiveBackground(theme)
    : 'inherit',
  borderRadius: theme.shape.borderRadius,
  color: getColor({
    isActive: $isActive,
    isOrgName: $isOrgName,
    theme,
  }),
  margin: theme.spacing(1),
  padding: theme.spacing(0.5, 1.5, 0.5, 1),
  width: $isFullWidth ? '100%' : undefined,
})) as typeof ListItemButton;
/* eslint-enable no-nested-ternary */

// Component Definition
const EnhancedMenuItem: FC<Props> = ({
  icon,
  isActive,
  isFullWidth,
  isOrgName,
  onClick,
  secondaryText = '',
  showNewChip,
  text,
  to,
  ...other
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // We move the navigate function here to construct semantic HTML in the nav
  const handleClick = useCallback(() => {
    // The onClick closes the Drawer when the user clicks/presses a nav menu item
    onClick?.();

    if (to) {
      navigate(to);
    }
  }, [navigate, onClick, to]);

  const styledMenuItemProps: StyledMenuItemProps = useMemo(() => ({
    $isActive: isActive,
    $isFullWidth: isFullWidth,
    $isOrgName: isOrgName,
  }), [isActive, isFullWidth, isOrgName]);

  return (
    <StyledListItemButton
      {...styledMenuItemProps}
      component="li"
      onClick={handleClick}
      onKeyPress={handleClick}
      role="menuitem"
      selected={!isOrgName && isActive}
      tabIndex={0}
      {...other}
    >
      {icon && (
        <LeftIcon
          icon={icon}
          iconColor={(isActive && isOrgName)
            ? theme.palette.navMenuItemIcon
            : getColor({
              isActive,
              isOrgName,
              theme,
            })}
          isActive={isActive}
        />
      )}
      <ListItemText
        primary={text}
        secondary={secondaryText}
        secondaryTypographyProps={{
          component: 'div',
        }}
      />
      {showNewChip && (
        <Chip
          color="primary"
          label="NEW"
          size="small"
          sx={{
            marginLeft: 1,
          }}
          variant="filled"
        />
      )}
    </StyledListItemButton>
  );
};

export default EnhancedMenuItem;
