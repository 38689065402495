// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Form, Formik } from 'formik';
import { ObjectSchema } from 'yup';
import { ReportTypes } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import CardActions from '@mui/material/CardActions';

// Internal Dependencies
import {
  CustomSelect,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  SaveButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { mapEnum } from 'utils/lib/map_enum';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { useCreateReport } from 'utils/api/report';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from '@reach/router';
import DynamicFormFields from 'pages/DistrictAdmin/AdminReports/New/DynamicFormFields';

// Local Variables
// TODO: make a util to convert PascalCase to Title Case
const mappedReportTypes = mapEnum(ReportTypes).map((reportType) => ({
  id: reportType.id,
  label: toTitleCase(reportType.label),
}));

// Component Definition
const ReportsNew: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [yupSchema, setYupSchema] = useState<ObjectSchema | null>(null);

  const initialReportTypeId = useMemo(() => {
    const params = new URLSearchParams(search);

    const paramsReportTypeId = params.get('report_type_id');

    if (paramsReportTypeId && !Number.isNaN(Number(paramsReportTypeId))) {
      return Number(paramsReportTypeId);
    }

    return ReportTypes.DistrictFinancialTransactionReport;
  }, [search]);

  const dispatch = useDispatch();

  const {
    mutate: createReport,
  } = useCreateReport();

  const handleFormikSubmit = useCallback((values) => {
    createReport({
      payload: values.payload,
      reportTypeId: Number(values.reportTypeId),
    }, {
      onSuccess: () => {
        navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}`);
        dispatch(openDialogSuccess({
          animationData: null,
          description: 'Your report is processing and will be sent to your email when complete.',
          title: 'Report Processing!',
        }));
      },
    });
  }, [createReport, dispatch, navigate]);

  return (
    <div>
      <EnhancedContainer>
        <Formik
          initialValues={{
            payload: {}, // fields dynamically set based on report type id
            reportTypeId: initialReportTypeId,
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={yupSchema}
        >
          {({
            values,
          }) => (
            <Form>
              <EnhancedCard>
                <EnhancedCardContent>
                  <CustomSelect
                    label="Report Type"
                    name="reportTypeId"
                    options={mappedReportTypes}
                    required
                  />

                  <DynamicFormFields
                    reportTypeId={Number(values.reportTypeId)}
                    setYupSchema={setYupSchema}
                  />
                </EnhancedCardContent>

                <CardActions
                  sx={{ justifyContent: 'flex-end' }}
                >
                  <SaveButton>
                    Generate Report
                  </SaveButton>
                </CardActions>
              </EnhancedCard>
            </Form>
          )}
        </Formik>
      </EnhancedContainer>
    </div>
  );
};

export default ReportsNew;
