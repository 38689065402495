// External Dependencies
import {
  DialogContentText,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { FC, ReactElement } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import styled from 'styled-components';

// Internal Dependencies
import { CONTACT_INFO } from 'utils/constants';

// Local Dependencies
import Flex from './Flex';
import StyledLink from './StyledLink';
import ZeroStateIcon from './ZeroStateIcon';

// Local Typing
export interface Props {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  excludeNeedHelp?: boolean;
  maxTextWidth?: string;
  message?: string | ReactElement;
  padding?: number;
}
interface StyledBoxProps {
  $maxTextWidth?: string;
}

// Local Variables
const StyledRoot = styled(Flex)<StyledBoxProps>(({
  $maxTextWidth,
  theme,
}) => ({
  '.emptyStateText': {
    [theme.breakpoints.up('md')]: {
      maxWidth: $maxTextWidth,
    },
    marginTop: theme.spacing(2.5),
    maxWidth: '80%',
    textAlign: 'center',
  },
}));

// Component Definition
const ZeroState: FC<Props> = ({
  Icon = MusicOffIcon,
  excludeNeedHelp,
  maxTextWidth = '50%',
  message = 'Add data to this table by importing items or adding individually.',
  padding = 32,
  ...otherProps
}) => (
  <StyledRoot
    $maxTextWidth={maxTextWidth}
    alignContent="center"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    padding={padding ? `${padding}px` : 0}
    {...otherProps}
  >
    <ZeroStateIcon Icon={Icon} />

    <DialogContentText
      className="emptyStateText"
      component="div"
      variant="body1"
    >
      {message}
    </DialogContentText>

    {!excludeNeedHelp && (
      <Typography
        className="emptyStateText"
        variant="body1"
      >
        Need help?
        {' '}
        <StyledLink href={CONTACT_INFO.MAILTO_SUPPORT}>
          Contact us!
        </StyledLink>
      </Typography>
    )}
  </StyledRoot>
);

export default ZeroState;
