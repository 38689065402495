// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  FieldArray, Form, Formik,
} from 'formik';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedContainer,
  FormActions,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetChecklist } from 'gql/queries';
import { useUpdateChecklistMembers } from 'gql/mutations';

// Local Dependencies
import { checklistMembersSchema } from '../../shared/schema';
import ChecklistErrorAlert from '../../shared/ChecklistErrorAlert';
import ChecklistMembersFormUI from './ChecklistMembersFormUI';
import ShowChecklistBasicInfoCard from '../../shared/ShowChecklistBasicInfoCard';

// Local Typings
interface Props {
  checklistId: string;
}

// Component Definition
const EditChecklistMembersForm: FC<Props> = ({ checklistId }) => {
  const navigate = useNavigate();

  const {
    data: checklistData,
    error: getChecklistDataError,
    loading: isLoadingChecklistData,
  } = useGetChecklist(checklistId);

  const [checklistMemberIds, setChecklistMemberIds] = useState<string[]>([]);

  // If the checklist data arrives, we want to update the local state
  useEffect(() => {
    const existingMemberIds = checklistData?.checklist.memberIds;

    if (checklistData) {
      setChecklistMemberIds(existingMemberIds ?? []);
    }
  }, [checklistData]);

  const initialValues: GQL.IUpdateChecklistMembersInput = {
    checklistId,
    memberIds: checklistMemberIds,
  };

  const checklistsParams = useSelector(tableQueryParams('checklists'));

  const handleNavigateToItemsStep = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}/${checklistId}/edit/items?step=3`);
  }, [checklistId, navigate]);

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}${checklistsParams}`);
  }, [checklistsParams, navigate]);

  const [
    updateChecklistMembers,
    {
      error: updateChecklistMembersError,
      loading: isUpdatingChecklistMembers,
    },
  ] = useUpdateChecklistMembers(
    {
      clearCachePredicates: ['checklists'],
      onCompleted: handleNavigateToItemsStep,
    },
  );

  if (isLoadingChecklistData) {
    return null;
  }

  const handleFormikSubmit = (
    values: GQL.IUpdateChecklistMembersInput,
  ) => {
    updateChecklistMembers({
      variables: {
        input: values,
      },
    });
  };

  return (
    <Formik<GQL.IUpdateChecklistMembersInput>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={checklistMembersSchema}
    >
      {({
        handleSubmit,
        touched,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <EnhancedContainer>
                <Box marginBottom={2}>
                  <ShowChecklistBasicInfoCard checklistData={checklistData?.checklist} />
                </Box>

                <Box mb={2}>
                  <FieldArray name="memberIds">
                    {(arrayHelpers) => (
                      <ChecklistMembersFormUI arrayHelpers={arrayHelpers} />
                    )}
                  </FieldArray>
                </Box>

                <ChecklistErrorAlert
                  errorMessage={updateChecklistMembersError?.message
                    || getChecklistDataError?.message}
                />

                <EnhancedCard>
                  <FormActions
                    context="Checklist"
                    isEditing={false}
                    isFormTouched={isFormTouched}
                    isSubmitting={isUpdatingChecklistMembers}
                    onPressCancelOrBackButton={handlePressCancelOrBackButton}
                    onPressSubmit={handleNavigateToItemsStep}
                    submitButtonText="Next"
                    type={!isFormTouched ? 'button' : 'submit'}
                  />
                </EnhancedCard>
              </EnhancedContainer>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditChecklistMembersForm;
