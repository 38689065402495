// External Dependencies
import { Form, Formik } from 'formik';
import { createMemberSchema } from '@presto-assistant/api_types/schemas/user';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDebounce } from 'use-debounce/lib';
import { useIsOpen } from 'hooks/useIsOpen';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { GET_SELF } from 'gql/queries';
import { JOIN_ORGANIZATION } from 'gql/mutations';
import { PATHS } from 'utils/constants/routes';
import { checkOrgCode } from 'utils/api';
import { isDistrictAdmin as isDistrictAdminSelector } from 'state/self/selectors';

import { useMutationEnhanced } from 'utils/lib/graphql';
import useDidMount from 'hooks/useDidMount';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import {
  ConfirmationDialog,
  CustomInput,
  DatePickerField,
  EnhancedAlert,
  EnhancedCard,
  SaveButton,
} from '..';
import { MemberInfoFormType } from '.';

// Local Typings
interface Props {
  isOnboarding: boolean;
  onResetFormType: () => void;
}

// Local Variables
const StyledCustomInput = styled(CustomInput)(({ theme }) => ({
  '.error': {
    color: theme.palette.error.dark,
  },
  '.success': {
    color: theme.palette.success.main,
  },
}));

const formType: MemberInfoFormType = 'joining';

// TODO: this gets the job done for now. I don't like matching on this string
const pendingErrorMessage = 'Your membership is pending approval for this organization.';

const hasSpaceInMiddle = (inputString: string): boolean => /\s/.test(inputString);

// Component Definition
const JoinOrganizationForm = ({
  isOnboarding,
  onResetFormType,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const [joinError, setJoinError] = useState('');
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [orgCodeValue, setOrgCodeValue] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [debouncedOrgCodeValue] = useDebounce(orgCodeValue.trim(), 500);
  const [showMemberFormElements, setShowMemberFormElements] = useState(false);

  const {
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const isDistrictAdmin = useSelector(isDistrictAdminSelector);

  const theme = useTheme();

  const {
    refetch: refetchSelf,
    self,
  } = useSelfQuery();

  const didMount = useDidMount();

  const handleChangeOrgCodeValue = (value: string) => {
    if (didMount) {
      setOrgCodeValue(value);
    }
  };

  const inputError = submitError || (isCodeValid === false && 'Invalid code') || null;

  const queryString = useMemo(
    () => `organization=${organizationName}&form_type=${formType}`,
    [organizationName],
  );

  const [
    joinOrganization,
    {
      loading: isSubmitting,
    },
  ] = useMutationEnhanced<{
    joinOrganization: GQL.IUserOrganization
  }, GQL.IJoinOrganizationOnMutationArguments>(
    JOIN_ORGANIZATION,
    {
      clearCachePredicates: [],
      onCompleted: () => {
        if (!isOnboarding) {
          const fullPath = `/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}?${queryString}`;

          // We need to clear a ton of state here when switching organizations. Let's just reload.
          window.location.href = fullPath;
        }
      },
      onError: (error) => {
        // Used when a signed-in member joins a new organization
        if (!isOnboarding) {
          if (error.message === pendingErrorMessage) {
            navigate(isDistrictAdmin
              ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}?${queryString}`
              : `/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}?${queryString}`);
          }
          setSubmitError(error.message);
          // Onboarding Member Info step
        } else {
          setJoinError(error.message);
        }
      },
      refetchQueries: [{ query: GET_SELF }],
    },
  );

  const handleSubmit = useCallback((values: GQL.IJoinOrganizationOnMutationArguments) => {
    joinOrganization({
      variables: values,
    });
  }, [joinOrganization]);

  useEffect(() => {
    if (joinError === pendingErrorMessage) {
      toggleIsOpen();
      refetchSelf();
    }
  }, [joinError, refetchSelf, toggleIsOpen]);

  useEffect(() => {
    async function handleCheckOrgCode() {
      setIsCodeValid(null);

      const response = await checkOrgCode({ code: debouncedOrgCodeValue });
      const isValidOrg = response.data && Boolean(response.data.organization);
      const organizationNameFromRes = isValidOrg
        ? response.data.organization.label
        : '';
      setIsCodeValid(isValidOrg);
      setOrganizationName(organizationNameFromRes);
    }

    if (debouncedOrgCodeValue && !hasSpaceInMiddle(debouncedOrgCodeValue)) {
      handleCheckOrgCode();
    }
  }, [
    debouncedOrgCodeValue,
    setIsCodeValid,
    setOrganizationName,
  ]);

  useEffect(() => {
    setSubmitError(null);
  }, [
    debouncedOrgCodeValue,
    setSubmitError,
  ]);

  const handlePressNextButton = useCallback(() => {
    setShowMemberFormElements(true);
  }, []);

  return (
    <>
      <Formik<GQL.IJoinOrganizationOnMutationArguments>
        enableReinitialize
        initialValues={{
          dateOfBirth: self?.dateOfBirth ?? '',
          email: self?.email ?? '',
          firstName: self?.firstName ?? '',
          lastName: self?.lastName ?? '',
          organizationCode: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={createMemberSchema}
      >
        {({
          errors,
        }) => {
          const hasOrganizationCodeError = Boolean(errors.organizationCode);

          return (
            <Form>
              <EnhancedCard sx={{ marginTop: 2, textAlign: 'left' }}>
                <CardContent>
                  <Typography
                    component="h3"
                    sx={{
                      marginBottom: 1.5,
                    }}
                    variant="h6"
                  >
                    Enter your Organization Code
                  </Typography>

                  <Typography sx={{ marginBottom: 1.5 }}>
                    You will be a member of the organization when the director
                    accepts your request to join and sends you a Welcome Email.
                  </Typography>

                  <StyledCustomInput
                    FormHelperTextProps={{
                      className: isCodeValid === false
                        || hasOrganizationCodeError ? 'error' : 'success',
                    }}
                    helperText={inputError || organizationName}
                    htmlInputProps={{ readOnly: showMemberFormElements }}
                    label="Organization Code"
                    name="organizationCode"
                    onChangeValue={handleChangeOrgCodeValue}
                    variant="filled"
                  />

                  <Collapse in={showMemberFormElements}>
                    <Typography
                      component="h3"
                      sx={{
                        marginBottom: 1.5,
                        marginTop: 1,
                      }}
                      variant="h6"
                    >
                      Enter your Personal Information
                    </Typography>

                    <Typography sx={{ marginBottom: 1.5 }}>
                      Help your director identify you so they can approve your membership.
                    </Typography>

                    <CustomInput
                      label="Email"
                      name="email"
                    />

                    <CustomInput
                      label="First Name"
                      name="firstName"
                    />

                    <CustomInput
                      label="Last Name"
                      name="lastName"
                    />

                    <DatePickerField
                      label="Date of Birth"
                      name="dateOfBirth"
                    />

                    <Collapse in={Boolean(joinError)}>
                      <Box
                        marginTop={1.5}
                        textAlign="left"
                      >
                        <EnhancedAlert
                          severity={joinError === pendingErrorMessage ? 'success' : 'error'}
                        >
                          {joinError}
                        </EnhancedAlert>
                      </Box>
                    </Collapse>
                  </Collapse>
                </CardContent>
              </EnhancedCard>

              <Box
                display="flex"
                gap={theme.spacing(2)}
                justifyContent={isOnboarding ? 'center' : 'flex-end'}
                marginTop={theme.spacing(3)}
              >
                <Button
                  color="primary"
                  onClick={onResetFormType}
                  variant="outlined"
                >
                  Go Back
                </Button>

                {showMemberFormElements ? (
                  <SaveButton
                    isSaving={isSubmitting}
                    type="submit"
                  >
                    Join
                  </SaveButton>
                ) : (
                  <Button
                    color="primary"
                    disabled={!isCodeValid}
                    onClick={handlePressNextButton}
                    variant="contained"
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>

      <ConfirmationDialog
        confirmButtonAction={onResetFormType}
        description={(
          <>
            Your membership is pending director approval for this organization.
          </>
        )}
        disableEscapeKeyDown
        handleClose={toggleIsOpen}
        hideDeclineButton
        open={isOpen}
        title="Your Membership is Pending"
      />
    </>
  );
};

export default JoinOrganizationForm;
