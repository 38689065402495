// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
  pluralize,
} from 'utils';
import {
  useGetEndOfYearOutstandingItems,
  useGetNonReturningUsers,
  useGetOrganization,
  useGetReturningGroups,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import { StyledStrong } from '../shared/styles';
import DialogConfirmStartNextSchoolYear from './DialogConfirmStartNextSchoolYear';

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    width: '100%',
  },

  borderRadius: 100, // a high number that will ensure round corners regardless of button height
  fontSize: 18,
  padding: theme.spacing(1.5, 0),
  width: '60%',
}));

// Component Definition
const EndOfYearReview: FC = () => {
  const navigate = useNavigate();

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: toggleConfirmationDialogIsOpen,
  } = useIsOpen();

  const { data: orgData, loading: isLoadingOrganization } = useGetOrganization();

  const { data: outstandingItemData } = useGetEndOfYearOutstandingItems({
    schoolYearEnding: orgData?.organization.currentSchoolYearEnding,
  });

  const { data: nonReturningUsersData } = useGetNonReturningUsers();

  const { data: returningGroupsData } = useGetReturningGroups();

  const handlePressEditButton = useCallback((step: number) => {
    navigate(`?step=${step}`);
  }, [navigate]);

  if (isLoadingOrganization) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        mt={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!orgData?.organization || !outstandingItemData
    || !nonReturningUsersData || !returningGroupsData) {
    return null;
  }

  const { currentSchoolYearEnding } = orgData.organization;

  const fullCurrentSchoolYear = `${currentSchoolYearEnding - 1}-${currentSchoolYearEnding}`;
  const fullNextSchoolYear = `${currentSchoolYearEnding}-${currentSchoolYearEnding + 1}`;

  const returningUsersCount = nonReturningUsersData.nonReturningUsers.length;

  const returningGroupsCount = returningGroupsData?.returningGroups.length;

  const {
    financialFeesOverview: {
      totalOutstandingBalanceInCents,
    },
    inventoryCheckoutCount,
    uniformCheckoutCount,
  } = outstandingItemData;

  const totalOutstandingBalanceInDollars = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalOutstandingBalanceInCents),
  );

  return (
    <>
      <Box mb={2}>
        <Subtitle>Outstanding Items</Subtitle>

        <EnhancedCard>
          <CardContent>
            <Typography variant="subtitle1">Remaining outstanding items:</Typography>

            <ul>
              <li>
                <Typography>
                  <StyledStrong>{inventoryCheckoutCount}</StyledStrong>{' '}
                  checked out inventory {pluralize(inventoryCheckoutCount, 'item')}.
                </Typography>
              </li>

              <li>
                <Typography>
                  <StyledStrong>{uniformCheckoutCount}</StyledStrong>{' '}
                  checked out {pluralize(uniformCheckoutCount, 'uniform')}.
                </Typography>
              </li>

              <li>
                <Typography>
                  <StyledStrong>{totalOutstandingBalanceInDollars}</StyledStrong>{' '}
                  in fees.
                </Typography>
              </li>
            </ul>
          </CardContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={() => handlePressEditButton(1)}
              variant="outlined"
            >
              Edit
            </Button>
          </DialogActions>
        </EnhancedCard>
      </Box>

      <Box mb={2}>
        <Subtitle>Members</Subtitle>

        <EnhancedCard>
          <CardContent>
            <Box mb={2}>
              <Typography>
                {
                  returningUsersCount === 0
                    ? (
                      <>
                        All members are returning for {fullNextSchoolYear}.
                      </>
                    )
                    : (
                      <>
                        <StyledStrong>{returningUsersCount}</StyledStrong>{' '}
                        {pluralize(returningUsersCount, 'member')}{' '}
                        not returning for {fullNextSchoolYear}.
                      </>
                    )
                }
              </Typography>
            </Box>

            <EnhancedAlert severity="info">
              {fullCurrentSchoolYear} members will be{' '}
              visible in the &quot;Previous Members&quot;{' '}
              table.
            </EnhancedAlert>
          </CardContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={() => handlePressEditButton(2)}
              variant="outlined"
            >
              Edit
            </Button>
          </DialogActions>
        </EnhancedCard>
      </Box>

      <Box mb={2}>
        <Subtitle>Groups</Subtitle>

        <EnhancedCard>
          <CardContent>
            <Box mb={2}>
              <Typography>
                {
                  returningGroupsCount === 0
                    ? (
                      <>
                        No groups will carry over for {fullNextSchoolYear}.
                      </>
                    )
                    : (
                      <>
                        <StyledStrong>{returningGroupsCount}</StyledStrong>{' '}
                        {pluralize(returningGroupsCount, 'group')}{' '}
                        will carry over to {fullNextSchoolYear}.
                      </>
                    )
                }
              </Typography>
            </Box>

            <EnhancedAlert severity="info">
              {fullCurrentSchoolYear} groups will be{' '}
              <strong>read-only</strong> after completing this step
            </EnhancedAlert>
          </CardContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={() => handlePressEditButton(3)}
              variant="outlined"
            >
              Edit
            </Button>
          </DialogActions>
        </EnhancedCard>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mt={4}
      >
        <StyledButton
          color="primary"
          onClick={toggleConfirmationDialogIsOpen}
          variant="contained"
        >
          Start Next School Year
        </StyledButton>
      </Box>

      <DialogConfirmStartNextSchoolYear
        fullNextSchoolYear={fullNextSchoolYear}
        isOpen={isConfirmationDialogOpen}
        toggleIsOpen={toggleConfirmationDialogIsOpen}
      />
    </>
  );
};

export default EndOfYearReview;
