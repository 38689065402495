// External Dependencies
import { FC, useCallback } from 'react';
import { UserRoles } from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { GET_DASHBOARD_METRICS } from 'gql/queries';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateUser } from 'gql/mutations';
import DangerZone, { DangerZoneItemProps } from 'components/shared/DangerZone';

// Local Dependencies
import RemoveMemberDialog from './RemoveMemberDialog';

// Local Typings
interface Props {
  memberId?: string;
  memberRoleId?: UserRoles;
  organizationMembershipId: string;
  returnToOnSuccess: string;
}

// Local Variables
const FAMILY_USER_ROLES = {
  PARENT: 'parent',
  STUDENT: 'student',
};

// Component Definition
const PeopleDangerZone: FC<Props> = ({
  memberId,
  memberRoleId,
  organizationMembershipId,
  returnToOnSuccess,
}) => {
  const navigate = useNavigate();

  const {
    isOpen: isRemoveUserConfirmationDialogOpen,
    toggleIsOpen: toggleRemoveUserConfirmationDialogIsOpen,
  } = useIsOpen();
  const {
    isOpen: isSwitchRoleConfirmationDialogOpen,
    toggleIsOpen: toggleSwitchRoleConfirmationDialogIsOpen,
  } = useIsOpen();

  const canRemoveMembers = useSelector(hasPermission('users', 'delete'));

  const isMobileWidth = useSelector(isMobileScreenSize);

  const isStudent = memberRoleId === UserRoles.Student;

  const handleCompleted = useCallback(() => {
    navigate(returnToOnSuccess);
  }, [navigate, returnToOnSuccess]);

  const [updateUser, { loading }] = useUpdateUser(
    {
      clearCachePredicates: [
        'dashboardMetrics',
        'parentsIndex',
        'studentsIndex',
        'user',
      ],
      onCompleted: handleCompleted,
    },
  );

  const handleConfirmSwitchRole = () => {
    if (memberId) {
      updateUser({
        variables: {
          id: memberId,
          input: {
            roleId: isStudent ? UserRoles.Adult : UserRoles.Student,
          },
        },
      });
    }
  };

  const items: DangerZoneItemProps[] = [];

  if (canRemoveMembers) {
    items.push({
      buttonText: isMobileWidth ? 'Remove' : 'Remove member',
      description: 'Deactivate member in this organization. This does not delete their Presto member profile entirely.',
      onClick: toggleRemoveUserConfirmationDialogIsOpen,
      title: 'Remove member from organization',
    });
  }

  const userRoleText = memberRoleId === UserRoles.Student
    ? FAMILY_USER_ROLES.STUDENT
    : FAMILY_USER_ROLES.PARENT;
  const oppositeRoleText = memberRoleId === UserRoles.Student
    ? FAMILY_USER_ROLES.PARENT
    : FAMILY_USER_ROLES.STUDENT;

  // We only allow switching the adult/student role for parent/student members.
  // The director's `DangerZone` won't send this prop in.
  if (memberRoleId) {
    items.unshift({
      buttonText: 'Switch role',
      description: `Switch this member from a ${userRoleText} to a ${oppositeRoleText}.`,
      onClick: toggleSwitchRoleConfirmationDialogIsOpen,
      title: `Switch to a ${oppositeRoleText}`,
    });
  }

  return (
    <>
      <Box mt={4}>
        <DangerZone items={items} />
      </Box>

      <ConfirmationDialog
        canBeUndone
        confirmButtonAction={handleConfirmSwitchRole}
        confirmButtonText="Yes, Switch"
        declineButtonAction={toggleSwitchRoleConfirmationDialogIsOpen}
        description={(
          <DialogContentText component="div">
            <Typography paragraph>
              Are you sure you want to switch this member&apos;s role from
              {' '}
              <strong>{userRoleText.toUpperCase()}</strong>
              {' '}
              to
              {' '}
              <strong>{oppositeRoleText.toUpperCase()}</strong>?
            </Typography>

            <Typography paragraph>
              This should only be used in case this person accidentally signed
              up with the wrong type of user profile.
            </Typography>

            <Typography>
              {isStudent
                ? "This action will remove the student's grade value."
                : 'This will remove any permissions granted to the parent.'}
            </Typography>
          </DialogContentText>
        )}
        handleClose={toggleSwitchRoleConfirmationDialogIsOpen}
        isSubmitting={loading}
        open={isSwitchRoleConfirmationDialogOpen}
        title="Switch Member's Role?"
        useCustomText
      />

      {memberId && (
        <RemoveMemberDialog
          isOpen={isRemoveUserConfirmationDialogOpen}
          memberId={memberId}
          onClose={toggleRemoveUserConfirmationDialogIsOpen}
          onMemberDeleted={handleCompleted}
          organizationMembershipId={organizationMembershipId}
          refetchQueries={[{ query: GET_DASHBOARD_METRICS }]}
        />
      )}
    </>
  );
};

export default PeopleDangerZone;
