// External Dependencies
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';

// Internal Dependencies
import {
  ConfirmationDialog, EnhancedAlert, Flex, Page, SaveButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

import { addNotification } from 'state/notifications/actions';
import { useGetFormAsDirector } from 'gql/queries/form-queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { usePublishForm } from 'gql/mutations/form-mutations';

// Local Dependencies
import BlocksCard from './BlocksCard';
import DetailsCard from '../components/DetailsCard';

// Component Definition
const FormsEdit = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParamsWithId();

  const {
    handleClose: handleCloseConfirmPublishDialog,
    handleOpen: handleOpenConfirmPublishDialog,
    isOpen: isConfirmPublishDialogOpen,
  } = useIsOpen();

  const {
    handleClose: handleHideFormBlocksRequiredMessage,
    handleOpen: handleShowFormBlocksRequiredMessage,
    isOpen: isFormBlocksRequiredMessageShowing,
  } = useIsOpen();

  const {
    data: formData,
  } = useGetFormAsDirector(id);

  const form = useMemo(() => {
    return formData?.form;
  }, [formData]);

  const handleCompletePublish = useCallback(() => {
    handleCloseConfirmPublishDialog();

    dispatch(addNotification('Form published successfully.', 'success'));

    navigate(`/${PATHS.FORMS}/${form?.id}`, { replace: true });
  }, [dispatch, form?.id, handleCloseConfirmPublishDialog, navigate]);

  const [
    publishForm,
    {
      loading: isPublishingForm,
    },
  ] = usePublishForm({
    clearCachePredicates: ['formIndex'],
    onCompleted: handleCompletePublish,
  });

  const handleClickConfirmPublish = useCallback(async () => {
    publishForm({
      variables: {
        id: form?.id ?? '',
      },
    });
  }, [form, publishForm]);

  const handleClickPublish = useCallback(() => {
    if (!form?.formBlocks.length) {
      handleShowFormBlocksRequiredMessage();
    } else {
      handleOpenConfirmPublishDialog();
    }
  }, [
    form,
    handleOpenConfirmPublishDialog,
    handleShowFormBlocksRequiredMessage,
  ]);

  useEffect(() => {
    if (isFormBlocksRequiredMessageShowing && form?.formBlocks.length) {
      handleHideFormBlocksRequiredMessage();
    }
  }, [
    form?.formBlocks.length,
    handleHideFormBlocksRequiredMessage,
    isFormBlocksRequiredMessageShowing,
  ]);

  return (
    <Page
      backButtonProps={{
        label: 'Forms',
        path: `/${PATHS.FORMS}`,
      }}
    >
      <Container maxWidth="md">
        {form && (
          <Box marginTop={2}>
            <DetailsCard
              form={form}
              isEditMode
            />
          </Box>
        )}

        {form && (
          <Box marginTop={2}>
            <BlocksCard
              blocks={form.formBlocks}
              formId={form.id}
            />
          </Box>
        )}

        <Collapse in={isFormBlocksRequiredMessageShowing}>
          <EnhancedAlert
            severity="error"
            sx={{ marginTop: 2 }}
            title="Form Blocks Required"
          >
            You must add at least one block to this form before publishing.
          </EnhancedAlert>
        </Collapse>

        {form && !form.publishedAt && (
          <Flex
            justifyContent="flex-end"
            marginTop={2}
          >
            <SaveButton
              endIcon={<SendIcon />}
              isSaving={isPublishingForm}
              onClick={handleClickPublish}
            >
              Publish
            </SaveButton>
          </Flex>
        )}
      </Container>

      <ConfirmationDialog
        confirmButtonAction={handleClickConfirmPublish}
        confirmButtonText="Yes, Publish"
        declineButtonAction={handleCloseConfirmPublishDialog}
        description="After publishing this form, you will not be able to make revisions. You will still be able to assign/unassign to members."
        handleClose={handleCloseConfirmPublishDialog}
        open={isConfirmPublishDialogOpen}
        title="Publish Form?"
      />
    </Page>
  );
};

export default FormsEdit;
