// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '70%',
  },
});

// Component Definition
const ManageDistrictAssistantsListItem: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const navigateToDistrictAssistants = useCallback(() => {
    navigate(`${PATHS.DISTRICT_ASSISTANTS}`);
  }, [navigate]);

  return (
    <ListItem>
      <ListItemText
        primary="Manage District Assistants"
        secondary={`Manage assistants that help oversee your ${APP_NAME} district.`}
        secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
      />

      <ListItemSecondaryAction>
        <Button
          aria-label="View District Assistants"
          color="primary"
          onClick={navigateToDistrictAssistants}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          variant="outlined"
        >
          View
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ManageDistrictAssistantsListItem;
