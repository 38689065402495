// External Dependencies
import {
  FC,
} from 'react';
import Box from '@mui/system/Box';

// Internal Dependencies
import {
  EnhancedCard,
  Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetPaymentsFromTransactionId } from 'utils/api/financialTransaction';
import { useParamsWithId } from 'hooks/useParamsWithId';
import FinancialTransactionsShowTable from './FinancialTransactionsShowTable/FinancialTransactionsShowTable';
import TransactionPaymentsDetailsCard from './TransactionPaymentsDetailsCard';

// Component Definition
const FinancialTransactionsShow: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    isError,
    isLoading,
  } = useGetPaymentsFromTransactionId(id);

  const payments = data?.data.payments;

  return (
    <Page
      backButtonProps={{
        label: 'All Financial Transactions',
        path: `/${PATHS.FINANCIAL_TRANSACTIONS}`,
      }}
      isLoading={isLoading}
      notFound={isError}
    >
      {payments && (
        <TransactionPaymentsDetailsCard
          payments={payments}
        />
      )}

      <Box mb={4}>
        <EnhancedCard>
          <FinancialTransactionsShowTable
            loading={isLoading}
            rows={payments ?? []}
          />
        </EnhancedCard>
      </Box>
    </Page>
  );
};

export default FinancialTransactionsShow;
