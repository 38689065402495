// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { Formik } from 'formik';
import { updateFinancialPaymentSchema } from '@presto-assistant/api_types/schemas/financialPayments';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import { ConfirmationDialog, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, convertDollarsToCents } from 'utils';
import { formatYupErrors } from 'utils/lib/formatYupErrors';
import { useGetFinancialPayment } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateFinancialPayment } from 'gql/mutations';

// Local Dependencies
import ConfirmationDialogContent from './ConfirmationDialogContent';
import EditForm from './EditForm';

// Local Variables
const normalizePayload = (values: GQL.IUpdateFinancialPaymentInput) => {
  const input: GQL.IUpdateFinancialPaymentInput = {
    ...values,
    amountInCents: convertDollarsToCents(values.amountInCents),
  };

  const paymentTypeId = values.financialPaymentTypeId;

  if (paymentTypeId !== FinancialPaymentTypes.Check.toString()) {
    delete input.checkNumber;
  }

  if (paymentTypeId !== FinancialPaymentTypes.CreditCard.toString()) {
    delete input.creditCardAccountHolder;
    delete input.creditCardExp;
    delete input.creditCardLastFour;
  }

  if (paymentTypeId !== FinancialPaymentTypes.Other.toString()) {
    delete input.otherLabel;
    delete input.referenceNumber;
  }

  return input;
};

// eslint-disable-next-line consistent-return
const validate = async (values: GQL.IUpdateFinancialPaymentInput) => {
  try {
    await updateFinancialPaymentSchema.validate(
      normalizePayload(values),
      { abortEarly: false, strict: true },
    );

    return {};
  } catch (error) {
    return formatYupErrors(error);
  }
};

// Component Definition
const FinancialPaymentEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const [submitPayload, setSubmitPayload] = useState<GQL.IUpdateFinancialPaymentInput | null>(null);

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  const {
    data,
    error,
    loading,
  } = useGetFinancialPayment(id!);

  const initialValues = useMemo<GQL.IUpdateFinancialPaymentInput>(() => ({
    amountInCents: convertCentsToDollars(data?.financialPayment.amountInCents ?? 0),
    checkNumber: data?.financialPayment.checkNumber ?? '',
    creditCardAccountHolder: data?.financialPayment.creditCardAccountHolder ?? '',
    creditCardExp: data?.financialPayment.creditCardExp ?? '',
    creditCardLastFour: data?.financialPayment.creditCardLastFour ?? '',
    datePaid: data?.financialPayment.datePaid ?? '',
    financialPaymentTypeId: data?.financialPayment.financialPaymentType.id ?? '',
    note: data?.financialPayment.note ?? '',
    otherLabel: data?.financialPayment.otherLabel ?? '',
    referenceNumber: data?.financialPayment.referenceNumber ?? '',
  }), [data]);

  const showPath = `/${PATHS.FINANCIAL_PAYMENTS}/${id}`;

  const handleNavigateToShow = useCallback(() => {
    navigate(showPath);
  }, [navigate, showPath]);

  const [
    updatePayment,
    {
      loading: isSubmitting,
    },
  ] = useUpdateFinancialPayment({
    onCompleted: handleNavigateToShow,
  });

  const handleClearPayload = useCallback(() => {
    setSubmitPayload(null);
  }, []);

  const handleSetPayload = (values: GQL.IUpdateFinancialPaymentInput) => {
    const input = normalizePayload(values);

    setSubmitPayload(input);
    handleOpen();
  };

  const handleSubmitForm = useCallback(() => {
    if (submitPayload) {
      updatePayment({
        variables: {
          id: id!,
          input: submitPayload,
        },
      });
    }
  }, [id, submitPayload, updatePayment]);

  return (
    <Page
      backButtonProps={{
        label: 'Payment Detail',
        path: showPath,
      }}
      error={error}
      isLoading={loading}
    >
      <Formik<GQL.IUpdateFinancialPaymentInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSetPayload}
        validate={validate}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          touched,
          values,
        }) =>
          data?.financialPayment && (
            <EditForm
              financialItemId={data.financialPayment.financialFee.financialItem.id}
              financialItemLabel={data.financialPayment.financialFee.financialItem.label}
              isFormTouched={Object.keys(touched).length > 0}
              isSubmitting={isSubmitting}
              onClickCancel={handleNavigateToShow}
              onSubmit={handleSubmit}
              originalPriceInCents={data.financialPayment.financialFee.financialItem.priceInCents}
              paymentTypeId={values.financialPaymentTypeId}
              remainingBalanceInCents={data.financialPayment.financialFee.remainingBalanceInCents}
              user={data.financialPayment.user}
            />
          )}
      </Formik>

      <ConfirmationDialog
        confirmButtonAction={handleSubmitForm}
        confirmButtonText="Submit"
        declineButtonAction={handleClose}
        description={(
          <ConfirmationDialogContent payload={submitPayload} />
        )}
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        onExited={handleClearPayload}
        open={isOpen}
        title="Edit Payment Review"
        useCustomText
      />
    </Page>
  );
};

export default FinancialPaymentEdit;
