// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { tableQueryParams } from 'state/table/selectors';

const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '60%',
  },
});

// Component Definition
const ManageInactiveUsersListItem: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const inactiveUserQueryString = useSelector(tableQueryParams('inactiveUsers'));

  const navigateToInactiveUsers = useCallback(() =>
    navigate(`/${PATHS.INACTIVE_MEMBERS}${inactiveUserQueryString}`), [inactiveUserQueryString, navigate]);

  return (
    <ListItem>
      <ListItemText
        primary="Manage Inactive Members"
        secondary={`View users you have removed from your ${APP_NAME} organization.`}
        secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
      />
      <ListItemSecondaryAction>
        <Button
          aria-label="View Inactive Members"
          color="primary"
          onClick={navigateToInactiveUsers}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          variant="outlined"
        >
          View
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ManageInactiveUsersListItem;
