// External Dependencies
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import {
  ConfirmationDialog,
  TableDataGrid,
} from 'components/shared';
import {
  DistrictAssistant,
  useGetDistrictAdmin,
  useGetDistrictAssistantsIndex,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { selfId as selfIdSelector } from 'state/self/selectors';
import { useDeleteDistrictAssistant } from 'gql/mutations';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import AssistantsTableZeroState from './AssistantsTableZeroState';

// Component Definition
const AssistantsTableUI: FC = () => {
  const [isDeleteSelfDialogOpen, setIsDeleteSelfDialogOpen] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<DistrictAssistant | null>(null);

  const dispatch = useDispatch();

  const selfId = useSelector(selfIdSelector);

  const {
    data,
    isLoading,
    refetch,
  } = useGetDistrictAssistantsIndex();

  const adminData = useGetDistrictAdmin();

  const isHeadDistrictAdmin = adminData.data?.district.admin.id === selfId;

  const openDeleteDialog = useCallback((item: DistrictAssistant) => {
    setItemToBeDeleted(item);
  }, [setItemToBeDeleted]);

  const closeDeleteDialog = useCallback(() => {
    setItemToBeDeleted(null);
  }, [setItemToBeDeleted]);

  const handleClickRowDelete = (item: DistrictAssistant) => {
    if (item.id === selfId) {
      toggleDeleteSelfDialog();
    } else {
      openDeleteDialog(item);
    }
  };

  const columns = useColumns({
    isHeadDistrictAdmin,
    onDelete: handleClickRowDelete,
    selfId,
  });

  const toggleDeleteSelfDialog = useCallback(() => {
    setIsDeleteSelfDialogOpen((open) => !open);
  }, [setIsDeleteSelfDialogOpen]);

  const handleDeleteSuccess = async () => {
    const message = isHeadDistrictAdmin
      ? 'Assistant deleted!'
      : 'Successfully removed!';

    refetch();

    dispatch(addNotification(message, 'success'));
    closeDeleteDialog();
  };

  const [
    deleteAssistant,
    { loading: isDeleting },
  ] = useDeleteDistrictAssistant(
    {
      clearCachePredicates: ['districtAssistants'],
      onCompleted: handleDeleteSuccess,
    },
  );

  const handleConfirmDelete = () => {
    deleteAssistant({
      variables: {
        userId: itemToBeDeleted?.id,
      },
    });
  };

  return (
    <>
      {/* Only the district admin may add assistants */}

      <DataGridContainer>
        <TableDataGrid
          addButtonProps={isHeadDistrictAdmin ? {
            label: 'District Assistant',
            to: `${PATHS.DISTRICT_ASSISTANTS}/new`,
          } : null}
          columns={columns}
          components={{
            NoRowsOverlay: AssistantsTableZeroState,
          }}
          loading={isLoading}
          rows={data}
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmDelete}
        declineButtonAction={toggleDeleteSelfDialog}
        description={(
          <>
            Are you sure you want to remove yourself from {adminData.data?.district.label ?? 'this district'}?
            <br />
            You will maintain your user account with Presto, but no longer be a district assistant.
          </>
        )}
        handleClose={toggleDeleteSelfDialog}
        isSubmitting={isDeleting}
        open={isDeleteSelfDialogOpen}
        title="Remove Self?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmDelete}
        declineButtonAction={closeDeleteDialog}
        description="Are you sure you want to remove this assistant?"
        handleClose={closeDeleteDialog}
        isSubmitting={isDeleting}
        open={!!itemToBeDeleted}
        title="Delete Assistant?"
      />
    </>
  );
};

export default AssistantsTableUI;
