// External Dependencies
import { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { useLogout } from 'utils/api';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Component Definition
const Logout: FC<RouteComponentProps> = () => {
  const search = useParsedSearch();

  const { redirectUrl } = search;

  const logout = useLogout();

  const handleLogout = useCallback(async () => {
    await logout();

    // A hard refresh helps to ensure we clear all state
    window.location.href = `${window.location.origin}${redirectUrl || '/'}`;
  }, [logout, redirectUrl]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={4}
      height="80vh"
      justifyContent="center"
    >
      <Typography variant="h6">
        Logging out...
      </Typography>

      <CircularProgress />
    </Box>
  );
};

export default Logout;
