// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  DELETE_FINANCIAL_ACCOUNT,
  useUpdateFinancialAccount,
} from 'gql/mutations';
import { DangerZone, EnhancedContainer, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Dependencies
import { useGetFinancialAccount } from 'gql/queries';
import AccountForm from '../shared/AccountForm';

// Component Definition
const FinancialAccountsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const canDeleteAccounts = useSelector(hasPermission('financialAccounts', 'delete'));

  const {
    data,
    error,
    loading,
  } = useGetFinancialAccount(id!);

  const {
    isOpen: isDeleteFinancialAccountDialogOpen,
    toggleIsOpen: toggleDeleteFinancialAccountDialog,
  } = useIsOpen();

  const [updateFinancialAccount] = useUpdateFinancialAccount(
    {
      clearCachePredicates: ['history'],
      onCompleted: () => {
        navigate(`/${PATHS.FINANCIAL_ACCOUNTS}/${id}`);
      },
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.IUpdateFinancialAccountInput,
  ) =>
    updateFinancialAccount({
      variables: {
        id: id!,
        input: submitValues,
      },
    });

  const handleFormatPayload: DeleteDialogV2Props<GQL.IDeleteFinancialAccountOnMutationArguments>['formatPayload'] = useCallback((note) => {
    return {
      deletedNote: note ?? '',
      id: id ?? '',
    };
  }, [id]);

  const handleNavigateToFinancialOverview = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_OVERVIEW}`);
  }, [navigate]);

  return (
    <>
      <Page
        backButtonProps={{
          label: 'Financial Overview',
          path: `/${PATHS.FINANCIAL_OVERVIEW}`,
        }}
        error={error}
        isLoading={loading}
      >
        <EnhancedContainer>
          <AccountForm
            initialValues={{
              canApplyFundraiserCredits: data?.financialAccount.canApplyFundraiserCredits ?? false,
              label: data?.financialAccount.label ?? '',
              onlinePaymentsEnabled: data?.financialAccount.onlinePaymentsEnabled ?? false,
            }}
            onSubmit={handleSubmitForm}
          />

          {canDeleteAccounts && (
            <Box marginTop={5}>
              <DangerZone
                items={[
                  {
                    buttonText: 'Delete',
                    description: `Delete the "${data?.financialAccount.label}" financial account.`,
                    onClick: toggleDeleteFinancialAccountDialog,
                    title: 'Delete Financial Account',
                  },
                ]}
              />
            </Box>
          )}
        </EnhancedContainer>
      </Page>

      <DeleteDialogV2<GQL.IDeleteFinancialAccountOnMutationArguments>
        actionVerb="Delete"
        clearCachePredicates={['financialAccounts']}
        context={['financial account']}
        descriptionText={`The financial account "${data?.financialAccount.label}" will be marked as deleted.`}
        formatPayload={handleFormatPayload}
        isOpen={isDeleteFinancialAccountDialogOpen}
        mutation={DELETE_FINANCIAL_ACCOUNT}
        onClose={toggleDeleteFinancialAccountDialog}
        onSuccess={handleNavigateToFinancialOverview}
        withNote
      />
    </>
  );
};

export default FinancialAccountsEdit;
