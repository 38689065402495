// External Dependencies
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { CardDetails } from 'pages/Finances/MyFinancialPayments/New/shared/PaymentMethodCard';
import { PATHS } from 'utils/constants/routes';
import {
  Page,
  Stepper,
} from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganization } from 'gql/queries';
import EnterStripePaymentMethod from 'pages/Finances/MyFinancialPayments/New/Steps/EnterStripePaymentMethod';
import EnterVancoPaymentMethod
  from 'pages/Finances/MyFinancialPayments/New/Steps/EnterVancoPaymentMethod';
import VancoBillingAlert from 'pages/Finances/MyFinancialPayments/shared/VancoBillingAlert';

// Local Dependencies
import { PaymentsFormValues } from './PaymentTable';
import EnterPaymentAmounts from './Steps/EnterPaymentAmounts';
import PaymentReview from './Steps/PaymentReview';
import RevTrakUnavailable from './RevTrakUnavailable';
import StripeBillingAlert from '../shared/StripeBillingAlert';

// Local Variables
const stepLabels = ['Payment Amount', 'Payment Method', 'Review'];

// Component Definition
const MyFinancialPaymentsNew = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(1);
  const [paymentAmountValues, setPaymentAmountValues] = useState<PaymentsFormValues | null>(null);
  const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);

  const myPaymentsParams = useSelector(tableQueryParams('myFinancialPayments'));

  const {
    allNotCovered,
  } = useMemo(() => {
    if (!paymentAmountValues) {
      return {
        allCovered: false,
        allNotCovered: false,
        isMixed: false,
      };
    }

    const paymentsWithValue = paymentAmountValues
      .payments?.filter((payment) => payment.amountInDollars > 0) ?? [];

    const areAllCovered = paymentsWithValue
      .every((payment) => payment.isOrganizationCoveringStripeFee);

    const areAllNotCovered = paymentsWithValue
      .every((payment) => !payment.isOrganizationCoveringStripeFee);

    return {
      allCovered: areAllCovered,
      allNotCovered: areAllNotCovered,
      isMixed: !areAllCovered && !areAllNotCovered,
    };
  }, [paymentAmountValues]);

  const maxAvailableStep = useMemo(() => {
    if (!paymentAmountValues) {
      return 1;
    }

    if (!cardDetails) {
      return 2;
    }

    return 3;
  }, [cardDetails, paymentAmountValues]);

  const handleAdvanceStep = useCallback(() => {
    setActiveStep((step) => Math.max(step + 1, maxAvailableStep));
  }, [maxAvailableStep]);

  const handleSetPaymentAmountValues = useCallback(
    (value: PaymentsFormValues | null) => {
      if (value) {
        handleAdvanceStep();
      }

      setPaymentAmountValues(value);
    },
    [handleAdvanceStep],
  );

  const handleSetCardDetails = useCallback(
    (value: CardDetails | null) => {
      setCardDetails(value);
    },
    [],
  );

  useEffect(() => {
    setActiveStep(maxAvailableStep);
  }, [cardDetails, maxAvailableStep, paymentAmountValues]);

  const handleClearPaymentMethod = useCallback(() => {
    setCardDetails(null);
  }, []);

  const handleClearPaymentAmounts = useCallback(() => {
    setPaymentAmountValues(null);
  }, []);

  const organization = useGetOrganization();

  const isStripe = organization?.data?.organization.hasStripeId;
  const isVanco = organization?.data?.organization.hasVancoPcct;

  if (isVanco && process.env.REACT_APP_REVTRAK_SYSTEM_ISSUES === 'true') {
    return <RevTrakUnavailable />;
  }

  return (
    <Page
      backButtonProps={{
        label: 'My Payments',
        path: `/${PATHS.STUDENT_PAYMENTS}${myPaymentsParams}`,
      }}
    >
      <Box marginBottom={2}>
        <Stepper
          activeStep={activeStep}
          maxAvailableStep={maxAvailableStep}
          onSetActiveStep={setActiveStep}
          stepLabels={stepLabels}
        />
      </Box>

      {allNotCovered && (
        isStripe ? <StripeBillingAlert /> : <VancoBillingAlert />
      )}

      {activeStep === 1 && (
        <EnterPaymentAmounts
          hasFees={allNotCovered}
          onSetPaymentValues={handleSetPaymentAmountValues}
          paymentAmountValues={paymentAmountValues}
        />
      )}

      {activeStep === 2 && (
        isStripe ? (
          <EnterStripePaymentMethod
            cardDetails={cardDetails}
            onSetCardDetails={handleSetCardDetails}
          />
        ) : (
          <EnterVancoPaymentMethod
            cardDetails={cardDetails}
            onSetCardDetails={handleSetCardDetails}
          />
        )
      )}

      {activeStep === 3 && (
        <PaymentReview
          cardDetails={cardDetails}
          hasFees={allNotCovered}
          onEditPaymentAmounts={handleClearPaymentAmounts}
          onEditPaymentMethod={handleClearPaymentMethod}
          paymentAmountValues={paymentAmountValues}
          paymentPlatform={isStripe ? 'stripe' : 'vanco'}
        />
      )}
    </Page>
  );
};

export default MyFinancialPaymentsNew;
