// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import KeyIcon from 'mdi-material-ui/Key';

// Internal Dependencies
import {
  Container,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import {
  getDistrictAdminOrganizationIdFromPathname,
  getFullName,
} from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import {
  useGetOrganizationById,
  useGetUserByDistrictAdmin,
} from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateUserPermissionsByDistrictAdmin } from 'gql/mutations';
import PermissionEditPage from 'components/shared/PermissionEditPage';

// Component Definition
const DistrictAdminEditPermissions: FC = () => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const dispatch = useDispatch();

  const [permissions, setPermissions] = useState<GQL.IPermissions | null>(null);
  const [permissionIds, setPermissionIds] = useState<number[]>([]);
  const [mayEditPermissions, setMayEditPermissions] = useState(false);

  const organizationId = useMemo(getDistrictAdminOrganizationIdFromPathname, []);
  const districtDirectorsParams = useSelector(tableQueryParams('districtOrganizationsDirectors'));

  const {
    data: adultData,
    error,
    loading: isLoadingUser,
  } = useGetUserByDistrictAdmin({
    id: id!,
    organizationId,
  });

  const {
    data: organizationData,
  } = useGetOrganizationById({
    id: organizationId,
  });

  const pathToOrganizationDetails = `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${organizationId}${districtDirectorsParams}`;

  const handleCompletedMutation = useCallback(() => {
    dispatch(addNotification('Permissions updated', 'success'));
    navigate(pathToOrganizationDetails);
  }, [dispatch, navigate, pathToOrganizationDetails]);

  const [
    updateUser,
    { loading: isSaving },
  ] = useUpdateUserPermissionsByDistrictAdmin(
    {
      clearCachePredicates: ['userByDistrictAdmin'],
      onCompleted: handleCompletedMutation,
      refetchQueries: () => ['DirectorsByDistrictAdmin'],
      variables: {
        mayEditPermissions,
        organizationId,
        permissionIds,
        userId: adultData?.userByDistrictAdmin.id!,
      },
    },
  );

  useEffect(() => {
    setPermissions(adultData?.userByDistrictAdmin.permissions || null);
    setMayEditPermissions(!!adultData?.userByDistrictAdmin.mayEditPermissions);
  }, [adultData]);

  return (
    <Page
      backButtonProps={{
        label: 'Organization Details',
        path: pathToOrganizationDetails,
      }}
      error={error}
      isLoading={!adultData || isSaving || isLoadingUser}
    >
      <Container maxWidth={500}>
        {adultData?.userByDistrictAdmin ? (
          <ShowCard
            canEdit
            icon={KeyIcon}
            title={`Edit Permissions for ${getFullName(adultData?.userByDistrictAdmin)}`}
          >
            <PermissionEditPage
              isDirector
              isLoading={isSaving}
              mayEditPermissions={mayEditPermissions}
              onSave={updateUser}
              onUpdateMayEditPermissions={setMayEditPermissions}
              onUpdatePermissionIds={setPermissionIds}
              organizationName={organizationData?.organization.label}
              permissionIds={permissionIds}
              permissions={permissions}
            />
          </ShowCard>
        ) : null}
      </Container>
    </Page>
  );
};

export default DistrictAdminEditPermissions;
