// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { UserAlertFlags } from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import AccountPlusIcon from 'mdi-material-ui/AccountPlus';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  APP_NAME,
  ROLES,
  ROLE_IDS,
  SHORT_APP_NAME,
} from 'utils/constants';
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  useCreateChild,
  useCreateRelationship,
} from 'gql/mutations';
import { useGetOrganization } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import Age, { AgeFormValues } from './Steps/Age';
import Email, { OnCompletedEmailStepArgs } from './Steps/Email';
import Name, { NameFormValues } from './Steps/Name';
import RelationshipType, { RelationshipTypeFormValues } from './Steps/RelationshipType';
import UnderThirteen, { UnderThirteenFormValues } from './Steps/UnderThirteen';

// Local Typings
type Step = 'age' | 'email' | 'name' | 'underThirteen' | 'relationshipType';

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  li: {
    marginBottom: theme.spacing(1),
  },
}));

// Component Definition
const RelationshipsNew: FC = () => {
  const navigate = useNavigate();

  const navigateToProfile = useCallback(() => {
    navigate(`/${PATHS.PROFILE}`);
  }, [navigate]);

  const [emailStepArgs, setEmailStepArgs] = useState<OnCompletedEmailStepArgs>({
    doesMemberExist: false,
    email: '',
    userRoleId: null,
  });
  const [nameStepArgs, setNameStepArgs] = useState<NameFormValues>({
    firstName: '',
    lastName: '',
  });
  const [ageStepArgs, setAgeStepArgs] = useState<AgeFormValues>({
    isAtLeast13: '',
  });
  const [
    underThirteenStepArgs,
    setUnderThirteenStepArgs,
  ] = useState<UnderThirteenFormValues>({
    acceptedTerms: false,
    dateOfBirthChild: '',
    genderId: '',
    isParent: false,
    otherGenderLabel: '',
    password: '',
  });
  const [
    relationshipTypeStepArgs,
    setRelationshipTypeStepArgs,
  ] = useState<RelationshipTypeFormValues>({
    recipientRelationshipTypeId: '',
  });
  const isUserAtLeast13 = ageStepArgs.isAtLeast13 === 'yes';

  const [steps, setSteps] = useState<Step[]>(['email']);

  const { self } = useSelfQuery();
  const { data: orgData } = useGetOrganization();

  const isAdult = self?.role.id === ROLE_IDS[ROLES.ADULT];

  const [
    createRelationship,
    { loading: createRelationshipLoading },
  ] = useCreateRelationship(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['userRelationships'],
      onCompleted: navigateToProfile,
    },
  );

  const [
    createChild,
    { loading: createChildLoading },
  ] = useCreateChild(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['myChildrenUnderThirteen', 'userRelationships'],
      onCompleted: navigateToProfile,
    },
  );

  const handleGoBack = () => {
    const currentSteps = [...steps];
    if (currentSteps.length > 1) {
      currentSteps.pop();

      setSteps(currentSteps);
    }
  };

  const addStep = (nextStep: Step) => {
    setSteps((currentSteps) => [...currentSteps, nextStep]);
  };

  const handleCompletedEmailStep = (args: OnCompletedEmailStepArgs) => {
    setEmailStepArgs(args);
    addStep(args.doesMemberExist ? 'relationshipType' : 'name');
  };

  const handleCompletedNameStep = (args: NameFormValues) => {
    setNameStepArgs(args);
    addStep(isAdult ? 'age' : 'relationshipType');
  };

  const handleCompletedAgeStep = (args: AgeFormValues) => {
    setAgeStepArgs(args);
    addStep(args.isAtLeast13 === 'yes' ? 'relationshipType' : 'underThirteen');
  };

  const handleCompletedUnderThirteenStep = (args: UnderThirteenFormValues) => {
    setUnderThirteenStepArgs(args);
    addStep('relationshipType');
  };

  const handleCompletedRelationshipTypeStep = (args: RelationshipTypeFormValues) => {
    setRelationshipTypeStepArgs(args);
  };

  const handleSubmit = useCallback(async () => {
    const { doesMemberExist, email } = emailStepArgs;
    const { firstName, lastName } = nameStepArgs;
    const {
      acceptedTerms,
      dateOfBirthChild: dateOfBirth,
      genderId,
      isParent,
      otherGenderLabel,
      password: temporaryPassword,
    } = underThirteenStepArgs;
    const { recipientRelationshipTypeId } = relationshipTypeStepArgs;

    if (!isAdult || isUserAtLeast13 || doesMemberExist) {
      createRelationship({
        variables: {
          recipientEmail: email,
          recipientFirstName: firstName,
          recipientLastName: lastName,
          recipientRelationshipTypeId,
        },
      });
    } else {
      createChild({
        variables: {
          acceptedTerms: acceptedTerms && isParent,
          input: {
            dateOfBirth,
            email,
            firstName,
            genderId,
            lastName,
            otherGenderLabel,
            recipientRelationshipTypeId,
            temporaryPassword,
          },
        },
      });
    }
  }, [
    createChild,
    createRelationship,
    emailStepArgs,
    isAdult,
    isUserAtLeast13,
    nameStepArgs,
    relationshipTypeStepArgs,
    underThirteenStepArgs,
  ]);

  useEffect(() => {
    if (relationshipTypeStepArgs.recipientRelationshipTypeId) {
      handleSubmit();
    }
  }, [handleSubmit, relationshipTypeStepArgs]);

  const step = steps[steps.length - 1];

  return (
    <Page
      backButtonProps={{
        label: 'Dashboard',
        path: `/${PATHS.DASHBOARD}`,
      }}
    >
      <Container maxWidth={500}>
        {self?.alertFlagId === UserAlertFlags.DOBViolation && (
        <EnhancedAlert
          severity="error"
          sx={{ marginBottom: 2 }}
          title="Parent Permission Required"
        >
          You need approval from a parent to use {SHORT_APP_NAME}.
          Please add a parent, and ask them to authorize your account.
        </EnhancedAlert>
        )}

        <StyledEnhancedCard>
          <CardHeader
            avatar={(
              <Avatar className="avatar">
                <AccountPlusIcon aria-label="Add family member." />
              </Avatar>
              )}
            title={(
              <Typography
                component="h2"
                variant="h6"
              >
                Add Family Member
              </Typography>
              )}
          />

          <CardContent>
            <>
              <Email
                doesMemberExist={emailStepArgs.doesMemberExist}
                initialValues={emailStepArgs}
                onCompleted={handleCompletedEmailStep}
                readOnly={step !== 'email'}
                selfEmail={self?.email}
              />

              <Collapse in={!emailStepArgs.doesMemberExist}>
                <Collapse in={step !== 'email'}>
                  <Box
                    margin={1.5}
                    marginTop={2.5}
                  >
                    <Box marginBottom={1.5}>
                      <Typography>
                        Create your family member&apos;s {APP_NAME} profile.
                      </Typography>
                    </Box>

                    <ul>
                      <li>
                        <Typography variant="body2">
                          This member&apos;s profile will be added
                          to {orgData?.organization.label}.
                        </Typography>
                      </li>

                      <li>
                        <Typography variant="body2">
                          The relationship will be linked to your member profile.
                        </Typography>
                      </li>

                      <li>
                        <Typography variant="body2">
                          When a director approves the new family member,
                          they will receive an email to set up their account.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Collapse>

                {step !== 'email' && (
                  <Name
                    onBack={handleGoBack}
                    onCompleted={handleCompletedNameStep}
                    readOnly={step !== 'name'}
                  />
                )}

                {step !== 'email' && step !== 'name' && isAdult && (
                  // Intentionally not passing initial values
                  // to force a selection each time
                  <Age
                    onBack={handleGoBack}
                    onCompleted={handleCompletedAgeStep}
                    readOnly={step !== 'age'}
                  />
                )}

                <Collapse in={step !== 'email' && step !== 'name' && step !== 'age' && !isUserAtLeast13 && isAdult}>
                  <UnderThirteen
                    initialValues={underThirteenStepArgs}
                    onBack={handleGoBack}
                    onCompleted={handleCompletedUnderThirteenStep}
                    readOnly={step !== 'underThirteen'}
                  />
                </Collapse>
              </Collapse>

              <Collapse in={step === 'relationshipType'}>
                <Box
                  margin={1.5}
                  marginTop={2.5}
                >
                  <Typography>
                    How is this family member related to you?
                  </Typography>
                </Box>

                <RelationshipType
                  autoFocus
                  isSubmitting={createChildLoading || createRelationshipLoading}
                  memberRoleId={self?.role.id}
                  onBack={handleGoBack}
                  onCompleted={handleCompletedRelationshipTypeStep}
                  relationRoleId={emailStepArgs.userRoleId}
                />
              </Collapse>
            </>
          </CardContent>
        </StyledEnhancedCard>
      </Container>
    </Page>
  );
};

export default RelationshipsNew;
