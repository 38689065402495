// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { useGetMyOrganizationRequests } from 'gql/queries';

// Component Definition
const InvitationsToDfaNotification = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      mode,
      prestoPrimaryDark,
      primary,
    },
  } = useTheme();

  const {
    data,
  } = useGetMyOrganizationRequests();

  const requestCount = data?.myOrganizationRequests.length ?? 0;

  const handleClickViewInvitatons = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVITATIONS}`);
  }, [navigate]);

  const emptyStateContent = (
    <Box
      px={4}
      textAlign="center"
    >
      <Typography
        color="textSecondary"
        variant="body2"
      >
        Requests from directors who wish to link their {APP_NAME}{' '}
        organization to your district will appear here.
      </Typography>
    </Box>
  );

  const content = (
    <>
      <ZeroStateIcon
        Icon={ThumbUpAltIcon}
        htmlColor={mode === 'dark'
          ? prestoPrimaryDark
          : primary.light}
      />
      {requestCount ? (
        <>
          <Box
            px={2}
            textAlign="center"
          >
            <Typography
              color="textSecondary"
              variant="body2"
            >
              There {pluralize(requestCount, 'is', 'are')}
              {' '}
              {requestCount} {pluralize(requestCount, 'organization')}
              {' '}that{' '}
              {pluralize(requestCount, 'has', 'have')}
              {' '}
              requested to join your district.
            </Typography>
          </Box>

          <Button
            color="primary"
            onClick={handleClickViewInvitatons}
            size="small"
            variant="outlined"
          >
            View Invitations
          </Button>
        </>
      ) : emptyStateContent}
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Organization Requests"
      verticallyCenterContent
    />
  );
};

export default InvitationsToDfaNotification;
