// Internal Dependencies
import { KeyCodes } from 'types/keyCodes';
import { parseSearch } from 'utils';

export const handleChangePage = (
  _evt: any,
  newPage: number,
  handleUpdateParams: (updatedParams: object) => void,
) => {
  handleUpdateParams({
    page: newPage + 1,
  });
};

export const handleChangeRowsPerPage = (
  evt: React.ChangeEvent<HTMLInputElement>,
  handleUpdateParams: (updatedParams: object) => void,
) => {
  handleUpdateParams({
    limit: +evt.target.value,
    page: 1,
  });
};

export const handleSearch = (
  value = '',
  handleUpdateParams: (updatedParams: object) => void,
) => {
  handleUpdateParams({
    page: 1,
    q: value,
  });
};

export const handleSort = (
  orderBy: string,
  handleUpdateParams: (updatedParams: object) => void,
  currentParams?: string,
) => {
  const parsedSearch = parseSearch(currentParams ?? window.location.search);

  if (parsedSearch.orderBy === orderBy) {
    handleUpdateParams({
      asc: Boolean(!parsedSearch.asc),
    });
  } else {
    handleUpdateParams({
      asc: true,
      orderBy,
    });
  }
};

export const handleClickSort = (
  orderBy: string,
  handleUpdateParams: (updatedParams: object) => void,
  currentParams?: string,
) => () => {
  handleSort(orderBy, handleUpdateParams, currentParams);
};

export const handleKeyDownSort = (
  orderBy: string,
  handleUpdateParams: (updatedParams: object) => void,
  currentParams?: string,
) => (evt: React.KeyboardEvent) => {
  if (evt.keyCode === KeyCodes.Enter || evt.keyCode === KeyCodes.Space) {
    evt.preventDefault();
    handleSort(orderBy, handleUpdateParams, currentParams);
  }
};
