// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import AccountCheckIcon from 'mdi-material-ui/AccountCheck';
import AccountSearchIcon from 'mdi-material-ui/AccountSearch';
import CloudRefreshIcon from 'mdi-material-ui/CloudRefresh';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import EmailCheckIcon from 'mdi-material-ui/EmailCheck';
import EmailSearchIcon from 'mdi-material-ui/EmailSearch';
import TextBoxCheckIcon from 'mdi-material-ui/TextBoxCheck';
import TextBoxIcon from 'mdi-material-ui/TextBox';
import TextBoxSearchIcon from 'mdi-material-ui/TextBoxSearch';

// Internal Dependencies
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import {
  open as openStudentFileUploadDialog,
} from 'state/ui/studentFileUploadDialog/actions';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useIsOpen } from 'hooks/useIsOpen';
import AssignToGroupDialog from 'pages/People/shared/AssignToGroupDialog';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';

// Local Dependencies
import DialogStudentFileUpload from './DialogStudentFileUpload';

// Local Typings
interface Props {
  isSendStatementDialogOpen: boolean;
  isUpdatingStudents: boolean;
  memberIdsToAssignToGroup: string[];
  memberIdsToSendStatementsTo: string[] | null;
  onCloseAssignMembersToGroupDialog: () => void;
  onCloseSendStatementDialog: () => void;
}

type UseMemberToolbarActions = Props & {
  toolbarActions: IToolbarAction[];
}

// Local Variables
export const useMemberToolbarActions = ({
  filteredMemberIds,
  selectedMemberIds,
  withImports,
}: {
  filteredMemberIds: string[];
  selectedMemberIds: string[];
  withImports: boolean;
}): UseMemberToolbarActions => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isUpdatingStudents, setIsUpdatingStudents] = useState(false);

  const [
    memberIdsToSendStatementsTo,
    setMemberIdsToSendStatementsTo,
  ] = useState<string[] | null>(null);

  const [
    memberIdsToAssignToGroup,
    setMemberIdsToAssignToGroup,
  ] = useState<string[]>([]);

  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const canCreateUsers = useSelector(hasPermission('users', 'write'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));

  const {
    handleOpen: handleOpenSendStatementDialog,
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();

  const handleOpenDialogStudentFileUpload = useCallback((isUpdating: boolean) => () => {
    setIsUpdatingStudents(isUpdating);

    dispatch(openStudentFileUploadDialog());
  }, [dispatch]);

  const handleClickSendStatements = useCallback((memberIds: string[] | null) => () => {
    setMemberIdsToSendStatementsTo(memberIds);
    handleOpenSendStatementDialog();
  }, [handleOpenSendStatementDialog]);

  const handleClickAssignMembersToGroup = useCallback((memberIds: string[]) => () => {
    setMemberIdsToAssignToGroup(memberIds);
  }, []);

  const handleCloseAssignMembersToGroupDialog = useCallback(() => {
    setMemberIdsToAssignToGroup([]);
  }, []);

  const handleClickEmailMembers = useCallback((memberIds: string[]) => () => {
    // Add ids to the recipient ids for a new email
    dispatch(updateRecipients(memberIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch, navigate]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canCreateUsers && withImports) {
      actions.push({
        action: handleOpenDialogStudentFileUpload(false),
        icon: <CloudUploadIcon />,
        sectionTitle: 'Import',
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        text: 'Import new students',
      });
    }

    if (canEditUsers && withImports) {
      actions.push({
        action: handleOpenDialogStudentFileUpload(true),
        icon: <CloudRefreshIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        sectionTitle: canCreateUsers ? undefined : 'Import',
        text: 'Import student updates',
      });
    }

    if (canEmailMembers) {
      actions.push(...[
        {
          action: handleClickEmailMembers(selectedMemberIds),
          icon: <EmailCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Email',
          text: `Email selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickEmailMembers(filteredMemberIds),
          icon: <EmailSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Email filtered (${filteredMemberIds.length})`,
        },
      ]);
    }

    if (canReadFinances) {
      actions.push(...[
        {
          action: handleClickSendStatements(null),
          icon: <TextBoxIcon />,
          sectionTitle: 'Financial Statements',
          text: 'Send statements to everyone',
        },
        {
          action: handleClickSendStatements(selectedMemberIds as string[]),
          icon: <TextBoxCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          text: `Send statements to selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickSendStatements(filteredMemberIds as string[]),
          icon: <TextBoxSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Send statements to filtered (${filteredMemberIds.length})`,
        },
      ]);
    }

    if (canEditGroups) {
      actions.push(...[
        {
          action: handleClickAssignMembersToGroup(selectedMemberIds as string[]),
          icon: <AccountCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Group Members',
          text: `Add selected (${selectedMemberIds.length}) to a group`,
        },
        {
          action: handleClickAssignMembersToGroup(filteredMemberIds as string[]),
          icon: <AccountSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Add filtered (${filteredMemberIds.length}) to a group`,
        },
      ]);
    }

    return actions;
  }, [
    canCreateUsers,
    canEditGroups,
    canEditUsers,
    canEmailMembers,
    canReadFinances,
    filteredMemberIds,
    handleClickAssignMembersToGroup,
    handleClickEmailMembers,
    handleClickSendStatements,
    handleOpenDialogStudentFileUpload,
    selectedMemberIds,
    withImports,
  ]);

  return useMemo(() => ({
    isSendStatementDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    onCloseAssignMembersToGroupDialog: handleCloseAssignMembersToGroupDialog,
    onCloseSendStatementDialog: toggleSendStatementDialog,
    toolbarActions,
  }), [
    handleCloseAssignMembersToGroupDialog,
    isSendStatementDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    toggleSendStatementDialog,
    toolbarActions,
  ]);
};

// Component Definition
const MemberToolbarActionDialogs: FC<Props> = ({
  isSendStatementDialogOpen,
  isUpdatingStudents,
  memberIdsToAssignToGroup,
  memberIdsToSendStatementsTo,
  onCloseAssignMembersToGroupDialog,
  onCloseSendStatementDialog,
}) => {
  return (
    <>
      <DialogStudentFileUpload isUpdatingStudents={isUpdatingStudents} />

      <SendFinancialStatementDialog
        isOpen={isSendStatementDialogOpen}
        memberIds={memberIdsToSendStatementsTo}
        onClose={onCloseSendStatementDialog}
      />

      <AssignToGroupDialog
        memberIds={memberIdsToAssignToGroup}
        onClose={onCloseAssignMembersToGroupDialog}
      />
    </>
  );
};

export default MemberToolbarActionDialogs;
