// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const MyFormsCard = (): JSX.Element => {
  const navigate = useNavigate();

  const myFormsParams = useSelector(tableQueryParams('myForms'));

  const {
    palette: {
      loginJoinButton,
    },
  } = useTheme();

  const handleNavigateToMyUniformCheckouts = useCallback(() => {
    navigate(`/${PATHS.MY_FORMS}${myFormsParams}`);
  }, [myFormsParams, navigate]);

  const zeroStateIcon = useMemo(() => (
    <ZeroStateIcon
      Icon={DynamicFormIcon}
      htmlColor={loginJoinButton}
    />
  ), [loginJoinButton]);

  const content = useMemo(() => (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          View forms assigned to you by your directors.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToMyUniformCheckouts}
        size="small"
        variant="outlined"
      >
        View My Forms
      </Button>
    </>
  ), [handleNavigateToMyUniformCheckouts, zeroStateIcon]);

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="My Forms"
      verticallyCenterContent
    />
  );
};

export default MyFormsCard;
