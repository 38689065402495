// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateInventoryItem } from 'gql/mutations';
import InventoryForm,
{
  AdminInventoryPayloadValues,
  InventoryPayloadValues,
} from 'pages/Inventory/shared/InventoryForm';

// Component Definition
const AdminNewInventoryPage = (): JSX.Element => {
  const navigate = useNavigate();

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}`);
  }, [navigate]);

  const [handleCreateInventoryItem] = useCreateInventoryItem(
    {
      clearCachePredicates: ['districtInventory'],
      onCompleted: (data) => navigateToShow(data.createInventoryItem.id),
    },
  );

  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  const handleSubmit = async (values: InventoryPayloadValues | AdminInventoryPayloadValues) => {
    await handleCreateInventoryItem({
      variables: {
        input: {
          ...values,
          ...applyDynamicFields(values as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
      }}
    >
      <InventoryForm
        canUseDynamicFields
        isAdmin
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default AdminNewInventoryPage;
