// External Dependencies
import {
  FormTypes,
  OrgTypes,
  OrganizationEntityTypes,
} from '@presto-assistant/api_types';
import { Formik } from 'formik';
import { createFormSchema } from '@presto-assistant/api_types/schemas/form';
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';

// Internal Dependencies
import {
  EnhancedAlert,
  Page,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getEndingSchoolYear } from 'utils';
import { mapEnum } from 'utils/lib/map_enum';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateForm } from 'gql/mutations/form-mutations';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import AdminFormsNewUI from './AdminFormsNewUI';

// Local Typings
interface AdminDistrictFormValues {
  assignTo: GQL.ICreateFormInput['assignTo'];
  organizationEntityTypeIds: string[];
  organizationTypeIds: string[];
  schoolYearEnding: GQL.ICreateFormInput['schoolYearEnding'];
  title: GQL.ICreateFormInput['title'];
}

// Component Definition
const AdminFormsNew = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const parsedSearch = useParsedSearch<{ formTypeId: string }>();

  const schoolYearEnding = getEndingSchoolYear();

  const districtFormsParams = useSelector(tableQueryParams('districtForms'));
  const pathToDistrictForms = `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}${districtFormsParams}`;

  const formTypeId = useMemo(() => {
    return parsedSearch.formTypeId ? Number(parsedSearch.formTypeId) : FormTypes.Standard;
  }, [parsedSearch.formTypeId]);

  const [
    createForm,
    {
      loading: isCreatingForm,
    },
  ] = useCreateForm({
    clearCachePredicates: ['district'],
    onCompleted: async (data) => {
      const formId = data.createForm.id;

      await queryClient.invalidateQueries(['districtForms']);
      await navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}/${formId}/edit`, { replace: true });
    },
  });

  const handleFormikSubmit = useCallback((values: AdminDistrictFormValues) => {
    // For the organizationEntityTypeId and organizationTypeIds arrays,
    //  we need to convert the strings to numbers
    const updatedEntityTypeIds = values.organizationEntityTypeIds.map(Number);
    const updatedOrganizationTypeIds = values.organizationTypeIds.map(Number);

    createForm({
      variables: {
        input: {
          ...values,
          formTypeId,
          organizationEntityTypeIds: updatedEntityTypeIds,
          organizationTypeIds: updatedOrganizationTypeIds,
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  }, [createForm, formTypeId]);

  const mappedCampusTypes = mapEnum(OrganizationEntityTypes).map((o) => o.id.toString());
  const mappedOrganizationTypes = mapEnum(OrgTypes).map((o) => o.id.toString());

  const initialValues = useMemo<AdminDistrictFormValues>(() => {
    const isStandardForm = formTypeId === FormTypes.Standard;

    return {
      assignTo: isStandardForm ? ['directors', 'parents', 'students'] : [],
      organizationEntityTypeIds: isStandardForm ? mappedCampusTypes : [],
      organizationTypeIds: isStandardForm ? mappedOrganizationTypes : [],
      schoolYearEnding,
      title: '',
    };
  }, [
    formTypeId,
    mappedCampusTypes,
    mappedOrganizationTypes,
    schoolYearEnding,
  ]);

  return (
    <Page
      backButtonProps={{
        label: 'Forms',
        path: pathToDistrictForms,
      }}
    >
      <Container maxWidth="md">
        <Subtitle>
          Start a new {formTypeId === FormTypes.Standard ? 'district form' : 'inventory checkout form'}
        </Subtitle>

        {formTypeId === FormTypes.InventoryCheckout && (
          <EnhancedAlert
            sx={{
              marginBottom: 2,
            }}
          >
            Inventory checkout forms will automatically be assigned
            to members when an inventory item is checked out.
          </EnhancedAlert>
        )}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleFormikSubmit}
          validationSchema={createFormSchema}
        >
          {({
            handleSubmit,
          }) => {
            return (
              <AdminFormsNewUI
                formTypeId={formTypeId}
                isLoading={isCreatingForm}
                onSubmit={handleSubmit}
              />
            );
          }}
        </Formik>
      </Container>
    </Page>
  );
};

export default AdminFormsNew;
