// External Dependencies
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

// Local Typings
interface Props {
  userOrgRoleCounts: GQL.IUserOrgRoleCount[];
}

// Local Variables
// This provides enough space for the user org role name to stack nicely
const HEIGHT_MULTIPLIER = 48;

// Component Definition
const UserOrgRoleCounts = ({ userOrgRoleCounts }: Props): JSX.Element => {
  const {
    palette: {
      common,
      primary,
    },
  } = useTheme();

  // Width on ResponsiveContainer must be set to 99% to allow chart to change on screen resizes
  // 100% width breaks it so that the chart does not change width
  return (
    <ResponsiveContainer
      height={userOrgRoleCounts.length * HEIGHT_MULTIPLIER}
      width="99%"
    >
      <BarChart
        data={userOrgRoleCounts}
        layout="vertical"
        margin={{ left: 32 }}
      >
        {/* Due to a bug in recharts, the vertical bar graph
        requires an x-axis, so we hide it */}
        <XAxis
          hide
          type="number"
        />
        <YAxis
          dataKey="label"
          type="category"
          width={110}
        />
        <Bar
          dataKey="total"
          fill={primary.main}
          minPointSize={16}
        >
          <LabelList
            dataKey="total"
            position="center"
            style={{ fill: common.white, fontSize: 18, fontWeight: 600 }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>

  );
};

export default UserOrgRoleCounts;
