// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { Form, Formik, FormikHelpers } from 'formik';
import { createManyFinancialPaymentsSchema } from '@presto-assistant/api_types/schemas/financialPayments';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { yup } from '@presto-assistant/api_types/schemas/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import cloneDeep from 'lodash.clonedeep';

// Internal Dependencies
import {
  EnhancedAlert, EnhancedCard, FormActions,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertDollarsToCents, getParamFormattedDate } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { useGetFees, useGetFinancialItem } from 'gql/queries';

// Local Dependencies
import { StyledCardContent, StyledSubtitle } from './styles';
import PaymentFormItem from './PaymentFormItem';
import PaymentFormReviewDialog from './PaymentFormReviewDialog';
import PaymentsByItemTable from './PaymentsByItemTable';

// Local Typings
export interface PaymentsByItemFormValues {
  payments: GQL.ICreateFinancialPaymentInput[];
}

interface Props {
  financialItemId: string;
}

// Local Values
export const defaultRow: GQL.ICreateFinancialPaymentInput = {
  amountInCents: 0,
  checkNumber: '',
  creditAppliedAmountInCents: 0,
  creditCardAccountHolder: '',
  creditCardExp: '',
  creditCardLastFour: '',
  datePaid: getParamFormattedDate(new Date()),
  financialFeeId: '',
  financialPaymentTypeId: FinancialPaymentTypes.Cash.toString(),
  otherLabel: '',
  referenceNumber: '',
};

const initialFormValues: PaymentsByItemFormValues = {
  payments: [defaultRow],
};

// modify the api schema to meet the needs of this form
const clonedPaymentSchema = cloneDeep(createManyFinancialPaymentsSchema);

const clonedSchema = yup.object().shape({
  payments: clonedPaymentSchema,
});

// Component Definition
const PaymentFormByItem = ({ financialItemId }: Props): JSX.Element => {
  const navigate = useNavigate();

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}`);
  }, [navigate]);

  const [
    previewPayload,
    setPreviewPayload,
  ] = useState<GQL.ICreateFinancialPaymentInput[] | null>(null);

  const canEditFinances = useSelector(hasPermission('finances', 'edit'));

  const {
    getFees,
    results: { data: allFeesData },
  } = useGetFees();

  const {
    data: financialItemData,
  } = useGetFinancialItem(financialItemId);

  useEffect(() => {
    if (financialItemId) {
      getFees({ variables: { where: { financialItemId } } });
    }
  }, [getFees, financialItemId]);

  const handleClearPreviewPayload = useCallback(() => {
    setPreviewPayload(null);
  }, []);

  const handleFormikSubmit = async (
    values: PaymentsByItemFormValues,
    formikBag: FormikHelpers<PaymentsByItemFormValues>,
  ) => {
    const payload: GQL.ICreateManyFinancialPaymentsOnMutationArguments['input'] = values.payments.map((payment) => {
      const clonedPayment = { ...payment };

      const amountInCents = convertDollarsToCents(
        Number(clonedPayment.amountInCents),
      );
      const creditAppliedAmountInCents = convertDollarsToCents(
        Number(clonedPayment.creditAppliedAmountInCents),
      );

      const newPayment = {
        ...clonedPayment,
        amountInCents,
        creditAppliedAmountInCents,
      };

      if (newPayment.financialPaymentTypeId !== FinancialPaymentTypes.CreditCard.toString()) {
        delete newPayment.creditCardAccountHolder;
        delete newPayment.creditCardExp;
        delete newPayment.creditCardLastFour;
      }

      if (newPayment.financialPaymentTypeId !== FinancialPaymentTypes.Check.toString()) {
        delete newPayment.checkNumber;
      }

      if (newPayment.financialPaymentTypeId !== FinancialPaymentTypes.Other.toString()) {
        delete newPayment.otherLabel;
        delete newPayment.referenceNumber;
      }

      return newPayment;
    });

    await createManyFinancialPaymentsSchema.validate(
      payload,
      { abortEarly: false, strict: true },
    );

    setPreviewPayload(payload);

    formikBag.setSubmitting(false);
  };

  const handleClickEditItem = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}/${financialItemId}/edit`);
  }, [financialItemId, navigate]);

  if (!allFeesData?.financialFees || !financialItemData) {
    return <CircularProgress />;
  }

  if (financialItemData.financialItem && !financialItemData.financialItem.financialAccount) {
    return (
      <EnhancedAlert
        action={canEditFinances ? (
          <Box marginRight={1}>
            <Button onClick={handleClickEditItem}>
              Edit Item
            </Button>
          </Box>
        ) : null}
        severity="error"
      >
        This item does not belong to a financial account.
      </EnhancedAlert>
    );
  }

  return (
    <>
      <Formik<PaymentsByItemFormValues>
        initialValues={initialFormValues}
        onSubmit={handleFormikSubmit}
        validateOnChange={false}
        validationSchema={clonedSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <EnhancedCard>
              <StyledCardContent>
                <PaymentFormItem financialItemId={financialItemId} />

                <Box marginBottom={2}>
                  <Divider />
                </Box>

                <StyledSubtitle
                  component="h2"
                  variant="subtitle1"
                >
                  Enter payments
                </StyledSubtitle>

                <Form onSubmit={handleSubmit}>
                  <PaymentsByItemTable
                    financialAccountId={financialItemData.financialItem.financialAccount?.id ?? ''}
                    financialFees={allFeesData.financialFees}
                    financialItemId={financialItemId}
                    formValues={values}
                  />

                  <FormActions
                    context="Payments"
                    isButtonDisabled={values.payments.length === 0}
                    isFormTouched={isFormTouched}
                    isSubmitting={isSubmitting}
                    onPressCancelOrBackButton={handlePressCancelOrBackButton}
                  />
                </Form>
              </StyledCardContent>
            </EnhancedCard>
          );
        }}
      </Formik>

      <PaymentFormReviewDialog
        onClickCancel={handleClearPreviewPayload}
        payload={previewPayload}
      />
    </>
  );
};

export default PaymentFormByItem;
