// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import AccountCheckIcon from 'mdi-material-ui/AccountCheck';
import AccountRemove from 'mdi-material-ui/AccountRemove';
import AccountSearchIcon from 'mdi-material-ui/AccountSearch';
import EmailCheckIcon from 'mdi-material-ui/EmailCheck';
import EmailIcon from '@mui/icons-material/Email';
import EmailSearchIcon from 'mdi-material-ui/EmailSearch';

// Internal Dependencies
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { computeDataMutation } from 'components/shared/TableDataGrid/helpers';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { formatPhoneNumber } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useGetParentsIndex } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateUser } from 'gql/mutations';
import AssignToGroupDialog from 'pages/People/shared/AssignToGroupDialog';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import RemoveMemberDialog from 'pages/People/shared/PeopleDangerZone/RemoveMemberDialog';

// Local Dependencies
import { useColumns } from './hooks';
import ParentsTableZeroState from './ParentsTableZeroState';

// Local Variables
const pinnedColumns = ['firstName', 'lastName'];

const REQUIRED_FIELDS = [
  'email',
  'firstName',
  'lastName',
];
  // Include any optional fields that are strings or numbers
const OPTIONAL_FIELDS = [
  'middleName',
  'phoneNumber',
];

// Component Definition
const ParentTable: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);

  const [
    selectedMemberIds,
    setSelectedMemberIds,
  ] = useState<GridRowSelectionModel>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const [
    memberIdsToAssignToGroup,
    setMemberIdsToAssignToGroup,
  ] = useState<string[]>([]);

  const canWriteUsers = useSelector(hasPermission('users', 'write'));
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canRemoveMembers = useSelector(hasPermission('users', 'delete'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));
  const canReadGroups = useSelector(hasPermission('groups', 'read'));

  const {
    data,
    isLoading: isLoadingParentsData,
    refetch,
  } = useGetParentsIndex();

  const {
    isOpen: isRemoveUserConfirmationDialogOpen,
    toggleIsOpen: toggleRemoveUserConfirmationDialogIsOpen,
  } = useIsOpen();

  const [updateUser] = useUpdateUser({
    clearCachePredicates: ['parentsIndex', 'studentsIndex'],
  });

  const hasMoreThanOneParent = Boolean(data && data?.length > 1);

  const handleClickEmailMembers = useCallback((memberIds: string[]) => () => {
    // Add ids to the recipient ids for a new email
    dispatch(updateRecipients(memberIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch, navigate]);

  const handleClickAssignMembersToGroup = useCallback((memberIds: string[]) => () => {
    setMemberIdsToAssignToGroup(memberIds);
  }, []);

  const handleCloseAssignMembersToGroupDialog = useCallback(() => {
    setMemberIdsToAssignToGroup([]);
  }, []);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canEmailMembers) {
      actions.push(...[
        {
          action: handleClickEmailMembers(selectedMemberIds as string[]),
          icon: <EmailCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Email',
          text: `Email selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickEmailMembers(filteredRowIds as string[]),
          icon: <EmailSearchIcon />,
          isDisabled: filteredRowIds.length === 0,
          text: `Email filtered (${filteredRowIds.length})`,
        },
      ]);
    }

    if (canEditGroups) {
      actions.push(...[
        {
          action: handleClickAssignMembersToGroup(selectedMemberIds as string[]),
          icon: <AccountCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Group Members',
          text: `Add selected (${selectedMemberIds.length}) to a group`,
        },
        {
          action: handleClickAssignMembersToGroup(filteredRowIds as string[]),
          icon: <AccountSearchIcon />,
          isDisabled: filteredRowIds.length === 0,
          text: `Add filtered (${filteredRowIds.length}) to a group`,
        },
      ]);
    }

    return actions;
  }, [
    canEditGroups,
    canEmailMembers,
    filteredRowIds,
    handleClickAssignMembersToGroup,
    handleClickEmailMembers,
    selectedMemberIds,
  ]);

  const extraColumns = useMemo<GridColDef[]>(() => {
    const handleClickEmailParent = (row: GQL.IParentIndex) => {
      // Add user id to the recipient ids for a new email
      dispatch(updateRecipients([row.id]));
      navigate(`/${PATHS.EMAIL_NEW}`);
    };

    const handleRemoveParentClick = (row: GQL.IParentIndex) => {
      setSelectedMemberId(row.id);
      toggleRemoveUserConfirmationDialogIsOpen();
    };

    const actionsColumn = createDataGridActionsColumn<GQL.IParentIndex>([
      ...(canEmailMembers
        ? [
          {
            action: handleClickEmailParent,
            icon: <EmailIcon />,
            text: 'Email Parent',
          },
        ] : []),
      ...(canRemoveMembers ? [{
        action: handleRemoveParentClick,
        icon: <AccountRemove />,
        text: 'Remove Parent',
      }] : []),
    ]);

    return actionsColumn ? [actionsColumn] : [];
  }, [
    canEmailMembers,
    canRemoveMembers,
    dispatch,
    toggleRemoveUserConfirmationDialogIsOpen,
    navigate,
  ]);

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        // We check if any edits were made. If not, we resolve
        //  the promise with the `oldRow` and avoid calling the API.
        const dataWasMutated = computeDataMutation({
          newRow,
          oldRow,
          optionalKeysToCompare: OPTIONAL_FIELDS,
          requiredKeysToCompare: REQUIRED_FIELDS,
        });

        if (dataWasMutated) {
          const {
            email,
            firstName,
            id,
            lastName,
            middleName,
            phoneNumber,
          } = newRow;

          const inputPayload: GQL.IUpdateUserInput = {
            email,
            firstName,
            lastName,
            middleName: middleName ?? '',
            phoneNumber: formatPhoneNumber(phoneNumber),
          };

          updateUser({
            variables: {
              id,
              input: inputPayload,
            },
          });

          resolve(newRow); // Update the row with the new values
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [
      updateUser,
    ],
  );

  const columns = useColumns(extraColumns);

  const handleTableClickRow = (id: string) => `/${PATHS.PARENTS}/${id}`;

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={
            canWriteUsers
              ? {
                label: 'Parent',
                to: `/${PATHS.PARENTS}/new`,
              }
              : null
          }
          checkboxSelection={canEmailMembers && hasMoreThanOneParent}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: ParentsTableZeroState,
          }}
          leftPinnedColumns={pinnedColumns}
          loading={isLoadingParentsData}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={canEmailMembers && hasMoreThanOneParent
            ? setSelectedMemberIds
            : undefined}
          processRowUpdate={processRowUpdate}
          rows={data || []}
          tableResource="parents"
          toolbarActions={toolbarActions}
          withEditMode={canEditUsers}
          withSearch
        />

        {selectedMemberId && (
          <RemoveMemberDialog
            isOpen={isRemoveUserConfirmationDialogOpen}
            memberId={selectedMemberId}
            onClose={toggleRemoveUserConfirmationDialogIsOpen}
            onMemberDeleted={refetch}
          />
        )}
      </DataGridContainer>

      {canReadGroups && (
        <AssignToGroupDialog
          memberIds={memberIdsToAssignToGroup}
          onClose={handleCloseAssignMembersToGroupDialog}
        />
      )}
    </>
  );
};

export default ParentTable;
