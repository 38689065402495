// External Dependencies
import {
  FC, ReactElement, useCallback, useEffect,
} from 'react';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { alpha, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ExitIcon from '@mui/icons-material/ExitToApp';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  PrestoLogoHorizontalShortSvg,
  PrestoLogoHorizontalSvg,
} from 'components/shared';
import { getGradientBackground } from 'utils/lib/getGradientBackground';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useLogout } from 'utils/api';

// Local Dependencies
import Flex from './Flex';
import Footer from './Footer';
import Heading1 from './Heading1';
import NotificationHandler from './MainContentContainer/NotificationHandler';
import SkewedBackground from './SkewedBackground';

// Local Typings
interface Props extends RouteComponentProps {
  children: ReactElement | ReactElement[];
}

// Local Variables
const StyledBox = styled(Box)<{ $fontColor: string; }>(({
  $fontColor,
  theme,
}) => ({
  '.MuiButton-outlinedSecondary': {
    '&:hover': {
      backgroundColor: alpha($fontColor, 0.2),
      border: `1px solid ${$fontColor}`,
    },
    backgroundColor: alpha($fontColor, theme.palette.action.hoverOpacity),
    border: `1px solid ${$fontColor}`,
    color: $fontColor,
  },
  '.exitIcon': {
    marginRight: theme.spacing(1.5),
  },
  '.logo': {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: theme.spacing(18),
    },
    margin: 'inherit',
    width: theme.spacing(25),
  },
  '.logoLong': {
    margin: 'inherit',
    width: theme.spacing(30),
  },
  '.mainCard': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      padding: 0,
      width: '100%',
    },
    background: theme.palette.mode === 'dark'
      ? theme.palette.mainContentBackground : theme.palette.grey[300],
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
    minHeight: 500,
    padding: theme.spacing(1),
    textAlign: 'center',
    width: 440,
  },
  '.navBar': {
    background: getGradientBackground(theme),
    padding: theme.spacing(2),
  },
  '.simpleChildrenContainer': {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0 auto',
    paddingTop: theme.spacing(4),
    width: '100%',
    zIndex: 1,
  },
  '.simpleContainer': {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    backgroundColor: theme.palette.skewedBackgroundBottom,
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  },
  '.wideCard': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 640,
  },
}));

// Component Definition
const OnboardingContainer: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const fontColor = theme.palette.mode === 'dark'
    ? theme.palette.common.black : theme.palette.common.white;

  const { pathname } = useLocation();

  const shouldUseWideCard = window.location.pathname.includes(
    PATHS.ONBOARDING_ACCEPTABLE_USE_AGREEMENT,
  );

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  // If the pathname changes, we want to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logout = useLogout();

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.log('Something went wrong while logging you out.');
    }
  }, [logout, navigate]);

  return (
    <StyledBox
      $fontColor={fontColor}
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      width="100%"
    >
      <NotificationHandler />

      <SkewedBackground withGrid />

      <AppBar
        className="navBar"
        position="static"
        sx={{ zIndex: 1 }}
      >
        <Flex justifyContent="space-between">
          <Heading1>
            {isMobileOrTabletScreen
              ? (
                <PrestoLogoHorizontalShortSvg
                  htmlColor={fontColor}
                  rootClasses="logo"
                />
              ) : (
                <PrestoLogoHorizontalSvg
                  htmlColor={fontColor}
                  rootClasses="logoLong"
                />
              )}
          </Heading1>

          <Button
            color="secondary"
            onClick={handleLogout}
            size={isMobileOrTabletScreen ? 'small' : 'medium'}
            variant="outlined"
          >
            <ExitIcon
              className="exitIcon"
              fontSize="small"
            />
            Exit
          </Button>
        </Flex>
      </AppBar>

      <div className="simpleContainer">
        <main className="simpleChildrenContainer">
          <Card
            className={clsx(
              'mainCard',
              shouldUseWideCard && 'wideCard',
            )}
          >
            {children}
          </Card>
        </main>

        <Footer />
      </div>
    </StyledBox>
  );
};

export default OnboardingContainer;
