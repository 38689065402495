// External Dependencies
import CardContent from '@mui/material/CardContent';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import BillingSettingsCardHeader from 'components/shared/BillingSettingsCardHeader';

// Local Dependencies
import DownloadFormW9Section from './DownloadFormW9Section';

// Component Definition
const AdminBilling = (): JSX.Element => {
  return (
    <EnhancedCard useMaterialV3>
      <BillingSettingsCardHeader />

      <CardContent>
        <DownloadFormW9Section />
      </CardContent>
    </EnhancedCard>
  );
};

export default AdminBilling;
