// External Dependencies
import { acceptDistrictRequestSchema } from '@presto-assistant/api_types/schemas/districtRequest';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { isDirector as isDirectorSelector } from 'state/self/selectors';
import { pluralize } from 'utils';
import { useAcceptDistrictRequest } from 'gql/mutations';
import { useGetDistrictInvitations } from 'gql/queries/district-invitation-queries';
import { useGetDistrictRequests } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import { CustomSelect, DialogForm, EnhancedAlert } from '.';
import StyledLink from './StyledLink';

// Local Typings
interface Props {
  districtName?: string | undefined;
}

// Component Definition
const InviteAdmin = ({ districtName }:Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDirector = useSelector(isDirectorSelector);
  const {
    isOpen: isDialogOpen,
    toggleIsOpen: toggleIsDialogOpen,
  } = useIsOpen();

  const handleNavigateToInviteDistrictAdmin = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}#invite-district-admin-form`);
  }, [navigate]);

  const handleNavigateToConfirmDistrictAdminInvite = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}#confirm-container`);
  }, [navigate]);

  const handleCompleted = useCallback(() => {
    dispatch(addNotification('District accepted!', 'success'));
    toggleIsDialogOpen();
  }, [dispatch, toggleIsDialogOpen]);

  const [
    acceptDistrictRequest,
    {
      loading: isAcceptingDistrictRequest,
    },
  ] = useAcceptDistrictRequest(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['district', 'organization', 'self'],
      onCompleted: handleCompleted,
    },
  );

  const shouldSkipQuery = !isDirector || !!districtName;

  const {
    data: districtInvitationsData,
  } = useGetDistrictInvitations({ skip: shouldSkipQuery });

  const {
    data,
  } = useGetDistrictRequests({ skip: shouldSkipQuery });

  const handleFormikSubmit = useCallback((values: GQL.IAcceptDistrictRequestInput) => {
    acceptDistrictRequest({
      variables: {
        input: values,
      },
    });
  }, [acceptDistrictRequest]);

  if (!isDirector || districtName) {
    return null;
  }

  if (data?.districtRequests.length) {
    return (
      <>
        <Box mt={1}>
          <Typography component="span">
            {data.districtRequests.length} {pluralize(data.districtRequests.length, 'district has', 'districts have')} requested to claim your organization.
            {' '}
            <StyledLink
              onClick={toggleIsDialogOpen}
            >
              Review
            </StyledLink>
          </Typography>
        </Box>

        <DialogForm<GQL.IAcceptDistrictRequestInput>
          dialogProps={{
            fullWidth: true,
            maxWidth: 'sm',
            open: isDialogOpen,
          }}
          initialValues={{
            districtId: '',
          }}
          isSubmitting={isAcceptingDistrictRequest}
          onClose={toggleIsDialogOpen}
          onSubmit={handleFormikSubmit}
          subTitle="Select one district to accept an invitation."
          submitText="Accept Invitation"
          title="Accept District Request"
          validationSchema={acceptDistrictRequestSchema}
        >
          <CustomSelect
            label="District"
            name="districtId"
            options={data.districtRequests.map((request) => ({
              id: request.district.id,
              // eslint-disable-next-line no-irregular-whitespace
              label: `${request.district.label} — ${request.district.admin.email}`,
            }))}
          />

          <Box marginTop={2}>
            <EnhancedAlert>
              After you accept a district request, your inventory, library items
              {' '}and uniforms will be manageable by your district administrators.
            </EnhancedAlert>
          </Box>
        </DialogForm>
      </>
    );
  }

  const hasDistrictInvitesToConfirm = districtInvitationsData?.districtInvitations
    .some((invitation) => invitation.accepted === null);

  return (
    <Typography
      component="span"
      sx={{ marginTop: 2 }}
    >
      <Button
        endIcon={<ArrowRightIcon />}
        onClick={hasDistrictInvitesToConfirm
          ? handleNavigateToConfirmDistrictAdminInvite
          : handleNavigateToInviteDistrictAdmin}
        variant="contained"
      >
        Join District
      </Button>
    </Typography>
  );
};

export default InviteAdmin;
