// External Dependencies
import { AxiosResponse } from 'axios';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  Redirect,
  RouteComponentProps, useLocation,
  useNavigate,
} from '@reach/router';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

// Internal Dependencies
import {
  Flex,
  PrestoLogoVerticalSvg,
  SkewedBackground,
  StyledLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getToken } from 'utils/cookies';
import { login } from 'utils/api';
import { redirectUser } from 'utils/lib/redirect_user';
import { updateDocumentTitle } from 'utils';
import Heading1 from 'components/shared/Heading1';
import Maintenance from 'pages/Maintenance';
import RecoverPasswordDialog from 'components/shared/RecoverPasswordDialog';

// Local Dependencies
import { IS_PRODUCTION } from 'utils/constants';
import { useClassLinkSso } from 'utils/api/auth';
import LoginForm from './LoginForm';

// Local Variables
const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 360,
  },
  alignItems: 'center',
  border: `1px solid ${theme.palette.prestoPrimaryLight}`,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 1),
  width: 400,
  zIndex: 1,
}));

const JoinButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.prestoPrimaryLight,
      theme.palette.action.focusOpacity,
    ),
    borderColor: theme.palette.prestoPrimaryLight,
  },
  border: `1px solid ${theme.palette.prestoPrimaryLight}`,
  color: theme.palette.prestoPrimaryLight,
  fontWeight: 500,
}));

const LogoContainer = styled.section(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
    maxWidth: 272,
  },
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(7),
  marginTop: theme.spacing(8),
  maxWidth: 360,
  zIndex: 1,
}));

// Component Definition
const Login: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const [
    isRecoverPasswordDialogOpen,
    setIsRecoverPasswordDialogOpen,
  ] = useState(false);
  const [loginError, setLoginError] = useState('');

  const {
    mutate: getClassLinkSsoUrl,
  } = useClassLinkSso();

  const navigateToCreateUserProfile = useCallback(() => {
    navigate(`/${PATHS.SIGNUP}`);
  }, [navigate]);

  const handleClickClassLinkSso = useCallback(() => {
    getClassLinkSsoUrl({
      webAppQueryString: search,
    });
  }, [getClassLinkSsoUrl, search]);

  useEffect(() => {
    updateDocumentTitle('Login');
  }, []);

  const handleLogin = async (data: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => {
    const payload = {
      ...data,
      email: data.email.toLowerCase(),
    };
    try {
      const response: AxiosResponse = await login(payload);

      if (response.status === 200) {
        const {
          alertFlagId, currentOrgId, hasDistrict, id, onboarding,
        } = response.data;

        const nextOnboardingStage = onboarding.stages.find(
          (stage: GQL.IOnboardingStage) => {
            const { isComplete } = stage;
            return !isComplete;
          },
        );

        return redirectUser(
          navigate,
          id,
          currentOrgId,
          hasDistrict,
          alertFlagId,
          nextOnboardingStage,
        );
      }
      if (response && response.data && response.data.error) {
        return setLoginError(response.data.error);
      }
      return setLoginError('Something went wrong!');
    } catch (error) {
      console.error('handleLogin : error', error);
      return setLoginError('Something went wrong!');
    }
  };

  const toggleRecoverPasswordDialog = () => {
    setIsRecoverPasswordDialogOpen(!isRecoverPasswordDialogOpen);
  };

  if (getIsMaintenanceMode()) {
    return <Maintenance />;
  }

  if (getToken()) {
    return (
      <Redirect
        from="/"
        noThrow
        to={`/${PATHS.DASHBOARD}`}
      />
    );
  }

  return (
    <Flex
      alignContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <SkewedBackground />

      <LogoContainer>
        <Heading1>
          <PrestoLogoVerticalSvg />
        </Heading1>
      </LogoContainer>

      <StyledPaper variant="outlined">
        <LoginForm
          loginError={loginError}
          onLogin={handleLogin}
        />

        <Box
          mb={3}
          mt={1}
        >
          <StyledLink onClick={toggleRecoverPasswordDialog}>
            Forgot password?
          </StyledLink>
        </Box>

        <Box p={2}>
          <JoinButton
            color="primary"
            onClick={navigateToCreateUserProfile}
            role="link"
            variant="outlined"
          >
            Sign up
          </JoinButton>
        </Box>

        {!IS_PRODUCTION && (
          <Box marginBottom={2}>
            <Button onClick={handleClickClassLinkSso}>
              ClassLink SSO
            </Button>
          </Box>
        )}

        <RecoverPasswordDialog
          isOpen={isRecoverPasswordDialogOpen}
          onClose={toggleRecoverPasswordDialog}
        />
      </StyledPaper>
    </Flex>
  );
};

export default Login;
