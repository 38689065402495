// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateUncategorizedFinancialFee } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import UncategorizedFeeForm from '../shared/UncategorizedFeeForm';

// Component Definition
const FinancialFeesNew: FC = () => {
  const navigate = useNavigate();
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));

  const pathToFeesIndexTable = `/${PATHS.FINANCIAL_FEES}${financialFeesParams}`;

  const navigateToShow = useCallback(() => {
    navigate(pathToFeesIndexTable);
  }, [navigate, pathToFeesIndexTable]);

  const [createUncategorizedFinancialFee] = useCreateUncategorizedFinancialFee(
    {
      clearCachePredicates: [
        'financialFees',
        'financialFeesIndex',
        'financialFeesOverview',
      ],
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateUncategorizedFinancialFeeInput,
  ) =>
    createUncategorizedFinancialFee({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'All Fees',
        path: pathToFeesIndexTable,
      }}
    >
      <UncategorizedFeeForm onSubmit={handleSubmitForm} />
    </Page>
  );
};

export default FinancialFeesNew;
