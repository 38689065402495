// External Dependencies
import {
  Bar,
  BarChart,
  LabelList,
  XAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

// Local Typings
interface Props {
  gradeCounts: GQL.IGradeCount[];
}

// Component Definition
const StudentMetrics = ({ gradeCounts }: Props): JSX.Element => {
  const {
    palette: {
      common,
      stripeBlue,
    },
  } = useTheme();

  return (
    <BarChart
      data={gradeCounts}
      height={180}
      width={300}
    >
      <XAxis
        axisLine={false}
        dataKey="grade"
        tickLine={false}
      />
      <Bar
        dataKey="count"
        fill={stripeBlue['500']}
        textAnchor="center"
      >
        <LabelList
          dataKey="count"
          position="center"
          style={{ fill: common.white, fontSize: 18, fontWeight: 600 }}
        />
      </Bar>
    </BarChart>
  );
};

export default StudentMetrics;
