// External Dependencies
import {
  FC, useCallback, useState,
} from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';

// Internal Dependencies
import { Container } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import ChecklistFormStepper from './ChecklistFormStepper';
import ChecklistInfoFormWrapper from './ChecklistInfoFormWrapper';
import EditChecklistItemsForm from '../Edit/EditChecklistItemsForm';
import EditChecklistMembersForm from '../Edit/EditChecklistMembersForm';

// Local Typings
enum ChecklistFormStep {
  Info = 1,
  Members,
  Items,
}

// Local Variables
const getChecklistRoute = (step: number, id?: string) => {
  const basePath = `/${PATHS.CHECKLISTS}/${id}/edit`;

  if (step === ChecklistFormStep.Info) {
    return basePath;
  }

  return `${basePath}${step === 2 ? '/members' : '/items'}?step=${step}`;
};

// Component Definition
const ChecklistFormWrapper: FC = () => {
  const navigate = useNavigate();

  const { id: checklistId } = useParamsWithId();

  const parsedSearch = useParsedSearch();

  const isEditingExistingChecklist = Boolean(checklistId);

  const [activeStep, setActiveStep] = useState<number>(parsedSearch.step || 1);

  const handleNavigateToActiveStep = useCallback((newStep: number) => {
    setActiveStep(newStep);

    if (newStep === activeStep) {
      // We won't navigate anywhere if the user presses the stepper number
      //  for the step they are currently viewing
      return;
    }

    // If the user is editing an existing checklist, the id will be in the search params
    navigate(getChecklistRoute(newStep, checklistId));
  }, [activeStep, checklistId, navigate]);

  // The order of the steps in the stepper
  const checklistFormSteps = [
    <ChecklistInfoFormWrapper
      checklistId={checklistId}
      key="step1"
    />, // step 1
    ...(checklistId ? [
      <EditChecklistMembersForm
        checklistId={checklistId}
        key="step2"
      />, // step 2
      <EditChecklistItemsForm
        checklistId={checklistId}
        key="step3"
      />, // step 3
    ] : []),
  ];

  return (
    <>
      <ChecklistFormStepper
        activeStep={activeStep}
        maxAvailableStep={isEditingExistingChecklist
          ? checklistFormSteps.length
          : activeStep}
        onSetActiveStep={handleNavigateToActiveStep}
      />

      <Container maxWidth={600}>
        <Box my={2}>
          {checklistFormSteps[activeStep - 1]}
        </Box>
      </Container>
    </>
  );
};

export default ChecklistFormWrapper;
