// External Dependencies
import {
  GlobalEditField,
  globalEditResources,
} from '@presto-assistant/api_types/api/v1/globalEdit';
import { GridRowId } from '@mui/x-data-grid-pro';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { EnhancedDialog } from 'components/shared/index';

// Local Dependencies
import GlobalEditForm from './GlobalEditForm';
import ReviewUI from './ReviewUI';

// Local Typings
interface Props {
  fields: GlobalEditField[];
  globalEditResource: typeof globalEditResources[number];
  isOpen: boolean;
  onClose: () => void;
  rowIds: GridRowId[];
}

// Component Definition
const GlobalEditDialog = ({
  fields,
  globalEditResource,
  isOpen,
  onClose,
  rowIds,
}: Props): JSX.Element => {
  const [step, setStep] = useState<'form' | 'review'>('form');
  const [fieldValues, setFieldValues] = useState<Record<string, string>>({});
  const [selectedFields, setSelectedFields] = useState<GlobalEditField[]>([]);

  useEffect(() => {
    if (isOpen) {
      setFieldValues({});
      setSelectedFields([]);
      setStep('form');
    }
  }, [isOpen]);

  const rowCount = rowIds.length;

  const handleNext = useCallback(() => {
    setStep('review');
  }, []);

  const handleBack = useCallback(() => {
    setStep('form');
  }, []);

  return (
    <EnhancedDialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Global Edit
      </DialogTitle>

      {step === 'form' && (
        <GlobalEditForm
          fieldValues={fieldValues}
          fields={fields}
          globalEditResource={globalEditResource}
          onClickSubmit={handleNext}
          onClose={onClose}
          rowCount={rowCount}
          selectedFields={selectedFields}
          setFieldValues={setFieldValues}
          setSelectedFields={setSelectedFields}
        />
      )}

      {step === 'review' && (
        <ReviewUI
          fieldValues={fieldValues}
          globalEditResource={globalEditResource}
          onClickGoBack={handleBack}
          onClose={onClose}
          rowIds={rowIds}
          selectedFields={selectedFields}
        />
      )}
    </EnhancedDialog>
  );
};

export default GlobalEditDialog;
