// External Dependencies
import {
  FC,
  useCallback,
  useMemo,
} from 'react';
import {
  FinancialPaymentsForTransactionIdResponseItem,
} from '@presto-assistant/api_types/api/v1/financialTransaction';
import { useNavigate } from '@reach/router';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ReceiptIcon from '@mui/icons-material/Receipt';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialTransactionsTableZeroState from './FinancialTransactionShowTableZeroState';

// Local Typings
interface Props {
  loading: boolean,
  rows: FinancialPaymentsForTransactionIdResponseItem[]
}

// Component Definition
const FinancialTransactionsShowTable: FC<Props> = ({
  loading,
  rows,
}) => {
  const navigate = useNavigate();

  const handleViewPayment = useCallback((row: FinancialPaymentsForTransactionIdResponseItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}/${row.id}`);
  }, [navigate]);

  const handleViewItem = useCallback((row: FinancialPaymentsForTransactionIdResponseItem) => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}/${row.financialItemId}`);
  }, [navigate]);

  const extraCols = useMemo<DataGridColDef<FinancialPaymentsForTransactionIdResponseItem>[]>(() => {
    const actionCols = createDataGridActionsColumn<FinancialPaymentsForTransactionIdResponseItem>(
      [
        {
          action: handleViewPayment,
          icon: <LocalAtmIcon />,
          text: 'View Payment',
        },
        {
          action: handleViewItem,
          icon: <ReceiptIcon />,
          text: 'View Financial Item',
        },
      ],
    ) as DataGridColDef<FinancialPaymentsForTransactionIdResponseItem>;

    return actionCols ? [actionCols] : [];
  }, [handleViewPayment, handleViewItem]);

  const columns = useColumns(extraCols);

  return (
    <DataGridContainer>
      <TableDataGrid
        columns={columns}
        components={{
          NoRowsOverlay: FinancialTransactionsTableZeroState,
        }}
        loading={loading}
        rows={rows}
        tableResource="financialPaymentsOnTransaction"
      />
    </DataGridContainer>
  );
};

export default FinancialTransactionsShowTable;
