// External Dependencies
import {
  CreateDistrictInventoryAuditRequest,
} from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { createDistrictInventoryAuditBodySchema } from '@presto-assistant/api_types/schemas/districtInventoryAudit';
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormatListChecksIcon from 'mdi-material-ui/FormatListChecks';
import Grid from '@mui/material/Grid';

// Internal Dependencies
import {
  EnhancedContainer,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isDistrictAdmin } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import AdminInventoryAuditFormFields from './AdminInventoryAuditFormFields';

// Local Typings
export type AdminInventoryAuditFormValues = CreateDistrictInventoryAuditRequest['body'];

interface Props {
  inventoryAudit?: AdminInventoryAuditFormValues;
  onSubmit?: (values: AdminInventoryAuditFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
}

// Component Definition
const AdminInventoryAuditForm = ({
  inventoryAudit,
  onSubmit,
  readOnly,
  title,
}: Props): JSX.Element | null => {
  const navigate = useNavigate();

  const isDFA = useSelector(isDistrictAdmin);

  const inventoryAuditsParams = useSelector(tableQueryParams('inventoryAudits'));

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}${inventoryAuditsParams}`);
  }, [inventoryAuditsParams, navigate]);

  // If readonly view, we need to make sure the inventoryAudit data exists
  if (!isDFA || (readOnly && !inventoryAudit)) {
    return null;
  }

  const initialValues = {
    dueDate: inventoryAudit?.dueDate ?? '',
    label: inventoryAudit?.label ?? '',
  };

  const handleFormikSubmit = async (
    values: AdminInventoryAuditFormValues,
    formikProps: FormikHelpers<CreateDistrictInventoryAuditRequest['body']>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  return (
    <Formik<CreateDistrictInventoryAuditRequest['body']>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={createDistrictInventoryAuditBodySchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <EnhancedContainer>
                <Box mb={2}>
                  <ShowCard
                    icon={FormatListChecksIcon}
                    readOnly={readOnly}
                    title={title}
                  >
                    {readOnly && inventoryAudit ? (
                      <div>Show page — Coming soon...</div>
                    ) : (
                      <AdminInventoryAuditFormFields />
                    )}
                  </ShowCard>
                </Box>
              </EnhancedContainer>
            </Grid>

            {!readOnly && (
              <FormActions
                context="Inventory Audit"
                isEditing={!!inventoryAudit}
                isFormTouched={isFormTouched}
                isSubmitting={isSubmitting}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminInventoryAuditForm;
