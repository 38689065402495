// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import SignupCard from './SignupCard';
import SignupContainer from './SignupContainer';
import SignupRoleCardsContainer from './SignupRoleCardsContainer';
import StudentParentSignUpCards from './StudentParentSignUpCards';

// Local Variables
const rolesWithMoreChoices: DB.User.OnboardingRole[] = [
  'administrator',
  'director',
];

// Component Definition
const Signup: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const search = useParsedSearch();

  const isTrialSignup = search.trialSignup === true;

  const TRANSITION_DURATION = theme.transitions.duration.standard;

  // Clicking on the CTA will send the user to a search-params-driven url
  const handleClickCreateUser = useCallback((role: DB.User.OnboardingRole) => {
    // Admin and Director roles need to choose a further role
    if (rolesWithMoreChoices.includes(role)) {
      navigate(`/${PATHS.SIGNUP_CHOOSE_ROLE}?onboarding_role=${role}`);
    } else {
      navigate(`/${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}?onboarding_role=${role}`);
    }
  }, [navigate]);

  return (
    <SignupContainer>
      <SignupRoleCardsContainer>
        <Typography
          className="title"
          component="h1"
          gutterBottom
          variant="h4"
        >
          Who are you?
        </Typography>

        <section className="card-container">
          {/* ADMINISTRATOR */}
          <SignupCard
            buttonText="Start for free"
            listItem1="You're a Fine Arts Administrator or Assistant"
            listItem2="You need a modern way to track inventory, uniforms, and more across the district"
            listItem3="You want to start your 30-day free trial"
            onClickButton={() => handleClickCreateUser('administrator')}
            subheader={`Set up your ${APP_NAME} district`}
            title="Administrator"
            transitionTimeout={TRANSITION_DURATION}
          />

          {/* DIRECTOR */}
          <SignupCard
            buttonText="Start for free"
            listItem1="You're a director for band, choir, dance, guitar, mariachi, orchestra, or theater"
            listItem2="You need a tool to track inventory, student data,  emails, and more"
            listItem3="You want to start your 30-day free trial"
            onClickButton={() => handleClickCreateUser('director')}
            subheader={`Set up your ${APP_NAME} organization`}
            title="Director"
            transitionTimeout={TRANSITION_DURATION * 2}
          />

          {/* PARENT and STUDENT */}
          {!isTrialSignup && <StudentParentSignUpCards />}
        </section>
      </SignupRoleCardsContainer>
    </SignupContainer>
  );
};

export default Signup;
