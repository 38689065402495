// External Dependencies
// TODO: Add Delete icon when query is ready
// import DeleteIcon from '@mui/icons-material/Delete';
import {
  FC, ReactNode, useCallback, useMemo,
} from 'react';
import { ImportResources } from '@presto-assistant/api_types';
import { getOperationName } from '@apollo/client/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { SHORT_APP_NAME } from 'utils/constants';
import { StyledLink } from 'components/shared';
import { close } from 'state/ui/studentFileUploadDialog/actions';
import {
  isOpen as isStudentFileUploadDialogOpenSelector,
} from 'state/ui/studentFileUploadDialog/selectors';
import {
  useGetGettingStartedCounts,
  useGetOrganizationRoles,
  useGetStudentsIndexQuery,
} from 'gql/queries';
import CSVImporter from 'components/CSVImporter';

// Local Typings
interface Props {
  isUpdatingStudents: boolean;
}

// Component Definition
const DialogStudentFileUpload: FC<Props> = ({
  isUpdatingStudents,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentIndexQuery = useGetStudentsIndexQuery();

  const isStudentFileUploadDialogOpen = useSelector(
    isStudentFileUploadDialogOpenSelector,
  );

  const handleCloseDialogStudentFileUpload = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneStudent = (gettingStartedCountsData?.dashboardMetrics?.userRoleCounts[0]?.count ?? 0) > 0;

  const handleNavigateToNewStudentCreateRoleElement = useCallback(() => {
    navigate(`/${PATHS.STUDENTS}/new`);
    handleCloseDialogStudentFileUpload();
  }, [handleCloseDialogStudentFileUpload, navigate]);

  const { data: userOrganizationRoleData } = useGetOrganizationRoles();

  const tipDetails = useMemo(() => {
    const userOrganizationRoleLabels = (userOrganizationRoleData?.organizationRoles ?? [])
      .map((userOrganizationRole) =>
        userOrganizationRole.label);

    const hasOrganizationRoles = userOrganizationRoleLabels.length > 0;

    let fileUploadTipTitle = 'Role/Instrument';
    let fileUploadTipMessage: ReactNode = `The role/instrument of the students you are adding must be one of: ${userOrganizationRoleLabels.join('; ')}.`;

    if (!hasOrganizationRoles) {
      fileUploadTipMessage = (
        <Typography variant="body2">
          Your organization doesn&apos;t have any roles yet. To create new roles, visit{' '}
          <StyledLink onClick={handleNavigateToNewStudentCreateRoleElement}>
            this page
          </StyledLink>{' '}
          in {SHORT_APP_NAME} and press the &quot;Create Role&quot;{' '}
          button in the &quot;Role&quot; section.
        </Typography>
      );
    }

    if (isUpdatingStudents) {
      fileUploadTipTitle = 'Required Fields';
      fileUploadTipMessage = (
        <>
          <Typography
            gutterBottom
            variant="body2"
          >
            When updating <strong>student</strong> member data, the
            &quot;member&quot; field is required and will be used
            to look up the student in the system. All other fields provided
            will update values for existing students.
          </Typography>

          <Typography
            gutterBottom
            variant="body2"
          >
            The &quot;member&quot; field can be any of the following:

            <ul>
              <li>Student ID</li>
              <li>Email</li>
              <li>First Last</li>
              <li>Last, First</li>
            </ul>
          </Typography>

          <Typography
            gutterBottom
            variant="body2"
          >
            You can add new parents, but existing parent data will not be updated.
          </Typography>
        </>
      );
    }

    return {
      fileUploadTipMessage,
      fileUploadTipTitle,
    };
  }, [
    handleNavigateToNewStudentCreateRoleElement,
    isUpdatingStudents,
    userOrganizationRoleData?.organizationRoles,
  ]);

  return (
    <CSVImporter
      canMigrateFromAnotherSystem={!isUpdatingStudents}
      context="student"
      csvFileInfoResource={isUpdatingStudents ? 'studentsUpdate' : 'studentsCreate'}
      dialogTitle={isUpdatingStudents ? 'Update students' : 'Add students'}
      fileUploadTipMessage={tipDetails.fileUploadTipMessage}
      fileUploadTipTitle={tipDetails.fileUploadTipTitle}
      importResourceId={ImportResources.Members}
      insertMutationName={isUpdatingStudents ? 'insertStudentUpdateCsvFileToDB' : 'insertStudentCsvFileToDB'}
      insertMutationOptions={{
        clearCachePredicates: [
          'allMembers',
          'allDirectors',
          'dashboardMetrics',
          'itemsNeedingAttention',
          'groupsIndex',
          'parentsIndex',
          'peoplePickerIndex',
          'studentsIndex',
          'user',
          ...!hasAtLeastOneStudent ? ['dashboardMetrics'] : [],
        ],
        refetchQueries: () => [
          'NonDirectors',
          getOperationName(studentIndexQuery) as string,
          'Users',
        ],
      }}
      isOpen={isStudentFileUploadDialogOpen}
      onClose={handleCloseDialogStudentFileUpload}
      uploadMutationName={isUpdatingStudents ? 'csvStudentUpdateUpload' : 'csvStudentUpload'}
    />
  );
};

export default DialogStudentFileUpload;
