// External Dependencies
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FinancialPaymentTypes,
  StripeStatus,
} from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  DangerZone,
  EnhancedAlert,
  EnhancedCard,
  LabelWithLink,
  ShowCardHeader,
  ShowPageDataDisplay,
  UserLabelWithLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
  formatDate,
  getFullName,
} from 'utils';
import { hasPermission } from 'state/self/selectors';
import { useCreateFinancialPaymentRefund } from 'gql/mutations';
import { useGetOrganizationStripeBalance } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import RadioGroup from 'components/shared/IconButtonMenu/RadioGroup';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import { StyledChip } from '../shared/styles';
import { renderPaymentType } from '../shared/StripeStatusChip';
import AddPaymentListItem from './AddPaymentListItem';
import FinancialItemShowCard from '../shared/FinancialFeeShowCard';

// Local Typings
interface Props {
  editPath?: string | undefined;
  payment: GQL.IFinancialPayment | undefined;
}

enum RefundStep {
  RefundType = 1,
  RefundDetails,
}

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.actions-subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },

  '.avatar': {
    backgroundColor: theme.palette.showPage.people,
  },
  width: 512,
}));
const StyledStrong = styled.strong({ fontSize: '1.2em' });

const title = 'Payment Info';

// Component Definition
const ShowFinancialPaymentData = ({
  editPath,
  payment,
}: Props): JSX.Element | null => {
  const navigate = useNavigate();
  const refundedTextField = useTextField();
  const [isManualRefund, setIsManualRefund] = useState<boolean | null>(null);
  const [refundStep, setRefundStep] = useState<RefundStep>(RefundStep.RefundType);

  const [
    getStripeBalance,
    {
      data: organizationWithStripeBalanceData,
    },
  ] = useGetOrganizationStripeBalance();

  const {
    isOpen: isRefundConfirmationDialogOpen,
    toggleIsOpen: toggleIsRefundConfirmationDialogOpen,
  } = useIsOpen();

  const isStripePayment = payment?.financialPaymentType.id
    === FinancialPaymentTypes.Stripe.toString();

  useEffect(() => {
    if (!isRefundConfirmationDialogOpen) {
      setRefundStep(isStripePayment ? RefundStep.RefundType : RefundStep.RefundDetails);
      setIsManualRefund(null);
    }
  }, [isRefundConfirmationDialogOpen, isStripePayment]);

  const canCreateFinancialPayments = useSelector(hasPermission('payments', 'write'));
  const canEditFinancialPayments = useSelector(hasPermission('payments', 'edit'));
  const canReadFinancialPayments = useSelector(hasPermission('payments', 'read'));

  const stripeBalance = organizationWithStripeBalanceData?.organization.stripeBalance;

  const availableInCents = stripeBalance?.availableInCents;
  const pendingInCents = stripeBalance?.pendingInCents;

  const stripeBalanceAlert = useMemo(() => {
    if (availableInCents === null || availableInCents === undefined) {
      return (
        <EnhancedAlert severity="error">
          Unable to retrieve Stripe balance
        </EnhancedAlert>
      );
    }

    const availableStripeBalanceText = displayPriceStringFromDollarAmount(
      convertCentsToDollars(availableInCents),
    );
    const pendingStripeBalanceText = displayPriceStringFromDollarAmount(
      convertCentsToDollars(pendingInCents),
    );

    return (
      <EnhancedAlert title="Your Stripe Balance">
        Available: {availableStripeBalanceText}
        <br />
        Pending: {pendingStripeBalanceText}
      </EnhancedAlert>
    );
  }, [availableInCents, pendingInCents]);

  const canIssueAutomaticRefund = availableInCents !== null
    && availableInCents !== undefined
    && availableInCents >= (payment?.amountInCents ?? 0);

  const dialogDescription = useMemo(() => {
    const amountString = displayPriceStringFromDollarAmount(
      convertCentsToDollars(payment?.amountInCents ?? 0),
    );

    const dialogDescriptionHeading = (
      <Typography
        color="textSecondary"
        gutterBottom
      >
        Refund <strong>{amountString}</strong> to this member?
      </Typography>
    );

    if (isStripePayment && !isManualRefund) {
      return (
        <>
          {dialogDescriptionHeading}

          <EnhancedAlert>
            Since this payment was made online
            {' '}
            and you are issuing an <strong>automatic refund</strong>,
            {' '}
            the amount will be refunded to the original payment source.
          </EnhancedAlert>

          <ul>
            <li>
              <Typography gutterBottom>Do not refund this member with cash</Typography>
            </li>

            <li>
              <Typography gutterBottom>
                The member will not be refunded the payment processing fee
              </Typography>
            </li>

            <li>
              <Typography>This action cannot be undone</Typography>
            </li>
          </ul>
        </>
      );
    }

    return (
      <>
        {dialogDescriptionHeading}

        <EnhancedAlert>
          {isStripePayment
            ? <>Since you are choosing to issue a <strong>manual refund</strong></>
            : 'Since this payment was entered manually'}
          {', '}
          you are responsible for returning this amount to the member.
        </EnhancedAlert>
      </>
    );
  }, [payment, isStripePayment, isManualRefund]);

  const creditAppliedInCents = useMemo(
    () =>
      (payment?.financialCreditApplications ?? [])
        .reduce((prev, curr) => prev + curr.amountInCents, 0),
    [payment?.financialCreditApplications],
  );

  const [
    createRefund,
    {
      loading: isSubmittingRefund,
    },
  ] = useCreateFinancialPaymentRefund({
    onCompleted: toggleIsRefundConfirmationDialogOpen,
  });

  useEffect(() => {
    if (isStripePayment) {
      getStripeBalance();
    }
  }, [getStripeBalance, isStripePayment]);

  const handleConfirm = useCallback(() => {
    if (!payment || isManualRefund === null) {
      return;
    }

    createRefund({
      variables: {
        input: {
          financialPaymentId: payment.id,
          isManualRefund,
          refundedNote: refundedTextField.value,
        },
      },
    });
  }, [createRefund, isManualRefund, payment, refundedTextField.value]);

  const handleChangeIsManualRefund = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsManualRefund(event.target.value === 'true');
  }, []);

  const handleClickNext = useCallback(() => {
    setRefundStep(RefundStep.RefundDetails);
  }, []);

  const handleNavigateToShowTransaction = useCallback(() => {
    if (payment) {
      navigate(`/${PATHS.FINANCIAL_TRANSACTIONS}/${payment?.financialTransactionId}`);
    }
  }, [navigate, payment]);

  useEffect(() => {
    if (payment && !isStripePayment) {
      setRefundStep(RefundStep.RefundDetails);
      setIsManualRefund(true);
    }
  }, [isStripePayment, payment]);

  if (!payment) {
    return null;
  }

  const {
    amountInCents: paymentAmountInCents,
    checkNumber,
    creditCardAccountHolder,
    creditCardExp,
    creditCardLastFour,
    datePaid,
    financialFee,
    financialPaymentType: {
      id: paymentTypeId,
      label: paymentTypeLabel,
    },
    note,
    otherLabel,
    overpaymentCredit,
    referenceNumber,
    refundedAt,
    refundedBy,
    refundedNote,
    stripePaymentStatusId,
    stripeRefundStatusId,
    user,
    user: {
      firstName,
      id: userId,
      lastName,
      middleName,
    },
  } = payment;

  const financialPaymentTypeId = Number(paymentTypeId);
  const fullName = getFullName({ firstName, lastName, middleName });

  const didRefundFail = stripeRefundStatusId === StripeStatus.Failed;

  const shouldShowInsufficientFundsError = !canIssueAutomaticRefund && isManualRefund === false;

  return (
    <>
      <Grid container>
        <StyledContainer>
          <Box marginBottom={2}>
            <EnhancedCard>
              {refundedAt && refundedBy && !didRefundFail && (
                <EnhancedAlert
                  severity="error"
                  title="Payment refunded"
                >
                  Issued by: {getFullName(refundedBy)} on {formatDate(refundedAt)}
                  <br />
                  Reason: {refundedNote}
                  <br />
                  Manual refund: {payment.isManualRefund ? 'Yes' : 'No'}
                </EnhancedAlert>
              )}

              <ShowCardHeader
                avatar={(
                  <Avatar className="avatar">
                    <LocalAtmIcon aria-label={title} />
                  </Avatar>
                )}
                editPath={editPath}
                title={title}
              />

              <CardContent>
                <ShowPageDataDisplay
                  label="Name"
                  value={<UserLabelWithLink user={user} />}
                />

                <ShowPageDataDisplay
                  label="Payment Date"
                  type="date"
                  value={datePaid}
                />

                <ShowPageDataDisplay
                  label="Payment Type"
                  value={renderPaymentType({
                    financialPaymentTypeId,
                    financialPaymentTypeLabel: paymentTypeLabel,
                    stripePaymentStatusId,
                  })}
                />

                {financialPaymentTypeId === FinancialPaymentTypes.Check && (
                  <ShowPageDataDisplay
                    label="Check Number"
                    value={checkNumber}
                  />
                )}

                {financialPaymentTypeId === FinancialPaymentTypes.CreditCard && (
                  <>
                    <ShowPageDataDisplay
                      label="Credit Card Account Holder"
                      value={creditCardAccountHolder}
                    />
                    <ShowPageDataDisplay
                      label="Credit Card Expiration"
                      value={creditCardExp}
                    />
                    <ShowPageDataDisplay
                      label="Credit Card Last Four"
                      value={creditCardLastFour}
                    />
                  </>
                )}

                {financialPaymentTypeId === FinancialPaymentTypes.Other && (
                  <>
                    <ShowPageDataDisplay
                      label="Payment Type Name"
                      value={otherLabel}
                    />
                    <ShowPageDataDisplay
                      label="Reference Number"
                      value={referenceNumber}
                    />
                  </>
                )}

                <ShowPageDataDisplay
                  label="Credit Generated from Overpayment"
                  value={overpaymentCredit?.amountInCents
                    ? (
                      <LabelWithLink
                        label={
                          displayPriceStringFromDollarAmount(
                            convertCentsToDollars(overpaymentCredit.amountInCents),
                          )
                        }
                        to={`/${PATHS.FINANCIAL_CREDITS}/${overpaymentCredit.id}`}
                      />
                    ) : '—'}
                />

                <ShowPageDataDisplay
                  label="Note"
                  value={note}
                />
              </CardContent>

              <Divider />

              <CardContent>
                <Box
                  alignItems="flex-end"
                  display="flex"
                  flexDirection="column"
                  width="100%"
                >
                  <ShowPageDataDisplay
                    align="right"
                    label="Payment Amount"
                    type="currency"
                    value={paymentAmountInCents}
                  />

                  <ShowPageDataDisplay
                    align="right"
                    label="Credit Applied"
                    type="currency"
                    value={creditAppliedInCents}
                  />

                  <ShowPageDataDisplay
                    align="right"
                    label="Total Payment Amount"
                    value={(
                      <StyledStrong>
                        {displayPriceStringFromDollarAmount(
                          convertCentsToDollars(paymentAmountInCents + creditAppliedInCents),
                        )}
                      </StyledStrong>
                    )}
                  />
                </Box>
              </CardContent>

              {payment.financialCreditApplications.length > 0 && (
                <>
                  <Divider />

                  <CardContent>
                    <ShowPageDataDisplay
                      label="Credit Applications"
                      value={(
                        <List>
                          {payment.financialCreditApplications.map((application) => {
                            const {
                              financialCredit,
                            } = application;

                            return (
                              <ListItem key={application.id}>
                                <ListItemText
                                  primary={(
                                    <LabelWithLink
                                      label={displayPriceStringFromDollarAmount(
                                        convertCentsToDollars(application.amountInCents),
                                      )}
                                      to={`/${PATHS.FINANCIAL_CREDITS}/${financialCredit.id}`}
                                    />
                                  )}
                                  secondary={`${displayPriceStringFromDollarAmount(
                                    convertCentsToDollars(financialCredit.amountInCents),
                                  )} original credit`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    />
                  </CardContent>
                </>
              )}

              {canCreateFinancialPayments && (
                <>
                  <Divider />

                  <CardContent>
                    <Typography
                      className="actions-subtitle"
                      component="h6"
                    >
                      User Actions
                    </Typography>

                    <List>
                      <AddPaymentListItem
                        userFullName={fullName}
                        userId={userId}
                      />
                    </List>
                  </CardContent>
                </>
              )}

              {canReadFinancialPayments && (
                <>
                  <Divider />

                  <CardContent>
                    <Typography
                      className="actions-subtitle"
                      component="h6"
                    >
                      Financial Transaction Details
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                      }}
                    >
                      <StyledChip
                        clickable
                        label="Show Financial Transaction"
                        onClick={handleNavigateToShowTransaction}
                        sx={{ marginX: 'auto !important' }}
                      />
                    </Box>
                  </CardContent>
                </>
              )}
            </EnhancedCard>
          </Box>

          <Box marginBottom={2}>
            <FinancialItemShowCard
              financialItemId={financialFee?.financialItem.id}
              financialItemLabel={financialFee?.financialItem.label}
              originalPriceInCents={financialFee?.financialItem?.priceInCents}
              remainingBalanceInCents={financialFee.remainingBalanceInCents}
              user={user}
            />
          </Box>

          {canEditFinancialPayments && !refundedAt && (
            <DangerZone
              items={[
                {
                  buttonText: 'Refund',
                  description: 'Refund this payment to user. This does not delete or waive the fee.',
                  onClick: toggleIsRefundConfirmationDialogOpen,
                  title: 'Refund payment',
                },
              ]}
            />
          )}
        </StyledContainer>
      </Grid>

      {payment.financialPaymentType.id === FinancialPaymentTypes.Stripe.toString() && (
        <ConfirmationDialog
          confirmButtonAction={handleClickNext}
          confirmButtonText="Next"
          declineButtonAction={toggleIsRefundConfirmationDialogOpen}
          description={(
            <>
              <DialogContentText>
                This payment was made online. Do you want to issue a
                {' '}
                <strong>Manual Refund</strong>
                {' '}
                (cash, check, etc.), or an
                {' '}
                <strong>Automatic Refund</strong> (back to the cardholder)?
              </DialogContentText>

              <RadioGroup
                name="isManualRefund"
                onChange={handleChangeIsManualRefund}
                options={[
                  {
                    label: 'Manual Refund',
                    value: true,
                  },
                  {
                    label: 'Automatic Refund',
                    value: false,
                  },
                ]}
                title="Refund type"
                value={isManualRefund}
              />

              {stripeBalanceAlert}

              {shouldShowInsufficientFundsError && (
              <EnhancedAlert
                severity="error"
                sx={{ marginTop: 2 }}
              >
                Your Stripe account does not have sufficient available funds to refund this payment.
              </EnhancedAlert>
              )}
            </>
          )}
          handleClose={toggleIsRefundConfirmationDialogOpen}
          isConfirmButtonDisabled={shouldShowInsufficientFundsError || isManualRefund === null}
          open={isRefundConfirmationDialogOpen && refundStep === RefundStep.RefundType}
          title="Select refund type"
        />
      )}

      <ConfirmationDialog
        confirmButtonAction={handleConfirm}
        confirmButtonText="Yes, refund"
        declineButtonAction={toggleIsRefundConfirmationDialogOpen}
        deletedNoteInputProps={refundedTextField}
        description={dialogDescription}
        handleClose={toggleIsRefundConfirmationDialogOpen}
        isSubmitting={isSubmittingRefund}
        open={isRefundConfirmationDialogOpen && refundStep === RefundStep.RefundDetails}
        title="Refund payment"
        useCustomText
      />
    </>
  );
};

export default ShowFinancialPaymentData;
