// External Dependencies
import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Internal Dependencies
import { CustomInput, FormActionButtons, ShowCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetDistrict } from 'gql/queries';
import { useUpdateDistrictSettings } from 'gql/mutations';

// Component Definition
const InventorySettingsForm: FC = () => {
  const navigate = useNavigate();
  const inventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  const handleNavigateToInventory = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${inventoryParams}`);
  }, [inventoryParams, navigate]);

  const {
    data: districtData,
  } = useGetDistrict();

  const [
    updateDistrictSettings,
  ] = useUpdateDistrictSettings({
    onCompleted: handleNavigateToInventory,
  });

  const handleSubmit = useCallback((values: GQL.IUpdateDistrictSettingsInput) => {
    updateDistrictSettings({
      variables: {
        input: {
          ...values,
          customBarcodeCursor: values.customBarcodeCursor?.toString(),
        },
      },
    });
  }, [updateDistrictSettings]);

  return (
    <Formik<GQL.IUpdateDistrictSettingsInput>
      enableReinitialize
      initialValues={{
        customBarcodeCursor: districtData?.district.customBarcodeCursor,
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <ShowCard
            cardActions={(
              <FormActionButtons
                context="Inventory Settings"
                isEditing
                isSubmitting={false}
                onClickCancelButton={handleNavigateToInventory}
              />
            )}
            icon={WidgetsIcon}
            title="Inventory Settings"
          >
            <CustomInput
              helperText="If you provide a minimum barcode, Presto will auto-generate serialized barcodes for future items by default."
              label="Minimum barcode value"
              name="customBarcodeCursor"
              type="number"
            />
          </ShowCard>
        </Form>
      )}
    </Formik>
  );
};

export default InventorySettingsForm;
