// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

// Internal Dependencies
import { Address, EnhancedCard, ListItemWithSecondaryAction } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import {
  hasPermission,
  isDistrictAdmin,
  isDirector as selectIsDirector,
} from 'state/self/selectors';
import { open as openUpdatePasswordDialog } from 'state/ui/updatePasswordDialog/actions';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import DialogEditUsername from './DialogEditUsername';
import UpdatePasswordDialog from './UpdatePasswordDialog';

// Local Typings
interface Props {
  user: GQL.ISelf | null;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiListItemSecondaryAction-root': {
    // This will prevent the list item text from hitting the
    //  secondary action on tiny screens
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'initial',
      right: 'unset',
      top: 'unset',
      transform: 'none',
    },
  },
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  '.listItem': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const ProfileCard: FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleClose: handleCloseDialogEditUsername,
    handleOpen: handleOpenDialogEditUsername,
    isOpen: isDialogEditUsernameOpen,
  } = useIsOpen();

  const isDirector = useSelector(selectIsDirector);
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const isDFA = useSelector(isDistrictAdmin);

  const handleEditSelf = useCallback(() => {
    if (isDFA) {
      return navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}/edit`);
    }

    const userTypePath = isDirector
      ? PATHS.DIRECTORS
      : PATHS.MEMBERS;

    return navigate(`/${userTypePath}/${user?.id}/edit`);
  }, [isDFA, isDirector, user?.id, navigate]);

  const handleEditEmailSignature = useCallback(() => {
    navigate(`/${PATHS.EMAIL_SIGNATURE}`);
  }, [navigate]);

  const handleOpenUpdatePasswordDialog = () => {
    dispatch(openUpdatePasswordDialog());
  };

  if (!user) {
    return null;
  }

  const {
    addressOne,
    addressTwo,
    authUserEmail,
    city,
    phoneNumber,
    state,
    zipcode,
  } = user;

  return (
    <>
      <StyledEnhancedCard>
        <CardHeader
          avatar={(
            <Avatar className="avatar">
              <AccountCircle aria-label="User profile settings" />
            </Avatar>
          )}
          title={(
            <Typography
              component="h2"
              variant="h6"
            >
              Profile
            </Typography>
          )}
        />

        <CardContent>
          <Typography
            className="subtitle"
            component="h6"
          >
            Personal Info
          </Typography>

          <List>
            <ListItem className="listItem">
              <ListItemText
                primary={(
                  <>
                    <Typography>
                      {getFullName(user)}
                    </Typography>

                    {user.title && (
                      <Typography>
                        {user.title}
                      </Typography>
                    )}
                  </>
                )}
                primaryTypographyProps={{
                  component: 'div',
                }}
                secondary={(
                  <Address
                    addressOne={addressOne}
                    addressTwo={addressTwo}
                    city={city}
                    phoneNumber={phoneNumber}
                    state={state?.label ?? null}
                    zipcode={zipcode}
                  />
                )}
                secondaryTypographyProps={{
                  component: 'div',
                }}
              />

              <ListItemSecondaryAction>
                <Button
                  aria-label="Edit Personal Info"
                  color="primary"
                  onClick={handleEditSelf}
                  size="small"
                  variant="outlined"
                >
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          <Typography
            className="subtitle"
            component="h6"
          >
            Login Info
          </Typography>

          <List>
            <ListItemWithSecondaryAction
              primaryText="Username"
              secondaryAction={{
                buttonIcon: <EditIcon />,
                buttonText: 'Change Username',
                onClick: handleOpenDialogEditUsername,
                onlyShowIconOnMobile: true,
              }}
              secondaryText={authUserEmail}
            />

            <ListItemWithSecondaryAction
              primaryText="Password"
              secondaryAction={{
                buttonIcon: <EditIcon />,
                buttonText: 'Change Password',
                onClick: handleOpenUpdatePasswordDialog,
                onlyShowIconOnMobile: true,
              }}
              secondaryText="************"
            />
          </List>

          {canEmailMembers && !isDFA && (
            <>
              <Typography
                className="subtitle"
                component="h6"
              >
                Email Settings
              </Typography>

              <List>
                <ListItemWithSecondaryAction
                  primaryText="Signature"
                  secondaryAction={{
                    buttonIcon: <EditIcon />,
                    buttonText: 'Edit Signature',
                    onClick: handleEditEmailSignature,
                    onlyShowIconOnMobile: true,
                  }}
                  secondaryText={(
                    <Typography
                      color="textSecondary"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(user.emailSignature ?? 'No signature set'),
                      }}
                      variant="body2"
                    />
                  )}
                />
              </List>
            </>
          )}
        </CardContent>
      </StyledEnhancedCard>

      <DialogEditUsername
        isOpen={isDialogEditUsernameOpen}
        onClose={handleCloseDialogEditUsername}
      />

      <UpdatePasswordDialog />
    </>
  );
};

export default ProfileCard;
