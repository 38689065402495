// External Dependencies
import {
  DistrictLibraryIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/library';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { DELETE_LIBRARY_ITEMS } from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { isDistrictAdmin } from 'state/self/selectors';
import {
  useGetDistrictLibraryIndex,
  useGetDistrictLibraryIndexReport,
} from 'utils/api/library';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import TableDataGridServerSide, {
  useParsedDataGridQueryString,
} from 'components/shared/TableDataGrid/ServerSide';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import DialogLibraryFileUpload from './DialogLibraryFileUpload';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${id}`;

// Component Definition
const LibraryTable = (): JSX.Element => {
  const parsedSearch = useParsedSearch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const isDfa = useSelector(isDistrictAdmin);

  const [deleteLibraryItemId, setDeleteLibraryItemId] = useState<string | null>(null);

  const {
    handleClose: closeUploadDialog,
    handleOpen: openUploadDialog,
    isOpen: isUploadDialogOpen,
  } = useIsOpen();

  const {
    filters,
    pagination,
    sort,
  } = useParsedDataGridQueryString(search, {
    // TODO: look at this
    mapSortKeys: {
      categoryId: 'categoryLabel',
      onLoanToOrganizationId: 'onLoanToOrganizationLabel',
      organizationId: 'organizationLabel',
    },
  });

  const {
    data: districtLibraryData,
    isLoading,
  } = useGetDistrictLibraryIndex({
    filters,
    limit: pagination?.pageSize ?? 100,
    page: pagination?.page ?? 1,
    sort,
  });

  const {
    isLoading: isExporting,
    mutate: handleExportLibrary,
  } = useGetDistrictLibraryIndexReport();

  const handleClickExportLibrary = useCallback(() => {
    handleExportLibrary({
      filters,
      limit: pagination?.pageSize ?? 100,
      page: pagination?.page ?? 1,
      sort,
    });
  }, [
    handleExportLibrary,
    filters,
    pagination?.pageSize,
    pagination?.page,
    sort,
  ]);

  const handleNavigateToDynamicFields = useCallback(() => {
    const organizationTypeId = parsedSearch.organizationTypeId ?? '1';
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}?organization_type_id=${organizationTypeId}&table_ref=library`);
  }, [navigate, parsedSearch]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [
      {
        action: handleClickExportLibrary,
        icon: <DownloadIcon />,
        isDisabled: isExporting || (districtLibraryData?.data.fullCount ?? 0) < 1,
        text: 'Export library',
      },
      ...(isDfa ? [
        {
          action: openUploadDialog,
          icon: <CloudUploadIcon />,
          // TODO: Update this to the new way the API tells us about active
          // isDisabled: !self?.currentOrgActive,
          text: 'Import library',
        },
        {
          action: handleNavigateToDynamicFields,
          icon: <CategoryIcon />,
          text: 'Dynamic fields',
        },
      ] : []),
    ];

    return actions;
  }, [
    districtLibraryData?.data.fullCount,
    handleClickExportLibrary,
    handleNavigateToDynamicFields,
    isDfa,
    isExporting,
    openUploadDialog,
  ]);

  const handleDeleteLibraryItem = useCallback((row: DistrictLibraryIndexResponseItem) => {
    setDeleteLibraryItemId(row.id);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteLibraryItemId(null);
  }, []);

  const extraColumns = useMemo<DataGridColDef<DistrictLibraryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<DistrictLibraryIndexResponseItem>(
        isDfa
          ? [
            {
              action: handleDeleteLibraryItem,
              icon: <DeleteIcon />,
              text: 'Delete library item',
            },
          ]
          : [],
      );

      return (actionsColumn
        ? [actionsColumn]
        : []
      ) as DataGridColDef<DistrictLibraryIndexResponseItem>[];
    },
    [
      handleDeleteLibraryItem,
      isDfa,
    ],
  );

  const columnsArgs = useMemo(() => ({
    extraColumns,
  }), [extraColumns]);

  const columns = useColumns(columnsArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGridServerSide
          addButtonProps={isDfa ? {
            label: 'Library',
            to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY_NEW}`,
          } : null}
          clickRowTo={isDfa ? handleTableClickRow : undefined}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          fullCount={districtLibraryData?.data.fullCount ?? 0}
          loading={isLoading}
          rows={districtLibraryData?.data.data ?? null}
          tableResource="districtLibraryItems"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogLibraryFileUpload
        isOpen={isUploadDialogOpen}
        onClose={closeUploadDialog}
      />

      <DeleteDialog
        clearCachePredicates={['districtLibrary', 'library']}
        context={['library']}
        isOpen={Boolean(deleteLibraryItemId)}
        mutation={DELETE_LIBRARY_ITEMS}
        onClose={handleCloseDeleteDialog}
        reduxTableKey="districtLibraryItems"
        singleItemId={deleteLibraryItemId}
        size={districtLibraryData?.data.fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default LibraryTable;
