// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  StyledLink,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { useGetSelf } from 'gql/queries';

// Local Dependencies
import AdminOrganizationsTable from './AdminOrganizationsTable';
import InvitationsList from './InvitationList';

// Component Definition
const AdminOrganizations = (): JSX.Element => {
  const navigate = useNavigate();

  const self = useGetSelf();

  const userOrgData = useMemo(() => self.data?.self.userOrgData, [self.data]);

  const selfAuthUserId = self.data?.self.authUserId;

  const handleNavigateToOrgClaims = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS_CLAIM}`);
  }, [navigate]);

  const myUnclaimedOrgCount = useMemo(
    () => userOrgData?.filter((userOrg) =>
      userOrg.organization.creatorId === selfAuthUserId
      && !userOrg.district).length ?? 0,
    [selfAuthUserId, userOrgData],
  );

  const unclaimedContent = useMemo(() => (
    <EnhancedAlert isAlertOpen>
      You have {myUnclaimedOrgCount} unclaimed {pluralize(myUnclaimedOrgCount, 'organization')}. <StyledLink onClick={handleNavigateToOrgClaims}>View</StyledLink>
    </EnhancedAlert>
  ), [handleNavigateToOrgClaims, myUnclaimedOrgCount]);

  return (
    <div>
      <Subtitle>
        Organizations
      </Subtitle>

      <EnhancedCard
        sx={{
          marginY: 2,
        }}
      >
        <AdminOrganizationsTable />
      </EnhancedCard>

      {myUnclaimedOrgCount > 0 && unclaimedContent}

      <Box
        component="section"
        my={2}
      >
        <InvitationsList />
      </Box>
    </div>
  );
};

export default AdminOrganizations;
