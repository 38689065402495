// External Dependencies
import { InternalRefetchQueriesInclude } from '@apollo/client';
import { UserRoles } from '@presto-assistant/api_types';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from '@presto-assistant/api_types/utils';
import {
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import {
  GET_UNIFORM,
  useGetFinancialFeesIndexWhere,
  useGetInventoryCheckoutsByMemberId,
  useGetMember,
  useGetUniformCheckoutsByUserId,
} from 'gql/queries';
import { SELECTION_TYPES } from 'utils/constants';
import { addNotification } from 'state/notifications/actions';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useCheckInInventoryItem,
  useCheckInUniform,
  useDeactivateMember,
  useRemoveMembersFromSingleGroup,
} from 'gql/mutations';
import ExpandableAlert from './ExpandableAlert';

interface Props {
  isOpen: boolean;
  memberId: string;
  onClose: () => void;
  onMemberDeleted?: () => void;
  organizationMembershipId?: string;
  refetchQueries?: InternalRefetchQueriesInclude;
}

// Component Definition
const RemoveMemberDialog = ({
  isOpen,
  memberId,
  onClose,
  onMemberDeleted,
  organizationMembershipId,
  refetchQueries,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { data: memberData } = useGetMember(memberId);

  const isStudent = memberData?.user.role.id === UserRoles.Student.toString();

  // GROUPS
  const [removeMemberFromGroup, {
    loading: isRemovingFromGroups,
  }] = useRemoveMembersFromSingleGroup({});

  const handleRemoveFromGroups = useCallback(async () => {
    const removeMemberFromGroups = (memberData?.user.groups ?? []).map((group) => {
      return removeMemberFromGroup({
        variables: {
          id: group.id,
          selection: {
            ids: [memberId],
            mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
            queryParams: {},
          },
        },
      });
    });

    await Promise.all(removeMemberFromGroups);
  }, [memberData, memberId, removeMemberFromGroup]);

  // UNIFORMS
  const { data: uniformCheckoutData } = useGetUniformCheckoutsByUserId(memberId);
  const uniformsCheckedOut = uniformCheckoutData?.uniformCheckoutsByUserId.uniformCheckouts;

  const uniformsToBeCheckedIn = useMemo(() => {
    return uniformCheckoutData?.uniformCheckoutsByUserId.uniformCheckouts.map((u) => {
      return (
        `${u.uniform.uniformType.label} ${u.uniform.category?.label}: ${u.uniform.uniformNumber}`
      );
    });
  }, [uniformCheckoutData]);

  const [checkInUniform, {
    loading: isCheckingInUniforms,
  }] = useCheckInUniform({
    awaitRefetchQueries: true,
    clearCachePredicates: ['uniformsIndex'],
  });

  const handleCheckInUniforms = useCallback(async () => {
    const uniformCheckouts = (uniformsCheckedOut ?? []).map((uniformCheckout) => {
      return checkInUniform({
        refetchQueries: () => [
          { query: GET_UNIFORM, variables: { id: uniformCheckout.uniform.id } },
        ],
        variables: {
          uniformCheckoutId: uniformCheckout.id,
        },
      });
    });

    await Promise.all(uniformCheckouts);
  }, [checkInUniform, uniformsCheckedOut]);

  // INSTRUMENT CHECKOUTS
  const {
    data: inventoryCheckoutData,
  } = useGetInventoryCheckoutsByMemberId(memberId);

  const inventoryCheckouts = useMemo(() => {
    return inventoryCheckoutData?.inventoryCheckoutsByMemberId.inventoryCheckouts;
  }, [inventoryCheckoutData]);

  const [checkInInventoryItem, {
    loading: isCheckingInInstruments,
  }] = useCheckInInventoryItem({
    onCompleted: () => {
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleCheckInInventoryItems = useCallback(async () => {
    const checkInInventoryItems = (inventoryCheckouts ?? []).map((itemCheckedOut) => {
      return checkInInventoryItem({
        variables: {
          inventoryCheckoutId: itemCheckedOut.id,
        },
      });
    });

    await Promise.all(checkInInventoryItems);
  }, [checkInInventoryItem, inventoryCheckouts]);

  const inventoryToBeCheckedIn = useMemo(() => {
    return inventoryCheckouts?.map((checkout) => {
      return checkout.item.label;
    });
  }, [inventoryCheckouts]);

  // FINANCIAL FEES
  const { data: financeData } = useGetFinancialFeesIndexWhere({
    schoolYearEnding: 0,
    userId: memberId,
  });

  const outstandingFees = useMemo(() => {
    const fees: GQL.IFinancialFeeIndexItem[] = [];
    let totalDueInCents = 0;

    financeData?.financialFeesIndex.data.forEach((fee) => {
      if (fee.balanceDueInCents !== 0) {
        totalDueInCents += fee.balanceDueInCents;
        fees.push(fee);
      }
    });

    return {
      fees,
      totalDueInCents,
    };
  }, [financeData]);

  const feesToBeWaived = useMemo(() => {
    return outstandingFees?.fees.map((fee) => {
      return (`${fee.financialItemLabel}: ${displayPriceStringFromDollarAmount(convertCentsToDollars(fee.balanceDueInCents))}`);
    });
  }, [outstandingFees]);

  // REMOVING USER
  const [
    deactivateUser,
    {
      loading: isRemovingUser,
    },
  ] = useDeactivateMember({
    onCompleted: () => {
      if (onMemberDeleted) {
        onMemberDeleted();
      }
      onClose();
    },
    refetchQueries,
  });

  const handleConfirmRemoveUser = async () => {
    const orgId = organizationMembershipId || memberData?.user.userOrgData?.id;

    if (orgId) {
      await handleRemoveFromGroups();
      await handleCheckInUniforms();
      await handleCheckInInventoryItems();

      await deactivateUser({
        variables: { userOrganizationId: orgId },
      });

      dispatch(addNotification('Student removed successfully', 'success'));
    }
  };

  const isLoading = useMemo(() => {
    return isCheckingInInstruments
    || isRemovingFromGroups
    || isCheckingInUniforms
    || isRemovingUser;
  }, [
    isCheckingInInstruments,
    isCheckingInUniforms,
    isRemovingFromGroups,
    isRemovingUser,
  ]);

  return (
    <ConfirmationDialog
      canBeUndone
      confirmButtonAction={handleConfirmRemoveUser}
      confirmButtonText="Yes, Remove"
      declineButtonAction={onClose}
      description={(
        <>
          {Boolean(inventoryCheckouts?.length) && (
            <ExpandableAlert
              items={inventoryToBeCheckedIn ?? []}
              message={`${inventoryCheckouts?.length} ${pluralize(inventoryCheckouts?.length, 'inventory item')} will be checked in`}
              title="Inventory Items"
            />
          )}

          {Boolean(uniformsCheckedOut?.length) && (
            <ExpandableAlert
              items={uniformsToBeCheckedIn ?? []}
              message={`${uniformsCheckedOut?.length} ${pluralize(uniformsCheckedOut?.length, 'uniform')} will be checked in`}
              title="Uniforms"
            />
          )}

          {Boolean(feesToBeWaived?.length) && (
            <ExpandableAlert
              items={feesToBeWaived}
              message={`This student has ${displayPriceStringFromDollarAmount(convertCentsToDollars(outstandingFees.totalDueInCents))} due in outstanding fees`}
              title="Fees"
            />
          )}

          { isStudent && (
            <DialogContentText gutterBottom>
              Any adult relatives will also be removed unless they have
              other student relatives in your organization.
            </DialogContentText>
          )}

          <DialogContentText>
            The member will still have a Presto account to use with other
            organizations, and can be added back to this organization later
            from the Inactive Members table.
          </DialogContentText>
        </>
      )}
      handleClose={onClose}
      isSubmitting={isLoading}
      maxWidth="md"
      open={isOpen}
      title="Remove Member?"
      useCustomText
    />
  );
};

export default RemoveMemberDialog;
