// External Dependencies
import { DistrictInventoryIndexResponseItem } from '@presto-assistant/api_types/api/v1/inventory';
import { OrgTypes } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { capitalize } from 'utils';
import { useGetDistrictInventoryCategories } from 'utils/api/inventory';
import { useGetDistrictOrganizations } from 'gql/queries';

export const useColumns = ({
  extraColumns,
}: {
  extraColumns?: DataGridColDef<DistrictInventoryIndexResponseItem>[];
}) => {
  const {
    data: categoryData,
  } = useGetDistrictInventoryCategories();

  const categoryOptions = useMemo(() => {
    return categoryData?.data.data.map((category) => ({
      label: `(${capitalize(OrgTypes[category.organizationTypeId])}) ${category.label}`,
      value: category.id,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [categoryData]);

  const {
    data: organizationData,
  } = useGetDistrictOrganizations();

  const organizations = useMemo(() => {
    return organizationData?.districtOrganizations ?? [];
  }, [organizationData]);

  const organizationOptions = useMemo(() => {
    return organizations.map((organization) => ({
      label: organization.label,
      value: organization.id,
    }));
  }, [organizations]);

  return useMemo(() => {
    const columns: DataGridColDef<DistrictInventoryIndexResponseItem>[] = [
      {
        field: 'categoryId',
        headerName: 'Category',
        renderCell: (params) => params.row.categoryLabel,
        type: 'singleSelect',
        valueGetter: (params) => params.row.categoryId,
        valueOptions: categoryOptions,
        width: 292,
      },
      {
        field: 'label',
        headerName: 'Name',
        width: 160,
      },
      {
        field: 'organizationId',
        headerName: 'Organization Assigned To',
        minWidth: 292,
        renderCell: (params) => params.row.organizationLabel,
        type: 'singleSelect',
        valueGetter: (params) => params.row.organizationId,
        valueOptions: organizationOptions,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        width: 292,
      },
      {
        field: 'model',
        headerName: 'Model',
        width: 292,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial #',
        width: 160,
      },
      {
        field: 'customBarcode',
        headerName: 'Barcode',
        width: 292,
      },
      {
        field: 'districtNumber',
        headerName: 'District #',
        width: 160,
      },
      {
        field: 'conditionLabel',
        headerName: 'Condition',
        width: 160,
      },
      {
        field: 'isMissing',
        headerName: 'Is Missing',
        sortable: false,
        type: 'boolean',
        width: 80,
      },
      {
        field: 'checkedOutTo',
        headerName: 'Checked Out To',
        minWidth: 200,
        valueGetter: ((params) => {
          // This handles the case where a student does not have an email address
          // ex John Smith () will become John Smith
          return params.row.checkedOutTo?.replaceAll('()', '').trim();
        }),
      },
      {
        field: 'onLoanToOrganizationId',
        headerName: 'On Loan To',
        minWidth: 292,
        renderCell: (params) => params.row.onLoanToOrganizationLabel,
        type: 'singleSelect',
        valueGetter: (params) => params.row.onLoanToOrganizationId,
        valueOptions: organizationOptions,
      },
      {
        field: 'caseNumber',
        headerName: 'Case #',
        width: 160,
      },
      {
        field: 'purchaseYear',
        headerName: 'Purchase Year',
        type: 'number',
        // the value formatter is used to prevent the "type: number" cell from rendering as 2,021
        valueFormatter: (params) => params.value,
        width: 160,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    categoryOptions,
    extraColumns,
    organizationOptions,
  ]);
};
