// External Dependencies
import { useMemo } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

// Internal Dependencies
import { APP_NAME, FORM_W9_HREF, SHORT_APP_NAME } from 'utils/constants';
import { ListItemWithSecondaryAction } from 'components/shared';

// Component Definition
const DownloadFormW9Section = (): JSX.Element => {
  const secondaryAction = useMemo(() => ({
    buttonIcon: <DownloadIcon />,
    buttonText: 'Download',
    onClick: () => {
      window.open(FORM_W9_HREF, '_blank noopener noreferrer');
    },
  }), []);

  return (
    <section>
      <ListItemWithSecondaryAction
        primaryText={`Download ${APP_NAME} Form W-9`}
        secondaryAction={secondaryAction}
        secondaryText={`Download the Form W-9 for ${SHORT_APP_NAME} if your district needs this document`}
        secondaryTypographyProps={{
          component: 'div',
        }}
      />
    </section>
  );
};

export default DownloadFormW9Section;
