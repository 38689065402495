// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import { useGetOrganizationById } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import AdminDirectorsTable from './AdminDirectorsTable';
import LinkToAdultsWithPermissions from './shared/LinkToAdultsWithPermissions';
import OrganizationInfoCard from './OrganizationInfoCard';

// Component Definition
const AdminOrganization: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    loading,
  } = useGetOrganizationById({
    id,
  });

  return (
    <>
      <Box
        component="section"
        mb={2}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <OrganizationInfoCard
            organization={data?.organization}
          />
        )}
      </Box>

      <Box
        component="section"
        my={2}
      >
        <AdminDirectorsTable />
      </Box>

      <LinkToAdultsWithPermissions organizationId={id} />
    </>
  );
};

export default AdminOrganization;
