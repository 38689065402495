// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useGetOrganization } from 'gql/queries';

// Local Variables
const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '75%',
  },
});

// Component Definition
const BeginEndOfYearProcessListItem: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const navigateToEndOfYear = useCallback(() => navigate(`/${PATHS.END_OF_YEAR}`), [navigate]);

  const { data } = useGetOrganization();

  const hasStartedEndOfYearProcess = Boolean(data?.organization?.maxCompletedEndOfYearStep);

  return (
    <ListItem>
      <ListItemText
        primary="End of Year Process"
        secondary={`${hasStartedEndOfYearProcess ? 'Continue' : 'Start'} process to check all outstanding items and promote students in your ${APP_NAME} organization.`}
        secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
      />

      <ListItemSecondaryAction>
        <Button
          aria-label={`${hasStartedEndOfYearProcess ? 'Continue' : 'Begin'} End-of-Year Process`}
          color="primary"
          onClick={navigateToEndOfYear}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          variant="outlined"
        >
          {hasStartedEndOfYearProcess ? 'Continue' : 'Begin'}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default BeginEndOfYearProcessListItem;
