// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { DELETE_INVENTORY_ITEM } from 'gql/mutations';
import { GET_INVENTORY_ITEMS } from 'gql/queries';
import { SELECTION_TYPES } from 'utils/constants';
import { getOperationName } from '@apollo/client/utilities';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DangerZone, { DangerZoneItemProps } from 'components/shared/DangerZone';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Typings
interface Props {
  inventoryId?: string;
  inventoryIndexUrl: string;
  organizationLabel: string;
}

// Local Variables
const deleteInventoryContext: [string] = ['inventory item'];

// Component Definition
const InventoryDangerZone: FC<Props> = ({
  inventoryId,
  inventoryIndexUrl,
  organizationLabel,
}) => {
  const navigate = useNavigate();
  const {
    isOpen: isRemoveInventoryConfirmationDialogOpen,
    toggleIsOpen: toggleInventoryConfirmationDialogIsOpen,
  } = useIsOpen();

  const canRemoveInventory = useSelector(hasPermission('inventory', 'delete'));
  const isMobileWidth = useSelector(isMobileScreenSize);

  const handleSuccess = useCallback(() => {
    navigate(inventoryIndexUrl);
  }, [inventoryIndexUrl, navigate]);

  const handleCloseDeleteInventoryDialog = () => {
    toggleInventoryConfirmationDialogIsOpen();
  };

  const handleOpenDeleteInventoryDialog = () => {
    toggleInventoryConfirmationDialogIsOpen();
  };

  const formatDeleteInventoryPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteInventoryItemOnMutationArguments>['formatPayload']>((note) => ({

    deletedNote: note ?? '',
    selection: {
      ids: [inventoryId ?? ''],
      mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
      queryParams: {},
    },

  }), [inventoryId]);

  const items: DangerZoneItemProps[] = [];
  if (canRemoveInventory) {
    items.push({
      buttonText: isMobileWidth ? 'Remove' : 'Remove item',
      description: `Remove this inventory item from the ${organizationLabel} organization. This does not delete the item from your district.`,
      onClick: handleOpenDeleteInventoryDialog,
      title: 'Remove inventory item',
    });
  }

  return (
    <>
      <Box mt={4}>
        <DangerZone items={items} />
      </Box>

      <DeleteDialogV2<GQL.IDeleteInventoryItemOnMutationArguments>
        actionVerb="Remove"
        clearCachePredicates={['inventory']}
        context={deleteInventoryContext}
        formatPayload={formatDeleteInventoryPayload}
        hasSelection={false}
        isOpen={isRemoveInventoryConfirmationDialogOpen}
        mutation={DELETE_INVENTORY_ITEM}
        onClose={handleCloseDeleteInventoryDialog}
        onSuccess={handleSuccess}
        refetchQueries={() => [getOperationName(GET_INVENTORY_ITEMS) as string]}
        withNote
      />
    </>
  );
};

export default InventoryDangerZone;
