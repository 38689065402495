// External Dependencies
import { GroupsInput } from 'pages/People/Students/All/StudentsTable/hooks';
import { UserRoles } from '@presto-assistant/api_types';
import {
  useMemo,
} from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { currencyFromCentsColumn } from 'utils/lib/tableColumns';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetFinancialAccounts, useGetFinancialItems } from 'gql/queries';

// Local Variables
export const useColumns = ({
  extraColumns,
  schoolYearEnding,
}: {
  extraColumns: DataGridColDef<GQL.IFinancialFeeIndexItem>[];
  schoolYearEnding: number;
}) => {
  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  const {
    data: financialItemsData,
  } = useGetFinancialItems(schoolYearEnding);

  return useMemo<DataGridColDef<GQL.IFinancialFeeIndexItem>[]>(() => {
    const columns: DataGridColDef<GQL.IFinancialFeeIndexItem>[] = [
      {
        field: 'userFirstName',
        headerName: 'First Name',
        width: 160,
      },
      {
        field: 'userLastName',
        headerName: 'Last Name',
        width: 160,
      },
      {
        field: 'userRoleId',
        headerName: 'Role',
        type: 'singleSelect',
        valueGetter: ({ row }) => UserRoles[row.userRoleId],
        valueOptions: mapEnum(UserRoles).map((option) => ({
          label: option.label,
          value: option.label,
        })),
        width: 160,
      },
      {
        field: 'isActiveMember',
        headerName: 'Active Member',
        type: 'boolean',
      },
      {
        field: 'financialItemLabel',
        headerName: 'Item',
        minWidth: 250,
        renderCell: (params) => params.row.financialItemLabel,
        type: 'singleSelect',
        valueFormatter: (params) =>
          (params.id
            ? params.api.getRow(params?.id).financialItemLabel
            : params.value),
        valueGetter: (params) => (params.row as GQL.IFinancialFeeIndexItem).financialItemId,
        valueOptions: financialItemsData?.financialItems.data.map((item) => ({
          label: item.label,
          value: item.id,
        })),
        width: 160,
      },
      currencyFromCentsColumn({
        field: 'priceInCents',
        headerName: 'Item Amount',
        minWidth: 160,
      }),
      // the groups hook has a lot of logic that is pulled from the students table
      // we should eventually make this reusable
      {
        field: 'groups',
        filterOperators: [
          {
            InputComponent: GroupsInput(schoolYearEnding),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IFinancialFeeIndexItem)
                  .groups?.map((g) => g.id) ?? [];

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return groupIds.some((id) => selectedGroupIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: GroupsInput(2023),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IFinancialFeeIndexItem)
                  .groups?.map((g) => g.id) ?? [];

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => groupIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: GroupsInput(schoolYearEnding),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IFinancialFeeIndexItem)
                  .groups?.map((g) => g.id) ?? [];

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => !groupIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Groups',
        minWidth: 200,
        valueGetter: (params) => (params.row as GQL.IFinancialFeeIndexItem)
          .groups?.map((group) => group.label).join('; '),
      },
      {
        field: 'financialAccountId',
        headerName: 'Account',
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IFinancialFeeIndexItem).financialAccountId,
        valueOptions: financialAccountsData?.financialAccounts.map((account) => ({
          label: account.label,
          value: account.id,
        })),
        width: 160,
      },
      {
        field: 'isWaived',
        headerName: 'Is Waived',
        type: 'boolean',
        width: 160,
      },
      {
        field: 'canBeDeleted',
        headerName: 'Can Be Deleted',
        type: 'boolean',
        width: 160,
      },
      {
        field: 'balanceDueInCents',
        headerName: 'Balance Due',
        type: 'number',
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(params.value),
        valueGetter: (params) =>
          convertCentsToDollars((params.row as GQL.IFinancialFeeIndexItem).balanceDueInCents),
        width: 160,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    financialAccountsData,
    financialItemsData,
    schoolYearEnding,
  ]);
};
