// External Dependencies
import { darken } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Component Definition
const BillingDashboardCard = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      mode,
      prestoSecondary,
    },
  } = useTheme();

  const handlePressViewDistrictAssistants = useCallback(
    () =>
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.BILLING}`),
    [navigate],
  );

  const content = useMemo(() => (
    <>
      <ZeroStateIcon
        Icon={CreditCardIcon}
        htmlColor={mode === 'dark'
          ? prestoSecondary
          : darken(prestoSecondary, 0.15)}
      />
      <Box
        px={2}
        textAlign="center"
      >
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Manage billing details for {APP_NAME}
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handlePressViewDistrictAssistants}
        size="small"
        variant="outlined"
      >
        Manage billing details
      </Button>
    </>
  ), [mode, prestoSecondary, handlePressViewDistrictAssistants]);

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Billing"
      verticallyCenterContent
    />
  );
};

export default BillingDashboardCard;
