// External Dependencies
import { NavigateFn } from '@reach/router';

// Local Dependencies
import { PATHS } from 'utils/constants/routes';
import { RedirectUserOptions, redirectUser } from './redirect_user';

export const onboardingRedirectUser = (
  navigate: NavigateFn,
  selfId: string | null,
  currentOrgId: string | null,
  hasDistrict: boolean | null,
  onboardingStages: GQL.IOnboardingStage[],
  alertFlagId: number | null,
  pathname: string,
  options?: RedirectUserOptions,
) => {
  const nextOnboardingStage = onboardingStages.find(
    (stage: GQL.IOnboardingStage) => {
      const { isComplete } = stage;
      return !isComplete;
    },
  );

  const isViewingNextOnboardingStage = nextOnboardingStage
    && window.location.href.endsWith(nextOnboardingStage.name);

  const allowedPagesOutsideOnboarding = [
    `/${PATHS.PROFILE}`,
    `/${PATHS.RELATIONSHIPS}`,
    `/${PATHS.RELATIONSHIPS}/new`,
  ];

  const isAllowedPageOutsideOnboarding = allowedPagesOutsideOnboarding.some(
    (allowedPage) => pathname.includes(allowedPage),
  );

  if (alertFlagId && !isAllowedPageOutsideOnboarding) {
    navigate(`/${PATHS.RELATIONSHIPS}/new`);
  }

  if (!isViewingNextOnboardingStage && !isAllowedPageOutsideOnboarding) {
    redirectUser(
      navigate,
      selfId,
      currentOrgId,
      hasDistrict,
      alertFlagId,
      nextOnboardingStage,
      options,
    );
  }
};
