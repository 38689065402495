// External Dependencies
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedCard,
  Page,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import GroupTable from './GroupTable';

// Component Definition
const GroupsShow: FC = () => {
  const { id } = useParamsWithId();

  const groupsParams = useSelector(tableQueryParams('groups'));

  const [groupTitle, setGroupTitle] = useState('');

  return (
    <Page
      backButtonProps={{
        label: 'Groups',
        path: `/${PATHS.GROUPS}${groupsParams}`,
      }}
    >
      <Subtitle>
        {groupTitle}
      </Subtitle>

      <EnhancedCard>
        <GroupTable
          groupId={id}
          onUpdateTitle={setGroupTitle}
        />
      </EnhancedCard>
    </Page>
  );
};

export default GroupsShow;
