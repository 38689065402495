// External Dependencies
import { MutationEnhancedOptions, useMutationEnhanced } from 'utils/lib/graphql';
import { useQueryClient } from '@tanstack/react-query';
import gql from 'graphql-tag';

// Local Typings
interface RemoveSingleMemberFromGroupArguments {
  id: string; // group id
  selection: GQL.ISelection;
}
interface GroupResponseData {
  group: GQL.IGroup;
}

const ADD_MEMBER_TO_GROUP = gql`
  mutation AddMemberToGroup(
    $input: AddMemberToGroupInput!
  ) {
    addMemberToGroup(input: $input) {
      id
    }
  }
`;

// Local Variables
const groupClearCachePredicates = [
  'allMembers',
  'allDirectors',
  'group',
  'groupsIndex',
  'groupUsers',
  'peoplePickerIndex',
  'studentsIndex',
  'user',
];

export const CREATE_GROUP = gql`
  mutation CreateGroup(
    $input: CreateGroupInput!
  ) {
    createGroup(
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup(
    $id: ID!
  ) {
    deleteGroup(
      id: $id
    )
  }
`;

export const DELETE_GROUPS = gql`
  mutation DeleteGroups(
    $selection: Selection!
  ) {
    deleteGroups(
      selection: $selection
    )
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $id: ID!
    $input: UpdateGroupInput!
  ) {
    updateGroup(
      id: $id
      input: $input
    ) {
      id
      label
    }
  }
`;

export const REMOVE_MEMBERS_FROM_GROUP = gql`
  mutation RemoveMembersFromGroup(
    $id: ID!
    $selection: Selection!
  ) {
    removeMembersFromGroup(
      id: $id
      selection: $selection
    ) {
      id
      users {
        userList {
          id
        }
      }
    }
  }
`;

export const useCreateGroup = (
  options: MutationEnhancedOptions<
    GroupResponseData,
    GQL.ICreateGroupOnMutationArguments
  >,
) => useMutationEnhanced<
  GroupResponseData,
  GQL.ICreateGroupOnMutationArguments
>(
  CREATE_GROUP,
  {
    ...options,
    clearCachePredicates: [
      ...(options.clearCachePredicates ?? []),
      ...groupClearCachePredicates,
    ],
  },
);

export const useAddMemberToGroup = (
  options: MutationEnhancedOptions<
    GroupResponseData,
    GQL.IAddMemberToGroupOnMutationArguments
  >,
) => useMutationEnhanced<
  GroupResponseData,
  GQL.IAddMemberToGroupOnMutationArguments
>(
  ADD_MEMBER_TO_GROUP,
  {
    ...options,
    clearCachePredicates: [
      ...(options.clearCachePredicates ?? []),
      ...groupClearCachePredicates,
    ],
  },
);

export const useUpdateGroup = (
  options: MutationEnhancedOptions<
    GroupResponseData,
    GQL.IUpdateGroupOnMutationArguments
  >,
) => {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
    GroupResponseData,
    GQL.IUpdateGroupOnMutationArguments
  >(
    UPDATE_GROUP,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        ...groupClearCachePredicates,
      ],
      onCompleted: (data) => {
        queryClient.invalidateQueries(['formResponses']);

        options?.onCompleted?.(data);
      },
    },
  );
};

export const useRemoveMembersFromSingleGroup = (
  options: MutationEnhancedOptions<
    GroupResponseData,
    RemoveSingleMemberFromGroupArguments
  >,
) => useMutationEnhanced<
  GroupResponseData,
  RemoveSingleMemberFromGroupArguments
>(
  REMOVE_MEMBERS_FROM_GROUP,
  {
    ...options,
    clearCachePredicates: [
      ...(options.clearCachePredicates ?? []),
      ...groupClearCachePredicates,
    ],
  },
);
