// External Dependencies
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';

// Local Dependencies
import TableDataGrid, {
  TableDataGridProps,
  dataGridFilterKey,
  dataGridPaginationKey,
  dataGridSortKey,
} from '..';

// Local Typings
interface Props extends TableDataGridProps {
  fullCount: number;
}

interface ParsedDataGridQueryString {
  filters: GridFilterModel | null;
  pagination: GridPaginationModel | null;
  sort: GridSortModel | null;
}

interface UseParsedDataGridQueryStringOptions {
  mapFilterKeys?: Record<string, string>;
  mapSortKeys?: Record<string, string>;
}

export const useParsedDataGridQueryString = (
  queryString: string,
  options?: UseParsedDataGridQueryStringOptions,
): ParsedDataGridQueryString => {
  const params = new URLSearchParams(queryString);

  const filters = params.get(dataGridFilterKey);
  const pagination = params.get(dataGridPaginationKey);
  const sort = params.get(dataGridSortKey);

  return useMemo(() => {
    const parsedPagination: GridPaginationModel | null = pagination ? JSON.parse(pagination) : null;

    // our API is 1-based pagination
    if (parsedPagination) {
      parsedPagination.page += 1;
    }

    const parsedFilters: GridFilterModel | null = filters ? JSON.parse(filters) : null;
    let parsedSort: GridSortModel | null = sort ? JSON.parse(sort) : null;

    if (parsedFilters && options?.mapFilterKeys) {
      parsedFilters.items = parsedFilters.items.map((filter) => {
        const mappedKey = options.mapFilterKeys?.[filter.field];
        return {
          ...filter,
          field: mappedKey ?? filter.field,
        };
      });
    }

    if (parsedSort && options?.mapSortKeys) {
      parsedSort = parsedSort.map((sortItem) => {
        const mappedKey = options.mapSortKeys?.[sortItem.field];
        return {
          ...sortItem,
          field: mappedKey ?? sortItem.field,
        };
      });
    }

    return {
      filters: parsedFilters,
      pagination: parsedPagination,
      sort: parsedSort,
    };
  }, [
    filters,
    options?.mapFilterKeys,
    options?.mapSortKeys,
    pagination,
    sort,
  ]);
};

// Component Definition
const TableDataGridServerSide = ({
  fullCount,
  loading,
  rows,
  ...props
}: Props): JSX.Element => {
  const [data, setData] = useState<TableDataGridProps['rows']>(null);
  const [localFullCount, setLocalFullCount] = useState(fullCount);

  useEffect(() => {
    if (!loading) {
      setLocalFullCount(fullCount);
      setData(rows);
    }
  }, [fullCount, loading, rows]);

  return (
    <TableDataGrid
      {...props}
      hideExport
      loading={loading}
      rows={data}
      serverSide
      serverSideRowCount={localFullCount || 0}
      skipLocalDataFromIndexedDb
    />
  );
};

export default TableDataGridServerSide;
