// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateDistrictInventoryAudit } from 'utils/api/district';

// Local Dependencies
import AdminInventoryAuditForm from '../shared/AdminInventoryAuditForm';

// Component Definition
const AdminInventoryAuditsNew = (): JSX.Element => {
  const navigate = useNavigate();

  const inventoryAuditsParams = useSelector(tableQueryParams('inventoryAudits'));

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}/${id}`);
  }, [navigate]);

  const {
    mutate: createDistrictInventoryAudit,
  } = useCreateDistrictInventoryAudit();

  const handleSubmit = useCallback(async (values) => {
    await createDistrictInventoryAudit({
      body: {
        dueDate: values.dueDate,
        label: values.label,
      },
    }, {
      onSuccess: (data) => navigateToShow(data.data.data.id),
    });
  }, [createDistrictInventoryAudit, navigateToShow]);

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Audits',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}${inventoryAuditsParams}`,
      }}
    >
      <AdminInventoryAuditForm
        onSubmit={handleSubmit}
        title="New District Inventory Audit"
      />
    </Page>
  );
};

export default AdminInventoryAuditsNew;
