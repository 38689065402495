// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form, FormikErrors, FormikTouched } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  FormActions,
  StyledLink,
  Subtitle,
  TadaSvg,
} from 'components/shared';
import { EnhancedStyledLink, StyledStrong } from 'pages/EndOfYear/shared/styles';
import { PATHS } from 'utils/constants/routes';
import { getFullName, pluralize } from 'utils';
import { isMobileScreenSize } from 'state/device/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFees } from 'gql/queries';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import FinancialItemSelect from 'components/shared/Selectors/FinancialItemSelect';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';
import usePrevious from 'hooks/usePrevious';

// Local Typings
export type FeeFormValues = GQL.ICreateManyFinancialFeesInput & { schoolYearEnding: number };

interface Props {
  errors: FormikErrors<GQL.ICreateManyFinancialFeesInput>;
  formikValues: FeeFormValues;
  hideSelectMembers?: boolean;
  isSubmitting: boolean;
  onCancel?: () => void;
  onRemoveUserId: (userId: string) => () => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  rawUserIds: string[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<GQL.ICreateManyFinancialFeesInput>;
  userIds: CustomSetter[];
}

export interface CustomSetter {
  fieldName: string;
  fieldValue: any;
}

// Component Definition
const FeeFormFormikForm: FC<Props> = ({
  errors,
  formikValues,
  hideSelectMembers = false,
  isSubmitting,
  onCancel,
  onRemoveUserId,
  onSubmit,
  rawUserIds,
  setFieldValue,
  touched,
  userIds,
}) => {
  const navigate = useNavigate();

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [didDialogOpenWithDuplicateFees, setDidDialogOpenWithDuplicateFees] = useState(false);

  const handleClearUsersWithExistingFees = useCallback(() => {
    setIsAlertDialogOpen(false);
  }, []);

  const dispatch = useDispatch();

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const isMobileScreen = useSelector(isMobileScreenSize);
  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));

  const isFormTouched = Object.keys(touched).length > 0;

  const hasSelectedUserIds = rawUserIds.length > 0;

  const { financialItemId } = formikValues;

  const {
    getFees,
    results,
  } = useGetFees();

  const previousSchoolYearEnding = usePrevious(formikValues.schoolYearEnding);

  useEffect(() => {
    if (previousSchoolYearEnding && previousSchoolYearEnding !== formikValues.schoolYearEnding) {
      setFieldValue('financialItemId', '');
    }
  }, [formikValues.schoolYearEnding, previousSchoolYearEnding, setFieldValue]);

  useEffect(() => {
    if (financialItemId) {
      getFees({
        variables: {
          where: {
            financialItemId,
          },
        },
      });
    }
  }, [financialItemId, getFees]);

  useEffect(() => {
    // We use a custom setter to add the userIds to the formik values
    userIds.forEach(({ fieldName, fieldValue }) => {
      setFieldValue(fieldName, fieldValue);
    });
  }, [setFieldValue, userIds]);

  const handlePressSelectMembers = useCallback(() => {
    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  const handlePressCancelOrBackButton = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(`/${PATHS.FINANCIAL_FEES}${financialFeesParams}`);
    }
  }, [financialFeesParams, navigate, onCancel]);

  const usersWithExistingFee = useMemo(() => rawUserIds.map((userId) => {
    const existingFee = results.data?.financialFees.data
      .find((item) => item.user.id === userId);

    return existingFee?.user || null;
  }).filter(Boolean) as GQL.IUser[], [rawUserIds, results.data]);

  const handleSubmit: Props['onSubmit'] = useCallback((e) => {
    e?.preventDefault();

    setDidDialogOpenWithDuplicateFees(Boolean(usersWithExistingFee.length));
    setIsAlertDialogOpen(true);
  }, [usersWithExistingFee.length]);

  const dialogDescription = useMemo(() => {
    if (!didDialogOpenWithDuplicateFees) {
      return '';
    }

    if (usersWithExistingFee.length > 0) {
      return (
        <>
          {/* eslint-disable-next-line max-len */}
          Whoa! These users are about to be assigned this fee again. Do they need to pay for this item more than once?

          <Paper
            sx={{ m: 1 }}
            variant="outlined"
          >
            <List dense>
              {usersWithExistingFee.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText
                    primary={getFullName(user)}
                    secondary={user.email}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      disabled={isSubmitting}
                      onClick={onRemoveUserId(user.id)}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </>
      );
    }

    if (rawUserIds.length === 0) {
      return 'There are no users selected.';
    }

    return (
      <div> {/* This div prevents an odd layout reflow of the svg */}
        That was a close call! Ready to submit?

        <Box
          display="flex"
          justifyContent="center"
          p={2}
        >
          <TadaSvg width={120} />
        </Box>
      </div>
    );
  }, [
    didDialogOpenWithDuplicateFees,
    isSubmitting,
    onRemoveUserId,
    rawUserIds.length,
    usersWithExistingFee,
  ]);

  const addingFeesText = useMemo(() => (
    <Typography component="span">
      Adding fees for{' '}
      <StyledStrong>
        {rawUserIds.length}
      </StyledStrong>{' '}
      {pluralize(rawUserIds.length, 'member')}.
    </Typography>
  ), [rawUserIds]);

  const dialogTitleText = useMemo(() => {
    if (!didDialogOpenWithDuplicateFees) {
      return `Ready to add ${pluralize(rawUserIds.length, 'fee')}?`;
    }

    return usersWithExistingFee.length
      ? 'Pre-Assigned Fees Found!'
      : 'No Pre-Assigned Fees Found!';
  }, [didDialogOpenWithDuplicateFees, rawUserIds.length, usersWithExistingFee]);

  const handleClickAssignUncategorizedFee = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_FEES_UNCATEGORIZED_NEW}`);
  }, [navigate]);

  return (
    <Form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Subtitle>Select a school year</Subtitle>

        <EnhancedCard>
          <CardContent>
            <SchoolYearSelect
              fullWidth={false}
              name="schoolYearEnding"
              required
              variant="filled"
            />
          </CardContent>
        </EnhancedCard>
      </Box>

      <Box mb={2}>
        <Subtitle>Select a financial item</Subtitle>

        <EnhancedCard>
          <CardContent>
            {/* The 416 width is how long our 50-character max will be */}
            <Box
              marginBottom={1}
              maxWidth="416px"
            >
              <FinancialItemSelect
                name="financialItemId"
                schoolYearEnding={Number(formikValues.schoolYearEnding)}
                variant="filled"
              />
            </Box>

            <Typography
              color="textSecondary"
              variant="body2"
            >
              Creating a one-off fee without an item?
              {' '}
              <StyledLink onClick={handleClickAssignUncategorizedFee}>
                Assign an uncategorized fee
              </StyledLink>
            </Typography>
          </CardContent>
        </EnhancedCard>
      </Box>

      {!hideSelectMembers && (
        <Box mb={2}>
          <Subtitle>Select members</Subtitle>

          <EnhancedCard>
            <CardContent>
              <Collapse in={hasSelectedUserIds}>
                {addingFeesText}

                <Box
                  mb={1}
                  mt={3}
                >
                  <EnhancedStyledLink onClick={handlePressSelectMembers}>
                    View/Update Members
                  </EnhancedStyledLink>
                </Box>
              </Collapse>

              <Collapse in={!hasSelectedUserIds}>
                <Box
                  display="flex"
                  flexDirection={isMobileScreen ? 'column' : 'row'}
                  justifyContent="space-between"
                >
                  <Typography>Assign fees to organization members</Typography>

                  <Box mt={isMobileScreen ? 2 : 0}>
                    <Button
                      color="primary"
                      onClick={handlePressSelectMembers}
                      size="small"
                      variant="outlined"
                    >
                      Select Members
                    </Button>
                  </Box>
                </Box>
              </Collapse>

              <Collapse in={Boolean(touched.userIds) && Boolean(errors.userIds)}>
                <Box mt={2}>
                  <EnhancedAlert severity="error">
                    {errors.userIds}
                  </EnhancedAlert>
                </Box>
              </Collapse>
            </CardContent>
          </EnhancedCard>
        </Box>
      )}

      <ConfirmationDialog
        confirmButtonAction={onSubmit}
        confirmButtonText="Yes, Add fee"
        declineButtonAction={handleClearUsersWithExistingFees}
        description={(
          <>
            {dialogDescription}

            <Box
              component="span"
              mt={dialogDescription ? 4 : 0}
            >
              {addingFeesText}
            </Box>
          </>
        )}
        handleClose={handleClearUsersWithExistingFees}
        isConfirmButtonDisabled={rawUserIds.length === 0}
        isSubmitting={isSubmitting}
        open={isAlertDialogOpen}
        title={dialogTitleText}
      />

      <FormActions
        context="Fee"
        isButtonDisabled={results.loading}
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={handlePressCancelOrBackButton}
      />
    </Form>
  );
};

export default FeeFormFormikForm;
