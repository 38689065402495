// External Dependencies
import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { updateFileInputSchema } from '@presto-assistant/api_types/schemas/file';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import {
  EnhancedCard,
  FormActionButtons,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getSafeISOString } from 'utils/lib/getSafeISOString';
import { useUpdateFile } from 'gql/mutations';

// Local Dependencies
import FileDetailsForm, { FormValues } from './FileDetailsForm';

// Local Typings
interface Props {
  directory: string;
  file?: GQL.IFile;
  id: string;
}

// Component Definition
const FileEditForm: FC<Props> = ({
  directory,
  file,
  id,
}) => {
  const navigate = useNavigate();

  const navigateToShow = useCallback(() => {
    navigate(`/${PATHS.FILES}/${directory}/${id}`);
  }, [directory, id, navigate]);

  const [
    updateFile,
    {
      loading,
    },
  ] = useUpdateFile(navigateToShow);

  const handleSubmit = (values: FormValues) => {
    const year = Number(values.schoolYearEnding);

    const schoolYearEnding = Number.isNaN(year) ? null : year || null;

    updateFile({
      variables: {
        id: id!,
        input: {
          ...values,
          assignments: values.isPublic ? [] : values.assignments,
          endDate: values.endDate ? getSafeISOString(values.endDate) : null,
          schoolYearEnding,
          startDate: values.startDate ? getSafeISOString(values.startDate) : null,

        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={{
        assignments: file?.assignments
          ? file.assignments.map((assignment) => ({
            groupId: assignment.group?.id ?? '',
            organizationRoleId: assignment.organizationRole?.id ?? '',
          }))
          : null,
        directory: file?.directory ?? '',
        endDate: file?.endDate ? new Date(file.endDate).toISOString().split('T')[0] : null,
        fileName: file?.fileName ?? '',
        isPublic: file?.isPublic ?? false,
        schoolYearEnding: file?.schoolYearEnding ?? 0,
        startDate: file?.startDate ? new Date(file.startDate).toISOString().split('T')[0] : null,
      }}
      onSubmit={handleSubmit}
      validationSchema={updateFileInputSchema}
    >
      {({ values }) => (
        <Form>
          <FileDetailsForm
            assignments={values.assignments}
            fileId={id}
            isPublic={values.isPublic}
            maxDate={getSafeISOString(values.endDate) ?? null}
            minDate={getSafeISOString(values.startDate) ?? null}
          />

          <EnhancedCard
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
              px: 2,
            }}
          >
            <FormActionButtons
              context="File"
              isEditing
              isSubmitting={loading}
              onClickCancelButton={navigateToShow}
            />
          </EnhancedCard>
        </Form>
      )}
    </Formik>
  );
};

export default FileEditForm;
