// External Dependencies
import { FC, useCallback } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { navigateSearch } from 'utils/lib/navigate_search';
import { useColumns } from 'pages/Finances/FinancialCredits/All/FinancialCreditsTable/hooks';
import { useGetMyFinancialCreditsIndex } from 'gql/queries';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useMyFilesSelfData } from 'hooks/useMyFilesSelfData';
import { useParsedSearch } from 'hooks/useParsedSearch';
import ChildSelect from 'components/shared/Selectors/ChildSelect';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Variables
const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

// Component Definition
const MyFinancialCreditsTable: FC = () => {
  const parsedSearch = useParsedSearch();

  const {
    data,
    loading,
  } = useGetMyFinancialCreditsIndex();

  const columns = useColumns();

  const selfData = useMyFilesSelfData();

  // Parent user needs to check for relationships in this org
  const hasRelationships = useHasRelationships();

  const handleChangeUserId = useCallback((userId: string) => {
    navigateSearch({
      ...parsedSearch,
      page: 1,
      userId,
    });
  }, [parsedSearch]);

  let selectElement;

  // If parent with relationships, show the select
  //  and default to the parent's MyFinancialCredits table
  if (!selfData?.isStudent && hasRelationships) {
    selectElement = (
      <EnhancedCard sx={{ mb: 2 }}>
        <CardContent>
          <SectionSubtitle
            component="h2"
            variant="subtitle1"
          >
            Select a person
          </SectionSubtitle>

          <ChildSelect
            handleChangeUserId={handleChangeUserId}
            userId={parsedSearch.userId ?? selfData?.selfId}
          />
        </CardContent>
      </EnhancedCard>
    );
  }

  return (
    <>
      {selectElement}

      <DataGridContainer>
        <TableDataGrid
          addButtonProps={null}
          columns={columns}
          loading={loading}
          rows={data?.myFinancialCreditsIndex.data ?? []}
          tableResource="myFinancialCredits"
          withSearch={false}
        />
      </DataGridContainer>
    </>
  );
};

export default MyFinancialCreditsTable;
