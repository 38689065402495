// External Dependencies
import {
  CardContent,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, TableV2 } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { navigateSearch } from 'utils/lib/navigate_search';
import { open } from 'state/ui/paymentAlertDialog/actions';
import { parseSearch } from 'utils';
import { useCanMakePayments, useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';
import {
  useGetMyFinancialFeesAll,
  useGetMyFinancialPaymentsIndex,
  useGetOrganization,
} from 'gql/queries';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useMyFilesSelfData } from 'hooks/useMyFilesSelfData';
import ChildSelect from 'components/shared/Selectors/ChildSelect';
import DialogPaymentAlert from 'components/shared/DialogPaymentAlert';
import VancoBillingAlert from 'pages/Finances/MyFinancialPayments/shared/VancoBillingAlert';

// Local Dependencies
import StripeBillingAlert from '../../shared/StripeBillingAlert';

// Local Dependencies
import { useColumns } from './hooks';
import Filters from './Filters';

// Local Variables
const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

// Component Definition
const MyFinancialPaymentsTable: FC = () => {
  const dispatch = useDispatch();

  const {
    data,
    loading,
  } = useGetMyFinancialPaymentsIndex();

  const { data: organizationData } = useGetOrganization();

  const { data: myFeesAllData, loading: isLoadingFees } = useGetMyFinancialFeesAll();

  const canMakePayments = useCanMakePayments();
  const isOnlinePaymentsFeatureAvailable = useIsOnlinePaymentsFeatureAvailable();

  const { search } = useLocation();

  const parsedSearch = useMemo(() => parseSearch(search), [search]);

  const columns = useColumns();

  const selfData = useMyFilesSelfData();
  const isStudent = selfData?.isStudent;

  const hasRelationships = useHasRelationships();

  const handleChangeUserId = useCallback((userId: string) => {
    navigateSearch({
      ...parsedSearch,
      page: 1,
      userId,
    });
  }, [parsedSearch]);

  const hasFeesToPayOnline = useMemo(() => {
    if (isLoadingFees) {
      return false;
    }

    return myFeesAllData?.myFinancialFeesAll
      .some((fee) => fee.onlinePaymentsEnabled && fee.balanceDueInCents > 0);
  }, [isLoadingFees, myFeesAllData]);

  const shouldSeePaymentAlertDialog = isStudent || !hasFeesToPayOnline;

  const handleOpenPaymentAlertDialog = () => {
    dispatch(open(!hasFeesToPayOnline));
  };

  let selectElement;

  const isStripe = organizationData?.organization.hasStripeId;

  // If parent with relationships, show the select
  //  and default to the parent's MyFinancialPayments table
  if (!isStudent && hasRelationships) {
    selectElement = (
      <EnhancedCard sx={{ mb: 2 }}>
        <CardContent>
          <SectionSubtitle
            component="h2"
            variant="subtitle1"
          >
            Select a person
          </SectionSubtitle>

          <ChildSelect
            handleChangeUserId={handleChangeUserId}
            userId={parsedSearch.userId ?? selfData?.selfId}
          />
        </CardContent>
      </EnhancedCard>
    );
  }

  return (
    <>
      {selectElement}

      {isOnlinePaymentsFeatureAvailable && (
        isStripe ? <StripeBillingAlert /> : <VancoBillingAlert />
      )}

      <TableV2<GQL.IFinancialPaymentIndexItem>
        Filters={<Filters />}
        addButtonProps={canMakePayments ? {
          label: 'Payment',
          onClick: shouldSeePaymentAlertDialog
            ? handleOpenPaymentAlertDialog
            : undefined,
          to: shouldSeePaymentAlertDialog
            ? ''
            : `/${PATHS.STUDENT_PAYMENTS_NEW}`,
        } : undefined}
        columns={columns}
        data={data?.myFinancialPaymentsIndex.data ?? []}
        fullCount={data?.myFinancialPaymentsIndex.fullCount}
        isLoading={loading}
        paperProps={{ variant: 'outlined' }}
        reduxStateKey="myFinancialPayments"
        withSearch
        zeroStateMessage="Any payments you make will show here."
      />
      <DialogPaymentAlert />
    </>
  );
};

export default MyFinancialPaymentsTable;
