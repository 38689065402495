// External Dependencies
import {
  CreateDistrictInventoryAuditRequest,
} from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useGetAllDirectors = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.district.organizationDirectors();
  }, []);

  return useQuery({
    queryFn,
  });
};

export const useGetDistrictEntitiesByAdminId = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.district.entities();
  }, []);

  return useQuery({
    queryFn,
    queryKey: ['entities'],
  });
};

export const useGetDistrictInventoryAudits = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.districtInventoryAuditClient.listDistrictInventoryAudits();
  }, []);

  return useQuery({
    queryFn,
    queryKey: ['inventoryAudits'],
  });
};

export const useCreateDistrictInventoryAudit = () => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((request: Pick<CreateDistrictInventoryAuditRequest, 'body'>) => {
    return apiClient.v1.districtInventoryAuditClient.createDistrictInventoryAudit(request);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['inventoryAudits']);
    },
  });
};

export const useGetDistrictInventoryAudit = (id: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.districtInventoryAuditClient.getDistrictInventoryAudit({
        params: { id },
      });
    },
    queryKey: ['inventoryAudit', id],
  });
};
