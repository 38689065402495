// External Dependencies
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Local Dependencies
import { PaymentsByUserFormValues } from './PaymentFormByUserForm';
import PaymentsByUserTable from './PaymentsByUserTable';

// Local Typings
interface Props {
  formValues: PaymentsByUserFormValues;
  userId: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  '.sectionSubtitle': {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

// Component Definition
const PaymentFormFees = ({
  formValues,
  userId,
}: Props): JSX.Element => {
  // We will compare the total amount against the running total
  const [amountError, setAmountError] = useState('');

  // We alert the director to make sure that the fee amounts are equal to the total
  useEffect(() => {
    // Formik's `values.amount` is a string — we need a number for comparisons
    const totalAmountNumber = Number(formValues.amount);
    const runningTotal = formValues.payments
      .reduce((prev, curr) => prev + Number(curr.amountInCents), 0);

    const hasAmounts = totalAmountNumber > 0 || runningTotal > 0;

    if (hasAmounts && runningTotal > totalAmountNumber) {
      setAmountError('Fee amounts shouldn\'t be more than the member\'s payment');
    } else if (hasAmounts && runningTotal < totalAmountNumber) {
      setAmountError('Fee amounts shouldn\'t be less than the member\'s payment');
    } else {
      setAmountError('');
    }
  }, [formValues]);

  const subtitleText = `Apply this payment to fee${formValues.payments.length > 1 ? 's' : ''}`;

  return (
    <Collapse
      in={Boolean(userId)}
      mountOnEnter
      timeout="auto"
    >
      <Divider sx={{ marginX: 3, marginY: 0.5 }} />

      <StyledCardContent>
        <Typography
          className="sectionSubtitle"
          component="h2"
          variant="subtitle1"
        >
          {subtitleText}
        </Typography>

        <PaymentsByUserTable
          formValues={formValues}
          userId={userId}
        />
      </StyledCardContent>

      <Collapse in={Boolean(amountError)}>
        <Box
          marginX={6}
          marginY={1}
        >
          <EnhancedAlert severity="error">
            {amountError}
          </EnhancedAlert>
        </Box>
      </Collapse>
    </Collapse>
  );
};

export default PaymentFormFees;
