// External Dependencies
import { FC, useCallback } from 'react';
import { alpha } from '@mui/material/styles';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SYSTEM_ITEM_NUMBER_PREFIX } from 'utils/constants/inventory';
import { hasPermission } from 'state/self/selectors';

// Local Typings
interface Props {
  uniformCheckoutData: GQL.IUniformCheckout;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardHeader-root': {
    paddingBottom: 0,
  },

  '.MuiCardHeader-title': {
    fontSize: '1.1rem',
    paddingBottom: 0,
  },

  backgroundColor: alpha(theme.palette.dashboardOrgName, 0.3),
  margin: theme.spacing(0, 1, 1.5),
  width: '45%',
}));

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const UniformDataCard: FC<Props> = ({
  uniformCheckoutData,
}) => {
  const navigate = useNavigate();
  const canEditUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'edit'));

  const {
    checkoutDate,
    dueDate,
    id: uniformCheckoutId,
    uniform: {
      customBarcode,
      size,
      systemBarcode,
      uniformNumber,
    },
  } = uniformCheckoutData;

  const handleClickEditIconButton = useCallback(() => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/${uniformCheckoutId}/edit`);
  }, [navigate, uniformCheckoutId]);

  const action = uniformCheckoutId && canEditUniformCheckouts && (
    <IconButton
      aria-label="Edit"
      onClick={handleClickEditIconButton}
      size="large"
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  return (
    <StyledEnhancedCard>
      <CardHeader
        action={action}
        title="Uniform part"
      />
      <CardContent>
        <ShowPageDataDisplay
          label="Category"
          value={uniformCheckoutData.uniform.category?.label}
        />
        <ShowPageDataDisplay
          label="Uniform Number"
          value={uniformNumber}
        />
        <ShowPageDataDisplay
          label="Size"
          value={size}
        />
        <ShowPageDataDisplay
          label="Barcode"
          value={customBarcode ?? `${SYSTEM_ITEM_NUMBER_PREFIX}-${systemBarcode}`}
        />
        <ShowPageDataDisplay
          label="Checkout Date"
          value={checkoutDate}
        />
        <ShowPageDataDisplay
          label="Due Date"
          value={dueDate}
        />
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default UniformDataCard;
