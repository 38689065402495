// External Dependencies
import { FC } from 'react';
import {
  createDistrictAssistantSchema,
} from '@presto-assistant/api_types/schemas/districtAssistant';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateDistrictAssistant } from 'gql/mutations';

// Local Dependencies
import DistrictAssistantForm from './DistrictAssistantForm';

// Component Definition
const AdminAssistantsNew: FC = () => {
  const navigate = useNavigate();
  const districtAssistantsParams = useSelector(tableQueryParams('districtAssistants'));

  const districtAssistantsTablePath = `/${PATHS.DISTRICT_ADMIN}/${PATHS.DISTRICT_ASSISTANTS}?${districtAssistantsParams}`;

  const [createDistrictAssistant, { error, loading }] = useCreateDistrictAssistant(
    {
      clearCachePredicates: ['districtAssistants'],
      onCompleted: () => navigate(districtAssistantsTablePath),
    },
  );

  const handleSubmit = async (values: GQL.ICreateDistrictAssistantInput) => {
    await createDistrictAssistant({
      variables: {
        input: trimValues(values) as GQL.ICreateDistrictAssistantInput,
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'District Assistant',
        path: districtAssistantsTablePath,
      }}
    >
      <DistrictAssistantForm
        error={error?.message}
        isLoading={loading}
        onSubmit={handleSubmit}
        validationSchema={createDistrictAssistantSchema}
      />
    </Page>
  );
};

export default AdminAssistantsNew;
