// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
export interface SchoolYearFormValues {
  schoolYearEnding: number;
}

interface Props {
  getPathOnFilterChange: (schoolYearEnding: number) => string;
  previousYearsOnly?: boolean;
  schoolYearFormValues: SchoolYearFormValues;
}

// Component Definition
const SchoolYearForm: FC<Props> = ({
  getPathOnFilterChange,
  previousYearsOnly,
  schoolYearFormValues,
}) => {
  const navigate = useNavigate();

  const urlFilterPath = useMemo(() => {
    return getPathOnFilterChange(schoolYearFormValues.schoolYearEnding);
  }, [getPathOnFilterChange, schoolYearFormValues.schoolYearEnding]);

  useEffect(() => {
    navigate(urlFilterPath);
  }, [navigate, urlFilterPath]);

  return (
    <Form>
      <SchoolYearSelect
        previousYearsOnly={previousYearsOnly}
        required
      />
    </Form>
  );
};

export default SchoolYearForm;
