// External Dependencies
import {
  FC, useCallback, useMemo,
} from 'react';
import { Form, FormikErrors, FormikTouched } from 'formik';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  CustomInput,
  FormActions,
  MoneyInput,
  PriceChangeSvg,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { displayPriceStringFromDollarAmount, getFullName } from 'utils';
import { isDirector as isDirectorSelector } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useFormikTextField } from 'hooks/useFormikTextField';
import { useGetFamilyMembersOnly, useGetSimpleUser } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import FamilyMemberSelect from 'components/shared/FamilyMemberSelect';
import RelationshipCreateForm from 'pages/People/shared/RelationshipCreateForm';

// Local Typings
interface Props {
  financialCredit: GQL.IFinancialCredit;
  formikValues: GQL.ICreateFinancialCreditTransferInput;
  isSubmitting: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  touched: FormikTouched<GQL.ICreateFinancialCreditInput>;
  validateForm: () => Promise<FormikErrors<GQL.ICreateFinancialCreditTransferInput>>
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));
const StyledStrong = styled.strong({ fontSize: '1.25rem' });

const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Local Components
const PrinceChangeIcon = () => <PriceChangeSvg />;

// Component Definition
const CreditTransferFormFormikForm: FC<Props> = ({
  financialCredit,
  formikValues,
  isSubmitting,
  onSubmit,
  touched,
  validateForm,
}) => {
  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const isDirector = useSelector(isDirectorSelector);
  const navigate = useNavigate();

  const {
    isOpen: isAddCreditConfirmationDialogOpen,
    toggleIsOpen: toggleAddCreditConfirmationDialog,
  } = useIsOpen();

  // We use this data to display the user's name in the confirmation dialog
  const { data: transferFromUserData } = useGetSimpleUser(financialCredit.user.id);
  const { data: transferToUserData } = useGetSimpleUser(formikValues.userId);

  const transferFromName = useMemo(() => getFullName({
    firstName: transferFromUserData?.user?.firstName,
    lastName: transferFromUserData?.user?.lastName,
    middleName: transferFromUserData?.user?.middleName,
  }), [transferFromUserData]);
  const transferToName = useMemo(() => getFullName({
    firstName: transferToUserData?.user?.firstName,
    lastName: transferToUserData?.user?.lastName,
    middleName: transferToUserData?.user?.middleName,
  }), [transferToUserData]);

  const isFormTouched = Object.keys(touched).length > 0;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`);
  }, [financialCreditsParams, navigate]);

  const amountField = useFormikTextField('amountInCents', formikValues.amountInCents.toLocaleString());

  const handleSubmitCreditForm: Props['onSubmit'] = useCallback(async (event) => {
    event?.preventDefault();
    // Manually check for errors before opening the confirmation dialog
    // The value returned from validateForm is the new errors object
    const updatedErrors = await validateForm();

    // We short-circuit if the form has errors after we manually ran validation
    if (Object.values(updatedErrors).length > 0) {
      // Calling `onSubmit` will set all fields
      //  as "touched" so errors will show
      return onSubmit();
    }

    // If we have no errors, open the confirmation dialog
    return toggleAddCreditConfirmationDialog();
  }, [onSubmit, toggleAddCreditConfirmationDialog, validateForm]);

  const {
    familyMembers,
    isLoading: isFamilyMemberDataLoading,
  } = useGetFamilyMembersOnly(financialCredit.user.id);

  if (isFamilyMemberDataLoading) {
    return (
      <ShowCard
        icon={PrinceChangeIcon}
        title="Transfer Credit"
      >
        <CircularProgress />
      </ShowCard>
    );
  }

  if (familyMembers?.length === 0) {
    return (
      <ShowCard
        icon={PrinceChangeIcon}
        title="Transfer Credit"
      >
        <StyledTypography
          color="textSecondary"
          paragraph
        >
          {transferFromName} does not have any family members.
        </StyledTypography>

        {isDirector && (
          <RelationshipCreateForm
            memberRoleId={financialCredit.user.role.id}
            userId={financialCredit.user.id}
          />
        )}
      </ShowCard>
    );
  }

  return (
    <Form onSubmit={handleSubmitCreditForm}>
      <Box mb={2}>
        <ShowCard
          icon={PrinceChangeIcon}
          title="Transfer Credit"
        >
          <StyledTypography
            color="textSecondary"
            paragraph
          >
            Select a family member of {transferFromName} to be a recipient of this credit transfer.
            Please provide a note indicating why this credit transfer is being added.
          </StyledTypography>

          <Box mb={1}>
            <FamilyMemberSelect
              label="Family Member *"
              memberId={financialCredit.user.id}
              name="userId"
            />
          </Box>

          <Box mb={1}>
            <TextField
              InputProps={{
                inputComponent: MoneyInput as any,
                inputProps: {
                  'aria-label': 'Amount',
                },
                startAdornment,
              }}
              label="Amount *"
              variant="filled"
              {...amountField}
            />
          </Box>

          <CustomInput
            label="Note *"
            maxRows={5}
            multiline
            name="note"
            rows={2}
            variant="filled"
          />
        </ShowCard>
      </Box>

      <ConfirmationDialog
        confirmButtonAction={onSubmit}
        confirmButtonText="Yes, add transfer"
        declineButtonAction={toggleAddCreditConfirmationDialog}
        description={(
          <span>The credit amount of{' '}
            <StyledStrong>
              {displayPriceStringFromDollarAmount(formikValues.amountInCents)}
            </StyledStrong>{' '}
            will be transferred from
            {' '}
            <strong>{transferFromName}</strong>
            {' '}to{' '}
            <strong>{transferToName}</strong>.
          </span>
        )}
        handleClose={toggleAddCreditConfirmationDialog}
        isSubmitting={isSubmitting}
        open={isAddCreditConfirmationDialogOpen}
        title="Add Transfer?"
      />

      <FormActions
        context="Transfer"
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={handlePressCancelOrBackButton}
      />
    </Form>
  );
};

export default CreditTransferFormFormikForm;
