// External Dependencies
import { useEffect, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetFormAsDistrictAdmin } from 'gql/queries/form-queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import DetailsCard from 'pages/Forms/components/DetailsCard';

// Component Definition
const AdminFormShow = (): JSX.Element => {
  const navigate = useNavigate();

  const { id } = useParamsWithId();

  const {
    data: formData,
  } = useGetFormAsDistrictAdmin(id);

  const form = useMemo(() => {
    return formData?.form;
  }, [formData]);

  useEffect(() => {
    if (form && !form.publishedAt) {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}/${form.id}/edit`, { replace: true });
    }
  }, [form, navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'District Forms',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}`,
      }}
    >
      {form && (
        <Container maxWidth="md">
          <Box marginTop={2}>
            <DetailsCard
              form={form}
              isEditMode={false}
            />
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default AdminFormShow;
