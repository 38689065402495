// Internal Dependencies
import { AuthRouteProps } from 'components/shared/Auth';
import { PATHS } from 'utils/constants/routes';
import AdminAdultsWithPermissionsTable from 'pages/DistrictAdmin/AdminOrganization/AdminAdultsWithPermissionsTable/All';
import AdminAssistantsNew from 'pages/DistrictAdmin/AdminAssistants/New';
import AdminAssistantsTable from 'pages/DistrictAdmin/AdminAssistants/All/AdminAssistantsTable';
import AdminBilling from 'pages/DistrictAdmin/AdminBilling';
import AdminCreateOrganization from 'pages/DistrictAdmin/AdminCreateOrganization';
import AdminDirectorPermissions from 'pages/DistrictAdmin/AdminDirectorPermissions/All';
import AdminEditDistrictInfo from 'pages/DistrictAdmin/AdminSettings/AdminEditDistrictInfo';
import AdminFormShow from 'pages/DistrictAdmin/AdminForms/Show';
import AdminForms from 'pages/DistrictAdmin/AdminForms/All';
import AdminFormsEdit from 'pages/DistrictAdmin/AdminForms/Edit';
import AdminFormsNew from 'pages/DistrictAdmin/AdminForms/New';
import AdminInventoryAuditNotFoundItems from 'pages/DistrictAdmin/AdminInventoryAudits/Show/NotFoundItems';
import AdminLibrary from 'pages/DistrictAdmin/AdminLibrary/All';
import AdminLibraryEdit from 'pages/DistrictAdmin/AdminLibrary/Edit';
import AdminLibraryNew from 'pages/DistrictAdmin/AdminLibrary/New';
import AdminLibraryShow from 'pages/DistrictAdmin/AdminLibrary/Show';
import AdminLicenses from 'pages/DistrictAdmin/AdminLicenses/All';
import AdminOrganizationsClaim from 'pages/DistrictAdmin/AdminOrganizationsClaim';
import AdminProfileEdit from 'pages/DistrictAdmin/AdminProfileEdit';
import AdminUniforms from 'pages/DistrictAdmin/AdminUniforms/All';
import AdminUniformsEdit from 'pages/DistrictAdmin/AdminUniforms/Edit';
import AdminUniformsNew from 'pages/DistrictAdmin/AdminUniforms/New';
import AdminUniformsShow from 'pages/DistrictAdmin/AdminUniforms/Show';
import DistrictAdminDashboard from 'pages/DistrictAdmin/AdminDashboard';
import DistrictAdminDeletedInventory from 'pages/DistrictAdmin/AdminDeletedInventory/All';
import DistrictAdminDeletedInventoryShow from 'pages/DistrictAdmin/AdminDeletedInventory/Show';
import DistrictAdminDistrictSettings from 'pages/DistrictAdmin/AdminSettings/DistrictSettings';
import DistrictAdminDynamicFields from 'pages/DistrictAdmin/AdminDynamicFields/All';
import DistrictAdminDynamicFieldsEdit from 'pages/DistrictAdmin/AdminDynamicFields/Edit';
import DistrictAdminDynamicFieldsNew from 'pages/DistrictAdmin/AdminDynamicFields/New';
import DistrictAdminEditDirectorPermissions from 'pages/DistrictAdmin/AdminOrganization/shared/DistrictAdminEditPermissions';
import DistrictAdminEditParentPermissions from 'pages/DistrictAdmin/AdminOrganization/shared/DistrictAdminEditParentPermissions';
import DistrictAdminInventory from 'pages/DistrictAdmin/AdminInventory/All';
import DistrictAdminInventoryAuditsAll from 'pages/DistrictAdmin/AdminInventoryAudits/All';
import DistrictAdminInventoryAuditsNew from 'pages/DistrictAdmin/AdminInventoryAudits/New';
import DistrictAdminInventoryAuditsShow from 'pages/DistrictAdmin/AdminInventoryAudits/Show';
import DistrictAdminInventoryEdit from 'pages/DistrictAdmin/AdminInventory/Edit';
import DistrictAdminInventoryNew from 'pages/DistrictAdmin/AdminInventory/New';
import DistrictAdminInventorySettings from 'pages/DistrictAdmin/AdminInventory/Settings';
import DistrictAdminInventoryShow from 'pages/DistrictAdmin/AdminInventory/Show';
import DistrictAdminInvitations from 'pages/DistrictAdmin/AdminInvitations';
import DistrictAdminOrganization from 'pages/DistrictAdmin/AdminOrganization';
import DistrictAdminOrganizations from 'pages/DistrictAdmin/AdminOrganizations';
import DistrictAdminReports from 'pages/DistrictAdmin/AdminReports/All';
import DistrictAdminReportsNew from 'pages/DistrictAdmin/AdminReports/New';
import DistrictAdminSettings from 'pages/DistrictAdmin/AdminSettings';
import Feedback from 'pages/Feedback';
import JoinNewOrganization from 'pages/JoinNewOrganization';
import JoinNewOrganizationSuccess from 'pages/JoinNewOrganization/Success';
import Profile from 'pages/Profile';

// This isn't really a hook, but it could likely become one as we add more feature flags
export const useGetDistrictAdminRoutes = (): AuthRouteProps[] => [
  {
    component: DistrictAdminDashboard,
    path: PATHS.DASHBOARD,
  },
  {
    component: DistrictAdminDynamicFields,
    path: PATHS.DYNAMIC_FIELDS,
  },
  {
    component: DistrictAdminDynamicFieldsEdit,
    path: `${PATHS.DYNAMIC_FIELDS}/:id/edit`,
  },
  {
    component: DistrictAdminDynamicFieldsNew,
    path: `${PATHS.DYNAMIC_FIELDS}/new`,
  },
  // ** DISTRICT ASSISTANTS **
  {
    component: AdminAssistantsTable,
    path: PATHS.DISTRICT_ASSISTANTS,
  },
  {
    component: AdminAssistantsNew,
    path: `${PATHS.DISTRICT_ASSISTANTS}/new`,
  },
  // ** INVENTORY **
  {
    component: DistrictAdminInventory,
    path: PATHS.INVENTORY,
  },
  {
    component: DistrictAdminDeletedInventory,
    path: PATHS.INVENTORY_DELETED,
  },
  {
    component: DistrictAdminDeletedInventoryShow,
    path: `${PATHS.INVENTORY_DELETED}/:id`,
  },
  {
    component: DistrictAdminInventoryNew,
    path: `${PATHS.INVENTORY_NEW}`,
  },
  {
    component: DistrictAdminInventorySettings,
    path: `${PATHS.INVENTORY_SETTINGS}`,
  },
  {
    component: DistrictAdminInventoryShow,
    path: `${PATHS.INVENTORY}/:id`,
  },
  {
    component: DistrictAdminInventoryEdit,
    path: `${PATHS.INVENTORY}/:id/edit`,
  },
  {
    component: DistrictAdminInventoryAuditsAll,
    path: PATHS.INVENTORY_AUDITS,
  },
  {
    component: DistrictAdminInventoryAuditsNew,
    path: PATHS.INVENTORY_AUDITS_NEW,
  },
  {
    component: DistrictAdminInventoryAuditsShow,
    path: `${PATHS.INVENTORY_AUDITS}/:id`,
  },
  {
    component: AdminInventoryAuditNotFoundItems,
    path: `${PATHS.INVENTORY_AUDITS}/:id/${PATHS.INVENTORY_AUDITS_NOT_FOUND_ITEMS}`,
  },
  {
    component: DistrictAdminInvitations,
    path: `${PATHS.INVITATIONS}`,
  },
  {
    component: DistrictAdminOrganization,
    path: `${PATHS.ORGANIZATIONS}/:id`,
  },
  {
    component: AdminAdultsWithPermissionsTable,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}`,
  },
  {
    component: DistrictAdminEditParentPermissions,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}/:id/edit/${PATHS.PERMISSIONS}`,
  },
  {
    component: DistrictAdminEditDirectorPermissions,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.DIRECTORS}/:id/edit/${PATHS.PERMISSIONS}`,
  },
  {
    component: DistrictAdminOrganizations,
    path: PATHS.ORGANIZATIONS,
  },
  {
    component: AdminCreateOrganization,
    path: `${PATHS.ORGANIZATIONS}/new`,
  },
  {
    component: AdminOrganizationsClaim,
    path: `${PATHS.ORGANIZATIONS_CLAIM}`,
  },
  {
    component: JoinNewOrganization,
    path: PATHS.JOIN_NEW_ORGANIZATION,
  },
  {
    component: JoinNewOrganizationSuccess,
    path: PATHS.JOIN_NEW_ORGANIZATION_SUCCESS,
  },
  {
    component: AdminLicenses,
    path: `${PATHS.LICENSES}`,
  },
  {
    component: DistrictAdminSettings,
    path: PATHS.SETTINGS,
  },
  {
    component: DistrictAdminDistrictSettings,
    path: `${PATHS.SETTINGS}/district_settings`,
  },
  {
    component: AdminDirectorPermissions,
    path: PATHS.DIRECTOR_PERMISSIONS,
  },
  // ** LIBRARY **
  {
    component: AdminLibrary,
    path: PATHS.LIBRARY,
  },
  {
    component: AdminLibraryNew,
    path: `${PATHS.LIBRARY}/new`,
  },
  {
    component: AdminLibraryShow,
    path: `${PATHS.LIBRARY}/:id`,
  },
  {
    component: AdminLibraryEdit,
    path: `${PATHS.LIBRARY}/:id/edit`,
  },
  // ** PROFILE **
  {
    component: Profile,
    path: `${PATHS.PROFILE}`,
  },
  {
    component: AdminProfileEdit,
    path: `${PATHS.PROFILE}/edit`,
  },
  // ** UNIFORMS **
  {
    component: AdminUniforms,
    path: PATHS.UNIFORMS,
  },
  {
    component: AdminUniformsNew,
    path: `${PATHS.UNIFORMS}/new`,
  },
  {
    component: AdminUniformsShow,
    path: `${PATHS.UNIFORMS}/:id`,
  },
  {
    component: AdminUniformsEdit,
    path: `${PATHS.UNIFORMS}/:id/edit`,
  },
  // ** REPORTS **
  {
    component: DistrictAdminReports,
    path: `${PATHS.REPORTS}`,
  },
  {
    component: DistrictAdminReportsNew,
    path: `${PATHS.REPORTS}/new`,
  },
  // ** SETTINGS **
  {
    component: AdminEditDistrictInfo,
    path: PATHS.SETTINGS_DISTRICT_INFO,
  },
  // ** FEEDBACK **
  {
    component: Feedback,
    path: PATHS.FEEDBACK,
  },
  // ** FORMS **
  {
    component: AdminForms,
    path: PATHS.FORMS,
  },
  {
    component: AdminFormsEdit,
    path: `${PATHS.FORMS}/:id/edit`,
  },
  {
    component: AdminFormsNew,
    path: PATHS.FORMS_NEW,
  },
  {
    component: AdminFormShow,
    path: `${PATHS.FORMS}/:id`,
  },
  // ** BILLING **
  {
    component: AdminBilling,
    path: PATHS.BILLING,
  },
];
