// External Dependencies
import { AxiosResponse } from 'axios';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { InventoryIndexResponseItem, InventoryItemsResponse } from '@presto-assistant/api_types/api/v1/inventory';
import { getOperationName } from '@apollo/client/utilities';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import FormatListChecksIcon from 'mdi-material-ui/FormatListChecks';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TieIcon from 'mdi-material-ui/Tie';

// Internal Dependencies
import {
  ConfirmationDialog,
  DialogPermissionRequired,
  TableDataGrid,
} from 'components/shared';
import {
  DELETE_INVENTORY_ITEM,
  mapUpdateItemToIndexItem,
  useCheckInInventoryItem,
  useCheckInOneInventoryItemAtATime,
  useUpdateInventoryItem,
} from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import {
  GET_INVENTORY_ITEMS_INDEX,
  useGetDistrictOrganizations,
  useGetInventoryCategoryOptions,
  useGetInventoryItem,
  useGetOrganization,
} from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { apiClient } from 'utils/apiClient';
import { computeDataMutation } from 'components/shared/TableDataGrid/helpers';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission, isDirector } from 'state/self/selectors';
import { open } from 'state/ui/inventoryFileUploadDialog/actions';
import { pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DialogInventoryFileUpload from 'components/shared/DialogInventoryFileUpload';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import QuickCheckoutDialog from './QuickCheckoutDialog';

// Local Typings
type InventoryQueryData = InfiniteData<AxiosResponse<InventoryItemsResponse>>;

// Local Variables
const tableResource: 'inventoryItems' = 'inventoryItems';
const handleTableClickRow = (id: string) => `/${PATHS.INVENTORY}/${id}`;
const dataSelector = (response: InventoryItemsResponse) => response?.data;
const fullCountSelector = (response: InventoryItemsResponse) => response?.fullCount;

const REQUIRED_FIELDS = [
  'categoryId',
  'label',
];
  // Include any optional fields that are strings or numbers
const OPTIONAL_FIELDS = [
  // Basic Info
  'brand',
  'model',
  'serialNumber',
  'customBarcode',
  'districtNumber',
  // Status Info
  'conditionId',
  'accessories',
  'qualityId',
  'comments',
  'isMissing',
  // Location Info
  'location',
  'caseNumber',
  'locker',
  'lockNumber',
  'combination',
  // Purchase Info
  'purchaseYear',
  'purchasedAt',
  'purchaseOrderNumber',
];

// Component Definition
const InventoryTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    data: organizationsData,
  } = useGetDistrictOrganizations();

  const {
    data: categoriesData,
  } = useGetInventoryCategoryOptions();

  const handleOptimisticUpdate = useCallback(({
    variables,
  }: {
    variables: GQL.IUpdateInventoryItemOnMutationArguments
  }) => {
    const previousData = queryClient
      .getQueryData<InventoryQueryData>(
        ['inventoryItemsIndex', 'infinite'],
      );

    if (!previousData) {
      return;
    }

    const newData = {
      ...previousData,
      pages: previousData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          data: page.data.data.map((item) => {
            if (item.id === variables.id) {
              return mapUpdateItemToIndexItem({
                categories: categoriesData?.inventoryCategories ?? [],
                input: variables.input,
                item,
                organizations: organizationsData?.districtOrganizations ?? [],
              });
            }

            return item;
          }),
        },
      })),
    };

    queryClient.setQueryData<InventoryQueryData>(
      ['inventoryItemsIndex', 'infinite'],
      newData,
    );
  }, [
    queryClient,
    categoriesData,
    organizationsData,
  ]);

  const inventoryAuditsParams = useSelector(tableQueryParams('inventoryAudits'));

  const isUserDirector = useSelector(isDirector);

  const { data: orgData } = useGetOrganization();

  const [checkInInventoryItemId, setCheckInInventoryItemId] = useState<string | null>(null);
  const [checkInAllProgress, setCheckInAllProgress] = useState(0);
  const [deleteInventoryItemId, setDeleteInventoryItemId] = useState<string | null>(null);
  const [
    quickCheckoutInventoryItemId,
    setQuickCheckoutInventoryItemId,
  ] = useState<string | null>(null);
  const [showUnableToDeleteItemMessage, setShowUnableToDeleteItemMessage] = useState(false);

  const [inventoryItemsToCheckIn, setInventoryItemsToCheckIn] = useState<string[] | []>([]);
  const [isCheckingInAllInventoryItems, setIsCheckingInAllInventoryItems] = useState(false);

  const handleCloseQuickCheckoutDialog = useCallback(() => {
    setQuickCheckoutInventoryItemId(null);
  }, []);

  const handleNavigateToInventoryCheckout = useCallback((row: InventoryIndexResponseItem) => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/new?inventoryItem_id=${row.id}`);
  }, [navigate]);

  const handleNavigateToInventoryAudits = useCallback(() => {
    navigate(`/${PATHS.INVENTORY_AUDITS}${inventoryAuditsParams}`);
  }, [inventoryAuditsParams, navigate]);

  const {
    isOpen: isPermissionRequiredDialogOpen,
    toggleIsOpen: toggleIsPermissionRequiredDialogOpen,
  } = useIsOpen();

  const {
    handleClose: handleCloseCheckInAllInventoryItemsDialog,
    handleOpen: handleOpenCheckInAllInventoryItemsDialog,
    isOpen: isCheckInAllInventoryItemsDialogOpen,
  } = useIsOpen();

  const canCreateInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'write'));
  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));
  const canCreateInventory = useSelector(hasPermission('inventory', 'write'));
  const canDeleteInventory = useSelector(hasPermission('inventory', 'delete'));

  const canEditInventoryBasicInfo = useSelector(hasPermission('inventory', 'edit'));
  const canEditInventoryStatusInfo = useSelector(hasPermission('inventoryStatusInfo', 'edit'));
  const canEditInventoryLocationInfo = useSelector(hasPermission('inventoryLocationInfo', 'edit'));
  const canEditInventoryPurchaseInfo = useSelector(hasPermission('inventoryPurchaseInfo', 'edit'));
  const canEditInventoryDynamicFieldInfo = useSelector(hasPermission('inventoryDynamicFields', 'edit'));

  const showEditMode = canEditInventoryBasicInfo
    || canEditInventoryStatusInfo
    || canEditInventoryLocationInfo
    || canEditInventoryPurchaseInfo
    || canEditInventoryDynamicFieldInfo;

  const {
    data: inventoryCheckinItemData,
  } = useGetInventoryItem(checkInInventoryItemId);

  const [handleUpdateInventoryItem] = useUpdateInventoryItem(
    {
      bustCache: false,
    },
  );

  const inventoryItemToCheckIn = inventoryCheckinItemData?.inventoryItem;

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.inventory.index(queryParams);
  }, []);

  const {
    data,
    fullCount,
    isLoading,
    refetch,
  } = useInfinitePaginatedListQuery<InventoryItemsResponse, InventoryIndexResponseItem>({
    dataSelector,
    fullCountSelector,
    pageSize: 500,
    queryKey: ['inventoryItemsIndex'],
    request: fetchData,
  });

  // If there is at least one inventory item that has an active checkout,
  //  the user will be able to check in all inventory items.
  const hasAtLeastOneCheckedOutInventoryItem = useMemo(() => {
    return data?.some((inventoryItem) => inventoryItem.activeCheckouts.length > 0);
  }, [data]);

  useEffect(() => {
    // When the dialog is opened to check in all inventory items,
    //  create the list of active checkout ids.
    // This is done once the dialog opens to avoid doing an O^2 operation
    //  until that data is needed.
    if (data && isCheckInAllInventoryItemsDialogOpen) {
      const activeCheckoutIds = data.flatMap((inventoryItem) =>
        inventoryItem.activeCheckouts.map((activeCheckout) => activeCheckout.id));

      setInventoryItemsToCheckIn(activeCheckoutIds);
    }
  }, [data, isCheckInAllInventoryItemsDialogOpen]);

  const [checkInInventoryItem, {
    loading: isCheckingInInventoryItem,
  }] = useCheckInInventoryItem({
    onCompleted: () => {
      setCheckInInventoryItemId(null);

      dispatch(
        addNotification('Inventory item checked in!', 'success'),
      );

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleOpenDialogInventoryFileUpload = useCallback(() => {
    if (!canCreateInventory) {
      return toggleIsPermissionRequiredDialogOpen();
    }
    return dispatch(open());
  }, [canCreateInventory, dispatch, toggleIsPermissionRequiredDialogOpen]);

  const handleQuickCheckIn = useCallback((row: InventoryIndexResponseItem) => {
    setCheckInInventoryItemId(row.id);
  }, []);

  const handleConfirmCheckIn = useCallback(async () => {
    if (!inventoryItemToCheckIn) {
      return;
    }

    const activeCheckouts = inventoryItemToCheckIn.checkouts.filter((checkout) => checkout.active);

    if (!activeCheckouts.length) {
      dispatch(
        addNotification('Inventory item is not checked out!', 'error'),
      );
    }

    if (activeCheckouts.length > 1) {
      dispatch(
        addNotification('This item has multiple checkouts. Please check it in from the checkout page.', 'error'),
      );

      return;
    }

    checkInInventoryItem({
      variables: {
        inventoryCheckoutId: activeCheckouts[0].id,
      },
    });
  }, [
    checkInInventoryItem,
    dispatch,
    inventoryItemToCheckIn,
  ]);

  const [checkInOneInventoryItemAtATime] = useCheckInOneInventoryItemAtATime();

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    actions.push({
      action: handleOpenDialogInventoryFileUpload,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      sectionTitle: 'Import',
      text: 'Import Inventory',
    });

    if (isUserDirector) {
      actions.push({
        action: handleNavigateToInventoryAudits,
        icon: <FormatListChecksIcon />,
        sectionTitle: 'Actions',
        text: 'Inventory Audits',
      });
    }

    if (canEditInventoryCheckouts) {
      actions.push({
        action: handleOpenCheckInAllInventoryItemsDialog,
        icon: <RemoveIcon />,
        isDisabled: !hasAtLeastOneCheckedOutInventoryItem,
        text: 'Check in all inventory items',
      });
    }

    return actions;
  }, [
    canEditInventoryCheckouts,
    handleNavigateToInventoryAudits,
    handleOpenCheckInAllInventoryItemsDialog,
    handleOpenDialogInventoryFileUpload,
    hasAtLeastOneCheckedOutInventoryItem,
    isUserDirector,
  ]);

  const handleHandlePressAddInventoryItem = useCallback(() => {
    // For a user without the required create permission, show
    //  the confirmation dialog
    if (!canCreateInventory) {
      return toggleIsPermissionRequiredDialogOpen();
    }

    return null;
  }, [canCreateInventory, toggleIsPermissionRequiredDialogOpen]);

  const handleDeleteInventoryItem = useCallback((row: InventoryIndexResponseItem) => {
    if (!canDeleteInventory) {
      toggleIsPermissionRequiredDialogOpen();
    } else if (row.onLoanToOrganizationId === orgData?.organization.id) {
      setShowUnableToDeleteItemMessage(true);
    } else {
      setDeleteInventoryItemId(row.id);
    }
  }, [canDeleteInventory, orgData, toggleIsPermissionRequiredDialogOpen]);

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteInventoryItemId(null);
  }, []);

  const handleConfirmCheckInAll = useCallback(async () => {
    try {
      setIsCheckingInAllInventoryItems(true);

      // eslint-disable-next-line no-restricted-syntax
      for (const acticeCheckoutId of inventoryItemsToCheckIn ?? []) {
        if (acticeCheckoutId) {
          // eslint-disable-next-line no-await-in-loop
          await checkInOneInventoryItemAtATime({
            variables: {
              inventoryCheckoutId: acticeCheckoutId,
            },
          });

          setCheckInAllProgress((prevProgress) => prevProgress + 1);
        }
      }

      setIsCheckingInAllInventoryItems(false);

      handleCloseCheckInAllInventoryItemsDialog();

      refetch();

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));

      dispatch(
        addNotification('All inventory items checked in!', 'success'),
      );
    } finally {
      setIsCheckingInAllInventoryItems(false);
      setCheckInAllProgress(0);
    }
  }, [
    checkInOneInventoryItemAtATime,
    dispatch,
    handleCloseCheckInAllInventoryItemsDialog,
    refetch,
    inventoryItemsToCheckIn,
  ]);

  const handleCloseCheckInDialog = useCallback(() => {
    setCheckInInventoryItemId(null);
  }, []);

  const checkInAllUniformsContent = useMemo(() => (
    <>
      <DialogContentText paragraph>
        Your organization has{' '}
        <DialogContentText
          component="span"
          sx={{ fontSize: '1.2rem', fontWeight: 500 }}
          variant="body1"
        >
          {inventoryItemsToCheckIn?.length}
        </DialogContentText>{' '}
        active inventory{' '}
        {pluralize(inventoryItemsToCheckIn?.length, 'checkout')}{' '}
        right now.
      </DialogContentText>

      {isCheckingInAllInventoryItems ? (
        <>
          <DialogContentText gutterBottom>
            Checking in all inventory items...
          </DialogContentText>

          <PercentageProgressBar
            denominator={inventoryItemsToCheckIn?.length ?? 1}
            hideCaption
            label="Check in progress"
            numerator={checkInAllProgress}
          />
        </>
      ) : (
        <>
          <DialogContentText gutterBottom>
            You can check them in all at once.
          </DialogContentText>

          <DialogContentText>
            Hang on tight — this might take several seconds.
          </DialogContentText>
        </>
      )}
    </>
  ), [
    checkInAllProgress,
    isCheckingInAllInventoryItems,
    inventoryItemsToCheckIn?.length,
  ]);

  const extraColumns = useMemo<DataGridColDef<InventoryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<InventoryIndexResponseItem>([
        ...(canCreateInventoryCheckouts ? [{
          action: handleNavigateToInventoryCheckout,
          icon: <TieIcon />,
          text: 'Add inventory checkout',
        }] : []),
        ...(canEditInventoryCheckouts ? [{
          action: handleQuickCheckIn,
          icon: <RemoveIcon />,
          isDisabled: (row: InventoryIndexResponseItem) => !row.checkedOutTo,
          text: 'Quick check-in',
        }] : []),
        {
          action: handleDeleteInventoryItem,
          icon: <DeleteIcon />,
          text: 'Delete inventory item',
        },
      ]);

      return (actionsColumn ? [actionsColumn] : []) as DataGridColDef<InventoryIndexResponseItem>[];
    },
    [
      canCreateInventoryCheckouts,
      canEditInventoryCheckouts,
      handleQuickCheckIn,
      handleDeleteInventoryItem,
      handleNavigateToInventoryCheckout,
    ],
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        // We check if any edits were made. If not, we resolve
        //  the promise with the `oldRow` and avoid calling the API.
        const dataWasMutated = computeDataMutation({
          newRow,
          oldRow,
          optionalKeysToCompare: OPTIONAL_FIELDS,
          requiredKeysToCompare: REQUIRED_FIELDS,
        });

        if (dataWasMutated) {
          const {
            accessories,
            brand,
            caseNumber,
            categoryId,
            combination,
            comments,
            conditionId,
            currentValueInCents,
            customBarcode,
            districtNumber,
            id,
            isMissing,
            label,
            location,
            lockNumber,
            locker,
            model,
            onLoanToOrganizationId,
            organizationId,
            organizationTypeId,
            purchaseOrderNumber,
            purchaseValueInCents,
            purchaseYear,
            qualityId,
            quantity,
            serialNumber,
          } = newRow;

          const inputPayload: GQL.IUpdateInventoryItemInput = {
            accessories: accessories ?? '',
            brand,
            caseNumber: caseNumber ?? '',
            categoryId: String(categoryId),
            combination: combination ?? '',
            comments: comments ?? '',
            // Unsetting conditionId will be null here, but the API expects a string
            conditionId: conditionId ? String(conditionId) : '',
            currentValueInCents,
            customBarcode,
            districtNumber,
            isMissing,
            label,
            location: location ?? '',
            lockNumber: lockNumber ?? '',
            locker: locker ?? '',
            model: model ?? '',
            onLoanToOrganizationId,
            organizationId,
            organizationTypeId,
            purchaseOrderNumber: purchaseOrderNumber ?? '',
            purchaseValueInCents,
            purchaseYear,
            // Unsetting qualityId will be null here, but the API expects a string
            qualityId: qualityId ? String(qualityId) : '',
            quantity,
            serialNumber: serialNumber ?? '',
          };

          handleOptimisticUpdate({
            variables: {
              id,
              input: inputPayload,
            },
          });

          handleUpdateInventoryItem({
            variables: {
              id,
              input: inputPayload,
            },
          });

          resolve(newRow); // Update the row with the new values
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [
      handleOptimisticUpdate,
      handleUpdateInventoryItem,
    ],
  );

  const columnsArgs = useMemo(() => ({
    extraColumns,
    onClickQuickCheckout: setQuickCheckoutInventoryItemId,
    tableResource,
  }), [extraColumns]);

  const columns = useColumns(columnsArgs);

  const getIsCellEditable = useCallback((row: GridRowModel) => {
    // If an inventory item has an onLoanToOrganizationId value, the item is not editable
    //  because it is currently on loan from another organization.
    // If the current org owns that item, the item can be edited.
    return row.colDef.editable
      && row.row.onLoanToOrganizationId !== orgData?.organization.id;
  }, [orgData?.organization.id]);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            label: 'Inventory',
            onClick: canCreateInventory
              ? undefined
              : handleHandlePressAddInventoryItem,
            to: `/${PATHS.INVENTORY}/new`,
          }}
          checkboxSelection={showEditMode}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          globalEditResource="inventory_item"
          isCellEditable={getIsCellEditable}
          loading={isLoading}
          processRowUpdate={processRowUpdate}
          rows={data}
          tableResource={tableResource}
          toolbarActions={toolbarActions}
          withEditMode={showEditMode}
          withSearch
        />
      </DataGridContainer>

      <DialogInventoryFileUpload />

      <DialogPermissionRequired
        isOpen={isPermissionRequiredDialogOpen}
        onClose={toggleIsPermissionRequiredDialogOpen}
      />

      <DeleteDialog
        clearCachePredicates={['inventory']}
        context={['inventory']}
        isOpen={Boolean(deleteInventoryItemId)}
        mutation={DELETE_INVENTORY_ITEM}
        onClose={handleCloseDeleteDialog}
        reduxTableKey={tableResource}
        refetchQueries={() => [getOperationName(GET_INVENTORY_ITEMS_INDEX) as string]}
        singleItemId={deleteInventoryItemId}
        size={fullCount}
        withNote
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckIn}
        confirmButtonText="Yes, check in"
        declineButtonAction={handleCloseCheckInDialog}
        description="Are you sure you want to check in this inventory item?"
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInInventoryItem}
        open={Boolean(checkInInventoryItemId)}
        title="Check in inventory item?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckInAll}
        confirmButtonText="Yes, check in all inventory items"
        declineButtonAction={handleCloseCheckInAllInventoryItemsDialog}
        description={checkInAllUniformsContent}
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInAllInventoryItems}
        open={isCheckInAllInventoryItemsDialogOpen}
        title="Check in all inventory items?"
        useCustomText
      />

      <ConfirmationDialog
        confirmButtonAction={() => setShowUnableToDeleteItemMessage(false)}
        confirmButtonText="Ok"
        description="You are unable to delete this item because it is loaned out to your orgnaization."
        handleClose={() => setShowUnableToDeleteItemMessage(false)}
        hideDeclineButton
        open={showUnableToDeleteItemMessage}
        title="Item Loaned Out"
      />

      <QuickCheckoutDialog
        inventoryItemId={quickCheckoutInventoryItemId}
        onClose={handleCloseQuickCheckoutDialog}
      />
    </>
  );
};

export default InventoryTable;
