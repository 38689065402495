// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { GET_SELF, useGetMyFinancialFeesOverview, useGetOrganization } from 'gql/queries';
import { hasFeatureFlag } from 'state/self/selectors';
import client from 'gql/client';

export const useHasABalance = (): boolean => {
  const {
    data,
  } = useGetMyFinancialFeesOverview();

  const hasABalance = Boolean(data?.myFinancialFeesOverview.balanceDueInCentsThisYear
    || data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears);

  return Boolean(hasABalance);
};

/*
This hook is rendered in useGetOrganizationUserRoutes
We should not use hooks that make graphql requests
*/
export const useIsOnlinePaymentsFeatureAvailable = (): boolean => {
  const hasOnlinePaymentFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  // We want this to only pull from Apollo cache
  const selfResult = client.readQuery({ query: GET_SELF });

  const self: GQL.ISelf | null = selfResult?.self;

  const organizationId = self?.currentOrgId;

  const {
    data: orgData,
  } = useGetOrganization({
    // eslint-disable-next-line max-len
    skip: !organizationId, // do not remove, due to rendering this hook in useGetOrganizationUserRoutes
  });

  const organization = orgData?.organization;

  const district = organization?.district;

  const areOnlinePaymentsEnabledForDistrict = district?.areOnlinePaymentsEnabled;
  const areOnlinePaymentsEnabledForOrg = organization?.areOnlinePaymentsEnabled;

  const areOnlinePaymentsEnabled = hasOnlinePaymentFeatureFlag
    && areOnlinePaymentsEnabledForDistrict
    && areOnlinePaymentsEnabledForOrg;

  const hasStripeId = organization?.hasStripeId;
  const hasVancoPcct = organization?.hasVancoPcct;

  const hasPaymentProcessor = hasStripeId || hasVancoPcct;

  return Boolean(areOnlinePaymentsEnabled && hasPaymentProcessor);
};

export const useCanMakePayments = (): boolean => {
  const { data: orgData } = useGetOrganization();

  const hasStripe = orgData?.organization?.hasStripeId;
  const hasVancoPcct = orgData?.organization?.hasVancoPcct;

  const hasABalance = useHasABalance();

  const isOnlinePaymentsFeatureAvailable = useIsOnlinePaymentsFeatureAvailable();

  const hasPaymentProcessor = hasStripe || hasVancoPcct;

  // Temporary feature flag to hide payment button until Vanco is completely rolled out
  const canMakePayments = isOnlinePaymentsFeatureAvailable && hasABalance && hasPaymentProcessor;

  return Boolean(canMakePayments && process.env.REACT_APP_SHOW_PAYMENT_BUTTON === 'true');
};
