// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { TextFieldProps } from '@mui/material/TextField';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedContainer,
  FormActions,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import { checklistInfoSchema } from '../schema';
import ChecklistErrorAlert from '../ChecklistErrorAlert';
import ChecklistInfoFormUI from './ChecklistInfoFormUI';

// Local Typings
interface Props {
  checklistData?: GQL.IChecklist | undefined;
  checklistDataError?: string;
  inputSize: TextFieldProps['size'];
  loading: boolean;
  onSubmit: (values: GQL.ICreateChecklistInput) => void;
}

// Component Definition
const ChecklistInfoForm: FC<Props> = ({
  checklistData,
  checklistDataError,
  inputSize,
  loading,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const isNewChecklist = !checklistData;
  const checklistsParams = useSelector(tableQueryParams('checklists'));

  const handleNavigateToMembersStep = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}/${checklistData?.id}/edit/members?step=2`);
  }, [checklistData?.id, navigate]);

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}${checklistsParams}`);
  }, [checklistsParams, navigate]);

  const currentSchoolYearEnding = useGetOrganizationSchoolYear();

  const initialValues: GQL.ICreateChecklistInput = useMemo(() => ({
    emailRemindersEnabled: checklistData?.emailRemindersEnabled ?? false,
    label: checklistData?.label ?? '',
    schoolYearEnding: checklistData?.schoolYearEnding ?? currentSchoolYearEnding,
  }), [
    checklistData?.emailRemindersEnabled,
    checklistData?.label,
    checklistData?.schoolYearEnding,
    currentSchoolYearEnding,
  ]);

  const handleFormikSubmit = useCallback((
    values: GQL.ICreateChecklistInput,
  ) => {
    onSubmit(values);
  }, [onSubmit]);

  return (
    <Formik<GQL.ICreateChecklistInput>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={checklistInfoSchema}
    >
      {({
        handleSubmit,
        touched,
        // values,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <EnhancedContainer>
                <Box mb={2}>
                  <ChecklistInfoFormUI
                    // emailRemindersEnabled={values.emailRemindersEnabled}
                    inputSize={inputSize}
                  />
                </Box>

                <ChecklistErrorAlert errorMessage={checklistDataError} />

                <EnhancedCard>
                  <FormActions
                    context="Checklist"
                    isEditing={false}
                    isFormTouched={isFormTouched}
                    isSubmitting={loading}
                    onPressCancelOrBackButton={handlePressCancelOrBackButton}
                    onPressSubmit={handleNavigateToMembersStep}
                    submitButtonText="Next"
                    type={!isNewChecklist && !isFormTouched ? 'button' : 'submit'}
                  />
                </EnhancedCard>

              </EnhancedContainer>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChecklistInfoForm;
