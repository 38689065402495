// External Dependencies
import {
  FC, ReactElement, ReactNode, useCallback, useMemo,
} from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

// Internal Dependencies
import { SubscriberAddButton } from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface SecondaryActionProps {
  alwaysShowButton?: boolean;
  buttonIcon: ReactElement;
  buttonSize?: ButtonProps['size'];
  buttonText: string;
  disabled?: boolean;
  isAddButton?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  onlyShowIconOnMobile?: boolean;
  shouldUseIconButton?: boolean;
  to?: string;
}
interface Props {
  avatarElement?: ReactElement;
  primaryText: ReactNode;
  secondaryAction?: SecondaryActionProps;
  secondaryText?: ReactNode;
  secondaryTypographyProps?: {
    component?: React.ElementType;
  };
}

// Local Variables
const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
})) as typeof ListItem;

// Component Definition
const ListItemWithSecondaryAction: FC<Props> = ({
  avatarElement,
  primaryText,
  secondaryAction,
  secondaryText,
  secondaryTypographyProps = undefined,
}) => {
  const navigate = useNavigate();
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleClickSecondaryAction = useCallback(() => {
    if (secondaryAction?.to) {
      navigate(secondaryAction.to);
    } else {
      secondaryAction?.onClick?.();
    }
  // The rule wants us to pass in the entire secondaryAction object, but that
  // is not necessary. We only need to pass in the onClick function and to.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, secondaryAction?.onClick, secondaryAction?.to]);

  const button = useMemo(() => {
    if (secondaryAction?.isLoading) {
      return <CircularProgress />;
    }

    if (!secondaryAction?.alwaysShowButton
        && (isMobileOrTabletScreen || secondaryAction?.shouldUseIconButton)) {
      return (
        <IconButton
          color="primary"
          disabled={secondaryAction?.disabled}
          edge="end"
          onClick={handleClickSecondaryAction}
          size="large"
        >
          {secondaryAction?.buttonIcon}
        </IconButton>
      );
    }

    if (secondaryAction?.isAddButton) {
      return (
        <SubscriberAddButton
          disabled={secondaryAction?.disabled}
          label={secondaryAction.buttonText}
          onClick={handleClickSecondaryAction}
        />
      );
    }

    return (
      <Button
        color="primary"
        disabled={secondaryAction?.disabled}
        onClick={handleClickSecondaryAction}
        size={secondaryAction?.buttonSize ?? 'small'}
        startIcon={secondaryAction?.onlyShowIconOnMobile ? undefined : secondaryAction?.buttonIcon}
        variant="outlined"
      >
        {secondaryAction?.buttonText}
      </Button>
    );
  }, [
    handleClickSecondaryAction,
    isMobileOrTabletScreen,
    secondaryAction?.alwaysShowButton,
    secondaryAction?.buttonIcon,
    secondaryAction?.buttonSize,
    secondaryAction?.buttonText,
    secondaryAction?.disabled,
    secondaryAction?.isAddButton,
    secondaryAction?.isLoading,
    secondaryAction?.onlyShowIconOnMobile,
    secondaryAction?.shouldUseIconButton,
  ]);

  return (
    <StyledListItem>
      {avatarElement}

      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        secondaryTypographyProps={secondaryTypographyProps}
      />

      {secondaryAction && (
        <Box>
          {button}
        </Box>
      )}
    </StyledListItem>
  );
};

export default ListItemWithSecondaryAction;
