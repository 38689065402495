// External Dependencies
import { FC, useCallback } from 'react';
import { FormTypes } from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import Button from '@mui/material/Button';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrict } from 'gql/queries';
import Collapse from '@mui/material/Collapse';

// Component Definition
const InventoryCheckoutFormAlert: FC = () => {
  const navigate = useNavigate();

  const handleClickCreateCheckoutForm = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS_NEW}?form_type_id=${FormTypes.InventoryCheckout}`);
  }, [navigate]);

  const {
    data: districtData,
    loading: isDistrictLoading,
  } = useGetDistrict();

  const hasInventoryCheckoutForm = Boolean(districtData?.district.inventoryCheckoutFormId);

  return (
    <Collapse in={!isDistrictLoading && !hasInventoryCheckoutForm}>
      <EnhancedAlert
        action={(
          <Button onClick={handleClickCreateCheckoutForm}>
            Add Checkout Form
          </Button>
        )}
        sx={{
          marginBottom: 2,
        }}
      >
        Inventory checkout forms are now available!
      </EnhancedAlert>
    </Collapse>
  );
};

export default InventoryCheckoutFormAlert;
