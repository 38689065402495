// External Dependencies
import { Form, Formik } from 'formik';
import {
  useMemo,
} from 'react';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

// Internal Dependencies
import { useGetFinancialItem, useGetOrganization } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import FinancialItemSelect from 'components/shared/Selectors/FinancialItemSelect';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Dependencies
import {
  StyledAddButton, StyledChip, StyledSubtitle,
} from './styles';

// Local Typings
interface Props {
  financialItemId?: string;
}
interface FormikValues {
  financialItemId: string;
  schoolYearEnding: number;
}

// Local Variables
const initialValues: FormikValues = {
  financialItemId: '',
  schoolYearEnding: 0,
};

// Component Definition
const PaymentFormItem = ({ financialItemId }: Props): JSX.Element => {
  const navigate = useNavigate();

  const { isOpen, toggleIsOpen } = useIsOpen();

  const { data: orgData } = useGetOrganization();

  const { data: itemData, loading: isLoadingItem } = useGetFinancialItem(financialItemId);

  const hasAddedItem = Boolean(financialItemId);

  const handleFormikSubmit = async (
    values: FormikValues,
  ) => {
    navigate(`?financial_item_id=${values.financialItemId}`);
    toggleIsOpen();
  };

  const chipElement = useMemo(
    () => {
      const itemLabel = itemData?.financialItem.label;
      const accountLabel = itemData?.financialItem.financialAccount?.label;

      const label = accountLabel ? `${itemLabel} (${accountLabel})` : itemLabel;

      return (
        <StyledChip
          label={isLoadingItem ? <CircularProgress size={20} /> : label}
          variant="outlined"
        />
      );
    },
    [itemData, isLoadingItem],
  );

  return (
    <>
      <StyledSubtitle
        component="h2"
        variant="subtitle1"
      >
        Select an item
      </StyledSubtitle>

      {hasAddedItem ? (
        <>
          {chipElement}

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            onClick={toggleIsOpen}
            type="button"
            variant="body2"
          >
            Change
          </Link>
        </>
      ) : (
        <StyledAddButton
          label="Item"
          onClick={toggleIsOpen}
        />
      )}

      <Dialog
        disablePortal
        fullWidth
        maxWidth="sm"
        onClose={toggleIsOpen}
        open={isOpen}
      >
        <DialogTitle>
          Select an item
        </DialogTitle>

        <Formik<FormikValues>
          enableReinitialize
          initialValues={{
            ...initialValues,
            schoolYearEnding: orgData?.organization?.currentSchoolYearEnding
              ?? initialValues.schoolYearEnding,
          }}
          onSubmit={handleFormikSubmit}
        >
          {({
            values: formikValues,
          }) => (
            <Form>
              <DialogContent>
                <SchoolYearSelect
                  fullWidth={false}
                  name="schoolYearEnding"
                  required
                  variant="filled"
                />

                {/* The 416 width is how long our 50-character max will be */}
                <Box maxWidth="416px">
                  <FinancialItemSelect
                    name="financialItemId"
                    schoolYearEnding={Number(formikValues.schoolYearEnding)}
                    variant="filled"
                  />
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  onClick={toggleIsOpen}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  disabled={!formikValues.schoolYearEnding || !formikValues.financialItemId}
                  type="submit"
                  variant="contained"
                >
                  Next
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default PaymentFormItem;
