// External Dependencies
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WidgetsIcon from '@mui/icons-material/Widgets';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { useGetMyInventoryCheckouts } from 'gql/queries';

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const InventoryCheckoutsCard = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      history,
    },
  } = useTheme();

  const handleNavigateToMyInventoryCheckouts = () => {
    navigate(`/${PATHS.STUDENT_INVENTORY}`);
  };

  const { data } = useGetMyInventoryCheckouts();

  const zeroStateIcon = (
    <ZeroStateIcon
      Icon={WidgetsIcon}
      htmlColor={history.timelineDot}
    />
  );

  const inventoryCheckoutsCount = data?.myInventoryCheckouts.inventoryCheckouts.length;

  const content = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          You have
          {' '}
          <StyledStrong>{inventoryCheckoutsCount}</StyledStrong>
          {' '}
          inventory {pluralize(inventoryCheckoutsCount, 'item')}
          {' '}
          checked out.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToMyInventoryCheckouts}
        size="small"
        variant="outlined"
      >
        See checkouts
      </Button>
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Inventory Checkouts"
      verticallyCenterContent
    />
  );
};

export default InventoryCheckoutsCard;
